import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory('lessonBookendMixin', [
    '$injector',
    function factory($injector) {
        const scopeTimeout = $injector.get('scopeTimeout');

        return (scope, elem) => {
            Object.defineProperty(scope, 'lesson', {
                get() {
                    return scope.playerViewModel.lesson;
                },
            });

            // Because of the nested animations, regular old ng-animate
            // strategies do not work.  We manually handle the ng-enter
            // here to make it all work.  It's terrible
            if (!elem.addClass('ng-enter-hacked-up')) {
                throw new Error('Lesson bookends must be initialized with the ng-enter-hacked-up class already there');
            }

            // hacking around the fact that it is hard to
            // animate the children of the element with an ng-if
            // using ng-animate
            scope.startAnimation = () => {
                scopeTimeout(
                    scope,
                    () => {
                        elem.removeClass('ng-enter-hacked-up');
                    },
                    50,
                );
            };
        };
    },
]);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import template from 'Lessons/angularModule/views/lesson/frame_list/frame/componentized/component/challenge_overlay_blank/challenge_overlay_blank.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('cfChallengeOverlayBlank', [
    'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentDirHelper',
    'Lesson.FrameList.Frame.Componentized.Component.Challenge.ChallengeBlankDirHelper',
    (UiComponentDirHelper, ChallengeBlankDirHelper) =>
        UiComponentDirHelper.getOptions({
            templateUrl,
            link(scope) {
                UiComponentDirHelper.link(scope);
                ChallengeBlankDirHelper.link(scope);

                Object.defineProperty(scope, 'cssClasses', {
                    get() {
                        const classes = [];
                        classes.push(scope.model.contentType);

                        if (scope.frameViewModel.editorMode) {
                            classes.push('editable');
                        }

                        if (scope.model.hasBackground) {
                            classes.push('has_background');
                        }
                        if (this.viewModel.challengeViewModel.type === 'MultipleChoiceChallengeViewModel') {
                            classes.push('multiple_choice');
                        }

                        if (this.viewModel.challengeViewModel.type === 'UserInputChallengeViewModel') {
                            classes.push('user_input');
                        }

                        if (!this.viewModel.complete && !this.viewModel.active) {
                            classes.push('activatable');
                        }

                        // stretchToFit will always be true, except in the
                        // editor when the 'Reset Dimensions'
                        // button was just pressed
                        if (this.viewModel.stretchToFit) {
                            classes.push('stretch-to-fit');
                        }

                        if (this.model.invisible) {
                            classes.push('hide');
                        }

                        // challengeBlankCssClasses comes from ChallengeBlankDirHelper
                        return classes.concat(this.challengeBlankCssClasses);
                    },
                });

                Object.defineProperty(scope, 'challengeBlankFontSize', {
                    get() {
                        let fontSize;
                        // wrap in try/catch in case any of these are undefined
                        try {
                            fontSize = this.model.text.fontSize;
                        } catch (e) {}
                        return fontSize;
                    },
                });

                scope.setChallengeActive = () => {
                    if (!scope.viewModel.challengeViewModel.complete) {
                        scope.viewModel.challengeViewModel.active = true;
                    }
                };

                scope.isDisabled = () =>
                    scope.viewModel.challengeViewModel.complete || scope.viewModel.challengeViewModel.active;
            },
        }),
]);

/* eslint-disable no-nested-ternary */
import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import template from 'Lessons/angularModule/views/lesson/frame_list/frame/componentized/component/continue_button/continue_button.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('cfAlwaysReadyContinueButton', [
    '$injector',
    $injector => {
        const UiComponentDirHelper = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentDirHelper',
        );
        const SoundManager = $injector.get('SoundManager');
        const SoundConfig = $injector.get('SoundConfig');

        return UiComponentDirHelper.getOptions({
            // Note: the template here is shared between this directive and challengesContinueButton
            templateUrl,
            link(scope) {
                UiComponentDirHelper.link(scope);

                // This should really be view model logic, but see
                // comment in ChallengesContinueButton
                scope.frameViewModel.continueButtonVisible = true;
                scope.showButton = true;
                scope.showContinueButton = true;
                scope.showPracticeModeContinueButtons = scope.frameViewModel.isPractice;

                scope.buttonClasses = () => ['green'];

                scope.lessonType = scope.frameViewModel?.playerViewModel?.lesson?.test
                    ? 'exam'
                    : scope.frameViewModel?.playerViewModel?.lesson?.assessment
                    ? 'assessment'
                    : 'lesson';

                scope.onContinueButtonClick = () => {
                    if (scope.frameViewModel.playerViewModel.activeFrame !== scope.viewModel.frame) {
                        return;
                    }

                    SoundManager.playUrl(SoundConfig.DEFAULT_CLICK);

                    scope.frameViewModel.gotoNextFrame();
                };
            },
        });
    },
]);

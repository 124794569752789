import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import template from 'Lessons/angularModule/views/lesson/frame_list/frame/componentized/component/answer_list/answer_list_buttons.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('cfAnswerListButtons', [
    'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentDirHelper',
    'Lesson.FrameList.Frame.Componentized.Component.AnswerList.AnswerButtonsDirHelper',
    (UiComponentDirHelper, AnswerButtonsDirHelper) => ({
        restrict: 'E',
        templateUrl,

        link(scope) {
            Object.defineProperty(scope, 'buttonWrapperClassesPlus', {
                get() {
                    if (this.model.skin === 'matching') {
                        return [];
                    }
                    return scope.buttonWrapperClasses;
                },
            });

            AnswerButtonsDirHelper.setupButtonClassWatch(scope, {
                getComponents() {
                    return this.model.answers;
                },

                useSingleColumn() {
                    return this.model.useSingleColumn();
                },
            });
        },
    }),
]);

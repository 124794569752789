
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.choose_institution = window.Smartly.locales.modules.en.back_royal.choose_institution || {};
window.Smartly.locales.modules.en.back_royal.choose_institution.choose_institution = window.Smartly.locales.modules.en.back_royal.choose_institution.choose_institution || {};
window.Smartly.locales.modules.en.back_royal.choose_institution.choose_institution = {...window.Smartly.locales.modules.en.back_royal.choose_institution.choose_institution, ...{
    "choose_your_institution": "Choose Your Institution",
    "degree_heading_quantic": "MBA and Executive MBA",
    "degree_heading_valar": "MBA and Executive MBA",
    "description_quantic_1": "Quantic is highly selective with a rigorous yet flexible {degreeDurationMonths}-month curriculum.",
    "description_quantic_2": "Join influential leaders from renowned companies in its world-class global alumni network.",
    "description_valar_1": "Valar democratizes access with more affordable {degreeDurationMonths}-month degree programs.",
    "description_valar_2": "Its more inclusive admissions cultivate larger, diverse global cohorts of ambitious leaders.",
    "apply_now": "Apply Now"
}
}
    
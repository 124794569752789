import { NON_INSTITUTIONAL_SUBDOMAINS } from 'PedagoDomainConstants';

/*
    Return the subdomain. For example, if the user is at
    jll.smart.ly, then 'id' will be equal to 'jll'.
*/
// FIXME: We may consider renaming this to be externalInstitutionSubdomain
// See: https://trello.com/c/QFVa6l5W
export default angular.module('institutionalSubdomain', []).factory('institutionalSubdomain', [
    '$injector',
    () => {
        // This code is written without any angular dependencies
        // for easy copy-pasting into marketing index page

        // http://stackoverflow.com/questions/2090551/parse-query-string-in-javascript
        function getQueryVariable(variable) {
            const query = window.location.search.substring(1);
            const vars = query.split('&');
            for (let i = 0; i < vars.length; i++) {
                const pair = vars[i].split('=');
                if (decodeURIComponent(pair[0]) === variable) {
                    return decodeURIComponent(pair[1]);
                }
            }
            return undefined;
        }

        function getInstitutionalSubdomain() {
            let id = null;
            // only match something.smart.ly
            const match = window.location.hostname.match(/(\w+)\.smart\.ly/);

            if (match) {
                id = match[1];
            }

            // 'app', 'cn', etc. are not institutional subdomains
            if (NON_INSTITUTIONAL_SUBDOMAINS.includes(id)) {
                id = null;
            }

            // Local testing does not work using a real subdomain, so
            // allow it to be added as a query param
            const queryParam = getQueryVariable('subdomain');
            let source;
            if (!id && queryParam) {
                id = queryParam;
                source = 'queryParam';
            } else {
                source = 'subdomain';
            }
            return {
                id,
                source,
            };
        }

        return getInstitutionalSubdomain();
    },
]);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.Text.Behaviors.ProcessesModals', [
    'AModuleAbove',

    AModuleAbove =>
        new AModuleAbove({
            included(TextModel) {
                TextModel.supportBehavior('ProcessesModals');
            },
        }),
]);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.ChallengeValidator.Behaviors.HasNoUnexpectedAnswers',
    [
        'AModuleAbove',
        'Lesson.FrameList.Frame.Componentized.Component.ChallengeValidator.ValidationError.HasUnexpectedAnswer',
        (AModuleAbove, HasUnexpectedAnswer) =>
            new AModuleAbove({
                included(ChallengeValidatorModel) {
                    ChallengeValidatorModel.supportBehavior('HasNoUnexpectedAnswers');

                    // set up a callback to be run whenever a ViewModel is initialized
                    ChallengeValidatorModel.ViewModel.setCallback('after', 'initialize', function () {
                        this.model.on('behavior_added:HasNoUnexpectedAnswers', () => {
                            // do not reference challengeViewModel here.  Since we're still in the
                            // initialize calling viewModelFor will cause a max call stack error
                            // reuse HasNoUnexpectedAnswers for compose blanks but with a different expected answer matcher == .matches method
                            if (!this.model.expectedAnswerMatchers) {
                                throw new Error('HasNoUnexpectedAnswers requires an "expectedAnswerMatchers" list.');
                            }

                            this.validations.push(function (errors, challengeResponses) {
                                challengeResponses.forEach(challengeResponse => {
                                    if (!challengeResponse.matchesAny(this.model.expectedAnswerMatchers)) {
                                        errors.push(
                                            new HasUnexpectedAnswer(challengeResponse.answer, challengeResponse.text),
                                        );
                                    }
                                });
                            });
                        });
                    });
                },
            }),
    ],
);

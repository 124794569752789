import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import { describeChallengesComponentForTutorbot, getFallbackTutorbotDescriptionForChallengesComponent } from 'TutorBot';

/*

    Challenges has a list of challenges.  It determines which one of them
    is active at any particular time and then delegates the determination of which components
    should be on the screen to the active challenge.

    Challenges supports a certain list of states needed by the
    ChallengesContinueButton

    Example:

    {
        "id": "bdd00fc8-71ad-471c-aeb5-fe51dc764413",
        "component_type": "ComponentizedFrame.Challenges",
        "layout_id": "e2201326-e648-4a81-9a8d-b8afc4ee2a15",
        "challenge_ids": [
            "f7a7aa6a-6801-4551-8e82-7b92282fb7b3",
            "fc7d3324-7150-4585-91cd-0759416ec545"
        ],
        "behaviors": {
            "GotoNextOnChallengeComplete": {},
            "CompleteOnAllChallengesComplete": {}
        }
    },

*/
angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.Challenges.ChallengesModel', [
    '$injector',
    $injector => {
        const UiComponentModel = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentModel',
        );
        const ChallengesViewModel = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.Challenges.ChallengesViewModel',
        );
        const GotoNextOnChallengeComplete = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.Challenges.Behaviors.GotoNextOnChallengeComplete',
        );
        const CompleteOnAllChallengesComplete = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.Challenges.Behaviors.CompleteOnAllChallengesComplete',
        );
        const RandomizeChallengeOrder = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.Challenges.Behaviors.RandomizeChallengeOrder',
        );
        const GotoNextFrameOnComplete = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.Challenges.Behaviors.GotoNextFrameOnComplete',
        );
        const TextModel = $injector.get('Lesson.FrameList.Frame.Componentized.Component.Text.TextModel');
        const ErrorLogService = $injector.get('ErrorLogService');

        return UiComponentModel.subclass(function () {
            this.alias('ComponentizedFrame.Challenges');
            this.extend({
                ViewModel: ChallengesViewModel,
            });
            this.setEditorViewModel(
                'Lesson.FrameList.Frame.Componentized.Component.Challenges.ChallengesEditorViewModel',
            );

            this.include(GotoNextOnChallengeComplete);
            this.include(CompleteOnAllChallengesComplete);
            this.include(RandomizeChallengeOrder);
            this.include(GotoNextFrameOnComplete);

            this.references('challenges').through('challenge_ids');
            this.references('layout').through('layout_id');

            // optional.  use when all challenges should share the same content for some section
            this.references('sharedContentForText').through('shared_content_for_text_id');
            this.references('sharedContentForFirstImage').through('shared_content_for_image_id');
            this.references('sharedContentForSecondImage').through('shared_content_for_image_2_id');
            this.references('sharedContentForInteractive').through('shared_content_for_interactive_id');
            this.references('sharedContentForInteractiveImage').through('shared_content_for_interactive_image_id');

            this.key('context_image_size');
            this.key('context_image_2_size');

            const getImageContext = function (key) {
                const sizeKey = {
                    sharedContentForFirstImage: 'context_image_size',
                    sharedContentForSecondImage: 'context_image_2_size',
                }[key];

                const size = this[sizeKey];

                return this.layout.getImageContext(size);
            };
            this.setImageContext('sharedContentForFirstImage', getImageContext);
            this.setImageContext('sharedContentForSecondImage', getImageContext);

            // Used outside when the frame interfaces with things in the editor.
            // For switching between componentized and non-componentized
            // frame types and for populating the thumbnails
            Object.defineProperty(this.prototype, 'mainTextComponent', {
                get() {
                    // warning: if this ever changes such that it does not just map to staticContentForText,
                    // see _updateMainTextComponentConfig in editor view model
                    if (this.sharedContentForText && this.sharedContentForText.isA(TextModel)) {
                        return this.sharedContentForText;
                    }
                    return undefined;
                },
                set(val) {
                    this.editorViewModel.mainTextComponent = val;
                },
            });

            // tested at Lessons/angularModule/spec/lesson/frame_list/frame/tutorBotDescription.spec.ts
            Object.defineProperty(this.prototype, 'tutorBotDescription', {
                get() {
                    if (!this.$$tutorBotDescription) {
                        const extra = {
                            frameId: this.frame().id,
                            lessonId: this.frame().lesson().id,
                        };
                        try {
                            this.$$tutorBotDescription = describeChallengesComponentForTutorbot(this);
                        } catch (e1) {
                            ErrorLogService.notifyInProd(e1, null, extra);
                            try {
                                // If we run into an error trying to describe all the challenges, then at
                                // least include the main text
                                this.$$tutorBotDescription = getFallbackTutorbotDescriptionForChallengesComponent(this);
                            } catch (e2) {
                                ErrorLogService.notifyInProd(e2, null, extra);
                                this.$$tutorBotDescription = null;
                            }
                        }
                    }
                    return this.$$tutorBotDescription;
                },
                configurable: true,
            });

            return {
                // In order to prevent people on test lessons from completing a frame
                // and then refreshing before they have been moved on to the next frame,
                // we update the frameBookmarkId once the frame is completed. Note that
                // no_incorrect_answer frames are not complete until the learner clicks to
                // move on.
                savesProgressOnComplete: true,

                initialize($super, attrs) {
                    // eslint-disable-next-line no-prototype-builtins
                    if (!attrs.hasOwnProperty('challenge_ids')) {
                        attrs.challenge_ids = [];
                    }
                    $super(attrs);
                },
            };
        });
    },
]);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.UserInputMessage.UserInputMessageViewModel',

    [
        'Lesson.FrameList.Frame.Componentized.Component.ComponentViewModel',
        'Lesson.FrameList.Frame.Componentized.Component.ComponentEventListener',

        (ComponentViewModel, ComponentEventListener) =>
            ComponentViewModel.subclass(() => ({
                initialize($super, frameViewModel, model) {
                    $super(frameViewModel, model);
                    this._createListeners();
                },

                _createListeners() {
                    this._listenForValidated();
                },

                _listenForValidated() {
                    new ComponentEventListener(
                        this.challengeViewModel,
                        'validatedCorrect',
                        validationResult => {
                            const message = this._showMessage();
                            validationResult.addMessage(message);
                        },
                        {
                            priority: 1,
                        },
                    );
                },

                _clearMessage() {
                    this.playerViewModel.clearMessage();
                },

                _showMessage() {
                    this.playerViewModel.showMessage({
                        content: '<cf-text view-model="textViewModel"></cf-text>',
                        scope: {
                            textViewModel: this.frameViewModel.viewModelFor(this.model.messageText),
                        },
                        correctExists: true,
                        correct: true,
                    });

                    return this.model.messageText;
                },
            })),
    ],
);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.InteractiveCards.InteractiveCardsViewModel',

    [
        'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentViewModel',
        'Lesson.FrameList.Frame.Componentized.Component.ComponentEventListener',

        (UiComponentViewModel, ComponentEventListener) =>
            UiComponentViewModel.subclass(function () {
                Object.defineProperty(this.prototype, 'currentCard', {
                    get() {
                        return this.model.overlays[this.currentIndex];
                    },
                });

                Object.defineProperty(this.prototype, 'hasPrev', {
                    get() {
                        return !!this.model.overlay_ids[this.currentIndex - 1];
                    },
                });

                Object.defineProperty(this.prototype, 'hasNext', {
                    get() {
                        return !!this.model.overlay_ids[this.currentIndex + 1];
                    },
                });

                return {
                    directiveName: 'cf-interactive-cards',
                    currentIndex: 0,

                    initialize($super, frameViewModel, model) {
                        $super(frameViewModel, model);

                        const activatedListeners = {};
                        this.model.on(
                            '.overlays[].overlayComponents[]:set:challenge',
                            challenge => {
                                // be defensive against a challenge being removed and added (not sure
                                // if this will ever happer), so we don't have multiple listeners for
                                // the same event.
                                if (challenge && !activatedListeners[challenge.id]) {
                                    const challengeViewModel = this.viewModelFor(challenge);
                                    if (challengeViewModel.active) {
                                        this.showCardForChallenge(challenge);
                                    }

                                    activatedListeners[challenge.id] = new ComponentEventListener(
                                        challengeViewModel,
                                        'activated',
                                        this.showCardForChallenge.bind(this, challenge),
                                    );
                                }
                            },
                            true,
                        );

                        this.model.on('.overlays[].text:set:formatted_text', () => {
                            this.fire('interactiveCards:shouldSetCardHeights');
                        });

                        this.model.on('.overlays[]:set:background_color', () => {
                            this.fire('interactiveCards:shouldSetCardHeights');
                        });
                    },

                    goBack() {
                        return this.move(-1);
                    },

                    goForward() {
                        return this.move(1);
                    },

                    move(dir) {
                        const newIndex = this.currentIndex + dir;
                        if (newIndex >= 0 && this.model.overlays[newIndex]) {
                            this.currentIndex = newIndex;
                        }
                    },

                    challengeOnCurrentCard(challenge) {
                        return this.model.cardIndexForChallenge(challenge) === this.currentIndex;
                    },

                    showCardForChallenge(challenge) {
                        this.currentIndex = this.model.cardIndexForChallenge(challenge);
                    },

                    // tested in the directive test
                    activateSomeChallengeOnCurrentCard() {
                        const self = this;
                        const challengeViewModel = _.chain(this.currentCard.overlayComponents)
                            .map('challenge')
                            .map(challenge => self.viewModelFor(challenge))
                            // prefer to activate an incomplete challenge
                            .sortBy(challengeViewModel => (challengeViewModel.complete ? 1 : 0))
                            .first()
                            .value();

                        if (challengeViewModel) {
                            challengeViewModel.active = true;
                        }
                    },
                };
            }),
    ],
);

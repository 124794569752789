import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import 'ExtensionMethods/array';

angularModule.factory('Lesson.FrameList.Frame.Componentized.ComponentizedBaseViewModel', [
    '$injector',

    $injector => {
        const FrameViewModel = $injector.get('Lesson.FrameList.FrameViewModel');
        const ComponentModel = $injector.get('Lesson.FrameList.Frame.Componentized.Component.ComponentModel');

        return FrameViewModel.subclass(function () {
            this.whoAmI = 'ComponentizedBaseViewModel';

            Object.defineProperty(this.prototype, 'continueButtonViewModel', {
                get() {
                    const continueButton = this.frame.continueButton;
                    if (!continueButton && !this.editorMode) {
                        throw new Error('No continue button found on frame');
                    }
                    if (continueButton) {
                        return this.viewModelFor(continueButton);
                    }
                    return undefined;
                },
            });

            Object.defineProperty(this.prototype, 'complete', {
                get() {
                    throw new Error('Subclasses of ComponentizedBaseViewModel must implement complete');
                },
                configurable: true,
            });

            // in order to handle some
            // logging in scope.$digest, angular was trying to toJson the
            // frameViewModel.  The circular reference in _viewModels was then throwing an error.
            // This removes _viewModels  from the enumerable properties, alowing it to
            // be toJsonned.
            Object.defineProperty(this.prototype, '_viewModels', {
                get() {
                    return this.$$viewModels;
                },
                set(val) {
                    this.$$viewModels = val;
                },
            });

            return {
                initialize($super, frame, options) {
                    $super(frame, options);
                    this.bodyBackgroundColor = 'white'; // can be set by a view model's initialize function
                    this.continueButtonVisible = false;
                    this._viewModels = {};

                    this.callbacks = {
                        completed: [],
                        giveHint: [],
                    };

                    // initialize all viewModels
                    this.frame.components.forEach(component => {
                        this.viewModelFor(component);
                    });
                },

                setBackgroundColor() {
                    if (!this.editorMode) {
                        this.playerViewModel.setBodyBackground(this.bodyBackgroundColor);
                    }
                },

                destroy($super) {
                    $super();
                    this.$$_completedEventListener?.cancel();
                },

                viewModelFor(model) {
                    return this.viewModelsFor([model])[0];
                },

                viewModelsFor(models) {
                    const viewModels = [];
                    angular.forEach(models, model => {
                        if (!model || !model.isA || !model.isA(ComponentModel)) {
                            console.error('Not a ComponentModel: ', model);
                            throw new Error(
                                `Cannot create ComponentViewModel for something which is not a ComponentModel: ${model}`,
                            );
                        }
                        if (model.frame() !== this.frame) {
                            const modelFrameId = model.frame() ? model.frame().id : 'undefined';
                            throw new Error(
                                `Cannot create viewModel for component that is unrelated to this frame. model.frame() = ${modelFrameId}, model.id = ${model.id}`,
                            );
                        }

                        // if we don't have a viewModel yet, and this component supports ViewModels, create one
                        if (!this._viewModels[model.id] && model.constructor.ViewModel) {
                            this._viewModels[model.id] = model.createViewModel(this);
                        }
                        viewModels.push(this._viewModels[model.id]);
                    });
                    return viewModels;
                },

                on(event, callback) {
                    this.callbacks[event].push(callback);
                },

                off(event, callback) {
                    Array.remove(this.callbacks[event], callback);
                },

                gotoNextFrame() {
                    const nextFrameId = this.viewModelFor(this.frame.frameNavigator).nextFrameId;
                    if (!this.editorMode && this.playerViewModel.logProgress) {
                        const historyLen = this.playerViewModel.frameHistory.length;
                        if (this.playerViewModel.frameHistory[historyLen - 1] !== this.frame.id) {
                            this.playerViewModel.frameHistory.push(this.frame.id);
                        }
                    }
                    if (nextFrameId) {
                        this.playerViewModel.gotoFrameId(nextFrameId);
                    } else {
                        this.playerViewModel.gotoNext();
                    }
                },

                _triggerCallbacks(event) {
                    // grab all the arguments after 'event' so we can pass them into
                    // the callback
                    // eslint-disable-next-line prefer-rest-params
                    const args = Array.prototype.slice.call(arguments, 1);
                    const callbacks = this.callbacks[event];
                    if (!callbacks) {
                        throw new Error(`Callbacks not supported for event "${event}"`);
                    }
                    callbacks.forEach(callback => {
                        callback(...args);
                    });
                },
            };
        });
    },
]);

export default angular.module('FrontRoyal.Institutions', []).factory('Institution', [
    'Iguana',
    Iguana =>
        Iguana.subclass(function () {
            this.setCollection('institutions');
            this.alias('Institution');
            this.setIdProperty('id');

            this.setCallback('after', 'copyAttrsOnInitialize', function () {
                this.playlist_pack_ids = this.playlist_pack_ids || [];
                this.reports_viewer_ids = this.reports_viewer_ids || [];
                this.users = this.users || [];
                this.external = this.external === undefined ? true : this.external;
            });

            this.extend({
                // FIXME: Move this to AppBrandConfig when it becomes needed, probably in https://trello.com/c/Y6tzpA2t
                //
                // When Marketing decides that a particular institution needs a disclaimer field in the
                // app footer, add a new locale to the `dashboard_footer-en.json` file and map the
                // institution's ID to that new locale key below.
                DISCLAIMER_LOCALE_KEY_MAP: {
                    // '85fec419-8dc5-45a5-afbd-0cc285a595b9': 'disclaimer_quantic',
                },
            });

            Object.defineProperty(this.prototype, 'createdAt', {
                get() {
                    return new Date(this.created_at * 1000);
                },
            });

            Object.defineProperty(this.prototype, 'updatedAt', {
                get() {
                    return new Date(this.updated_at * 1000);
                },
            });

            Object.defineProperty(this.prototype, 'groupNamesString', {
                get() {
                    return _.map(this.groups, 'name').join(', ');
                },
            });

            Object.defineProperty(this.prototype, 'disclaimerLocaleKey', {
                get() {
                    return this.constructor.DISCLAIMER_LOCALE_KEY_MAP[this.id];
                },
            });

            return {};
        }),
]);

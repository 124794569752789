import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*
    UiComponent inherits from Component.  Any component that is rendered on the screen as
    a UI element should inherit from UiComponent and define 'directiveName', which indicates
    the directive to place on the screen.
*/
angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentModel', [
    'Lesson.FrameList.Frame.Componentized.Component.ComponentModel',
    'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentViewModel',
    (ComponentModel, UiComponentViewModel) =>
        ComponentModel.subclass(function () {
            this.alias('Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentModel');
            this.extend({
                ViewModel: UiComponentViewModel,
            });

            return {};
        }),
]);

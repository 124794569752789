import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.ChallengeValidator.ValidationResult.MultipleChoiceValidationResult',

    [
        'Lesson.FrameList.Frame.Componentized.Component.ChallengeValidator.ValidationResult',

        ValidationResult =>
            ValidationResult.subclass(() => ({
                filterAnswersViewModels(query) {
                    query = angular.extend(
                        {
                            selected: null,
                            correct: null,
                        },
                        query,
                    );

                    const answerViewModels = [];
                    this.challengeViewModel.answersViewModels.forEach(answerViewModel => {
                        // filter out answers with the wrong selected value
                        if (query.selected !== null && answerViewModel.selected !== query.selected) {
                            return;
                        }

                        // filter out incorrect answers if asking for only correct ones
                        if (query.correct === true && this.hasErrorRelatedToAnswer(answerViewModel.model)) {
                            return;
                        }

                        // filter out correct answers if asking for only incorrect ones
                        if (query.correct === false && !this.hasErrorRelatedToAnswer(answerViewModel.model)) {
                            return;
                        }

                        answerViewModels.push(answerViewModel);
                    });

                    return answerViewModels;
                },
            })),
    ],
);

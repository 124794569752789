import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import template from 'Lessons/angularModule/views/lesson/show_lesson.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('showLesson', [
    '$injector',
    function factory($injector) {
        const SiteMetadata = $injector.get('SiteMetadata');
        const $timeout = $injector.get('$timeout');
        const ContentAccessHelper = $injector.get('ContentAccessHelper');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                playerViewModel: '<',
            },
            link(scope) {
                scope.$watch('playerViewModel', playerViewModel => {
                    if (!playerViewModel) {
                        return;
                    }
                    playerViewModel.linkToWindow($injector);

                    SiteMetadata.updateHeaderMetadata({
                        title: scope.playerViewModel.lesson.entity_metadata.title,
                        description: scope.playerViewModel.lesson.entity_metadata.description,
                        path: scope.playerViewModel.lesson.entity_metadata.canonical_url,
                        image: scope.playerViewModel.lesson.entity_metadata.imageSrc(),
                    });
                });

                scope.$watch('playerViewModel.replaceMeWithThisPlayerViewModel', newPlayerViewModel => {
                    if (newPlayerViewModel) {
                        scope.playerViewModel = null;

                        // Make sure the old lesson play is all cleaned up before creating the
                        // new one.  See https://trello.com/c/Vr4zonno/834-bug-setting-activeframe-on-destroyed-player-view-model
                        $timeout(0).then(() => {
                            scope.playerViewModel = newPlayerViewModel;
                        });
                    }
                });

                scope.$watchGroup(['playerViewModel.lesson', 'playerViewModel.regularPlayerMode'], () => {
                    if (scope.playerViewModel && scope.playerViewModel.regularPlayerMode) {
                        new ContentAccessHelper(scope.playerViewModel.lesson).showModalIfCannotLaunch();
                    }
                });
            },
        };
    },
]);

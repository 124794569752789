import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import template from 'Lessons/angularModule/views/lesson/frame_list/frame/componentized/component/component_overlay/component_overlay.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('cfComponentOverlay', [
    '$injector',

    $injector => {
        const UiComponentDirHelper = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentDirHelper',
        );
        const $timeout = $injector.get('$timeout');
        const $window = $injector.get('$window');
        const isMobile = $injector.get('isMobile');

        return UiComponentDirHelper.getOptions({
            templateUrl,
            link(scope, elem) {
                UiComponentDirHelper.link(scope);

                scope.classesFor = function (overlayComponentViewModel) {
                    const options = this.viewModel.optionsFor(overlayComponentViewModel);
                    const classes = [];

                    // we only support % units for this feature, but that's
                    // all we ever use anyway
                    if (options.units === '%') {
                        // FIXME: if we ever need them, we can add left-half, top-half, close-to-bottom-edge, etc.
                        const distanceFromLeft = options.x;
                        const distanceFromRight = 100 - (options.x + options.width);
                        // we add the 'in-right-half-of-overlay' class if the right edge is closer
                        // to the right than the left edge is from the left
                        if (distanceFromRight < distanceFromLeft) {
                            classes.push('in-right-half-of-overlay');
                        }
                    }
                    return classes;
                };

                scope.previousScale = 1;
                let rescaleAgainTimer;

                scope.onClickContentWrapper = evt => {
                    // if someone clicked outside of one of the overlays (presumably
                    // by accident), then fire an event down.  In the future we may
                    // want to indicate what the closest one was, but is not 100% necessary
                    // right now for the compose blanks on image case, which is the one
                    // causing trouble.
                    if (
                        $(evt.target).closest('[positionable]').length === 0 ||
                        $(evt.target).closest('[disabled]').length > 0
                    ) {
                        scope.$broadcast('component_overlay:click_outside_any_overlay');
                    }
                };

                // If the component_overlay uses an image, and it needs to be scaled
                // down, then we use css transform so that everything will be scaled down
                // together

                // This isn't tested anywhere because it's hard/impossible to test things
                // that rely on element sizing and css
                let cancelLoading;
                scope.$emit('frame:rendering', scope.$id);

                scope.rescale = event => {
                    function showAndEmitRendered() {
                        elem.show();
                        scope.$emit('frame:rendered', scope.$id);
                    }

                    $timeout.cancel(rescaleAgainTimer);

                    // We support text in component_overlay now, so only do this scaling when an image is
                    // present, and be sure to call show since we hid the element prior to calling this.
                    if (!(scope.model.image && scope.model.image.image)) {
                        // We may have set a transform and height inline if we're in the editor and this
                        // component_overlay was previously an image. Make sure we unset those if we are
                        // not an image anymore so that we aren't trying to transform markdown content.
                        elem.css({
                            '-webkit-transform': '',
                            '-o-transform': '',
                            transform: '',
                            height: '',
                        });

                        showAndEmitRendered();
                        return;
                    }

                    const mainImage = scope.model.image;
                    const imageEl = elem.find(`.main_image [component-id="${mainImage.id}"] img`);

                    // - poll for the image to show up
                    // - elem.width() check seems only necessary on ios/Chrome
                    //   when going back to a previous frame.  See
                    //   https://trello.com/c/sSSAoYKU/357-bug-interactive-image-fails-to-size-properly-when-backtracking-to-screen-fin-1-2-screen-8-iphone-5-ios-8-3-chrome-mobile
                    if (imageEl.length === 0 || elem.width() === 0) {
                        rescaleAgainTimer = $timeout(scope.rescale, 200);
                        return;
                    }

                    // If the image is not loaded yet, wait for it to load.  Reproduce this
                    // issue by refreshing the window on an interactive cards frame
                    if (imageEl[0].naturalHeight === 0) {
                        // if we've already set up the load listener, just wait for it
                        if (cancelLoading) {
                            return;
                        }
                        cancelLoading = () => {
                            imageEl.off('load.componentOverlay.scale');
                        };
                        imageEl.one('load.componentOverlay.scale', () => {
                            scope.rescale(undefined); // do not pass the event through (see hack below)
                        });
                        return;
                    }

                    // stop hiding once we've rescaled once
                    showAndEmitRendered();

                    /*
                        Notes on scaling:

                        What we need to calculate here is how much the component-overlay element
                        needs to be scaled down from how it looked to the content author  when he/she was
                        working on it in the editor.

                        The thing that's really confusing about all this is that it's likely
                        the image was already scaled down at that point, since it has a max-height
                        and a max-width in the editor.  But that scaling does not concern us here.  Here
                        we only care about how much we need to scale down the component-overlay to make
                        it fit on the screen given the size we have right now.

                        So we need to figure out, how big was the image when the editor was
                        looking at it in the editor, and how big do we want it to be now?  Then
                        we need to get it down to the desired size by scaling the whole
                        component-overlay element, because that will ensure that all the overlays
                        are scaled down the same and the fonts still are the same size relative
                        to the main image.
                    */

                    // The max size of images in the editor has always been 700x275.  If that
                    // ever changes, this will get real confusing.
                    const maxWidthInEditor = 700;
                    const maxHeightInEditor = 275;
                    let heightOfImageInEditor;
                    let widthOfImageInEditor;
                    const aspectRatio = imageEl[0].naturalWidth / imageEl[0].naturalHeight;

                    //  wide image
                    if (aspectRatio > maxWidthInEditor / maxHeightInEditor) {
                        widthOfImageInEditor = Math.min(700, imageEl[0].naturalWidth);
                        heightOfImageInEditor =
                            (widthOfImageInEditor / imageEl[0].naturalWidth) * imageEl[0].naturalHeight;
                    }
                    // tall image
                    else {
                        heightOfImageInEditor = Math.min(275, imageEl[0].naturalHeight);
                        widthOfImageInEditor =
                            (heightOfImageInEditor / imageEl[0].naturalHeight) * imageEl[0].naturalWidth;
                    }

                    // FIXME?: do we want to limit to 200 on mobile?
                    const availableHeight = isMobile() ? 200 : 275;
                    const availableWidth = elem[0].clientWidth;

                    // determine how much we would have to scale down in order
                    // to fit horizontally and vertically.  The actual
                    // scaling is whichever is smaller
                    const vScale = Math.min(availableHeight / heightOfImageInEditor, 1);
                    const hScale = Math.min(availableWidth / widthOfImageInEditor, 1);
                    const scale = Math.min(vScale, hScale);

                    // console.log('scale ', {
                    //     availableHeight,
                    //     availableWidth,
                    //     naturalWidth: imageEl[0].naturalWidth,
                    //     naturalHeight: imageEl[0].naturalHeight,
                    //     vScale,
                    //     hScale,
                    //     widthOfImageInEditor,
                    //     elemWidth: elem.width(),
                    //     elemClientWidth: elem[0].clientWidth,
                    //     scale
                    // });

                    if (!scale || scope.previousScale === scale) {
                        return;
                    }

                    scope.previousScale = scale;

                    if (!scope.originalHeight) {
                        scope.originalHeight = elem[0].clientHeight;
                    }

                    // Stop scaling the image down using regular css
                    const translateValue = `scale(${scale})`;

                    // Scale the element using transform, so that text scales down as well
                    elem.css({
                        '-webkit-transform': translateValue,
                        '-o-transform': translateValue,
                        transform: translateValue,
                    });

                    // we also need to change the height of the element
                    // so that the buttons below it show up in the right place
                    elem.height(scale * scope.originalHeight);

                    // This is awful, but the resize event fires before the browser has actually
                    // responded and resized the image (at least in mobile safari).  One second
                    // later, check again to see if it has changed (Only call this when this function
                    // was kicked off by a browser event to avoid infinite loop)
                    if (event) {
                        rescaleAgainTimer = $timeout(scope.rescale, 1000);
                    }
                };

                elem.hide(); // hide the element until we've scaled it
                scope.rescale();
                scope.$on('frame_container_resized', () => scope.rescale());

                scope.$watch('viewModel.imageViewModel.model.image', () => {
                    scope.previousScale = 1;
                    scope.rescale();
                });

                scope.$on('$destroy', () => {
                    $($window).off(`resize.${scope.model.id}`);
                    $timeout.cancel(rescaleAgainTimer);
                    if (cancelLoading) {
                        cancelLoading();
                    }
                });
            },
        });
    },
]);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.ChallengeOverlayBlank.ChallengeOverlayBlankModel',
    [
        '$injector',
        $injector => {
            const UiComponentModel = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentModel',
            );
            const ChallengeOverlayBlankViewModel = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.ChallengeOverlayBlank.ChallengeOverlayBlankViewModel',
            );
            const HasTextOrImage = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.Mixins.HasTextOrImage',
            );

            return UiComponentModel.subclass(function () {
                this.alias('ComponentizedFrame.ChallengeOverlayBlank');
                this.extend({
                    ViewModel: ChallengeOverlayBlankViewModel,
                });
                this.setEditorViewModel(
                    'Lesson.FrameList.Frame.Componentized.Component.ChallengeOverlayBlank.ChallengeOverlayBlankEditorViewModel',
                );

                this.include(HasTextOrImage);
                this.setImageContext('image', 'challengeOverlayBlank');
                this.references('challenge').through('challenge_id');
                this.key('invisible'); // used in multiple_choice_w_multiple_cards

                Object.defineProperty(this.prototype, 'displayName', {
                    value: 'Challenge Blank',
                });

                return {};
            });
        },
    ],
);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import template from 'Lessons/angularModule/views/lesson/frame_list/frame/componentized/component/image/image.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('cfImage', [
    '$injector',
    $injector => {
        const UiComponentDirHelper = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentDirHelper',
        );
        const $sce = $injector.get('$sce');

        return UiComponentDirHelper.getOptions({
            templateUrl,
            link(scope) {
                UiComponentDirHelper.link(scope);

                Object.defineProperty(scope, 'src', {
                    get() {
                        const src = scope.viewModel.model.urlForContext(scope.context);
                        return $sce.trustAs($sce.RESOURCE_URL, src);
                    },
                });
            },
        });
    },
]);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.Text.Behaviors.ProcessesStorableImages', [
    '$injector',
    $injector => {
        const AModuleAbove = $injector.get('AModuleAbove');

        return new AModuleAbove({
            included(TextModel) {
                // This behavior has been deprecated and its functionality moved into
                // ProcessesInlineImages.  But it's too much work to go back and remove the
                // reference to the behavior in all existing content
                TextModel.supportBehavior('ProcessesStorableImages');
            },
        });
    },
]);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import template from 'Lessons/angularModule/views/lesson/lesson_finish_screen_footer.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { gsap } from 'FrontRoyalGsap';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('lessonFinishScreenFooter', [
    '$injector',
    function factory($injector) {
        const $interval = $injector.get('$interval');
        const $window = $injector.get('$window');
        const Capabilities = $injector.get('Capabilities');
        const ContentAccessHelper = $injector.get('ContentAccessHelper');
        const TranslationHelper = $injector.get('TranslationHelper');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                playerViewModel: '<',
            },
            link(scope, elem) {
                const translationHelper = new TranslationHelper('lessons.lesson.lesson_finish_screen_footer');

                const animationDuration = 0.5;

                scope.$watch('playerViewModel', () => {
                    if (scope.playerViewModel.lessonFailed) {
                        scope.continueButtonDisplay = 'retrySmartcase';
                    } else if (ContentAccessHelper.canLaunch(scope.lesson.nextLessonInStream)) {
                        scope.continueButtonDisplay = 'nextLesson';
                    } else {
                        scope.continueButtonDisplay = 'streamDashboard';
                    }
                });

                Object.defineProperty(scope, 'isNextLessonSmartcase', {
                    get() {
                        return scope.lesson?.nextLessonInStream?.assessment && !scope.lesson?.nextLessonInStream?.test;
                    },
                });

                scope.feedback = {
                    feedbackIsPositive: undefined,
                    feedbackReasons: undefined,
                    feedbackAdditionalThoughts: undefined,
                };
                scope.feedbackSubmitted = false;
                scope.feedbackState = 'show-request';

                scope.goodReasons = _.shuffle([
                    translationHelper.get('useful'),
                    translationHelper.get('fun'),
                    translationHelper.get('fast'),
                    translationHelper.get('easy'),
                    translationHelper.get('challenging'),
                ]);
                scope.badReasons = _.shuffle([
                    translationHelper.get('not_useful'),
                    translationHelper.get('too_easy'),
                    translationHelper.get('too_hard'),
                    translationHelper.get('boring'),
                    translationHelper.get('confusing'),
                ]);

                // store references to all the elements we're going
                // to animate in local variables to save extra lookups later
                const widthableElement = elem.find('.lesson-finish-screen-footer');
                const feedback = elem.find('.feedback');
                const giveFeedback = feedback.find('.give-feedback');
                const feedbackForm = feedback.find('.feedback-form');
                const thumbs = feedback.find('.thumbs');
                const feedbackFormReason = feedback.find('.feedback-form-reason');
                const feedbackFormDetails = feedback.find('.feedback-form-details');
                const feedbackSubmitted = feedback.find('.feedback-submitted');

                Object.defineProperty(scope, 'lesson', {
                    get() {
                        return scope.playerViewModel && scope.playerViewModel.lesson;
                    },
                });

                /*
                      - feedbackState starts at show-request.
                      - Once thumbs-up or (god forbid) thumbs-down is
                        clicked, the state changes to show-reason
                      - Once a reason is selected, the state changes to show-form
                      - Once 'submit' is clicked, the state changes to
                        submitted.
                      (Note: if touch is enabled, we immediately send the feedback
                      once the state switches to show-form, which skips us ahead
                      to submitted)
                    */
                Object.defineProperty(scope, 'feedbackState', {
                    get() {
                        if (angular.isDefined(scope.feedback.feedbackIsPositive)) {
                            if (scope.feedbackSubmitted) {
                                return 'submitted';
                            }
                            return angular.isDefined(scope.feedback.feedbackReasons) ? 'show-form' : 'show-reason';
                        }
                        return 'show-request';
                    },
                });

                scope.toggledReasons = [];

                scope.isToggled = reason => scope.toggledReasons.includes(reason);

                scope.toggleReason = reason => {
                    const index = scope.toggledReasons.indexOf(reason);
                    if (index > -1) {
                        scope.toggledReasons.splice(index, 1);
                    } else {
                        scope.toggledReasons.push(reason);
                    }
                };

                scope.submitReasons = () => {
                    scope.feedback.feedbackReasons = scope.toggledReasons;
                };

                scope.sendFeedback = withStateTransition => {
                    scope.playerViewModel.log('lesson:feedback', scope.feedback);
                    if (withStateTransition) {
                        scope.feedbackSubmitted = true;
                    }
                };

                function show(_elem, duration, onComplete) {
                    if (duration === undefined) {
                        duration = animationDuration;
                    }

                    const startOpts = {
                        opacity: 0,
                        display: 'none',
                    };

                    const endOpts = {
                        duration,
                        opacity: 1,
                        display: 'inline-block',
                        ease: 'power2.out',
                    };

                    if (onComplete) {
                        endOpts.onComplete = onComplete;
                    }

                    gsap.fromTo(_elem, startOpts, endOpts);
                }

                function hide(_elem, duration, onComplete) {
                    if (duration === undefined) {
                        duration = animationDuration;
                    }

                    const startOpts = {
                        opacity: 1,
                        display: 'inline-block',
                    };

                    const endOpts = {
                        duration,
                        opacity: 0,
                        display: 'none',
                        ease: 'power2.out',
                    };

                    if (onComplete) {
                        endOpts.onComplete = onComplete;
                    }

                    gsap.fromTo(_elem, startOpts, endOpts);
                }

                function setFeedbackPositions() {
                    // Initial state is show-request
                    if (scope.feedbackState === 'show-request') {
                        // Right now, the text 'Give feedback on this lesson' is visible,
                        // as well as the two thumb buttons. Center those elements.
                        const contentWidth = giveFeedback.width() + thumbs.width();
                        const contentHeight = giveFeedback.height();
                        const thumbsHeight = thumbs.height();

                        const giveFeedbackOffsetX = widthableElement.width() / 2 - contentWidth / 2;
                        const giveFeedbackOffsetY = (thumbsHeight - contentHeight) / 2;

                        // Position the give feedback (initial message) in the center
                        gsap.set(giveFeedback, {
                            x: giveFeedbackOffsetX,
                            y: giveFeedbackOffsetY,
                        });

                        // Positiong in the feedback form to the right, such that the thumbs are correctly positioned
                        gsap.set(feedbackForm, {
                            x: giveFeedbackOffsetX + giveFeedback.outerWidth(),
                        });

                        // hide the rest of the states for later
                        hide(feedbackFormDetails, 0);
                        hide(feedbackFormReason, 0);
                        hide(feedbackSubmitted, 0);
                    }

                    // After a thumb is clicked, then state changes to show-reason.
                    else if (scope.feedbackState === 'show-reason') {
                        hide(giveFeedback, 0);

                        // Translate the feedback form to the left
                        gsap.to(feedbackForm, {
                            duration: 0.3,
                            x: 0,
                        });

                        // Fade-in the reasons form
                        show(feedbackFormReason);
                    }

                    // After a reason is clicked, then state changes to show-form. (If on
                    // a touch device, we will then immediately switch to feedback-submitted)
                    else if (scope.feedbackState === 'show-form') {
                        // If not on a touch device, center the feedback form on the screen
                        // and fade in the text box and the submit button.
                        if (!Capabilities.touchEnabled) {
                            // Fade-in the reasons form
                            hide(feedbackFormReason, undefined, () => {
                                show(feedbackFormDetails);
                            });

                            // Otherwise, move directly to the submitted state and send feedback
                        } else {
                            scope.sendFeedback(true); // switch to submitted state
                        }
                    }

                    // After the submit button is clicked (or after a thumb is clicked
                    // on a touch device_ drop the form away and drop the thank you message in
                    else if (scope.feedbackState === 'submitted') {
                        // NOTE: display tweens (since there are no intermediate values between block/inline/inline-block etc and none) occur at
                        // the start of the tween if the display value is not 'none', and at the end if it is 'none'.

                        // hide the initial feedback form if for some reason it's still hanging around (possible?)
                        hide(giveFeedback, 0);

                        // hide the feedback form
                        hide(feedbackForm, animationDuration, () => {
                            const xPos = widthableElement.width() / 2 - feedbackSubmitted.outerWidth() / 2;

                            gsap.set(feedbackSubmitted, {
                                x: xPos,
                                display: 'inline-block',
                            });

                            gsap.fromTo(
                                feedbackSubmitted,
                                {
                                    opacity: 0,
                                    x: xPos,
                                    y: '-=100',
                                },
                                {
                                    duration: animationDuration,
                                    opacity: 1,
                                    x: xPos,
                                    y: 0,
                                    ease: 'power2.out',
                                },
                            );
                        });
                    }
                }

                function startWatchingFeedbackState() {
                    // use opacity instead of hide()/show() so that we
                    // can set hide/show in the css without having it overridden
                    feedback.css({
                        opacity: 1,
                    });
                    scope.$watch('feedbackState', setFeedbackPositions);
                }

                // use opacity instead of hide()/show() so that we
                // can set hide/show in the css without having it overridden
                feedback.css({
                    opacity: 0,
                });

                const clearInterval = $interval(() => {
                    if (widthableElement.width() > 0) {
                        startWatchingFeedbackState();
                        $interval.cancel(clearInterval);
                    }
                }, 10);

                $($window).on(`resize.${scope.$id}`, setFeedbackPositions);
                scope.$on('$destroy', () => {
                    $($window).off(`resize.${scope.$id}`);
                    $interval.cancel(clearInterval);

                    // Send partial feedback if it isn't totally blank and it hasn't already been sent
                    if (scope.feedbackState !== 'submitted' && scope.feedbackState !== 'show-request') {
                        scope.sendFeedback(false);
                    }
                });
            },
        };
    },
]);

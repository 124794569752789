import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.AnswerList.AnswerListViewModel', [
    '$injector',
    $injector => {
        const UiComponentViewModel = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentViewModel',
        );
        const ComponentEventListener = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.ComponentEventListener',
        );
        const ClientStorage = $injector.get('ClientStorage');

        return UiComponentViewModel.subclass(function () {
            Object.defineProperty(this.prototype, 'orderedAnswerViewModels', {
                get() {
                    return this._orderedAnswerViewModels || this.answersViewModels;
                },
                set(val) {
                    this._orderedAnswerViewModels = val;
                    return val;
                },
            });

            Object.defineProperty(this.prototype, 'currentChallengeViewModel', {
                get() {
                    // if the current challenge has been removed from the frame, then it is
                    // no longer the current one
                    if (this._currentChallengeViewModel && this._currentChallengeViewModel.frame !== this.frame) {
                        this.currentChallengeViewModel = undefined;
                    }
                    return this._currentChallengeViewModel;
                },
                set(val) {
                    if (val === this._currentChallengeViewModel) {
                        return val;
                    }

                    this._currentChallengeViewModel = val;
                    this.fire('set:currentChallengeViewModel', val);

                    if (this._validatedListener) {
                        this._validatedListener.cancel();
                    }

                    if (this._currentChallengeViewModel) {
                        this._validatedListener = new ComponentEventListener(
                            this._currentChallengeViewModel,
                            'validated',
                            validationResult => {
                                this.fire('validated', validationResult);
                            },
                        );
                    }

                    return val;
                },
            });

            Object.defineProperty(this.prototype, 'disallowedAnswerOrder', {
                get() {
                    return this._disallowedAnswerOrder;
                },
                set(val) {
                    if (_.isEqual(val, this.disallowedAnswerOrder)) {
                        return val;
                    }

                    this._disallowedAnswerOrder = val;

                    // this should really not be fired on the model, but sadly
                    // we don't have any way of firing viewModel events: see https://trello.com/c/lToCDWh7/196-event-listening-module-non-scope-non-dom
                    this.model.triggerCallbacks('set:disallowedAnswerOrder', val);
                    return val;
                },
            });

            return {
                directiveName: 'cf-answer-list',

                initialize($super, frameViewModel, model) {
                    $super(frameViewModel, model);
                    if (this.model.skin === 'poll') {
                        frameViewModel.bodyBackgroundColor = 'blue';
                    }
                },

                // set all answerViewModels' 'selected' property to false
                unselectAnswers() {
                    this.answersViewModels.forEach(answerViewModel => {
                        answerViewModel.selected = false;
                    });
                },

                currentlySelectedAnswerViewModel() {
                    const selectedAnswerViewModels = [];
                    this.answersViewModels.forEach(answerViewModel => {
                        if (answerViewModel.selected) {
                            selectedAnswerViewModels.push(answerViewModel);
                        }
                    });

                    if (selectedAnswerViewModels.length > 1) {
                        throw new Error(
                            'Found multiple selected answers when we were assuming we could only have one.',
                        );
                    }
                    return selectedAnswerViewModels[0];
                },

                // TODO: if we need it, could also add currentlySelectedAnswers method here

                currentlySelectedAnswerIndex() {
                    return this.answersViewModels.indexOf(this.currentlySelectedAnswerViewModel());
                },

                getAnswerViewModelIndex(answerViewModel) {
                    if (this.orderedAnswerViewModels) {
                        return this.orderedAnswerViewModels.indexOf(answerViewModel);
                    }
                    return undefined;
                },

                selectAnswerByIndex(index) {
                    if (this.orderedAnswerViewModels && this.orderedAnswerViewModels[index]) {
                        this.orderedAnswerViewModels[index].selected = true;
                    }
                },

                isCorrectForCurrentChallenge(answerViewModel) {
                    if (!this.currentChallengeViewModel) {
                        return false;
                    }

                    return _.includes(this.currentChallengeViewModel.correctAnswerViewModels, answerViewModel);
                },

                // for each answerViewModel:
                // - set selected to false
                // - resetCssClasses
                // - re-enable
                clearStates() {
                    this.unselectAnswers();
                    this.answersViewModels.forEach(answerViewModel => {
                        answerViewModel.resetCssClasses();
                    });
                },

                hasAnswerMessageFor(answerViewModel) {
                    return !!this.answerMessageFor(answerViewModel);
                },

                messageComponentFor(answerViewModel) {
                    if (!this.currentChallengeViewModel) {
                        return null;
                    }

                    const challenge = this.currentChallengeViewModel.model;
                    const messageComponent = challenge.messageComponentFor(answerViewModel.model);
                    return messageComponent;
                },

                messageViewModelFor(answerViewModel) {
                    const messageComponent = this.messageComponentFor(answerViewModel);
                    if (
                        messageComponent &&
                        messageComponent.messageText &&
                        messageComponent.messageText.formatted_text
                    ) {
                        return this.viewModelFor(messageComponent.messageText);
                    }
                    return null;
                },

                answerMessageFor(answerViewModel) {
                    const messageComponent = this.messageComponentFor(answerViewModel);

                    if (
                        messageComponent &&
                        messageComponent.messageText &&
                        messageComponent.messageText.formatted_text
                    ) {
                        return messageComponent.messageText.formatted_text;
                    }
                    return undefined;
                },

                shouldShowAnswerMessageIcons(answerViewModel) {
                    return (
                        this.editorMode &&
                        ClientStorage.getItem('showEditorMessageIcons') !== 'false' &&
                        this.hasAnswerMessageFor(answerViewModel)
                    );
                },

                shouldShowCorrectIncorrectIcons() {
                    if (!this.currentChallengeViewModel) {
                        return false;
                    }
                    return this.editorMode && ClientStorage.getItem('showCorrectIncorrectIcons') !== 'false';
                },

                correctIncorrectIconsClasses(answerViewModel) {
                    return this.isCorrectForCurrentChallenge(answerViewModel) ? 'fa fa-check' : 'fa fa-times';
                },

                usageReportAnswerCount(answerViewModel) {
                    if (!this.currentChallengeViewModel) {
                        return undefined;
                    }

                    // Pull the usage report off of the challenge model
                    const challengeId = this.currentChallengeViewModel.model.id;
                    let firstAnswerCounts;
                    try {
                        firstAnswerCounts = this.frame.dereference(challengeId).usage_report.first_answer_counts;
                    } catch (e) {
                        return undefined;
                    }

                    // Initialize a local cache of responses and
                    // initialize an entry in it for the current challenge
                    this._answerCounts = this._answerCounts || {};
                    this._answerCounts[challengeId] = this._answerCounts[challengeId] || {};
                    const challengeEntry = this._answerCounts[challengeId];

                    // If there is not already an entry for this answer/challenge
                    // combination, then create one
                    if (!challengeEntry[answerViewModel.model.id]) {
                        // Count up the total number of answers for
                        // this challenge (If not there already)
                        if (angular.isUndefined(challengeEntry.total)) {
                            let total = 0;
                            angular.forEach(firstAnswerCounts, count => {
                                total += count;
                            });
                            challengeEntry.total = total;
                        }

                        // If there is not much data yet,
                        // don't show anything
                        if (challengeEntry.total < 20) {
                            return undefined;
                        }

                        // calculate stuff
                        const count = firstAnswerCounts[answerViewModel.model.id] || 0;
                        const percent = count / challengeEntry.total;
                        const formattedPercent = `${Math.round(100 * percent)}%`;
                        challengeEntry[answerViewModel.model.id] = {
                            count,
                            percent,
                            formattedPercent,
                        };
                    }

                    return challengeEntry[answerViewModel.model.id];
                },
            };
        });
    },
]);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.directive('cfUiComponent', [
    () => ({
        restrict: 'E',

        // we need replace false here so that the view-model attr is not overridden when
        // passing it down
        replace: false,

        scope: {
            viewModel: '<',
            context: '<',
        },

        template:
            '<div \
               observe="viewModel.model.id"\
               dynamic-node="viewModel.directiveName"\
               view-model="viewModel"\
               component-id="{{viewModel.model.id}}"\
               context="context"\
               ></div>',
    }),
]);

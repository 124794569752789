import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*
    When this behavior is on, all selected answers will be unselected before
    selecting a new answer.
*/
angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.Behaviors.DisallowMultipleSelect',
    [
        'AModuleAbove',
        'Lesson.FrameList.Frame.Componentized.Component.ComponentEventListener',
        (AModuleAbove, ComponentEventListener) =>
            new AModuleAbove({
                included(MultipleChoiceChallengeModel) {
                    MultipleChoiceChallengeModel.supportBehavior('DisallowMultipleSelect');

                    MultipleChoiceChallengeModel.ViewModel.setCallback('after', 'initialize', function () {
                        const challengeViewModel = this;
                        let listeners = [];

                        const clearListeners = () => {
                            listeners.forEach(l => l.cancel());
                            listeners = [];
                        };

                        this.model.on('behavior_added:DisallowMultipleSelect', () => {
                            clearListeners();

                            function onAnswerListSet(answerList) {
                                if (!answerList) {
                                    return;
                                }
                                const answerAddedListener = answerList.answers.on('childAdded', answer => {
                                    const answerViewModel = challengeViewModel.viewModelFor(answer);

                                    const answerSelectedListener = new ComponentEventListener(
                                        answerViewModel,
                                        'beforeSelected',
                                        () => {
                                            // if this is the active challenge, then unselect all answers before
                                            // selecting a new one
                                            if (challengeViewModel.active) {
                                                challengeViewModel.answerListViewModel.unselectAnswers();
                                            }
                                        },
                                    );
                                    listeners.push(answerSelectedListener);
                                });
                                listeners.push(answerAddedListener);
                            }

                            // whenever an answer list is added, watch for answers to be
                            // added to it, and then listen for each answer to be selected
                            if (challengeViewModel.model.answerList) {
                                onAnswerListSet(challengeViewModel.model.answerList);
                            }

                            const answerListListener = challengeViewModel.model.on('set:answerList', onAnswerListSet);
                            listeners.push(answerListListener);
                        });

                        this.model.on('behavior_removed:DisallowMultipleSelect', () => {
                            clearListeners();
                        });
                    });
                },
            }),
    ],
);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import template from 'Lessons/angularModule/views/lesson/frame_list/frame/componentized/component/interactive_cards/interactive_cards.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('cfInteractiveCards', [
    '$injector',
    $injector => {
        const UiComponentDirHelper = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentDirHelper',
        );
        const $timeout = $injector.get('$timeout');
        const Capabilities = $injector.get('Capabilities');
        const $window = $injector.get('$window');
        const safeApply = $injector.get('safeApply');

        return UiComponentDirHelper.getOptions({
            templateUrl,
            link(scope, elem) {
                UiComponentDirHelper.link(scope);
                let activateChallengeTimeout;

                scope.getOverlayClasses = (interactiveCardsViewModel, overlayViewModel) => {
                    const classes = {
                        card: true,
                    };

                    if (overlayViewModel.model.contentType) {
                        const key = `${overlayViewModel.model.contentType}-card`;
                        classes[key] = true;
                    }

                    if (overlayViewModel.model.background_color) {
                        const key = `background-${overlayViewModel.model.background_color}`;
                        classes[key] = true;
                        classes['has-background'] = true; // see interactive_cards.scss
                    }

                    if (interactiveCardsViewModel.currentCard === overlayViewModel.model) {
                        classes.active = true;
                    }

                    if (interactiveCardsViewModel.model.force_card_height) {
                        classes['force-card-height'] = true;
                    } else {
                        classes['full-height'] = true;
                    }

                    if (interactiveCardsViewModel.model.wide) {
                        classes.wide = true;
                    }

                    return classes;
                };

                // When content is right-to-left, we reverse the carousel direction
                scope.reverseDirection =
                    scope.viewModel &&
                    scope.viewModel.playerViewModel &&
                    scope.viewModel.playerViewModel.lesson &&
                    scope.viewModel.playerViewModel.lesson.localeDirection === 'rtl';
                const reverseMultiplier = scope.reverseDirection ? -1 : 1;

                // Listen for each challenge to be completed, and when it is:
                // 1. if the next challenge's blank is visible on the current card,
                //    then activate it
                // 2. otherwise, go to the card that has the next challenge's blank
                //    and then, once the transition is complete, activate it.
                //
                // I tried to make this a behavior, but it was tricky because it needs to know when
                // the transition is done.  In any case, it seems easy enough to get the flexibility
                // we need here.
                scope.model.on('.challengesComponent.challenges:childAdded', challenge => {
                    const challengesViewModel = scope.viewModel.challengesComponentViewModel;
                    const challengeViewModel = scope.viewModel.viewModelFor(challenge);

                    const delay = {
                        ChallengeModel: 0,
                        MultipleChoiceChallengeModel: 1500,
                        UserInputChallengeModel: 0,
                    }[challenge.type];

                    if (angular.isUndefined(delay)) {
                        throw new Error(`Cannot determine delay for "${challenge.type}"`);
                    }

                    if (activateChallengeTimeout) {
                        $timeout.cancel(activateChallengeTimeout);
                    }

                    challengeViewModel.on('completed', () => {
                        // in preview mode, the other window may have already moved us on
                        // to the next challenge
                        if (challengeViewModel.completedHandledByInteractiveCardsScope) {
                            return;
                        }
                        challengeViewModel.completedHandledByInteractiveCardsScope = scope.$id;

                        const nextChallengeViewModel = challengesViewModel.nextChallengeViewModel;

                        if (!nextChallengeViewModel) {
                            return;
                        }

                        /*
                            It's important that we don't use a timeout if delay
                            is 0, because we have to activate the next challenge
                            synchronously to prevent losing the keyboard in mobile
                            safari.
                        */
                        if (delay && delay > 0) {
                            activateChallengeTimeout = $timeout(() => {
                                scope.activateChallenge(nextChallengeViewModel);
                            }, delay);
                        } else {
                            scope.activateChallenge(nextChallengeViewModel);
                        }
                    });
                });

                scope.onAfterSlideChange = () => {
                    if (scope.nextChallengeViewModel) {
                        scope.nextChallengeViewModel.active = true;
                        scope.nextChallengeViewModel = undefined;
                    } else {
                        const currentChallenge =
                            scope.viewModel.challengesComponentViewModel.currentChallengeViewModel &&
                            scope.viewModel.challengesComponentViewModel.currentChallengeViewModel.model;
                        if (currentChallenge && !scope.viewModel.challengeOnCurrentCard(currentChallenge)) {
                            scope.viewModel.activateSomeChallengeOnCurrentCard();
                        }
                    }
                    safeApply(scope);
                };

                scope.activateChallenge = nextChallengeViewModel => {
                    if (scope.viewModel.challengeOnCurrentCard(nextChallengeViewModel.model)) {
                        nextChallengeViewModel.active = true;
                    } else {
                        scope.viewModel.showCardForChallenge(nextChallengeViewModel.model);
                        scope.nextChallengeViewModel = nextChallengeViewModel;
                    }
                    safeApply(scope);
                };

                // We need to update and translate the carousel when...
                scope.$watch('model.force_card_height', () => updateAndTranslateCarousel()); // an editor toggles force_card_height
                scope.$watch('model.wide', () => updateAndTranslateCarousel()); // an editor toggles wide
                scope.$watchCollection('model.overlay_ids', () => updateAndTranslateCarousel()); // an editor adds or removes an overlay
                scope.viewModel.on('interactiveCards:shouldSetCardHeights', () => updateAndTranslateCarousel()); // an editor changes text content
                scope.$on('frame:rendered', () => updateAndTranslateCarousel()); // component_overlay_dir rescales an image
                scope.$watch(
                    () => getWidth(),
                    () => updateAndTranslateCarousel(),
                ); // the window is resized

                // Once we know the carousel is in the DOM, set it on scope and set
                // up the image load listeners.
                scope.$watch(
                    () => elem.find('.carousel')[0],
                    () => {
                        scope.carousel = elem.find('.carousel');

                        // listen for image loads
                        const imgs = elem.find('.carousel img').toArray();
                        imgs.forEach(img => {
                            if (!img.onload) {
                                img.onload = updateAndTranslateCarousel;
                            }
                        });
                    },
                );

                function getWidth() {
                    return elem.find('.cf-interactive-cards').width();
                }

                // After the directive renders, or if card content or options change,
                // programmatically resize all the cards to match the
                // tallest. I tried doing this with CSS (table layout and flex), but we also need the
                // width to be fluid for the carousel to work; so I couldn't figure out a pure-CSS solution.
                function setCardHeights() {
                    try {
                        // Push the resize to the next event loop to ensure that the browser has rendered
                        // the changes that triggered setCardHeights.
                        // For example, the rescale code in `component_overlay_dir` could trigger this,
                        // but the image might not actually be rendered in the browser yet, which is necessary
                        // for this function to work.
                        $timeout(() => {
                            const cardSelector = '.card-wrapper .card';

                            if (scope.model.force_card_height) {
                                const cards = elem.find(cardSelector).toArray();

                                if (cards.length === 0) {
                                    return;
                                }

                                cards.forEach(card => {
                                    card.style.height = null;
                                });
                            } else {
                                const componentOverlays = elem.find('.carousel cf-component-overlay').toArray();
                                const maxCardHeight =
                                    componentOverlays.length !== 0
                                        ? Math.max(...componentOverlays.map(overlay => overlay.offsetHeight))
                                        : null;

                                const cards = elem.find(cardSelector).toArray();

                                if (cards.length === 0) {
                                    return;
                                }

                                cards.forEach(card => {
                                    card.style.height = `${maxCardHeight}px`;
                                });
                            }
                        });
                    } catch (error) {
                        // See https://trello.com/c/EMNxZlU1 for more details on this error.
                        // We had reports from an editor saying that the page would freeze
                        // when going into edit mode from preview mode. Perhaps the freezing
                        // was a result of these errors continually being thrown without being
                        // caught. Maybe simply catching them will prevent the page from freezing
                        // since they didn't seem to cause the app to break during initial testing
                        // locally and on prod?
                        if (error.name !== 'NS_ERROR_NOT_INITIALIZED') {
                            throw error;
                        }
                    }
                }

                function updateAndTranslateCarousel(panning = 0) {
                    const carousel = elem.find('.carousel');
                    const cardWrappers = elem.find('.card-wrapper').toArray();

                    if (!cardWrappers.length || !carousel) {
                        return;
                    }

                    setCardHeights();

                    const elemWidth = getWidth();
                    const maxMobileCardWidth = 355; // decent width while allowing enough space for forward/back buttons
                    const maxDesktopCardWidth = 400; // original width for desktop cards was set to 400px via $TILE_PROMPT_WIDTH
                    const containerWidthMinusMargin = elemWidth - 120; // 60px margin on each side for forward/back buttons
                    const cardWidth = (() => {
                        if (scope.model.wide) {
                            return containerWidthMinusMargin;
                        }

                        if (containerWidthMinusMargin > maxDesktopCardWidth) {
                            return maxDesktopCardWidth;
                        }

                        if (containerWidthMinusMargin > maxMobileCardWidth) {
                            return maxMobileCardWidth;
                        }

                        return containerWidthMinusMargin;
                    })();
                    const cardMargin = (elemWidth - cardWidth) / 2;

                    [...cardWrappers].forEach(cardWrapper => {
                        cardWrapper.style.width = `${cardWidth}px`;
                        cardWrapper.style.marginLeft = `${cardMargin}px`;
                        cardWrapper.style.marginRight = `${cardMargin}px`;
                    });

                    const index = scope.viewModel.currentIndex;
                    const cardWidthPlusMargin = cardWidth + cardMargin * 2;
                    const position = -cardWidthPlusMargin * index + panning;
                    const translateValue = `translateX(${reverseMultiplier * position}px)`;

                    // Scale the element using transform, so that text scales down as well
                    carousel[0].style['-webkit-transform'] = translateValue;
                    carousel[0].style['-o-transform'] = translateValue;
                    carousel[0].style.transform = translateValue;
                }

                scope.$watch(
                    () => scope.carousel[0],
                    () => {
                        if (Capabilities.touchEnabled && scope.carousel[0]) {
                            // Hammer.js recognizes vertical panning on touch devices and touch emulators
                            // (Chrome device emulator) as “panleft”. See this answer on the issue for the
                            // 'pointercancel' suggestion: https://github.com/hammerjs/hammer.js/issues/1050#issuecomment-279239388
                            //
                            // See also an issue that was caused by only checking for this sometimes: https://trello.com/c/WDPIf6fG
                            const runIfNotPointerCancel = (e, cb) => e.srcEvent.type !== 'pointercancel' && cb();

                            if (scope.viewModel.overlaysViewModels.length > 1) {
                                scope.mc = new $window.Hammer(scope.carousel[0]);

                                scope.mc.on('panstart', ev => {
                                    runIfNotPointerCancel(ev, () => {
                                        scope.carousel.addClass('panning');
                                        scope.carousel.css({
                                            transitionDuration: '0ms',
                                        });
                                    });
                                });

                                scope.mc.on('panend', ev => {
                                    runIfNotPointerCancel(ev, () => {
                                        scope.carousel.removeClass('panning');
                                        scope.carousel.css({
                                            transitionDuration: '300ms',
                                        });
                                        const width = getWidth();

                                        // if the card hs been moved far enough, or if it has been flicked (velocity is high)
                                        if (ev.deltaX < -0.25 * width * reverseMultiplier || ev.velocityX > 0.5) {
                                            scope.viewModel.goForward();
                                        } else if (
                                            ev.deltaX > 0.25 * width * reverseMultiplier ||
                                            ev.velocityX < -0.5
                                        ) {
                                            scope.viewModel.goBack();
                                        }

                                        // hold off on the digest until the
                                        // transition is done.  Makes it smoother
                                        scope.carousel.one('transitionend', () => {
                                            scope.pannedTransition = true;
                                            safeApply(scope);
                                        });

                                        // even if the currentIndex did not change,
                                        // we still want to translate the carousel again
                                        updateAndTranslateCarousel();
                                    });
                                });

                                scope.mc.on('panleft panright', ev => {
                                    runIfNotPointerCancel(ev, () => {
                                        updateAndTranslateCarousel(ev.deltaX);
                                    });
                                });
                            }
                        }
                    },
                );

                // Note: this does not perfectly handle the case where we switch the order
                // of the interactive cards in the editor.  In that case, you end up with the
                // active challenge on a card that is not active.  But it's a bit tricky
                // to fix and it's not either very common or very severe, so I'm leaving it for now.
                scope.$watch('viewModel.currentIndex', () => {
                    // We want to enable the transition only when the currentIndex changes. This avoids janky animations for all of
                    // the other situations in which updateAndTranslateCarousel is called (e.g. when the lesson player bot opens).
                    const carousel = scope.carousel[0];
                    carousel.style.transitionDuration = '300ms';

                    updateAndTranslateCarousel();

                    $timeout().then(() => {
                        carousel.style.transitionDuration = '0ms';
                    });

                    // during panned transitions, we don't digest until after the transition has already completed.
                    // in that case, go ahead and perform the slide change, as the carousel won't refire the event.
                    if (scope.pannedTransition) {
                        scope.pannedTransition = false;
                        scope.onAfterSlideChange();
                    } else {
                        scope.carousel.one('transitionend', scope.onAfterSlideChange);
                    }

                    scope.disableButtons = true;
                    scope.cancelDisableButtonsTimeout = $timeout(() => {
                        scope.disableButtons = false;
                    }, 500);
                });

                scope.$on('$destroy', () => {
                    if (scope.mc) {
                        scope.mc.destroy();
                    }
                    $timeout.cancel(scope.cancelDisableButtonsTimeout);
                });
            },
        });
    },
]);

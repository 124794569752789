import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*
    A ValidationError will be created by a validation and added to the 'errors'
    list that ChallengeValidator creates when validate() is called.

    The ValidationError includes information about the error that happened.
*/
angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.ChallengeValidator.ValidationError',

    [
        'AClassAbove',

        AClassAbove =>
            AClassAbove.subclass(() => ({
                initialize(options) {
                    options = angular.extend(
                        {
                            relatedAnswers: [],
                        },
                        options || {},
                    );

                    this.relatedAnswers = options.relatedAnswers;
                },

                isRelatedToAnswer(answerModel) {
                    return this.relatedAnswers.includes(answerModel);
                },

                asJson() {
                    throw new Error('Subclasses of ValidationError should define asJson()');
                },
            })),
    ],
);

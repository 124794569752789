import angularModule from 'Lessons/angularModule/scripts/lessons_module';
// FIXME: let's move this to Challenge
angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.ChallengeValidator.Behaviors.HasAllExpectedAnswers',
    [
        'AModuleAbove',
        'Lesson.FrameList.Frame.Componentized.Component.ChallengeValidator.ValidationError.MissingExpectedAnswer',
        (AModuleAbove, MissingExpectedAnswer) =>
            new AModuleAbove({
                included(ChallengeValidatorModel) {
                    ChallengeValidatorModel.supportBehavior('HasAllExpectedAnswers');

                    // set up a callback to be run whenever a ViewModel is initialized
                    ChallengeValidatorModel.ViewModel.setCallback('after', 'initialize', function () {
                        this.model.on('behavior_added:HasAllExpectedAnswers', () => {
                            if (!this.model.expectedAnswerMatchers) {
                                throw new Error('HasAllExpectedAnswers requires an "expectedAnswerMatchers" list.');
                            }

                            this.validations.push(function (errors, challengeResponses) {
                                this.model.expectedAnswerMatchers.forEach(answerMatcher => {
                                    if (!answerMatcher.matchesAny(challengeResponses)) {
                                        errors.push(new MissingExpectedAnswer(answerMatcher));
                                    }
                                });
                            });
                        });
                    });
                },
            }),
    ],
);

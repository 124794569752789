import 'SafeApply/angularModule';

// dynamic runtime determinations of "mobile" status based on width calculations
export default angular
    .module('isMobile', ['safeApply'])

    // Returns 'true' if the screen is less than $screen-sm-min, false otherwise
    // NOTE: each call to this factory will cause a browser re-flow, so please
    // attempt to isolate and reduce these calls appropriately!
    .factory('isMobile', [
        '$injector',
        $injector => {
            const $window = $injector.get('$window');

            return () => $window.innerWidth < 768;
        },
    ])

    // adds directive support for resize events
    .factory('isMobileMixin', [
        '$injector',
        $injector => {
            const $window = $injector.get('$window');
            const isMobile = $injector.get('isMobile');
            const safeDigest = $injector.get('safeDigest');
            const $timeout = $injector.get('$timeout');
            return {
                onLink(scope) {
                    function updateIsMobile() {
                        scope.isMobile = isMobile();
                        scope.xsOrSm = $window.innerWidth < 992;
                        scope.windowInnerWidth = $window.innerWidth;
                        safeDigest(scope);
                    }
                    updateIsMobile();

                    $($window).on(
                        `resize.${scope.$id}`,
                        () => {
                            // timeout here seems to help issues where inner(Width|Height) are not immediately updated on the window after the event
                            // It seems to be isolated to webkit
                            const delay = $window.clientIdentifier === 'ios' ? 40 : 0;
                            $timeout(updateIsMobile, delay);
                        },
                        updateIsMobile,
                    );

                    scope.$on('$destroy', () => {
                        $($window).off(`resize.${scope.$id}`);
                    });
                },
            };
        },
    ]);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*

    A Challenge represents a task that the user is expected to complete correctly.  The Challenge
    is not a UiComponent, but it holds references to the UiComponents that should be placed on the
    screen when the challenge is active.  (These are in contentForText, contentForImage,
    and contentForInteractive properties.)

    Any challenge will be referenced by an instance of Challenges, which will
    be responsible for activating and de-activating the challenge.

    The challenge has a reference to a validator.  The challenge will call validator.validate()
    when it is time to check the user's answers.  The validator holds all information about what
    the expected answers are and all of the logic required to compare the user's current answers
    to the expected ones.  The validator will fire a 'validated' event when it is done validating,
    at which point the challenge will fire 'validated' and either 'validatedCorrect' or 'validatedIncorrect'.

    See the following flowchart for a picture of how all the pieces of a
    Multiple Choice Challenge fit together:
    https://pedago.atlassian.net/wiki/display/TECH/Componentized+Frame#ComponentizedFrame-MCChallengeFlowchart


    Example:

    {
        "id": "f7a7aa6a-6801-4551-8e82-7b92282fb7b3",
        "component_type": "ComponentizedFrame.MultipleChoiceChallenge",
        "__type": "Lesson::FrameList::Frame::Componentized::Component::Challenge::MultipleChoiceChallenge",
        "validator_id": "948e0f78-9241-40ef-930f-128db55a49f7",
        "content_for_text_ids": ["f5913da1-0a66-44a1-85f0-6d5e1185c1e4"],
        "answer_list_id": "a93b590a-7d89-4cf4-a137-1ebcb780413f",
        "content_for_image_ids": [
            // spinning prompt
            "e4921fb8-e94b-457c-a357-ec03bb63e469"
        ],
        "content_for_interactive_ids": [
            // answer list (is it bad that this has to be referenced in two places?)
            "a93b590a-7d89-4cf4-a137-1ebcb780413f"
        ],
        "behaviors": {
            "ImmediateValidation": {},
            "CompleteOnCorrect": {},
            "ResetAnswersOnActivated": {},
            "DisallowMultipleSelect": {},
            "ShowCorrectStyling": {},
            "FlashIncorrectStyling": {},
            "ClearMessagesOnAnswerSelect": {},
            "ClearMessagesOnDeactivated": {}
        }
    },

*/
angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.Challenge.ChallengeModel', [
    'Lesson.FrameList.Frame.Componentized.Component.ComponentModel',
    'Lesson.FrameList.Frame.Componentized.Component.Challenge.ChallengeViewModel',
    'Lesson.FrameList.Frame.Componentized.Component.Challenge.Behaviors.CompleteOnCorrect',
    'Lesson.FrameList.Frame.Componentized.Component.Challenge.Behaviors.ClearMessagesOnDeactivated',

    (ComponentModel, ChallengeViewModel, CompleteOnCorrect, ClearMessagesOnDeactivated) =>
        ComponentModel.subclass(function () {
            this.alias('ComponentizedFrame.Challenge');
            this.extend({
                ViewModel: ChallengeViewModel,
            });
            this.setEditorViewModel(
                'Lesson.FrameList.Frame.Componentized.Component.Challenge.ChallengeEditorViewModel',
            );

            this.include(CompleteOnCorrect);
            this.include(ClearMessagesOnDeactivated);

            // all challenges need a validator
            this.references('validator').through('validator_id');

            // all sections are optional
            this.references('contentForText').through('content_for_text_id');
            this.references('contentForImage').through('content_for_image_id');
            this.references('contentForInteractive').through('content_for_interactive_id');
            this.references('contentForInteractiveImage').through('content_for_interactive_image_id');

            // Only relevant for challenges that have an associated blank.  When set to
            // true, it is possible to assign a different value to the blank and to the
            // answer in the editor.
            this.key('unlink_blank_from_answer');

            return {};
        }),
]);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*
    A MissingExpectedAnswer ValidationError will be created by the HasAllExoectedAnswers behavior
    if there is an answerMatcher in the validator's expectedAnswerMatchers that does not match
    any of the answers in challenge.userAnswers.
*/
angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.ChallengeValidator.ValidationError.MissingExpectedAnswer',

    [
        'Lesson.FrameList.Frame.Componentized.Component.ChallengeValidator.ValidationError',

        ValidationError =>
            ValidationError.subclass(() => ({
                initialize($super, expectedAnswerMatcher, options) {
                    $super(options);
                    this.expectedAnswerMatcher = expectedAnswerMatcher;
                },

                asJson() {
                    return {
                        error_class: 'MissingExpectedAnswer',
                        expected_answer_matcher_id: this.expectedAnswerMatcher.id,
                    };
                },
            })),
    ],
);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Layout.LayoutViewModel',

    [
        'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentViewModel',

        UiComponentViewModel => UiComponentViewModel.subclass(() => ({})),
    ],
);

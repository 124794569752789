import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*
    When this behavior is on, we will move onto the next frame once the challenges component
    is marked as complete, without waiting for the continue button.
*/
angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.Challenges.Behaviors.GotoNextFrameOnComplete', [
    'AModuleAbove',
    'Lesson.FrameList.Frame.Componentized.Component.ComponentEventListener',
    (AModuleAbove, ComponentEventListener) =>
        new AModuleAbove({
            included(ChallengesModel) {
                ChallengesModel.supportBehavior('GotoNextFrameOnComplete');

                ChallengesModel.ViewModel.setCallback('after', 'initialize', function () {
                    const viewModel = this;
                    const listeners = [];

                    viewModel.model.on('behavior_added:GotoNextFrameOnComplete', () => {
                        const listener = new ComponentEventListener(
                            viewModel,
                            'completed',
                            () => {
                                viewModel.frameViewModel.gotoNextFrame();
                            },
                            {
                                // let everything else that needs to happen happen before moving on
                                // to the next frame.  Specifically, we were seeing an error where
                                // no_incorrect_answer frames would move on before logging finish
                                priority: 99999,
                            },
                        );
                        listeners.push(listener);
                    });

                    viewModel.model.on('behavior_removed:GotoNextFrameOnComplete', () => {
                        while (listeners[0]) {
                            const listener = listeners.pop();
                            listener.cancel();
                        }
                    });
                });
            },
        }),
]);

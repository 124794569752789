import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.Text.Behaviors.ProcessesInlineImages', [
    '$injector',
    $injector => {
        const AModuleAbove = $injector.get('AModuleAbove');
        const $q = $injector.get('$q');

        return new AModuleAbove({
            included(TextModel) {
                TextModel.supportBehavior('ProcessesInlineImages');

                // A text model needs to be able to define the urls of all
                // the images embedded in it.  This is used in recursivelyProcessImages
                // for preloading images for the player and for offline mode.
                TextModel.imageProcessors().push(function (processImage) {
                    const promises = [];

                    $(this.formatted_text)
                        .find('img')
                        .each((i, el) => {
                            const url = $(el).attr('storable-image');
                            promises.push(processImage(url));
                        });

                    return $q.all(promises);
                });
            },
        });
    },
]);

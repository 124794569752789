import { regionAwareImageUrlForFormat } from 'regionAwareImage';
import angularModule from '../../../../../../lessons_module';

/*
    an Image is a UiComponent that will be rendered as an <img> tag when included on the screen.

    It should include the to-jsonned version of an S3Asset, which will allow it
    to determine the src of the image.


    Example:

    {
	    "id": "49cfa0e4-18a1-41b1-8c1a-d30f44c7b44b",
	    "__type": "Lesson::FrameList::Frame::Componentized::Component::Image",
	    "component_type": "ComponentizedFrame.ImageModel",
        "image": {
            "id": 1,
            "file_file_name": "three_cluster_2_3_6.png",
            "formats": {
                "150x150": {
                    "url": "https://s3.amazonaws.com/pedago/front_royal_assets/lessons/bf9e8941-d9a3-4593-b32b-2730071bef39/images/Fire1/150x150/Fire1.png?1390925319",
                    "width": 150,
                    "height": 150
                },
                "565x275": {
                    "url": "https://s3.amazonaws.com/pedago/front_royal_assets/lessons/bf9e8941-d9a3-4593-b32b-2730071bef39/images/Fire1/565x275/Fire1.png?1390925319",
                    "width": 565,
                    "height": 275
                },
                "original": {
                    "url": "https://s3.amazonaws.com/pedago/front_royal_assets/lessons/bf9e8941-d9a3-4593-b32b-2730071bef39/images/Fire1/original/Fire1.png?1390925319"
                }
            }
        }
    }
*/
angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.Image.ImageModel', [
    'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentModel',
    'Lesson.FrameList.Frame.Componentized.Component.Image.ImageViewModel',
    '$injector',
    (UiComponentModel, ImageViewModel, $injector) => {
        const $window = $injector.get('$window');
        const ConfigFactory = $injector.get('ConfigFactory');
        const ErrorLogService = $injector.get('ErrorLogService');

        const ImageModel = UiComponentModel.subclass(function subclass() {
            this.alias('ComponentizedFrame.Image');
            this.extend({
                ViewModel: ImageViewModel,
            });
            this.setEditorViewModel('Lesson.FrameList.Frame.Componentized.Component.Image.ImageEditorViewModel');

            Object.defineProperty(this.prototype, 'hasImage', {
                get() {
                    return !!this.image || !!this.$$dataUrl;
                },
            });

            Object.defineProperty(this.prototype, 'aspectRatio', {
                get() {
                    let aspectRatio;

                    // wrap in try/catch in case any of these are missing
                    try {
                        aspectRatio = this.image.dimensions.width / this.image.dimensions.height;
                        // eslint-disable-next-line no-empty
                    } catch (e) {}

                    // do not return NaN, return undefined
                    return aspectRatio || undefined;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'src', {
                get() {
                    if (this.imageFormats) {
                        return regionAwareImageUrlForFormat(this.image, 'original', ConfigFactory.getSync(true));
                    }
                    return this.$$dataUrl;
                },
                set(val) {
                    this.image = {
                        src: val,
                        formats: {
                            original: {
                                url: val,
                            },
                        },
                    };
                },
            });

            Object.defineProperty(this.prototype, 'uploadingImage', {
                get() {
                    return !this.image && this.$$dataUrl;
                },
            });

            Object.defineProperty(this.prototype, 'has3xSizes', {
                get() {
                    return !!this.formats && !!this.formats['2100x825'];
                },
            });

            Object.defineProperty(this.prototype, 'allowUnreferenced', {
                get() {
                    // if no image has been uploaded and the image component
                    // is unreferenced, then let it be cleaned up on save
                    return !!this.image;
                },
            });

            Object.defineProperty(this.prototype, 'imageFormats', {
                get() {
                    // There is a brief period of time in which an imageModel may not have an image. For example,
                    // we fall back to $$dataUrl vs. image if we haven't completed uploading an image (see this.hasImage).
                    //
                    // If we don't have an image, we should return null here because it's impossible to get the
                    // formats of a nonexistent image.
                    // If we do have an image, but that image doesn't have formats somehow, let's just log the
                    // errors and details.
                    if (!this.image) {
                        return null;
                    }

                    if (!this.image.formats) {
                        ErrorLogService.notify('Could not find formats for the ImageModel', undefined, {
                            fingerprint: ['Could not find formats for the ImageModel'],
                            lesson_id: this.frame().lesson().id,
                            frame_id: this.frame().id,
                            image_model_id: this.id,
                        });
                        return null;
                    }

                    return this.image.formats;
                },
            });

            this.key('image');
            this.key('label');
            return {
                urlForFormat(format) {
                    // if image is not set, try to fallback to the dataUrl, which
                    // will be set in the editor if this file is still being uploaded
                    if (this.uploadingImage) {
                        return this.$$dataUrl;
                    }
                    if (!this.imageFormats) {
                        return null;
                    }

                    const url = regionAwareImageUrlForFormat(this.image, format, ConfigFactory.getSync(true));

                    return this.frame().getAlteredUrl(url);
                },

                clone($super, newId) {
                    const model = $super(newId);
                    model.$$dataUrl = this.$$dataUrl;
                    return model;
                },

                urlForContext(context, forceMaxPixelRatio) {
                    // if image is not set, try to fallback to the dataUrl, which
                    // will be set in the editor if this file is still being uploaded
                    if (!this.image && this.$$dataUrl) {
                        return this.$$dataUrl;
                    }
                    // isMobile and devicePixelRatio are added to the lesson in FrontRoyalStoreApi#getPublishedStream.
                    // If they are set, it means we are loading a lesson image that was saved in the store.
                    // Having these values from the time that they were stored allows us to correctly load them when offline
                    // even if the device window changed size.
                    const { is_mobile: isMobile, device_pixel_ratio: devicePixelRatio } = this.lesson;

                    const format = this._formatForContext({ context, forceMaxPixelRatio, isMobile, devicePixelRatio });
                    const url = this.urlForFormat(format);

                    return url;
                },

                replaceDataUrlWithImage(image) {
                    const dataUrl = this.$$dataUrl;

                    if (!dataUrl) {
                        // If the same image content was uploaded by two different instances of content-item-image-upload
                        // at once, then this model will already have had it's dataUrl replaced with the
                        // real image content.  If that's the case, though, then everything is fine and we can just go on.
                        return;
                    }

                    // replace the image on any image that has this dataUrl (important
                    // if the image has been cloned when switching frame types or copying
                    // image across frames)
                    // image across frames)
                    if (this.frame()) {
                        this.frame()
                            .lesson()
                            .frames.forEach(frame => {
                                frame.componentsForType(ImageModel).forEach(imageModel => {
                                    if (imageModel.$$dataUrl === dataUrl) {
                                        imageModel.image = angular.copy(image);
                                        imageModel.$$dataUrl = undefined;
                                    }
                                });
                            });
                    }
                },

                /*
                    see also: lesson.rb

                    CSS-dictated image sizing (standard res / retina)

                        content:
                            < 768px:        510x200 / 1020x400
                            >= 768px:       700x275 / 1400x550

                        inline:
                            *:              84x33 / 168x66

                        answer buttons:
                            < 768px:        293x115 / 586x230
                            >= 768px:       408x160 / 816x320

                        tile prompts:
                            < 768px:        344x135 / 688x270
                            >= 768px:       573x225 / 1146x450

                    */
                _formatForContext({ context, forceMaxPixelRatio, isMobile, devicePixelRatio }) {
                    if (!this.imageFormats) {
                        return null;
                    }

                    const useMobileImage = this._checkIsMobile(isMobile);
                    const pixelRatio = this._getDevicePixelRatio(devicePixelRatio);
                    const isGIF = this._isGIF();
                    let baseFormat;
                    let format;

                    context = context || 'original';
                    // ImageMagick seems to not handle resizing animated gifs very well.
                    // In particular, we've seen some artifacts when a lot of transparency was used.
                    // Therefore, we'll default to using the original version when it's a gif to avoid problems.
                    context = isGIF ? 'original' : context;

                    // determine the most appropriate resolution for given context
                    switch (context) {
                        case 'original':
                        case 'zoom':
                            format = 'original';
                            break;

                        case 'tallContextImage':
                        case 'shortContextImage':
                        case 'secondImage':
                        case 'challengeOverlayBlank':
                        case 'wideAnswerButton':
                        case 'display':
                            if (useMobileImage) {
                                baseFormat = [510, 200];
                            } else {
                                baseFormat = [700, 275];
                            }
                            break;

                        case 'answerButton':
                            if (useMobileImage) {
                                baseFormat = [293, 115];
                            } else {
                                baseFormat = [408, 160];
                            }
                            break;

                        case 'tilePrompt':
                            if (useMobileImage) {
                                baseFormat = [344, 135];
                            } else {
                                baseFormat = [573, 225];
                            }
                            break;

                        case 'inline':
                            baseFormat = [84, 33];
                            break;

                        default:
                            throw new Error(`Unable to find format for unknown context "${context}"`);
                    }

                    if (baseFormat) {
                        let x = 1;

                        if (pixelRatio > 2 || forceMaxPixelRatio) {
                            x = 3;
                        } else if (pixelRatio > 1) {
                            x = 2;
                        }

                        format = baseFormat.map(n => x * n).join('x');
                    }

                    // if the expected format is available return
                    if (this.imageFormats[format] && this.imageFormats[format].url) {
                        return format;
                    }

                    // otherwise fallback to original image
                    return 'original';
                },

                // separate method to stub in tests
                _checkIsMobile(isMobile) {
                    // Use width OR height because smaller tablets can change orientation, and we need to
                    // be able to handle that and show offline images.
                    return isMobile ?? ($window.innerWidth < 768 || $window.innerHeight < 768);
                },

                // separate method to stub in tests
                _getDevicePixelRatio(ratio) {
                    return ratio ?? $window.devicePixelRatio;
                },

                _isGIF() {
                    return this.src && this.src.match(/\.gif$/i);
                },
            };
        });

        return ImageModel;
    },
]);

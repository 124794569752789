import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*
    UserInputChallenge inherits from challenge.

*/
angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Challenge.UserInputChallenge.UserInputChallengeModel',
    [
        'Lesson.FrameList.Frame.Componentized.Component.Challenge.ChallengeModel',
        'Lesson.FrameList.Frame.Componentized.Component.Challenge.UserInputChallenge.UserInputChallengeViewModel',
        (ChallengeModel, UserInputChallengeViewModel) =>
            ChallengeModel.subclass(function () {
                this.alias('ComponentizedFrame.UserInputChallenge');
                this.extend({
                    ViewModel: UserInputChallengeViewModel,
                });
                this.setEditorViewModel(
                    'Lesson.FrameList.Frame.Componentized.Component.Challenge.UserInputChallenge.UserInputChallengeEditorViewModel',
                );

                this.references('messages').through('message_ids');

                Object.defineProperty(this.prototype, 'correctAnswerText', {
                    get() {
                        const matcher = this.validator.expectedAnswerMatchers[0];
                        if (!matcher) {
                            return undefined;
                        }
                        return matcher.expectedText;
                    },
                });

                Object.defineProperty(this.prototype, 'caseSensitive', {
                    get() {
                        const answerMatcher = this.validator.expectedAnswerMatchers[0];
                        return answerMatcher && answerMatcher.caseSensitive;
                    },
                    set(val) {
                        if (val === this.caseSensitive) {
                            return val;
                        }

                        this.validator.expectedAnswerMatchers[0].caseSensitive = val;
                        return val;
                    },
                });

                Object.defineProperty(this.prototype, 'correctThreshold', {
                    get() {
                        const answerMatcher = this.validator.expectedAnswerMatchers[0];
                        return answerMatcher && answerMatcher.correctThreshold;
                    },
                    set(val) {
                        if (val === this.correctThreshold) {
                            return val;
                        }

                        this.validator.expectedAnswerMatchers[0].correctThreshold = val;
                        return val;
                    },
                });

                Object.defineProperty(this.prototype, 'answerMatcherMode', {
                    get() {
                        const answerMatcher = this.validator.expectedAnswerMatchers[0];
                        return answerMatcher && answerMatcher.mode;
                    },
                    set(val) {
                        if (val === this.correctThreshold) {
                            return val;
                        }

                        this.validator.expectedAnswerMatchers[0].mode = val;
                        return val;
                    },
                });

                return {
                    hasMessage() {
                        return !!this.messageComponent();
                    },

                    // this is inefficient and called over and over
                    // cache the result and listen for the answer to change
                    // to invalidate the cache. But it doesn't seem to
                    // be a big deal for now.
                    messageComponent() {
                        return this.messages && this.messages[0];
                    },
                };
            }),
    ],
);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import 'bootstrap-sass/assets/javascripts/bootstrap/collapse';

angularModule.directive('frameExpandPanelInitially', [
    '$injector',
    function factory() {
        return {
            restrict: 'A',
            link(scope, elem) {
                if (scope.frameViewModel.frame.$$expandExtraPanelsInitially) {
                    elem.collapse('show');
                }
            },
        };
    },
]);

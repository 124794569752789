import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory('Lesson.FrameList.FrameListGrader', [
    '$injector',
    $injector => {
        const SuperModel = $injector.get('SuperModel');
        const MultipleChoiceMessageModel = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.MultipleChoiceMessage.MultipleChoiceMessageModel',
        );
        const MultipleChoiceChallengeModel = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.MultipleChoiceChallengeModel',
        );
        const UserInputChallengeModel = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.Challenge.UserInputChallenge.UserInputChallengeModel',
        );
        const TextModel = $injector.get('Lesson.FrameList.Frame.Componentized.Component.Text.TextModel');
        const AnswerListModel = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.AnswerList.AnswerListModel',
        );
        const $q = $injector.get('$q');
        const { getInteractionTypeForFrame, getInteractionTypeTitle, isInteractiveType } =
            $injector.get('frameInteractionTypes');

        const FrameListGrader = SuperModel.subclass(function () {
            const CRITICAL_ISSUE = 'CRITICAL_ISSUE';
            const POTENTIAL_ISSUE = 'POTENTIAL_ISSUE';
            const NOTE = 'NOTE';
            const ISSUE_CLASSES = [];

            function defineIssueClass(id, title, type) {
                const entry = {
                    id,
                    type,
                    title,
                };
                ISSUE_CLASSES.push(entry);
                ISSUE_CLASSES[id] = entry;
            }

            defineIssueClass('no_tag', 'No Tag', CRITICAL_ISSUE);
            defineIssueClass('no_description', 'No Description', CRITICAL_ISSUE);
            defineIssueClass('frame_count', 'Invalid Frame Count', CRITICAL_ISSUE);
            defineIssueClass('duplicated_words', 'Duplicated Words', CRITICAL_ISSUE);

            defineIssueClass('no_fill_in_the_blank_or_multiple_choice', 'No FITB or MC', POTENTIAL_ISSUE);
            defineIssueClass('more_than_two_special_frames', '>2 Special Frames', POTENTIAL_ISSUE);
            defineIssueClass('more_than_50_no_interaction', 'More than 50% NI', POTENTIAL_ISSUE);
            defineIssueClass('more_than_80_no_interaction', 'More than 80% NI', POTENTIAL_ISSUE);
            defineIssueClass(
                'more_than_80_fill_in_the_blank_or_multiple_choice',
                'More than 80% FITB or MC',
                POTENTIAL_ISSUE,
            );
            defineIssueClass('same_phrase_bolded_twice', 'Same Phrase Bolded Twice', POTENTIAL_ISSUE);
            defineIssueClass(
                'same_frame_type_more_than_twice',
                'Same Frametype More Than Twice In a Row',
                POTENTIAL_ISSUE,
            );
            defineIssueClass('duplicate_answer_message', 'Duplicate Answer Message', POTENTIAL_ISSUE);
            defineIssueClass('not_enough_frames_with_messages', '>70% Without Messages', POTENTIAL_ISSUE);
            defineIssueClass('punctuation_within_highlighting', 'Punctuation Inside Highlighting', POTENTIAL_ISSUE);
            defineIssueClass('too_much_colored_text', 'More Than 30% With Colored Text', POTENTIAL_ISSUE);
            defineIssueClass('exclamation_in_a_row', '!?!?!?!?!?!', POTENTIAL_ISSUE);
            defineIssueClass('hyphen', 'Hyphen Instead of Emdash', POTENTIAL_ISSUE);
            defineIssueClass('not_enough_images', 'Not Enough Images', POTENTIAL_ISSUE);
            defineIssueClass('too_much_typing', 'Too Much Typing', POTENTIAL_ISSUE);
            defineIssueClass('too_long_compose_challenge', 'Too Long Compose Challenge', POTENTIAL_ISSUE);
            defineIssueClass('bold_in_message_or_modal', 'Bold In Message Or Modal', POTENTIAL_ISSUE);
            defineIssueClass('improper_description_grammar', 'Improper Grammar In Description', POTENTIAL_ISSUE);
            defineIssueClass('frame_count_warning', 'Frame Count Close To Maximum', POTENTIAL_ISSUE);

            defineIssueClass('does_not_start_with_no_interaction', 'Does Not Start With No Interaction', NOTE);
            defineIssueClass('red_character_limit', 'Red Character Limit', NOTE);

            this.headerConfig = [
                {
                    title: 'Grade',
                    prop: 'letterGrade',
                },
                {
                    title: 'Lesson Title',
                    fn() {
                        return this.lesson.title;
                    },
                },
                {
                    title: 'Stream Title',
                    fn() {
                        return this.lesson.streamTitlesToS;
                    },
                },
                {
                    title: 'Lesson Id',
                    fn() {
                        return this.lesson.id;
                    },
                },
            ];

            ISSUE_CLASSES.forEach(issueClass => {
                this.headerConfig.push({
                    title: `${issueClass.type}: ${issueClass.title}`,
                    fn() {
                        const issues = this.issuesByClass[issueClass.id] || [];
                        return issues.map(issue => issue.message);
                    },
                });
            });

            Object.defineProperty(this, 'csvColumns', {
                get() {
                    return this.headerConfig.map(entry => entry.title);
                },
            });

            return {
                initialize(lesson) {
                    this.lesson = lesson;
                },

                toCsvRow() {
                    return this.constructor.headerConfig.map(entry =>
                        entry.prop ? this[entry.prop] : entry.fn.apply(this),
                    );
                },

                grade() {
                    if (this._graded) {
                        throw new Error('grade() can only be called once');
                    }
                    this.issues = [];
                    this.criticalIssues = [];
                    this.potentialProblems = [];
                    this.notes = [];
                    this.issuesByClass = {};
                    this.totalPenalty = 0;

                    // cache possiblePaths since it is a bit intensive to
                    // calculate
                    this.possiblePaths = this.lesson.possiblePaths;

                    /*
                        Two little bits of craziness going on here:

                        1. In order to avoid looping through the frames
                            again and again, we have this method called
                            _loopThroughFrames, which takes a list of methods.
                            Each of those methods will be called before the
                            looping begins, and is expected to return an
                            object with the keys: 'withEachFrame' and 'after'. The
                            values of those two keys are functions that will
                            be called at the appropriate times.  See below for examples.
                        2. This is asynchronous because it may rely on text formatting,
                            which is asynchronous.  So, the withEachFrame and after methods
                            can return promises, and grading will not continue until
                            all those promises have resolved.  The grade() method also
                            returns a promise.
                    */
                    return this._loopThroughFrames(
                        this._initializeEditorViewModels,
                        this._setFrameTypeCounts,
                        this._validateSameTextNotBoldedMoreThanOnce,
                        this._validateDuplicateAnswerMessages,
                        this._validateMessageQuota,
                        this._validateNoPunctuationWithinMarkdownHighlighting,
                        this._validatedColoredTextLimit,
                        this._validateNoConsecutivePunctuation,
                        this._validateNoHyphens,
                        this._validateImageQuota,
                        this._validateUserInputChallengeTextLimits,
                        this._validateTextLengths,
                        this._validateNoBoldedTextInModalsOrMessages,
                        this._validateDuplicatedWords,
                    ).then(() => {
                        this._validateTag();
                        this._validateDescription();
                        this._validateShortestPathLength();
                        this._validateAtLeastOneMultipleChoiceOrFillInTheBlanks();
                        this._validateTwoFrameLimits();
                        this._validateNoInteractionLimits();
                        this._validateComposeLimits();
                        this._validateMultipleChoiceOrFillInTheBlanksLimit();
                        this._validatesNoMoreThanTwoFrameTypesInARow();
                        this._validatesStartsWithNoInteraction();

                        const grades = ['A+', 'A', 'A-', 'B', 'B-', 'C', 'C-', 'D', 'D-', 'F', 'F-'];
                        this.letterGrade = grades[this.totalPenalty] || 'F-';

                        // oratner: for testing purposes; want to see how many frames / texts are too long
                        this.tooLongFrameCount = 0;
                        this.tooLongFrames = [];
                        this.tooLongInteractionTypes = [];

                        const tooLongTexts = this.issuesByClass.red_character_limit;
                        if (tooLongTexts) {
                            this.tooLongFrameCount = tooLongTexts.length;
                            this.tooLongFrames = tooLongTexts
                                .map(issue => issue.metadata)
                                .sort((a, b) => a.frame.displayIndex() - b.frame.displayIndex());
                            this.tooLongInteractionTypes = this.tooLongFrames.map(tooLongEntry =>
                                getInteractionTypeForFrame(tooLongEntry.frame),
                            );
                        }

                        return this;
                    });
                },

                _addIssue(identifier, message, frame, metadata) {
                    const issueClass = ISSUE_CLASSES[identifier];

                    if (!issueClass) {
                        throw new Error(`Unsupported issue class: ${identifier}`);
                    }
                    const type = issueClass.type;

                    const penalty = {
                        CRITICAL_ISSUE: 1000,
                        POTENTIAL_ISSUE: 1,
                        NOTE: 0,
                    }[type];

                    const issue = {
                        type,
                        penalty,
                        message,
                        frame,
                        identifier,
                        metadata,
                    };

                    this.totalPenalty += penalty;
                    this.issues.push(issue);
                    if (!this.issuesByClass[identifier]) {
                        this.issuesByClass[identifier] = [];
                    }
                    this.issuesByClass[identifier].push(issue);
                    if (type === CRITICAL_ISSUE) {
                        this.criticalIssues.push(issue);
                    } else if (type === POTENTIAL_ISSUE) {
                        this.potentialProblems.push(issue);
                    } else if (type === NOTE) {
                        this.notes.push(issue);
                    }
                },

                // setting this up in this funny way to make sure we only need
                // to loop through the frames one time.  This is probably
                // a silly optimization, but there was only one loop in the
                // rake task and I didn't want to be accused of being non-performant
                _loopThroughFrames(...args) {
                    const tasks = Array.prototype.slice.call(args, 0);
                    const grader = this;

                    const withEachFrame = [];
                    const after = [];

                    const promises = [];

                    tasks.forEach(task => {
                        const result = task.apply(grader);

                        if (result.withEachFrame) {
                            withEachFrame.push(result.withEachFrame);
                        }
                        if (result.after) {
                            after.push(result.after);
                        }
                    });

                    this.lesson.frames.forEach(frame => {
                        withEachFrame.forEach(fn => {
                            const result = fn.apply(grader, [frame]);

                            // if the function returns a promise, save
                            // it in the array so we can progress after
                            // they all resolve
                            if (result && result.then) {
                                promises.push(result);
                            }
                        });
                    });

                    after.forEach(fn => {
                        const result = fn.apply(grader);

                        // if the function returns a promise, save
                        // it in the array so we can progress after
                        // they all resolve
                        if (result && result.then) {
                            promises.push(result);
                        }
                    });

                    return $q.all(promises);
                },

                _initializeEditorViewModels() {
                    return {
                        withEachFrame(frame) {
                            if (frame.editorViewModelsFor) {
                                frame.editorViewModelsFor(frame.components);
                            }
                        },
                    };
                },

                // see comment in _loopThroughFrames for why this function
                // is set up in this odd way
                _setFrameTypeCounts() {
                    const defaultEntry = () => ({
                        count: 0,
                        percent: 0,
                        percentOfInteractive: 0,
                    });
                    this._frameTypeCounts = {
                        basicMultipleChoice: defaultEntry(),
                        fillInTheBlanks: defaultEntry(),
                        multipleChoicePoll: defaultEntry(),
                        matching: defaultEntry(),
                        thisOrThat: defaultEntry(),
                        noInteraction: defaultEntry(),
                        composeBlanks: defaultEntry(),
                        composeBlanksOnImage: defaultEntry(),
                        // The loop below will add extra entries to this object for any other
                        // interaction types that aren't explicitly listed here.
                    };

                    const totalCount = this.lesson.frames.length;
                    let interactiveCount = 0;

                    return {
                        withEachFrame(frame) {
                            const interactionType = getInteractionTypeForFrame(frame);

                            if (!this._frameTypeCounts[interactionType]) {
                                this._frameTypeCounts[interactionType] = defaultEntry();
                            }
                            const entry = this._frameTypeCounts[interactionType];
                            entry.count += 1;
                            if (isInteractiveType(interactionType)) {
                                interactiveCount += 1;
                            }
                        },
                        after() {
                            angular.forEach(this._frameTypeCounts, (obj, interactionType) => {
                                if (isInteractiveType(interactionType)) {
                                    obj.percentOfInteractive = obj.count / interactiveCount;
                                }
                                obj.percent = obj.count / totalCount;
                            });
                        },
                    };
                },

                _validateSameTextNotBoldedMoreThanOnce() {
                    const boldedCounts = {};

                    return {
                        withEachFrame(frame) {
                            if (!frame.text_content) {
                                return;
                            }
                            frame.text_content.replace(/\*\*([^*]+)\*/g, (...args) => {
                                const match = args[1];
                                if (!boldedCounts[match]) {
                                    boldedCounts[match] = {
                                        count: 0,
                                        frameIndexes: {},
                                    };
                                }
                                const entry = boldedCounts[match];
                                entry.count += 1;
                                entry.frameIndexes[frame.displayIndex()] = true;
                            });
                        },
                        after() {
                            angular.forEach(boldedCounts, (entry, match) => {
                                const count = entry.count;
                                if (count > 1) {
                                    const indexes = Object.keys(entry.frameIndexes).sort();
                                    this._addIssue(
                                        'same_phrase_bolded_twice',
                                        `'${match}' is bolded more than once in the lesson: frames ${indexes.join(
                                            ', ',
                                        )}`,
                                    );
                                }
                            });
                        },
                    };
                },

                _validateDuplicateAnswerMessages() {
                    return {
                        withEachFrame(frame) {
                            const messageTextCounts = {};
                            if (!frame.componentsForType) {
                                return;
                            }
                            frame.componentsForType(MultipleChoiceMessageModel).forEach(message => {
                                const text = message.messageText.text;
                                if (!messageTextCounts[text]) {
                                    messageTextCounts[text] = 0;
                                }
                                messageTextCounts[text] += 1;
                            });

                            angular.forEach(messageTextCounts, (count, text) => {
                                if (count > 1) {
                                    this._addIssue(
                                        'duplicate_answer_message',
                                        `Duplicate answer messages for frame ${frame.displayIndex()}. '${text}'`,
                                        frame,
                                    );
                                }
                            });
                        },
                    };
                },

                _validateNoPunctuationWithinMarkdownHighlighting() {
                    return {
                        withEachFrame(frame) {
                            if (!frame.text_content) {
                                return;
                            }
                            const matches = [];

                            // We want to disallow punctuation inside bold or italics, unless
                            // an entire sentence is being highlighted.
                            //
                            // So, we check for cases witha word break, followed by possibly a * or ],
                            // followed by some amount of whitespace, followed by an italic or
                            // bold block with punctuation inside.
                            frame.text_content.replace(/\b[*\]]*\s+(\*+?[^*]+[.,!?]+\*+)/g, (...args) => {
                                const match = args[1];
                                matches.push(`'${match}'`);
                            });
                            if (matches.length > 0) {
                                this._addIssue(
                                    'punctuation_within_highlighting',
                                    `Frame ${frame.displayIndex()} has punctuation inside of markdown: ${matches.join(
                                        ', ',
                                    )}`,
                                    frame,
                                );
                            }
                        },
                    };
                },

                _validatedColoredTextLimit() {
                    const framesWithColoredTextMap = {};
                    return {
                        withEachFrame(frame) {
                            if (!frame.text_content) {
                                return;
                            }

                            frame.text_content.replace(
                                /({(purple|plum|yellow|blue|green|pink|grey|orange|red|white|coral|turquoise|darkturquoise|darkpurple|darkyellow|darkblue|darkgreen|darkcoral|darkorange|darkred|eggplant):)(.*?)(\})/g,
                                () => {
                                    framesWithColoredTextMap[frame.id] = frame;
                                },
                            );

                            frame.text_content.replace(/textcolor\{/g, () => {
                                framesWithColoredTextMap[frame.id] = frame;
                            });
                        },
                        after() {
                            const framesWithColoredText = Object.values(framesWithColoredTextMap);
                            if (framesWithColoredText.length / this.lesson.frames.length > 0.3) {
                                this._addIssue(
                                    'too_much_colored_text',
                                    'Overuse of colored text in this lesson (greater than 30% of frames)',
                                );
                            }
                        },
                    };
                },

                _validateMessageQuota() {
                    let messagableFrameCount = 0;
                    const messagableFrameIndexesWithoutMessages = [];

                    return {
                        withEachFrame(frame) {
                            if (!frame.componentsForType) {
                                return;
                            }
                            // we do not require messages on poll screens
                            if (
                                getInteractionTypeForFrame(frame) !== 'multipleChoicePoll' &&
                                frame.componentsForType(MultipleChoiceChallengeModel).length > 0
                            ) {
                                messagableFrameCount += 1;

                                if (frame.componentsForType(MultipleChoiceMessageModel).length === 0) {
                                    messagableFrameIndexesWithoutMessages.push(frame.displayIndex());
                                }
                            }
                        },
                        after() {
                            if (messagableFrameIndexesWithoutMessages.length / messagableFrameCount > 0.7) {
                                this._addIssue(
                                    'not_enough_frames_with_messages',
                                    `Greater than 70% of interactive frames that could have messages do not (Frames ${messagableFrameIndexesWithoutMessages.join(
                                        ', ',
                                    )})`,
                                );
                            }
                        },
                    };
                },

                _validateNoConsecutivePunctuation() {
                    return {
                        withEachFrame(frame) {
                            if (!frame.text_content) {
                                return;
                            }
                            const matches = [];
                            frame.text_content.replace(/[^!?]{0,10}[!?]{2,}(?!\[.*?\])/g, (...args) => {
                                const match = args[0];
                                matches.push(`'...${match}'`);
                            });
                            if (matches.length > 0) {
                                this._addIssue(
                                    'exclamation_in_a_row',
                                    `Frame ${frame.displayIndex()} has too many question marks and/or exclamation points in a row: ${matches.join(
                                        ', ',
                                    )}`,
                                    frame,
                                );
                            }
                        },
                    };
                },

                _validateNoHyphens() {
                    const FormatsText = $injector.get('FormatsText');
                    return {
                        withEachFrame(frame) {
                            if (!frame.text_content) {
                                return;
                            }

                            const content = FormatsText.removeMathjaxBeforeMarkdown(frame.text_content, this);

                            const matches = [];
                            content.replace(/[^ ]{0,5} - [^ ]{0,5}/g, (...args) => {
                                const match = args[0];

                                // if not markdown list syntax
                                if (match.match(/^ *- +/gm) === null) {
                                    matches.push(`'...${match}...'`);
                                }
                            });
                            if (matches.length > 0) {
                                this._addIssue(
                                    'hyphen',
                                    `Frame ${frame.displayIndex()} uses a hyphen (-) instead of an emdash (—): ${matches.join(
                                        ', ',
                                    )}`,
                                    frame,
                                );
                            }
                        },
                    };
                },

                _validateImageQuota() {
                    let framesWithImageCount = 0;
                    return {
                        withEachFrame(frame) {
                            if (!frame.getReferencedImages) {
                                return;
                            }
                            if (frame.getReferencedImages().length > 0) {
                                framesWithImageCount += 1;
                            }
                        },
                        after() {
                            if (framesWithImageCount < 2) {
                                this._addIssue(
                                    'not_enough_images',
                                    'Too few slides with images in this lesson (less than 2)',
                                );
                            }
                        },
                    };
                },

                _validateUserInputChallengeTextLimits() {
                    return {
                        withEachFrame(frame) {
                            if (!frame.componentsForType) {
                                return;
                            }
                            frame.componentsForType(UserInputChallengeModel).forEach(challenge => {
                                if (challenge.correctAnswerText.length > 20) {
                                    this._addIssue(
                                        'too_long_compose_challenge',
                                        `Frame ${frame.displayIndex()} requires text to type that is longer than 20 characters: '${
                                            challenge.correctAnswerText
                                        }'`,
                                        frame,
                                    );
                                }
                            });
                        },
                    };
                },

                _validateTextLengths() {
                    return {
                        withEachFrame(frame) {
                            if (!frame.componentsForType) {
                                return;
                            }
                            const grader = this;
                            const tooLongTexts = [];

                            // get answer lists
                            let wideAnswers = false;
                            const answerListModels = frame.componentsForType(AnswerListModel);

                            if (answerListModels.length > 0) {
                                wideAnswers = !!answerListModels[0].force_single_column;
                            }

                            // Loop through all components; find if they reference text, and if so, validate it and add context
                            frame.components.forEach(component => {
                                // get referenced text models
                                const textModels = component.referencedComponents({
                                    only: TextModel,
                                });

                                if (textModels.length === 0) {
                                    return;
                                }

                                textModels.forEach(textModel => {
                                    const result = textModel.editorViewModel.validateTextLength();
                                    if (!result.valid && angular.isDefined(result.textLength)) {
                                        // context is the component that references this TextModel
                                        const context = component.constructor
                                            .alias()
                                            .replace(/^ComponentizedFrame.Text$/, 'Modal')
                                            .replace('ComponentizedFrame.', '')
                                            .replace('MatchingChallengeButton', 'Matching Prompt')
                                            .replace('TextImageInteractive', 'Non-Interactive Main Text')
                                            .replace('MultipleChoiceMessage', 'Message')
                                            .replace('Challenges', 'Challenge Main Text')
                                            .replace('SelectableAnswer', 'Answer Choice');

                                        tooLongTexts.push({
                                            message: `'${textModel.text.slice(0, 75).trim()}...'`,
                                            fullText: textModel.text,
                                            textLength: result.textLength,
                                            maxTextLength: result.maxTextLength,
                                            percentageOver: Math.round(
                                                (100 * (result.textLength - result.maxTextLength)) /
                                                    result.maxTextLength,
                                            ),
                                            lengthOver: result.textLength - result.maxTextLength,
                                            context,
                                            wideAnswers: context === 'Answer Choice' ? wideAnswers : undefined,
                                        });
                                    }
                                });
                            });

                            if (tooLongTexts.length > 0) {
                                // create the shortened message
                                const message = tooLongTexts.map(t => t.message).join(', ');

                                // create the metadata object
                                const metadata = {
                                    frame,
                                    longTexts: tooLongTexts,
                                };

                                grader._addIssue(
                                    'red_character_limit',
                                    `Frame ${frame.displayIndex()} text exceeds recommended character limit: ${message}`,
                                    frame,
                                    metadata,
                                );
                            }
                        },
                    };
                },

                _validateNoBoldedTextInModalsOrMessages() {
                    return {
                        withEachFrame(frame) {
                            if (!frame.componentsForType) {
                                return;
                            }
                            let err = false;

                            function checkText(text) {
                                if (text.match(/\*\*([^*]+)\*/)) {
                                    err = true;
                                }
                            }

                            frame.componentsForType(TextModel).forEach(textModel => {
                                if (!textModel.modals) {
                                    return;
                                }
                                textModel.modals.forEach(modal => {
                                    checkText(modal.text);
                                });
                            });

                            frame.componentsForType(MultipleChoiceMessageModel).forEach(message => {
                                checkText(message.messageText.text);
                            });

                            if (err) {
                                this._addIssue(
                                    'bold_in_message_or_modal',
                                    `Do not introduce key terms in message or modals (frame ${frame.displayIndex()})`,
                                    frame,
                                );
                            }
                        },
                    };
                },

                _validateTag() {
                    if (!this.lesson.tag || this.lesson.tag === '') {
                        this._addIssue('no_tag', 'Lesson must have a tag.');
                    }
                },

                _validateDescription() {
                    // first check if description is empty
                    if (!this.lesson.description || this.lesson.description.length === 0) {
                        this._addIssue('no_description', 'Lesson must have a description.');
                    } else {
                        // iterate through the description items
                        const descriptions = this.lesson.description;
                        for (let i = 0; i < descriptions.length; i++) {
                            const desc = descriptions[i];
                            // if improper capitalization or end punctuation
                            const startsWithUppercase = /^[A-Z]/;
                            const endsWithPunctuation = /.*[.?!]$/;
                            if (!startsWithUppercase.test(desc) || !endsWithPunctuation.test(desc)) {
                                this._addIssue(
                                    'improper_description_grammar',
                                    'Ensure that descriptions begin with a capital letter and end with a punctuation mark',
                                );

                                break;
                            }
                        }
                    }
                },

                _validateDuplicatedWords() {
                    const wordOrBlock = [
                        'the',
                        'for',
                        'that',
                        'this',
                        'and',
                        'an',
                        'is',
                        'are',
                        'at',
                        'from',
                        'how',
                        'in',
                        'other',
                        'right',
                        'to',
                        'when',
                        'why',
                        'you',
                        'be',
                    ]
                        .map(word => `${word} ${word}`)
                        .join('|');
                    const duplicateWordRegex = new RegExp(`\\b(${wordOrBlock})\\b`, 'gi');

                    return {
                        withEachFrame(frame) {
                            // This is mostly to make testing easier
                            if (!frame.text_content || !frame.componentsForType) {
                                return;
                            }

                            let matches = [];

                            function checkText(text) {
                                matches = [
                                    ...matches,
                                    ...[...text.matchAll(duplicateWordRegex)].map(match => `...${match[0]}`),
                                ];
                            }

                            frame.componentsForType(TextModel).forEach(textModel => {
                                checkText(textModel.text);
                            });

                            if (matches.length > 0) {
                                this._addIssue(
                                    'duplicated_words',
                                    `Frame ${frame.displayIndex()} has duplicated words: ${matches.join(', ')}`,
                                    frame,
                                );
                            }
                        },
                    };
                },

                _validateShortestPathLength() {
                    let length;
                    try {
                        length = this.lesson.shortestPathLength;
                    } catch (e) {
                        // eslint-disable-next-line no-console
                        console.error(`Error in shortestPathLength for lesson: ${this.lesson.id}`);
                        // if shortest path length errors, just move on. I saw this error on a single lesson. See
                        // https://trello.com/c/Xfc6D0Rd/388-sending-branching-lesson-frames-can-be-hazardous for more details
                        return;
                    }

                    if (this.lesson.test && length < 8) {
                        this._addIssue(
                            'frame_count',
                            `Exam length is non-optimal; exams must be at least 8 screens long (${length})`,
                        );
                    } else if (this.lesson.test) {
                        // Exams no longer have an upper frame limit.
                    } else if (this.lesson.assessment && (length < 8 || length > 30)) {
                        this._addIssue(
                            'frame_count',
                            `Smartcase length is non-optimal; Smartcases must be 8 to 30 screens long, with a suggested max of 22 (${length})`,
                        );
                    } else if (this.lesson.assessment && length > 22 && length <= 30) {
                        this._addIssue(
                            'frame_count_warning',
                            `Smartcase length is within the max allowed 30 screens, but the suggested Smartcase max is 22 screens (${length})`,
                        );
                    } else if (length < 8 || length > 22) {
                        this._addIssue(
                            'frame_count',
                            `Lesson length is non-optimal; must be from 8 to 22 screens long (${length})`,
                        );
                    } else if (length === 21 || length === 22) {
                        this._addIssue('frame_count_warning', 'Lesson length is close to the maximum allowed');
                    }
                },

                _validateAtLeastOneMultipleChoiceOrFillInTheBlanks() {
                    if (
                        this._frameTypeCounts.basicMultipleChoice.count === 0 &&
                        this._frameTypeCounts.fillInTheBlanks.count === 0
                    ) {
                        this._addIssue(
                            'no_fill_in_the_blank_or_multiple_choice',
                            'No multiple choice or fill in the blanks in this lesson.',
                        );
                    }
                },

                _validateTwoFrameLimits() {
                    ['thisOrThat', 'multipleChoicePoll', 'matching'].forEach(interactionType => {
                        const name = getInteractionTypeTitle(interactionType);
                        if (this._frameTypeCounts[interactionType].count > 2) {
                            this._addIssue(
                                'more_than_two_special_frames',
                                `More than two '${name}' screens (${this._frameTypeCounts[interactionType].count})`,
                            );
                        }
                    });
                },

                _validateNoInteractionLimits() {
                    // This assumes that there is only one nonInteractive interaction type. If we ever had more
                    // that that, we could use the isInteractive flag to do the counting. See how we calculate percentOfInteractive
                    if (this._frameTypeCounts.noInteraction.percent > 0.5) {
                        this._addIssue('more_than_50_no_interaction', 'More than 50% no interaction screens.');
                    }

                    if (this._frameTypeCounts.noInteraction.percent > 0.8) {
                        this._addIssue('more_than_80_no_interaction', 'More than 80% no interaction screens.');
                    }
                },

                _validateComposeLimits() {
                    const percent =
                        this._frameTypeCounts.composeBlanks.percentOfInteractive +
                        this._frameTypeCounts.composeBlanksOnImage.percentOfInteractive;
                    if (percent > 0.4) {
                        this._addIssue(
                            'too_much_typing',
                            'Too many typing screens for this lesson (greater than 40% of interactive slides)',
                        );
                    }
                },

                _validateMultipleChoiceOrFillInTheBlanksLimit() {
                    if (
                        this._frameTypeCounts.basicMultipleChoice.percentOfInteractive +
                            this._frameTypeCounts.fillInTheBlanks.percentOfInteractive >
                        0.8
                    ) {
                        this._addIssue(
                            'more_than_80_fill_in_the_blank_or_multiple_choice',
                            'More than 80% of interactive screens are multiple choice or fill in the blanks.',
                        );
                    }
                },

                _validatesNoMoreThanTwoFrameTypesInARow() {
                    const grader = this;
                    const issuesAddedForFrameTypes = {};

                    function addIssueIfMoreThanTwoInStreak(streak) {
                        const interactionType = streak.interactionType;
                        const issueAlreadyAddedForFrameType = issuesAddedForFrameTypes[interactionType];
                        if (streak.length > 2 && !issueAlreadyAddedForFrameType) {
                            const name = getInteractionTypeTitle(interactionType);
                            let message = `'${name}' used ${streak.length} times in a row `;
                            const indexes = streak.map(frame => frame.displayIndex()).join(', ');
                            message = `${message}(${indexes}); `;
                            message = `${message}avoid using a frametype more than twice in a row.`;
                            grader._addIssue('same_frame_type_more_than_twice', message);
                            issuesAddedForFrameTypes[interactionType] = true;
                        }
                    }

                    this.possiblePaths.forEach(path => {
                        let frameTypeStreak = [];
                        path.frames.forEach(frame => {
                            const frameInteractionType = getInteractionTypeForFrame(frame);
                            if (
                                frameTypeStreak.length > 0 &&
                                frameInteractionType !== frameTypeStreak.interactionType
                            ) {
                                addIssueIfMoreThanTwoInStreak(frameTypeStreak);
                                frameTypeStreak = [];
                            }
                            frameTypeStreak.push(frame);
                            frameTypeStreak.interactionType = getInteractionTypeForFrame(frame);
                        });
                        addIssueIfMoreThanTwoInStreak(frameTypeStreak);
                    });
                },

                _validatesStartsWithNoInteraction() {
                    if (!this.lesson.frames[0]) return;
                    const firstFrameInteractionType = getInteractionTypeForFrame(this.lesson.frames[0]);
                    if (firstFrameInteractionType !== 'no_interaction') {
                        this._addIssue(
                            'does_not_start_with_no_interaction',
                            `Does not begin with 'No Interaction' (begins with '${getInteractionTypeTitle(
                                firstFrameInteractionType,
                            )}')`,
                        );
                    }
                },
            };
        });

        return FrameListGrader;
    },
]);

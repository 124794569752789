import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Text.TextViewModel',

    [
        'SuperModel',
        'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentViewModel',
        (SuperModel, UiComponentViewModel) =>
            UiComponentViewModel.subclass(() => ({
                directiveName: 'cf-text',
            })),
    ],
);

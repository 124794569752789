import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Challenge.UserInputChallenge.UserInputChallengeViewModel',
    [
        '$injector',

        $injector => {
            const ChallengeViewModel = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.Challenge.ChallengeViewModel',
            );
            const ComponentEventListener = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.ComponentEventListener',
            );
            const UserInputValidationResult = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.ChallengeValidator.ValidationResult.UserInputValidationResult',
            );
            const ChallengeResponse = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.Challenge.ChallengeResponse',
            );
            const SoundManager = $injector.get('SoundManager');
            const SoundConfig = $injector.get('SoundConfig');

            const hindiNumerals = $injector.get('HINDI_NUMERALS');

            return ChallengeViewModel.subclass(function () {
                this.extend({
                    ValidationResult: UserInputValidationResult,
                });

                Object.defineProperty(this.prototype, 'nextCharacterExpected', {
                    writable: true,
                });

                Object.defineProperty(this.prototype, 'showingIncorrectStyling', {
                    writable: true,
                });

                Object.defineProperty(this.prototype, 'challengeResponses', {
                    get() {
                        return [
                            new ChallengeResponse({
                                text: this.userAnswer || '',
                            }),
                        ];
                    },
                });

                // will be bound directly to the input element in the UI
                Object.defineProperty(this.prototype, 'userAnswer', {
                    get() {
                        return this._userAnswer;
                    },
                    set(val) {
                        // In arabic lessons, if someone types numerals like 012,
                        // we replace them with the hindi numerals, like ٠١٢
                        // See https://trello.com/c/I9mnnRpA
                        // Note: we also make sure the answer has arabic numerals in it; if not, we skip this logic
                        if (
                            val &&
                            this.model.lesson &&
                            this.model.lesson.locale === 'ar' &&
                            this.correctAnswerText &&
                            this.correctAnswerText.match(new RegExp(`[${hindiNumerals.join('')}]`))
                        ) {
                            for (let i = 0; i <= 9; i++) {
                                val = val.replace(new RegExp(i.toString(), 'g'), hindiNumerals[i]);
                            }
                        }
                        this._userAnswer = val;
                        return val;
                    },
                });

                Object.defineProperty(this.prototype, 'correctAnswerText', {
                    get() {
                        return this.model.correctAnswerText;
                    },
                });

                Object.defineProperty(this.prototype, 'showsCorrectnessDuringInput', {
                    get() {
                        // Probably a performance boost, but this property is referenced again and again.  The
                        // caching just prevents it from having to go over and over again to the model, frame, lesson
                        // and locale object to get this property, which so rarely change.
                        if (angular.isUndefined(this._showsCorrectnessDuringInput)) {
                            this._showsCorrectnessDuringInput = !this.model.localeObject.supportsIME;
                        }
                        return this._showsCorrectnessDuringInput;
                    },
                });

                Object.defineProperty(this.prototype, 'receivedHintStoredInClientStorage', {
                    get() {
                        return this.playerViewModel.getLessonClientStorage([
                            'ChallengeViewModel',
                            'receivedHint',
                            this.frame.id,
                            this.model.id,
                        ]);
                    },
                    set(val) {
                        if (val) {
                            this.playerViewModel.setLessonClientStorage(
                                ['ChallengeViewModel', 'receivedHint', this.frame.id, this.model.id],
                                true,
                            );
                        }
                        return !!val;
                    },
                });

                return {
                    directiveName: 'cf-user-input-challenge',

                    initialize($super, frameType, model) {
                        $super(frameType, model);

                        this._receivedHint = false;

                        new ComponentEventListener(this, 'activated', () => {
                            this.frameViewModel.showHintButton = true;
                        });

                        new ComponentEventListener(
                            this,
                            'completed',
                            () => {
                                this.frameViewModel.showHintButton = false;
                            },
                            {
                                // make sure this happens before moving
                                // on to the next challenge, in case that
                                // challenge wants to set this to true
                                priority: -1,
                            },
                        );

                        new ComponentEventListener(this, 'validatedIncorrect', validationResult => {
                            // check validationResult.userInputAnswerText so that after deleting
                            // an incorrect answer entirely and then entering the correct first
                            // character we flash black text instead of incorrect-styled text
                            this.showingIncorrectStyling =
                                validationResult.userInputAnswerText !== '' && !validationResult.partiallyCorrect;
                            this.showingIncompleteStyling =
                                validationResult.userInputAnswerText !== '' && validationResult.partiallyCorrect;
                            this.showingCorrectStyling = false;
                            this.userAnswer = validationResult.userAnswerToDisplay;
                            this.nextCharacterExpected = validationResult.answerMatchingDetails
                                ? validationResult.answerMatchingDetails.nextCharacterExpected
                                : undefined;
                        });

                        new ComponentEventListener(this, 'validatedCorrect', () => {
                            SoundManager.playUrl(SoundConfig.VALIDATE_CORRECT);
                            this.showingIncorrectStyling = false;
                            this.showingCorrectStyling = true;
                            this.showingIncompleteStyling = false;
                            this.nextCharacterExpected = undefined;
                        });

                        new ComponentEventListener(
                            this,
                            'validatedCorrect',
                            () => {
                                /*
                                If there is already a message showing and we have
                                a messsage for this challenge, then we don't want
                                to hide the UI and have it show up again.  We just want
                                the new message to swap in.

                                If there is already a message showing and we don't have
                                a messsage for this challenge, then we want to hide the
                                message.

                                This is not implemented as a behavior in order to avoid
                                needing to backfill old frames.
                            */
                                if (!this.model.hasMessage()) {
                                    this.playerViewModel.clearMessage();
                                }
                            },
                            {
                                priority: 0,
                            },
                        );

                        if (this.model.correctAnswerText) {
                            this.nextCharacterExpected = this.model.correctAnswerText.charAt(0);
                        }
                    },

                    // 1 point if the user got the correct answer without
                    // asking for a hint, regardless of how many times
                    // it was wrong first.  0 points if a hint was requested.
                    getScore() {
                        // See the getScore method in MultipleChoiceChallengeViewModel for an explanation
                        // of receivedHintStoredInClientStorage
                        if (
                            this._receivedHint ||
                            (this.playerViewModel.lesson.testOrAssessment && this.receivedHintStoredInClientStorage)
                        ) {
                            return 0;
                        }

                        for (const validationResult of this.validationResults) {
                            if (validationResult.result === true) {
                                return 1;
                            }
                        }

                        return null;
                    },

                    incrementHint() {
                        this._receivedHint = true;
                        this.receivedHintStoredInClientStorage = true;

                        if (this.showingIncorrectStyling && this.userAnswer.length > 0) {
                            // validated as incorrect. need to trim a character
                            this.userAnswer = this.userAnswer.slice(0, -1);
                        } else {
                            // validated as partially complete or unstarted need to add a character
                            this.nextCharacterExpected =
                                this.nextCharacterExpected || this.model.correctAnswerText.charAt(0);
                            this.userAnswer = (this.userAnswer || '') + this.nextCharacterExpected;
                        }
                    },
                };
            });
        },
    ],
);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import template from 'Lessons/angularModule/views/lesson/offline_mode_consent_modal.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { setupBrandNameProperties } from 'AppBranding';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('offlineModeConsentModal', [
    '$injector',

    function factory($injector) {
        const DialogModal = $injector.get('DialogModal');
        const $rootScope = $injector.get('$rootScope');
        const TranslationHelper = $injector.get('TranslationHelper');
        const translationHelper = new TranslationHelper('lessons.lesson.offline_mode_consent_modal');

        return {
            restrict: 'E',
            scope: {},
            templateUrl,
            link(scope) {
                scope.switchToggleValue = true;
                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });
                setupBrandNameProperties($injector, scope);

                scope.$watch('switchToggleValue', switchToggleValue => {
                    scope.statusText = switchToggleValue
                        ? translationHelper.get('enabled')
                        : translationHelper.get('disabled');
                });

                scope.saveAndContinue = () => {
                    const user = $rootScope.currentUser;

                    user.pref_offline_mode = scope.switchToggleValue;
                    user.has_seen_offline_mode_consent_modal = true;
                    user.save();

                    DialogModal.hideAlerts();
                };

                scope.toggleSwitch = () => {
                    scope.switchToggleValue = !scope.switchToggleValue;
                };
            },
        };
    },
]);

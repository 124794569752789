import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.AnswerList.AnswerButtonsDirHelper', [
    '$injector',
    $injector => {
        const frontRoyalStore = $injector.get('frontRoyalStore');
        const safeApply = $injector.get('safeApply');

        function setImageStyle(scope, viewModel) {
            const context = viewModel.model.imageContext('image');
            const url = viewModel.model.image.urlForContext(context);

            frontRoyalStore.getDataUrlIfStored(url).then(_url => {
                scope.imageStyles[viewModel.model.id] = {
                    backgroundImage: `url("${_url}")`,
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                };
                safeApply(scope);
            });
        }

        return {
            setupButtonClassWatch(scope, options) {
                const self = this;

                // useWideImageContextInSingleColumnMode is true for
                // answer_buttons_dir, because in that case buttons
                // will stretch the entire width of the screen in
                // singleColumn mode, meaning we need to load up larger
                // image files.  It is false, however, in matching mode,
                // because in that case we are always in singleColumn mode,
                // but in a skinny column, so the buttons never stretch so
                // large.
                scope.useWideImageContextInSingleColumnMode = options.useWideImageContextInSingleColumnMode;

                scope.imageStyleFor = viewModel => self.imageStyleFor(scope, viewModel);

                scope.$watch(() => {
                    // define these as methods so they will blow up if not defined
                    const components = options.getComponents.apply(scope);
                    const useSingleColumn = options.useSingleColumn.apply(scope);

                    const result = self._getButtonStyling(components, useSingleColumn);
                    scope.buttonWrapperClasses = result.buttonWrapperClasses;

                    // by returning the new value of buttonWrapperClasses, we tell
                    // angular whether or not it needs to do another digest loop to
                    // re-render.  If something has changed, it will do another loop.
                    // If not, it won't/
                    return scope.buttonWrapperClasses.join('');
                });
            },

            _getButtonStyling(components, useSingleColumn) {
                const classes = [];
                const hasImageButton = _.chain(components).map('contentType').includes('image').value();

                const buttonCount = components.length;
                const columns = useSingleColumn ? 1 : 2;
                const rows = Math.ceil(buttonCount / columns);

                // we use small buttons when we have more than 2 rows
                if (rows > 2) {
                    classes.push(hasImageButton ? 'short_image_buttons' : 'short_buttons');
                } else {
                    classes.push(hasImageButton ? 'tall_image_buttons' : 'tall_buttons');
                }

                // if useSingleColumn, then buttons will stretch the full
                // width of the parent element.
                if (useSingleColumn) {
                    classes.push('single_column');
                }

                return {
                    buttonWrapperClasses: classes,
                };
            },

            imageStyleFor(scope, viewModel) {
                const hasImage = viewModel.model.contentType === 'image' && viewModel.model.image.hasImage;
                if (!hasImage) {
                    return {};
                }

                /*
                        The way we're handling the asynchronicity here is
                        a little odd, but it is this way because originally
                        this method was setup to be synchronous.

                        The first time this gets called, it returns an empty object
                        and kicks off an asynchronous call to setImageStyle.  setImageStyle
                        calls safeApply(scope), so once it is finished the digest will cause
                        this method to get called again, and this time it will return
                        a meaningful value.
                    */
                scope.imageStyles = scope.imageStyles || {};
                if (scope.imageStyles[viewModel.model.id]) {
                    return scope.imageStyles[viewModel.model.id];
                }

                setImageStyle(scope, viewModel);
                return {};
            },
        };
    },
]);

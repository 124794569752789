import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import template from 'Lessons/angularModule/views/lesson/show_practice_player.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('showPracticePlayer', [
    '$injector',
    function factory($injector) {
        const PracticeFramesPlayerViewModel = $injector.get('Lesson.PracticeFramesPlayerViewModel');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                courseOrLesson: '@courseOrLesson',
                contentItemId: '@contentItemId',
            },
            link(scope) {
                scope.$watchGroup(['courseOrLesson', 'contentItemId'], () => {
                    if (scope.courseOrLesson && scope.contentItemId) {
                        if (scope.playerViewModel) {
                            scope.playerViewModel.destroy();
                        }
                        scope.playerViewModel = new PracticeFramesPlayerViewModel();
                        scope.playerViewModel.loadContent(scope.courseOrLesson, scope.contentItemId);
                    }
                });

                scope.$watch('playerViewModel', playerViewModel => {
                    if (playerViewModel) {
                        playerViewModel.linkToWindow($injector);
                    }
                });
            },
        };
    },
]);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.MultipleChoiceChallengeViewModel',

    [
        'Lesson.FrameList.Frame.Componentized.Component.Challenge.ChallengeViewModel',
        'Lesson.FrameList.Frame.Componentized.Component.ComponentEventListener',
        'Lesson.FrameList.Frame.Componentized.Component.ChallengeValidator.ValidationResult.MultipleChoiceValidationResult',
        'Lesson.FrameList.Frame.Componentized.Component.Challenge.ChallengeResponse',

        (ChallengeViewModel, ComponentEventListener, MultipleChoiceValidationResult, ChallengeResponse) =>
            ChallengeViewModel.subclass(function () {
                this.extend({
                    ValidationResult: MultipleChoiceValidationResult,
                });

                Object.defineProperty(this.prototype, 'answerList', {
                    get() {
                        return this.model.answerList;
                    },
                });

                Object.defineProperty(this.prototype, 'answersViewModels', {
                    get() {
                        return this.answerListViewModel.orderedAnswerViewModels;
                    },
                });

                Object.defineProperty(this.prototype, 'showingIncorrectStyling', {
                    get() {
                        if (!this.active || !this.answersViewModels) {
                            return false;
                        }

                        if (this.answersViewModels.some(answerViewModel => answerViewModel.showingIncorrectStyling)) {
                            return true;
                        }

                        return false;
                    },
                });

                Object.defineProperty(this.prototype, 'shouldShowCheckManyCaption', {
                    get() {
                        return (
                            this.correctAnswerViewModels.length > 1 &&
                            ['checkboxes', 'image_hotspot'].includes(this.answerListViewModel.model?.skin)
                        );
                    },
                });

                Object.defineProperty(this.prototype, 'challengeResponses', {
                    get() {
                        return this.selectedAnswersViewModels.map(answerViewModel =>
                            ChallengeResponse.fromSelectableAnswer(answerViewModel.model),
                        );
                    },
                });

                Object.defineProperty(this.prototype, 'userAnswers', {
                    get() {
                        return this.challengeResponses.map(challengeResponse => challengeResponse.answer);
                    },
                });

                Object.defineProperty(this.prototype, 'selectedAnswersViewModels', {
                    get() {
                        const selectedAnswersViewModels = [];
                        this.answersViewModels.forEach(answerViewModel => {
                            if (answerViewModel.selected) {
                                selectedAnswersViewModels.push(answerViewModel);
                            }
                        });
                        return selectedAnswersViewModels;
                    },
                });

                Object.defineProperty(this.prototype, 'availableAnswersViewModels', {
                    get() {
                        const availableAnswersViewModels = [];
                        this.answersViewModels.forEach(answerViewModel => {
                            if (!answerViewModel.disabled) {
                                availableAnswersViewModels.push(answerViewModel);
                            }
                        });
                        return availableAnswersViewModels;
                    },
                });

                // see comment about cachedCorrectAnswer in multipleChoiceChallengeModel
                Object.defineProperty(this.prototype, 'correctAnswerViewModel', {
                    get() {
                        return this.viewModelFor(this.model.correctAnswer);
                    },
                });

                // see comment about correctAnswer in multipleChoiceChallengeModel
                Object.defineProperty(this.prototype, 'correctAnswerViewModels', {
                    get() {
                        return this.viewModelsFor(this.model.correctAnswers);
                    },
                });

                Object.defineProperty(this.prototype, 'answeredIncorrectlyStoredInClientStorage', {
                    get() {
                        return this.playerViewModel.getLessonClientStorage([
                            'ChallengeViewModel',
                            'answeredIncorrectly',
                            this.frame.id,
                            this.model.id,
                        ]);
                    },
                    set(val) {
                        if (val) {
                            this.playerViewModel.setLessonClientStorage(
                                ['ChallengeViewModel', 'answeredIncorrectly', this.frame.id, this.model.id],
                                true,
                            );
                        }
                    },
                });

                return {
                    directiveName: 'cf-multiple-choice-challenge',

                    initialize($super, frameType, model) {
                        $super(frameType, model);

                        // there may not be an answerList yet at the moment when
                        // the challenge is set to active, so we need to listen
                        // for the answerList to be present, and deal with activated
                        // and deactivated then
                        if (this.model.answerList) {
                            this._setupActivateListeners(this.model.answerList);
                        }
                        this.model.on('set:answerList', this._setupActivateListeners.bind(this));
                        this.on('validatedIncorrect', this._storeIncorrectInLocalStorage.bind(this));
                    },

                    toggleAnswer(answerViewModel) {
                        answerViewModel.selected = !answerViewModel.selected;
                    },

                    onActivated() {
                        this.answerListViewModel.currentChallengeViewModel = this;
                    },

                    onDeActivated() {
                        if (this.answerListViewModel.currentChallengeViewModel === this) {
                            this.answerListViewModel.currentChallengeViewModel = undefined;
                        }
                    },

                    getScore() {
                        if (
                            this.model.no_incorrect_answers ||
                            this.model.selection_mode === 'check_all_selection_mode'
                        ) {
                            return undefined;
                        }

                        // On test or assessment lessons, we prevent users from cheating by
                        // refreshing the page by storing the fact that the got this challenge wrong
                        // in local storage.  For more info, see Attack 2 on https://trello.com/c/ilDRhkqF
                        let lesson;
                        try {
                            // lesson is not always defined (review mode)
                            lesson = this.playerViewModel.lesson;
                            // eslint-disable-next-line no-empty
                        } catch (e) {}
                        if (lesson && lesson.testOrAssessment && this.answeredIncorrectlyStoredInClientStorage) {
                            return 0;
                        }

                        // The score is null until the user enters an incorrect answer or completes
                        // the challenge correctly.
                        let score = null;

                        // eslint-disable-next-line no-restricted-syntax
                        for (const validationResult of this.validationResults) {
                            // We probably should not need the answerSelected check, but I've
                            // seen challenge validation events that happen when entering a
                            // this or that challenge, because it is unselecting the answer
                            // left over from the last challenge.
                            if (
                                validationResult.causedByEvent === 'answerSelected' &&
                                validationResult.totallyIncorrect
                            ) {
                                score = 0;
                                break;
                            }

                            if (validationResult.result === true) {
                                score = 1;

                                // This break should be irrelevant, since there should be no way to get an incorrect
                                // response AFTER a one with result===true, but including it anyway.
                                break;
                            }
                        }

                        return score;
                    },

                    _storeIncorrectInLocalStorage(validationResult) {
                        // The answerSelectedCheck definitely is important here, since
                        // challenge validation events that happen when entering a
                        // this or that challenge, because it is unselecting the answer
                        // left over from the last challenge.
                        if (validationResult.causedByEvent === 'answerSelected' && validationResult.totallyIncorrect) {
                            this.answeredIncorrectlyStoredInClientStorage = true;
                        }
                    },

                    _setupActivateListeners(answerList) {
                        // any time answerList is set, cancel old listeners
                        // and create new ones.
                        if (this._activateListener) {
                            this._activateListener.cancel();
                            this._deActivateListener.cancel();
                        }

                        if (answerList) {
                            // pay attention to current value of active
                            // and call the appropriate method
                            if (this.active) {
                                this.onActivated();
                            } else {
                                this.onDeActivated();
                            }

                            // listen for active to change and do what's needed again
                            this._activateListener = new ComponentEventListener(
                                this,
                                'activated',
                                this.onActivated.bind(this),
                            );

                            this._deActivateListener = new ComponentEventListener(
                                this,
                                'deActivated',
                                this.onDeActivated.bind(this),
                            );
                        }
                    },
                };
            }),
    ],
);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*
    TilePrompt is a UiComponent which causes a tile with some content to be visible within
    a card on the screen, dismissible with touch events
*/
angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.TilePrompt.TilePromptModel', [
    '$injector',
    $injector => {
        const UiComponentModel = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentModel',
        );
        const TilePromptViewModel = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.TilePrompt.TilePromptViewModel',
        );
        const HasTextOrImage = $injector.get('Lesson.FrameList.Frame.Componentized.Component.Mixins.HasTextOrImage');

        return UiComponentModel.subclass(function () {
            this.alias('ComponentizedFrame.TilePrompt');
            this.extend({
                ViewModel: TilePromptViewModel,
            });
            this.setEditorViewModel(
                'Lesson.FrameList.Frame.Componentized.Component.TilePrompt.TilePromptEditorViewModel',
            );

            this.include(HasTextOrImage);
            this.setImageContext('image', 'tilePrompt');

            this.references('challenge').through('challenge_id');

            Object.defineProperty(this.prototype, 'displayName', {
                value: 'This or That Card',
            });

            return {};
        });
    },
]);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.ChallengeValidator.ValidationResult.UserInputValidationResult',

    [
        'Lesson.FrameList.Frame.Componentized.Component.ChallengeValidator.ValidationResult',

        ValidationResult => ValidationResult.subclass(() => ({})),
    ],
);

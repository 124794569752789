import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import { setupBrandNameProperties, setupBrandScopeProperties } from 'AppBranding';
import template from 'Lessons/angularModule/views/lesson/lesson_finish_screen.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

import lessonFinishBrain from 'vectors/lesson_finish_brain.svg';
import summaryIcon from 'vectors/summary.svg';
import progressBadgeCompleteTurquoise from 'images/progress_badge_complete_turquoise.png';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('lessonFinishScreen', [
    '$injector',
    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const lessonBookendMixin = $injector.get('lessonBookendMixin');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const SummaryHelperMixin = $injector.get('Stream.SummaryHelperMixin');
        const TranslationHelper = $injector.get('TranslationHelper');
        const offlineModeManager = $injector.get('offlineModeManager');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                playerViewModel: '<',
            },
            link(scope, elem) {
                scope.lessonFinishBrain = lessonFinishBrain;
                scope.summaryIcon = summaryIcon;
                scope.progressBadgeCompleteTurquoise = progressBadgeCompleteTurquoise;
                scope.offlineModeManager = offlineModeManager;

                NavigationHelperMixin.onLink(scope);
                SummaryHelperMixin.onLink(scope);
                lessonBookendMixin(scope, elem);
                setupBrandNameProperties($injector, scope);
                setupBrandScopeProperties($injector, scope, ['lessonFinishScreenShield']);

                const translationHelper = new TranslationHelper('lessons.lesson.lesson_finish_screen');

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                scope.$watchGroup(['lesson', 'currentUser'], () => {
                    // prevent error on logout
                    if (!scope.currentUser) {
                        return;
                    }

                    if (scope.currentUser.testCompleteMessageKey) {
                        scope.testCompleteMessage =
                            scope.testCompleteMessage ||
                            translationHelper.get(scope.currentUser.testCompleteMessageKey);
                    }

                    scope.keyTerms = _.uniq(scope.lesson.getKeyTermsForDisplay());

                    if (scope.keyTerms.length === 0 || scope.lesson.assessment || scope.playerViewModel.demoMode) {
                        scope.startAnimation();
                    }
                });
            },
        };
    },
]);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*
    When this behavior is on, any time a challenge is completed, the next challenge
    will become active.

    This behavior could be turend off if for some reason you only wanted to support
    user navigation between the challenges in a frame.
*/
angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Challenges.Behaviors.GotoNextOnChallengeComplete',
    [
        'AModuleAbove',
        'Lesson.FrameList.Frame.Componentized.Component.ComponentEventListener',
        '$timeout',
        (AModuleAbove, ComponentEventListener, $timeout) =>
            new AModuleAbove({
                included(ChallengesModel) {
                    ChallengesModel.supportBehavior('GotoNextOnChallengeComplete');

                    ChallengesModel.ViewModel.setCallback('after', 'initialize', function () {
                        const challengesViewModel = this;

                        const listeners = [];

                        challengesViewModel.model.on('behavior_added:GotoNextOnChallengeComplete', function () {
                            let options = this.optionsForBehavior('GotoNextOnChallengeComplete');
                            options = angular.extend(
                                {
                                    delay: 1500,
                                },
                                options || {},
                            );

                            challengesViewModel.model.challenges.on('childAdded', challenge => {
                                const challengeViewModel = challengesViewModel.viewModelFor(challenge);

                                const listener = new ComponentEventListener(challengeViewModel, 'completed', () => {
                                    // only toggle to the next challenge if the current challenge is the same that was just completed
                                    function gotoNext() {
                                        if (challengesViewModel.currentChallengeViewModel === challengeViewModel) {
                                            challengesViewModel.gotoNextChallenge();
                                        }
                                    }

                                    /*
                                        It's important that we don't use a timeout if delay
                                        is 0, because we have to activate the next challenge
                                        synchronously to prevent losing the keyboard in mobile
                                        safari.
                                    */
                                    if (options.delay && options.delay > 0) {
                                        $timeout(() => {
                                            gotoNext();
                                        }, options.delay);
                                    } else {
                                        gotoNext();
                                    }
                                });
                                listeners.push(listener);
                            });
                        });

                        challengesViewModel.model.on('behavior_removed:GotoNextOnChallengeComplete', () => {
                            while (listeners[0]) {
                                const listener = listeners.pop();
                                listener.cancel();
                            }
                        });
                    });
                },
            }),
    ],
);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.ComponentOverlay.ComponentOverlayViewModel',

    [
        'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentViewModel',

        UiComponentViewModel =>
            UiComponentViewModel.subclass(() => ({
                directiveName: 'cf-component-overlay',

                optionsFor(componentViewModel) {
                    return this.model.optionsFor(componentViewModel.model);
                },
            })),
    ],
);

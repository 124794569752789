import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.ChallengeValidator.ValidationResult',

    [
        '$injector',
        $injector => {
            const AClassAbove = $injector.get('AClassAbove');
            const MissingExpectedAnswer = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.ChallengeValidator.ValidationError.MissingExpectedAnswer',
            );

            return AClassAbove.subclass(function () {
                Object.defineProperty(this.prototype, 'result', {
                    get() {
                        return this.errors.length === 0;
                    },
                });

                /*
                    An answer can be partially correct for one of two reasons:

                    1. The only errors are MissingExpectedAnswer errors, and there
                       is at least one ChallengeResponse. (check_many case)
                    2. There is one challenge response and one answer matcher,
                       and the answer matcher reports that there is a partial match
                       when comparing the two (UserInputChallenge case, see answerMatchingDetails below)
                */
                Object.defineProperty(this.prototype, 'partiallyCorrect', {
                    get() {
                        // cache result, since it cannot change
                        if (this.hasOwnProperty('_partiallyCorrect')) {
                            return this._partiallyCorrect;
                        }

                        // case 1 above.
                        if (this._challengeResponses.length > 0 && this._onlyHasMissingExpectedAnswerErrors) {
                            this._partiallyCorrect = true;

                            // case 2 above
                        } else {
                            this._partiallyCorrect =
                                (this.answerMatchingDetails && this.answerMatchingDetails.partialMatch) || false;
                        }

                        return this._partiallyCorrect;
                    },
                });

                Object.defineProperty(this.prototype, 'totallyIncorrect', {
                    get() {
                        return this.errors.length > 0 && !this.result && !this.partiallyCorrect;
                    },
                });

                /*
                    See answerMatchingDetails below.  Used with MatchesExpectedText.
                */
                Object.defineProperty(this.prototype, 'userAnswerToDisplay', {
                    get() {
                        return this.answerMatchingDetails && this.answerMatchingDetails.userAnswerToDisplay;
                    },
                });

                /*
                    If there is a single ChallengeResponse and a single AnswerMatcher, then there
                    can be extra details about the match.  This is used with MatchesExpectedText.

                    It may or may not be a hack that you can only get details when there
                    is a single answer matcher, but I didn't want to or need to deal
                    with the complications of returning details for multiple responses
                    matched against multiple expected answers matchers.
                */
                Object.defineProperty(this.prototype, 'answerMatchingDetails', {
                    get() {
                        // cache result, since it cannot change
                        if (this.hasOwnProperty('_answerMatchingDetails')) {
                            return this._answerMatchingDetails;
                        }

                        const singleExpectedAnswerMatcher =
                            this._validator.expectedAnswerMatchers.length === 1
                                ? this._validator.expectedAnswerMatchers[0]
                                : undefined;

                        if (
                            singleExpectedAnswerMatcher &&
                            this._challengeResponses.length === 1 &&
                            singleExpectedAnswerMatcher.matchesWithDetails
                        ) {
                            this._answerMatchingDetails = singleExpectedAnswerMatcher.matchesWithDetails(
                                this._challengeResponses[0],
                            );
                        } else {
                            this._answerMatchingDetails = undefined;
                        }

                        return this._answerMatchingDetails;
                    },
                });

                Object.defineProperty(this.prototype, 'challenge', {
                    get() {
                        return this.challengeViewModel.model;
                    },
                });

                Object.defineProperty(this.prototype, 'shouldLogEvent', {
                    get() {
                        // do not log every time the user types a character in a compose
                        // frame, so long as they are typing the correct answer
                        if (this.challenge.type === 'UserInputChallengeModel' && this.partiallyCorrect) {
                            return false;
                        }
                        return true;
                    },
                    configurable: true, // for tests
                });

                Object.defineProperty(this.prototype, 'causedByEvent', {
                    get() {
                        return this.info && this.info.event && this.info.event.type;
                    },
                });

                Object.defineProperty(this.prototype, 'userInputAnswerText', {
                    get() {
                        return (
                            this.challengeViewModel.model.type === 'UserInputChallengeModel' &&
                            this._challengeResponses[0].text
                        );
                    },
                });

                Object.defineProperty(this.prototype, '_challengeResponses', {
                    get() {
                        return this.challengeViewModel.challengeResponses;
                    },
                });

                Object.defineProperty(this.prototype, '_validator', {
                    get() {
                        return this.challengeViewModel.validator;
                    },
                });

                Object.defineProperty(this.prototype, '_onlyHasMissingExpectedAnswerErrors', {
                    get() {
                        // cache result, since it cannot change
                        if (this.hasOwnProperty('__onlyHasMissingExpectedAnswerErrors')) {
                            return this.__onlyHasMissingExpectedAnswerErrors;
                        }

                        this.__onlyHasMissingExpectedAnswerErrors = this.errors.length > 0;
                        this.errors.forEach(function (error) {
                            if (!error.isA(MissingExpectedAnswer)) {
                                this.__onlyHasMissingExpectedAnswerErrors = false;
                            }
                        }, this);
                        return this.__onlyHasMissingExpectedAnswerErrors;
                    },
                });

                return {
                    initialize(challengeViewModel, errors, info) {
                        this.challengeViewModel = challengeViewModel;
                        this.errors = errors;
                        this.messages = [];
                        this.info = info || {};
                    },

                    hasErrorRelatedToAnswer(answer) {
                        for (const error of this.errors) {
                            if (error.isRelatedToAnswer(answer)) {
                                return true;
                            }
                        }

                        return false;
                    },

                    addMessage(textModel) {
                        this.messages.push(textModel);
                    },

                    asJson() {
                        const asJson = obj => obj.asJson();

                        const json = {
                            challenge_id: this.challenge.id,
                            result: this.result,
                            partiallyCorrect: this.partiallyCorrect,
                            errors: this.errors.map(asJson),
                            challengeResponses: this._challengeResponses.map(asJson),
                            caused_by_event: this.causedByEvent,
                        };

                        if (this.challenge.type === 'UserInputChallengeModel') {
                            return angular.extend(json, this._jsonForUserInputChallenge());
                        }
                        if (this.challenge.type === 'MultipleChoiceChallengeModel') {
                            return angular.extend(json, this._jsonForMultipleChoiceChallenge());
                        }
                        throw new Error('Unexpected challenge type');
                    },

                    _jsonForUserInputChallenge() {
                        return {
                            userAnswerToDisplay: this.userAnswerToDisplay,
                        };
                    },

                    _jsonForMultipleChoiceChallenge() {
                        const toId = vm => vm.model.id;

                        return {
                            availableAnswerIds: this.challengeViewModel.availableAnswersViewModels.map(toId),
                        };
                    },
                };
            });
        },
    ],
);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.MatchingChallengeButton.MatchingChallengeButtonModel',
    [
        '$injector',
        $injector => {
            const UiComponentModel = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentModel',
            );
            const MatchingChallengeButtonViewModel = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.MatchingChallengeButton.MatchingChallengeButtonViewModel',
            );
            const HasTextOrImage = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.Mixins.HasTextOrImage',
            );

            return UiComponentModel.subclass(function () {
                this.alias('ComponentizedFrame.MatchingChallengeButton');
                this.extend({
                    ViewModel: MatchingChallengeButtonViewModel,
                });
                this.setEditorViewModel(
                    'Lesson.FrameList.Frame.Componentized.Component.MatchingChallengeButton.MatchingChallengeButtonEditorViewModel',
                );

                this.include(HasTextOrImage);
                this.setImageContext('image', 'answerButton');
                this.references('challenge').through('challenge_id');

                Object.defineProperty(this.prototype, 'displayName', {
                    value: 'Matching Button',
                });

                return {};
            });
        },
    ],
);

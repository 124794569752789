import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*
    An Answer represents a response to a Challenge.  For MultipleChoice challenges,
    there are several SelectableAnswers from which the user can choose.

    See the following flowchart for a picture of how all the pieces of a
    Multiple Choice Challenge fit together:
    https://pedago.atlassian.net/wiki/display/TECH/Componentized+Frame#ComponentizedFrame-MCChallengeFlowchart

    Example:

    {
        "id": "bbf992ed-6a34-401b-aece-af45e5c3dd3c",
        "component_type": "ComponentizedFrame.SelectableAnswer",
        "text_id": "e6f2f803-0690-486e-889c-4183f466fea7"
    }
*/
angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.Answer.AnswerModel', [
    'Lesson.FrameList.Frame.Componentized.Component.ComponentModel',
    'Lesson.FrameList.Frame.Componentized.Component.Answer.AnswerViewModel',
    (ComponentModel, AnswerViewModel) => {
        const AnswerModel = ComponentModel.subclass(function () {
            this.alias('ComponentizedFrame.Answer');
            this.extend({
                ViewModel: AnswerViewModel,
            });
            this.setEditorViewModel('Lesson.FrameList.Frame.Componentized.Component.Answer.AnswerEditorViewModel');

            return {};
        });

        return AnswerModel;
    },
]);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*
    When this behavior is on, the frame will be considered complete when all of it's
    challenges are complete.

    I can't imagine a situation where this behavior would be turned off.
*/
angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Challenges.Behaviors.CompleteOnAllChallengesComplete',
    [
        'AModuleAbove',
        'Lesson.FrameList.Frame.Componentized.Component.ComponentEventListener',
        (AModuleAbove, ComponentEventListener) =>
            new AModuleAbove({
                included(ChallengesModel) {
                    ChallengesModel.supportBehavior('CompleteOnAllChallengesComplete');

                    ChallengesModel.ViewModel.setCallback('after', 'initialize', function () {
                        const viewModel = this;
                        const listeners = [];

                        viewModel.model.on('behavior_added:CompleteOnAllChallengesComplete', () => {
                            const listener = new ComponentEventListener(viewModel, 'allChallengesComplete', () => {
                                viewModel.complete = true;
                            });
                            listeners.push(listener);
                        });

                        viewModel.model.on('behavior_removed:CompleteOnAllChallengesComplete', () => {
                            while (listeners[0]) {
                                const listener = listeners.pop();
                                listener.cancel();
                            }
                        });
                    });
                },
            }),
    ],
);

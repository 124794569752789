import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*
    The UiComponentDirHelper is used to help create the directives associated with UiComponents.

    Example usage:

        angularModule.directive('cfSomeComponent', [
            'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentDirHelper',

            function(UiComponentDirHelper) {

                return UiComponentDirHelper.getOptions({
                    template: '<div>Some component</div>',
                    link: function(scope, elem) {
                        UiComponentDirHelper.link(scope);
                        ...
                    }
                });

            }
*/
angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentDirHelper', [
    '$injector',
    () => {
        const UiComponentDirHelper = {
            link(scope) {
                Object.defineProperty(scope, 'frameViewModel', {
                    get() {
                        return this.viewModel && this.viewModel.frameViewModel;
                    },
                });

                Object.defineProperty(scope, 'model', {
                    get() {
                        return this.viewModel && this.viewModel.model;
                    },
                });

                function delegateToViewModel(meth) {
                    scope[meth] = function (...args) {
                        return this.viewModel && this.viewModel[meth](...args);
                    };
                }

                delegateToViewModel('viewModelFor');
                delegateToViewModel('viewModelsFor');
            },

            getOptions(overrides) {
                return angular.extend(
                    {
                        restrict: 'E',
                        scope: {
                            viewModel: '<',
                            context: '<',
                        },
                        link: UiComponentDirHelper.link,
                    },
                    overrides,
                );
            },
        };

        return UiComponentDirHelper;
    },
]);

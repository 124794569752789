import { type SnakeCaseKeys } from '@Types';
import { type AccessGroupSnakeCasedAttrs, type AccessGroup } from 'AccessGroup';
import { type Brand } from 'AppBranding';
import { type IguanaObject } from 'Iguana';

export enum InstitutionId {
    quantic = '85fec419-8dc5-45a5-afbd-0cc285a595b9',
    valar = '660f2d81-7ffd-42e4-98b7-47580309f8ca',
    smartly = 'ebc4730d-19d8-4cb3-b2ca-e20308bec8e3',
    miya_miya = 'a297c552-8cef-4c78-83b3-465c8711377c',
}

export interface Institution {
    id: InstitutionId;
    createdAt: number;
    updatedAt: number;
    branding: Brand;
    androidUrl: string;
    domain: string | null;
    external: boolean;
    groups: AccessGroup[];
    iosUrl: string;
    name: string;
    playlistPackIds: string[];
    privacyPolicyUrl: string;
    scormToken: string | null;
    signUpCode: string;
    termsUrl: string;
    disclaimerLocaleKey?: string;
}

export type InstitutionSnakeCasedAttrs = SnakeCaseKeys<Institution> & {
    // Since AccessGroupSnakeCasedAttrs could potentially be more complex than just SnakeCaseKeys<AccessGroup>,
    // we define it explicitly
    groups: AccessGroupSnakeCasedAttrs[];
};

export type InstitutionIguanaObject = IguanaObject<InstitutionSnakeCasedAttrs>;

import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*
    a Component OVerlay is a ui component which inlcudes one main
    component that has one or more other components overlaid on top of it
*/
angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.ComponentOverlay.ComponentOverlayModel', [
    'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentModel',
    'Lesson.FrameList.Frame.Componentized.Component.ComponentOverlay.ComponentOverlayViewModel',
    'Lesson.FrameList.Frame.Componentized.Component.Mixins.HasTextOrImage',
    (UiComponentModel, ComponentOverlayViewModel, HasTextOrImage) =>
        UiComponentModel.subclass(function () {
            this.alias('ComponentizedFrame.ComponentOverlay');
            this.extend({
                ViewModel: ComponentOverlayViewModel,
            });
            this.setEditorViewModel(
                'Lesson.FrameList.Frame.Componentized.Component.ComponentOverlay.ComponentOverlayEditorViewModel',
            );
            this.setImageContext('image', 'secondImage');
            this.references('overlayComponents').through('overlay_component_ids');
            this.key('overlayOptions');
            this.key('background_color');

            // In the past, ComponentOverlayModel only supported images and held a
            // main_component_id. Now we want to support both images and text in a
            // MultipleCardMultipleChoice screen, which uses ComponentOverlayModel (due to it being
            // a hacked overlay_blank, see blank.invisible), so we are adding in support for images
            // and text in ComponentOverlayModel. Supporting images and text allows us to leverage
            // HasTextOrImage, but we'll need to use image_id or text_id properties instead of
            // main_component_id. Use this callback to be compatible with old content in the
            // new system.
            // See https://trello.com/c/H2oBiOnj
            this.setCallback('after', 'copyAttrsOnInitialize', function () {
                if (this.main_component_id) {
                    this.image_id = this.main_component_id;

                    // FIXME: Once all clients support Text or Image instead of MainComponent
                    // we can uncomment this line to slowly cleanup old content
                    // See https://trello.com/c/e5vFpRcK
                    // delete this.main_component_id;
                }
            });

            this.include(HasTextOrImage);

            return {
                optionsFor(component) {
                    if (this.overlayOptions[component.id]) {
                        return this.overlayOptions[component.id];
                    }
                    throw new Error(`No overlayOptions found for component "${component.id}"`);
                },
            };
        }),
]);

/* eslint-disable radix */
import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import template from 'Lessons/angularModule/views/lesson/frame_list/frame/componentized/component/matching_board/matching_board.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('cfMatchingBoard', [
    '$injector',
    $injector => {
        const $timeout = $injector.get('$timeout');
        const UiComponentDirHelper = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentDirHelper',
        );
        const AnswerButtonsDirHelper = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.AnswerList.AnswerButtonsDirHelper',
        );

        return UiComponentDirHelper.getOptions({
            templateUrl,

            link(scope, elem) {
                UiComponentDirHelper.link(scope, elem);

                // When content is right-to-left, we reverse the columns
                scope.reverseDirection =
                    scope.viewModel &&
                    scope.viewModel.playerViewModel &&
                    scope.viewModel.playerViewModel.lesson &&
                    scope.viewModel.playerViewModel.lesson.localeDirection === 'rtl';

                //-------------------------
                // Display Helpers
                //-------------------------

                AnswerButtonsDirHelper.setupButtonClassWatch(scope, {
                    getComponents() {
                        return this.model.matchingChallengeButtons;
                    },

                    // Each list of buttons (the challenges on the left
                    // and the answers on the right), are in a single column.
                    useSingleColumn() {
                        return true;
                    },
                });

                //-------------------------
                // Layout Calculations
                //-------------------------

                // toggle to differentiate between initial rendering and translated rendering
                let initialLayoutComplete = false;

                // eslint-disable-next-line no-shadow
                function setMidpointY(elem, midpointY, reverse) {
                    const height = elem.outerHeight();
                    const y = midpointY - height / 2;
                    let translateOffset;

                    // for initial layout, place in initial absolute position
                    if (!initialLayoutComplete) {
                        elem.css('top', `${y}px`);
                        translateOffset = 0;
                    } else {
                        // determine the offset from the current location to the desired location
                        const currentTop = parseInt(elem.css('top'));
                        translateOffset = y - currentTop;
                    }

                    let translateValue = `translateY(${translateOffset}px)`;

                    // make sure that RTL languages are reversed (we undo the this transform in the CSS in matching_challenge_button.scss)
                    if (reverse) {
                        translateValue += ' scaleX(-1)';
                    }

                    // translate the element to target location
                    elem.css({
                        '-webkit-transform': translateValue,
                        '-ms-transform': translateValue,
                        '-o-transform': translateValue,
                        transform: translateValue,
                    });
                }

                scope.$emit('frame:rendering', scope.$id);

                function repositionButtons() {
                    // get listing of answer button elements
                    const answerButtons = elem.find('cf-answer-list button');

                    // we're here prior to the initial render cycle actually completing, which happens
                    // in angular 1.4.0-rc1 after a frame transition (see: dynamic_node_dir.js)
                    if (answerButtons.eq(0).outerHeight() <= 0) {
                        $timeout(() => {
                            repositionButtons();
                        }, 10);
                        return;
                    }

                    // get margins for height determination
                    const // pull margin from css declaration
                        marginRule = answerButtons.css('marginBottom') || '0px';

                    const margin = Math.abs(parseInt(marginRule));

                    // begin layout
                    let lastRowBottomY = 0;

                    // get viewModels and answer button element
                    scope.viewModel.answerListViewModel.orderedAnswerViewModels.forEach((answerViewModel, i) => {
                        const challengeViewModel = scope.viewModel.orderedMatchingChallengeButtonViewModels[i];
                        const answerButton = elem.find(
                            `cf-answer-list [component-id="${answerViewModel.model.id}"]button`,
                        );

                        // validate answer button exists
                        if (answerButton.length === 0) {
                            throw new Error('No answer button found.');
                        }

                        // validate challenge button exists
                        const challengeButton = elem.find(
                            `cf-matching-challenge-button[component-id="${challengeViewModel.model.id}"] button`,
                        );
                        if (challengeButton.length === 0) {
                            throw new Error('No challenge button found.');
                        }

                        // calculate positioning for row items
                        const rowHeight = Math.max(answerButton.outerHeight(), challengeButton.outerHeight());

                        const rowMiddle = lastRowBottomY + rowHeight / 2;

                        // update positioning
                        setMidpointY(answerButton, rowMiddle);
                        setMidpointY(challengeButton, rowMiddle, scope.reverseDirection);
                        lastRowBottomY = lastRowBottomY + rowHeight + margin;
                    });

                    // update height so that the app-main-container is sized appropriately and scrolling works
                    elem.height(lastRowBottomY);

                    // keep track of initial layout
                    scope.$emit('frame:rendered', scope.$id);
                    initialLayoutComplete = true;
                }

                //-------------------------
                // Watches
                //-------------------------

                // This repositionTrigger craziness is to make sure that we only
                // run repositionButtons once per digest, even if multiple things
                // have changed

                scope.$watchCollection('viewModel.answerListViewModel.orderedAnswerViewModels', () => {
                    scope.repositionTrigger = Math.random();
                });

                // if the underlying order of the challenges changes, then we
                // need to redo the initial layout
                scope.$watchCollection('model.matchingChallengeButtons', () => {
                    initialLayoutComplete = false;
                    scope.repositionTrigger = Math.random();
                });

                scope.$watchCollection('viewModel.orderedMatchingChallengeButtonViewModels', () => {
                    scope.repositionTrigger = Math.random();
                });

                scope.$on('frame_container_resized', () => {
                    scope.repositionTrigger = Math.random();
                });

                scope.$watch('repositionTrigger', () => {
                    $timeout(() => {
                        repositionButtons();
                    }, 0);
                });
            },
        });
    },
]);

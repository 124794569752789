import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*

 */
angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.MultipleChoiceMessage.MultipleChoiceMessageModel',
    [
        'Lesson.FrameList.Frame.Componentized.Component.ComponentModel',
        'Lesson.FrameList.Frame.Componentized.Component.MultipleChoiceMessage.MultipleChoiceMessageViewModel',

        (ComponentModel, MultipleChoiceMessageViewModel) =>
            ComponentModel.subclass(function () {
                this.alias('ComponentizedFrame.MultipleChoiceMessage');
                this.extend({
                    ViewModel: MultipleChoiceMessageViewModel,
                });
                this.setEditorViewModel(
                    'Lesson.FrameList.Frame.Componentized.Component.MultipleChoiceMessage.MultipleChoiceMessageEditorViewModel',
                );

                this.references('challenge').through('challenge_id');
                this.references('answerMatcher').through('answer_matcher_id');
                this.references('messageText').through('message_text_id');

                this.key('event');

                Object.defineProperty(this.prototype, 'displayName', {
                    value: 'Answer Message',
                });

                return {
                    appliesToAnswer(answer) {
                        return this.answerMatcher.matches(answer);
                    },
                };
            }),
    ],
);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import 'ExtensionMethods/array';

angularModule.factory('Lesson.FrameList.Frame.NoInteraction.NoInteractionFrameViewModel', [
    '$injector',

    $injector => {
        const ComponentizedBaseFrameViewModel = $injector.get(
            'Lesson.FrameList.Frame.Componentized.ComponentizedBaseViewModel',
        );
        return ComponentizedBaseFrameViewModel.subclass(function () {
            Object.defineProperty(this.prototype, 'complete', {
                get() {
                    return true;
                },
                configurable: true,
            });

            // FIXME: add a helper for creating these getters
            Object.defineProperty(this.prototype, 'mainTextComponentViewModel', {
                get() {
                    const mainTextComponent = this.frame.mainTextComponent;
                    if (mainTextComponent) {
                        return this.viewModelFor(mainTextComponent);
                    }

                    return undefined;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'topContextImageViewModel', {
                get() {
                    const topContextImage = this.frame.topContextImage;
                    if (topContextImage) {
                        return this.viewModelFor(topContextImage);
                    }

                    return undefined;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'bottomContextImageViewModel', {
                get() {
                    const bottomContextImage = this.frame.bottomContextImage;
                    if (bottomContextImage) {
                        return this.viewModelFor(bottomContextImage);
                    }

                    return undefined;
                },
                configurable: true,
            });

            return {};
        });
    },
]);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*
    The AlwaysReadyContinueButton looks for a specific list of states on the AlwaysReady component
    to determine it's own state at any particular time.

    The targetComponent should have exactly one of these states at all times:

    * waitingForAnswer
	* readyToValidate
	* ready

	The targetComponent can also set any of these flags to true at any time:

	* hasInvalidAnswer
*/
angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.ContinueButton.AlwaysReadyContinueButton.AlwaysReadyContinueButtonModel',
    [
        'Lesson.FrameList.Frame.Componentized.Component.ContinueButton.ContinueButtonModel',
        'Lesson.FrameList.Frame.Componentized.Component.ContinueButton.AlwaysReadyContinueButton.AlwaysReadyContinueButtonViewModel',
        (ContinueButtonModel, AlwaysReadyContinueButtonViewModel) =>
            ContinueButtonModel.subclass(function () {
                this.alias('ComponentizedFrame.AlwaysReadyContinueButton');
                this.extend({
                    ViewModel: AlwaysReadyContinueButtonViewModel,
                });
                this.setEditorViewModel(
                    'Lesson.FrameList.Frame.Componentized.Component.ContinueButton.AlwaysReadyContinueButton.AlwaysReadyContinueButtonEditorViewModel',
                );

                return {};
            }),
    ],
);

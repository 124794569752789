import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*

    An AnswerMatcher is used by a ChallengeValidator when validating the userAnswers for
    a challenge.

    For example, if a ChallengeValidator uses the HasNoUnexpectedAnswers behavior, it will
    go through each of the userAnswers and compare them against all of the AnswerMatchers by
    calling answerMatcher.matches(answer).  If any of the answers are not matched by an answerMatcher,
    then the validation will fail.

    See the following flowchart for a picture of how all the pieces of a
    Multiple Choice Challenge fit together:
    https://pedago.atlassian.net/wiki/display/TECH/Componentized+Frame#ComponentizedFrame-MCChallengeFlowchart

*/
angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.AnswerMatcher.AnswerMatcherModel', [
    'Lesson.FrameList.Frame.Componentized.Component.ComponentModel',
    ComponentModel => {
        const AnswerMatcherModel = ComponentModel.subclass(function () {
            this.alias('ComponentizedFrame.AnswerMatcher');
            this.extend({
                // answerMatchers have no state, so need no viewModel
                ViewModel: null,
            });
            this.setEditorViewModel(
                'Lesson.FrameList.Frame.Componentized.Component.AnswerMatcher.AnswerMatcherEditorViewModel',
            );

            return {
                matchesAny(challengeResponses) {
                    for (const challengeResponse of challengeResponses) {
                        if (this.matches(challengeResponse)) {
                            return true;
                        }
                    }

                    return false;
                },

                matches() {
                    throw new Error(`subclasses of AnswerMatcherModel should define matches.  ${this.type} does not.`);
                },
            };
        });

        return AnswerMatcherModel;
    },
]);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*
    When this behavior is on, any time an answer is selected and has not yet
    been validated, the challenge will be readyToValidate
*/
angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.Behaviors.ReadyToValidateWhenAnswerIsSelected',
    [
        'AModuleAbove',
        AModuleAbove =>
            new AModuleAbove({
                included(MultipleChoiceChallengeModel) {
                    MultipleChoiceChallengeModel.supportBehavior('ReadyToValidateWhenAnswerIsSelected');

                    MultipleChoiceChallengeModel.ViewModel.setCallback('after', 'initialize', function () {
                        const challengeViewModel = this;

                        this.model.on('behavior_added:ReadyToValidateWhenAnswerIsSelected', () => {
                            challengeViewModel._readyToValidateGetter = function () {
                                for (const answerViewModel of this.selectedAnswersViewModels) {
                                    if (!answerViewModel.showingValidatedStyling) {
                                        return true;
                                    }
                                }

                                return false;
                            };
                        });

                        this.model.on('behavior_removed:ReadyToValidateWhenAnswerIsSelected', () => {
                            challengeViewModel._readyToValidateGetter = undefined;
                        });
                    });
                },
            }),
    ],
);

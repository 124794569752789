import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentViewModel',

    [
        'Lesson.FrameList.Frame.Componentized.Component.ComponentViewModel',

        ComponentViewModel =>
            ComponentViewModel.subclass(function () {
                Object.defineProperty(
                    this.prototype,
                    'directiveName',
                    {
                        get() {
                            if (!this._directiveName) {
                                throw new Error(
                                    `Subclasses of UiComponentViewModel should define directiveName. "${this.type}" does not.`,
                                );
                            } else {
                                return this._directiveName;
                            }
                        },
                        set(override) {
                            this._directiveName = override;
                        },
                    },
                    true,
                );

                return {};
            }),
    ],
);

/* eslint-disable func-names */
import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import 'ExtensionMethods/array';

angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.ComponentModel', [
    'Iguana',
    'Lesson.FrameList.Frame.Componentized.Component.ComponentViewModel',
    'Lesson.FrameList.Frame.Componentized.Component.ComponentReference',
    'guid',
    '$injector',

    (Iguana, ViewModel, ComponentReference, guid, $injector) => {
        const $q = $injector.get('$q');
        const Locale = $injector.get('Locale');
        const $window = $injector.get('$window');
        const EventLogger = $injector.get('EventLogger');
        const $timeout = $injector.get('$timeout');
        const frontRoyalStore = $injector.get('frontRoyalStore');

        return Iguana.subclass(function () {
            this.setSciProperty('component_type');
            this.alias('Lesson.FrameList.Frame.Componentized.Component.ComponentModel');
            this.extend({
                ViewModel,
            });

            this.embeddedIn('frame');

            this.extendableArray('referenceIdKeys');
            this.extendableArray('referenceKeys');
            this.extendableArray('referenceClasses');
            this.extendableArray('supportedBehaviors');
            this.extendableArray('imageProcessors');
            this.extendableObject('_imageContexts');

            this.extend({
                // we have to keep a reference to the name of the model and inject it later
                // in order to avoid circular reference errors
                setEditorViewModel(path) {
                    Object.defineProperty(this, 'EditorViewModel', {
                        get() {
                            return $injector.get(path);
                        },
                    });
                },

                references(key) {
                    // keep track of this reference in the referenceKeys array
                    this.referenceKeys().push(key);

                    // return an object with a through() method, allowing some nice syntax
                    // for assiging the id
                    return {
                        through: idKey => {
                            const modelKlass = this;
                            const Reference = ComponentReference.subclass(function () {
                                this.extend({
                                    modelKlass,
                                    key,
                                    idKey,
                                });
                            });
                            this.referenceClasses().push(Reference);
                            const referenceProp = `$$referenceFor_${key}`;

                            Object.defineProperty(this.prototype, key, {
                                get() {
                                    if (!this[referenceProp]) {
                                        this[referenceProp] = new Reference(this);
                                    }
                                    return this[referenceProp].get();
                                },
                                set(modelOrModels) {
                                    if (!this[referenceProp]) {
                                        this[referenceProp] = new Reference(this);
                                    }
                                    const val = this[referenceProp].set(modelOrModels);
                                    this.triggerCallbacks(`set:${key}`, val);
                                },
                            });

                            // keep track of all references so we can clean up references when a component
                            // is removed
                            this.referenceIdKeys().push(idKey);
                        },
                    };
                },

                // FIXME: should this be in Iguana itself?
                key(key, transform) {
                    const internalKey = `$$___${key}`;

                    this.setCallback('after', 'copyAttrsOnInitialize', function () {
                        // if this property already exists, copy it to the internal key
                        if (Object.prototype.hasOwnProperty.call(this, key)) {
                            this[internalKey] = this[key];
                        }

                        // this has to be defined on the object, rather than the
                        // prototype, so it will should up in the json for the object
                        Object.defineProperty(this, key, {
                            get() {
                                return this[internalKey];
                            },
                            set(val) {
                                if (transform) {
                                    val = transform(val);
                                }

                                // do not trigger callbacks if value is not changing
                                const oldVal = this[internalKey];
                                if (val === oldVal) {
                                    return;
                                }
                                this[internalKey] = val;
                                this.triggerCallbacks(`set:${key}`, val, oldVal);
                            },
                            enumerable: true, // enumerable so it will be in the json
                        });
                    });
                },

                supportBehavior(name) {
                    this.supportedBehaviors().push(name);
                },

                supportsBehavior(name) {
                    return this.supportedBehaviors().includes(name);
                },

                setImageContext(key, context) {
                    this._imageContexts().set(key, context);
                },
            });

            this.setEditorViewModel('Lesson.FrameList.Frame.Componentized.Component.ComponentEditorViewModel');

            this.key('editor_template');

            Object.defineProperty(this.prototype, 'componentName', {
                get() {
                    try {
                        if (!this.$$_componentName) {
                            const parts = this.component_type.split('.');
                            this.$$_componentName = parts[parts.length - 1];
                        }
                        return this.$$_componentName;
                    } catch (e) {
                        // subclasses created for tests sometimes don't have an iguana_type
                        return 'UnidentifiedComponent';
                    }
                },
            });

            // subclasses can override this. This is the user-facing
            // name of the component.  Probably should only be ever used
            // in the editor.  Currently used in the lesson-diff stuff
            Object.defineProperty(this.prototype, 'displayName', {
                get() {
                    return this.componentName;
                },
            });

            Object.defineProperty(this.prototype, 'type', {
                get() {
                    if (!this.$$_type) {
                        this.$$_type = `${this.componentName}Model`;
                    }
                    return this.$$_type;
                },
            });

            Object.defineProperty(this.prototype, 'lesson', {
                get() {
                    return this.frame() ? this.frame().lesson() : undefined;
                },
            });

            Object.defineProperty(this.prototype, 'editorViewModel', {
                get() {
                    return this.frame().editorViewModelFor(this);
                },
            });

            // see comment in componentized.js text_content getter/setter
            Object.defineProperty(this.prototype, 'text_content', {
                get() {
                    return this.mainTextComponent && this.mainTextComponent.text;
                },
                set(val) {
                    if (!this.mainTextComponent) {
                        const TextModel = $injector.get(
                            'Lesson.FrameList.Frame.Componentized.Component.Text.TextModel',
                        );
                        this.editorViewModel.mainTextComponent = TextModel.EditorViewModel.addComponentTo(
                            this.frame(),
                        ).model;
                    }
                    this.mainTextComponent.text = val;
                },
                configurable: true,
            });

            // any component used as a mainUiComponent should define a
            // getter for mainTextComponent on it's model.  It's editorViewModel should
            // define setMainTextComponent
            Object.defineProperty(this.prototype, 'mainTextComponent', {
                get() {
                    throw new Error(
                        `Components that are used as mainUiComponents should define a getter for mainTextComponent. ${this.type} does not`,
                    );
                },
            });

            Object.defineProperty(this.prototype, 'localeObject', {
                get() {
                    let localeObject = this.lesson && this.lesson.localeObject;
                    if (!localeObject) {
                        localeObject = Locale.english;
                    }
                    return localeObject;
                },
                configurable: true,
            });

            // Setup special behavior processing.  We want to trigger callbacks
            // whenever behaviors are added or removed.  We can't do this on
            // the prototype because we want to to be included in the json, so
            // we do it in an after initialize callback.
            this.setCallback('after', 'copyAttrsOnInitialize', function () {
                // first, set up a getter/setter on the behaviors object itself,
                // so if the whole thing is replaced (with model.behaviors = {...}), the
                // callbacks will still work
                const internalBehaviorsKey = '$$___behaviors';
                this[internalBehaviorsKey] = this.behaviors || {};

                Object.defineProperty(this, 'behaviors', {
                    get() {
                        return this[internalBehaviorsKey];
                    },
                    set(newValue) {
                        newValue = angular.extend({}, newValue); // clone it
                        const behaviors = this[internalBehaviorsKey];

                        // reset the current values
                        angular.forEach(behaviors, (_, key) => {
                            behaviors[key] = newValue[key];
                            delete newValue[key];
                        });

                        // add any new values
                        angular.forEach(newValue, (_config, key) => {
                            behaviors[key] = newValue[key];
                        });
                    },
                    enumerable: true, // enumberable so it will be in json
                });

                // then create a getter/setter on the behaviors hash for each
                // behavior that has been added with supportBehavior.  When any of those is
                // added or removed, a callback will be triggered
                this.constructor.supportedBehaviors().forEach(name => {
                    const internalKey = `$$___${name}`;
                    const initialValue = this.behaviors[name];
                    const model = this;

                    Object.defineProperty(this.behaviors, internalKey, {
                        writable: true,
                        enumerable: false,
                    }); // make the internal key non-enumerable, so it won't show up when using angular.forEach

                    Object.defineProperty(this.behaviors, name, {
                        get() {
                            return this[internalKey];
                        },
                        set(val) {
                            const currentVal = this[internalKey];
                            if (angular.equals(val, currentVal)) {
                                return;
                            }

                            const fireRemoved = !!currentVal;
                            const fireAdded = !!val;

                            this[internalKey] = val;
                            if (fireRemoved) {
                                model.triggerCallbacks(`behavior_removed:${name}`);
                            }
                            if (fireAdded) {
                                model.triggerCallbacks(`behavior_added:${name}`, val);
                            }
                        },
                        enumerable: true,
                    });

                    if (angular.isDefined(initialValue)) {
                        this.behaviors[name] = initialValue;
                    }
                });
            });

            return {
                initialize($super, attrs) {
                    attrs = angular.extend(
                        {
                            id: guid.generate(),
                        },
                        attrs,
                    );
                    this.$$callbacks = {};
                    this.$$listeners = [];
                    $super(attrs);
                },

                clone(newId) {
                    const Model = this.constructor;
                    const json = this.asJson();
                    if (newId) {
                        delete json.id;
                    }
                    return Model.new(json);
                },

                createViewModel(frameViewModel) {
                    return new this.constructor.ViewModel(frameViewModel, this);
                },

                isReference(key) {
                    return this.constructor.referenceKeys().includes(key);
                },

                hasReferenceThrough(idKey) {
                    return this.constructor.referenceIdKeys().includes(idKey);
                },

                supportsBehavior(name) {
                    return this.constructor.supportsBehavior(name);
                },

                // FIXME: should this on/off stuff be in Iguana itself?
                on(event, callback, options) {
                    // support old api
                    if (options === true || options === false) {
                        options = {
                            runNowOnSet: options,
                        };
                    }
                    options = options || {};
                    options.priority = options.priority || 0;
                    const runNowOnSet = options.runNowOnSet;

                    if (event[0] === '.') {
                        return this._setCallbackOnReference(event, callback, options);
                    }

                    this.$$callbacks[event] = this.$$callbacks[event] || [];
                    this.$$callbacks[event].push({
                        callback,
                        options,
                    });

                    // special handling of behavior_added, so it
                    // will be called right away if the behavior
                    // is already there
                    if (event.match(/behavior_added/)) {
                        const behavior = _.last(event.split(':'));
                        if (!this.supportsBehavior(behavior)) {
                            throw new Error(
                                `Behavior "${behavior}" is not supported.  Call ${this.type}.supportBehavior("${behavior}")`,
                            );
                        }
                        if (this.includesBehavior(behavior)) {
                            callback.apply(this, [this.optionsForBehavior(behavior)]);
                        }
                    } else if (event.slice(0, 3) === 'set') {
                        if (runNowOnSet) {
                            const prop = event.slice(4);
                            callback(this[prop]);
                        }
                    }

                    const listener = {
                        cancel: () => {
                            this.off(event, callback);
                        },
                    };
                    this.$$listeners.push(listener);
                    return listener;
                },

                referencedComponents(options, fn) {
                    options = options || {};
                    const skipReferences = options.skipReferences || [];

                    const onlyType = options.only || false;

                    let referencedComponents = [];

                    this.constructor.referenceKeys().forEach(key => {
                        if (skipReferences.includes(key)) {
                            return;
                        }
                        let value = this[key];
                        let arr = [];

                        if (_.isArray(value)) {
                            if (onlyType) {
                                value = value.filter(v => v.isA && v.isA(onlyType));
                            }
                            referencedComponents = referencedComponents.concat(value);
                            arr = value;
                        } else if (value) {
                            if (onlyType && !value.isA(onlyType)) {
                                return;
                            }

                            referencedComponents.push(value);
                            arr = [value];
                        }

                        if (fn) {
                            arr.forEach(component => {
                                fn(component, key);
                            });
                        }
                    });

                    return referencedComponents;
                },

                /*
                    Return all components that are referenced by this one, following
                    the tree all the way down.
                */
                indirectlyReferencedComponents(options = []) {
                    const referencedComponentsSet = {};

                    function addReferencedComponents(component) {
                        if (!component) {
                            return;
                        }
                        referencedComponentsSet[component.id] = component;

                        component.referencedComponents(options).forEach(referencedComponent => {
                            if (!referencedComponentsSet[referencedComponent.id]) {
                                addReferencedComponents(referencedComponent);
                            }
                        });
                    }

                    addReferencedComponents(this);

                    return Object.values(referencedComponentsSet);
                },

                recursivelyPreloadImages() {
                    return this.recursivelyProcessImages(url => this.preloadImage(url));
                },

                // NOTE: this method does not use `this` internally, except to call
                // `this.frame`.  So, it really makes no difference which component
                // in a frame preloads an image. We got into this state because
                // of a refactor done https://github.com/quanticedu/back_royal/pull/7043. Really,
                // this method should probably be moved to the frame itself, but it makes no
                // practical difference, and I didn't feel like refactoring all the specs and
                // things over again.
                preloadImage(url) {
                    // `getStoredImageIntoCache` returns a native promise, so wrap it
                    // in $q so that it plays nice with angular
                    const cachePromise = frontRoyalStore.getStoredImageIntoTemporaryCache(url, $injector);
                    return $q.when(cachePromise).then(dataUrlIsNowCached => {
                        if (dataUrlIsNowCached) {
                            return true;
                        }

                        this.frame().$$_preloadPromises = this.frame().$$_preloadPromises || {};
                        const preloadPromises = this.frame().$$_preloadPromises;

                        if (!preloadPromises[url]) {
                            const frame = this.frame();
                            const baseDelay = 500;
                            const maxDelay = 5000;

                            const promise = $q(resolve => {
                                // have the browser load the upcoming images with single-retry cache-busting logic
                                // upon error. if we attempt a retry, update the frame's image references with the
                                // newly rewritten URL. finally, ensure that error / succes handles are cleaned up.
                                const img = new $window.Image();

                                if (!url) {
                                    resolve();
                                    return;
                                }

                                let retries = 0;
                                img.retried = false;

                                const onError = () => {
                                    if (retries === 3) {
                                        EventLogger.log(
                                            'image:load_failure',
                                            angular.extend(frame.logInfo(), {
                                                src: img.src,
                                                label: img.src,

                                                // see https://trello.com/c/iLC4Bz4p
                                                params: {
                                                    screenWidth: $window.innerWidth,
                                                    screenHeight: $window.innerHeight,
                                                },
                                            }),
                                            { segmentio: false },
                                        );
                                    }
                                    retries += 1;

                                    // exponential backoff
                                    const delay = retries ? Math.min(maxDelay, baseDelay * retries ** 2) : baseDelay;

                                    $timeout(() => {
                                        // generate a new url
                                        const newUrl = url + (!url.includes('?') ? '?g=' : '&g=') + guid.generate();

                                        // tell the frame that we are altering this url
                                        frame.useAlteredUrl(url, newUrl);

                                        // retry the preload
                                        img.src = newUrl;
                                    }, delay);
                                };
                                img.onabort = onError;
                                img.onerror = onError;

                                img.onload = () => {
                                    img.onerror = null;
                                    img.onabort = null;
                                    img.onload = null;
                                    resolve();
                                };

                                img.src = url; // kick off the loading process
                            });

                            preloadPromises[url] = promise;
                        }

                        return preloadPromises[url];
                    });
                },

                recursivelyGetImageUrls() {
                    return this.recursivelyProcessImages(url => $q.when(url));
                },

                // This method takes a processer and runs it on every image referenced by this
                // component or referenced by a component that this one references.  We do things
                // recursively in this way to make sure we don't process unused image models.
                // We use this to determine all of the image urls needed for a frame so that
                // we can preload them, either for the player or for offline mode.
                recursivelyProcessImages(processImage, alreadyVisitedComponentIds = {}) {
                    const promises = [];
                    let results = [];

                    function pushResults(_results) {
                        results = results.concat(_results);
                    }

                    this.referencedComponents({}, (component, key) => {
                        if (component.type === 'ImageModel') {
                            const image = component;
                            const context = this.imageContext(key);
                            const url = image.urlForContext(context);
                            const promise = processImage(url).then(pushResults);
                            promises.push(promise);
                        }

                        if (!alreadyVisitedComponentIds[component.id]) {
                            alreadyVisitedComponentIds[component.id] = true;
                            promises.push(
                                component
                                    .recursivelyProcessImages(processImage, alreadyVisitedComponentIds)
                                    .then(pushResults),
                            );
                        }
                    });

                    // imageProcessors is for images that are not directly referenced,
                    // i.e. inline images in text models.
                    this.constructor.imageProcessors().forEach(handler => {
                        promises.push(handler.apply(this, [processImage]).then(pushResults));
                    });

                    // return promise
                    return $q.all(promises).then(() => results);
                },

                // This method is overridden in SelectableAnswerModel, since in
                // that case there is more complex logic to determine which
                // context to use
                imageContext(key) {
                    const context = this.constructor._imageContexts()[key];
                    if (!context) {
                        $injector
                            .get('ErrorLogService')
                            .notify(new Error(`No image context defined for "${key}"`), undefined, {
                                lesson_id: this.lesson ? this.lesson.id : undefined,
                                frame_id: this.frame() ? this.frame().frame_id : undefined,
                                frame_index: this.frame() ? this.frame().index() : undefined,
                                component_id: this.component_id,
                                component_type: this.type,
                            });
                    }
                    return typeof context === 'function' ? context.apply(this, [key]) : context;
                },

                _setCallbackOnReference(event, callback, options) {
                    // event will look like ".prop:event" or ".prop.subprop:event"
                    let prop = event.match(/\.([^.:]+)/)[1];
                    let newEvent = event.slice(prop.length + 1);
                    if (newEvent[0] === ':') {
                        newEvent = newEvent.slice(1);
                    }
                    let listenOnChildren = false;
                    if (prop.match(/\[\]$/)) {
                        prop = prop.slice(0, -2);
                        listenOnChildren = true;
                    }

                    let listenersOnReference = [];

                    function cancelListenersOnReference() {
                        listenersOnReference.forEach(listener => {
                            listener.cancel();
                        });
                        listenersOnReference = [];
                    }

                    const onPropSet = val => {
                        cancelListenersOnReference();

                        if (val && val.on && !listenOnChildren) {
                            listenersOnReference.push(val.on(newEvent, callback, options));
                        } else if (val && val.on && listenOnChildren) {
                            listenersOnReference.push(
                                val.on('childAdded', child => {
                                    listenersOnReference.push(child.on(newEvent, callback, options));
                                }),
                            );
                            listenersOnReference.push(
                                val.on('childRemoved', child => {
                                    child.off(newEvent, callback);
                                }),
                            );
                        }
                    };
                    onPropSet(this[prop]);

                    const setListener = this.on(`set:${prop}`, onPropSet);
                    const listener = {
                        cancel() {
                            setListener.cancel();
                            cancelListenersOnReference();
                        },
                    };
                    this.$$listeners.push(listener);
                    return listener;
                },

                off(event, callback) {
                    if (!event) {
                        // It's not good enough to clear out the callbacks, because
                        // we may have setup nested listeners
                        this.$$listeners.forEach(listener => {
                            listener.cancel();
                        });
                        this.$$callbacks = {};
                    } else {
                        this.$$callbacks[event] = this.$$callbacks[event] || [];
                        const callbacks = this.$$callbacks[event] || [];
                        const entriesToRemove = [];
                        callbacks.forEach(entry => {
                            if (entry.callback === callback) {
                                entriesToRemove.push(entry);
                            }
                        });
                        entriesToRemove.forEach(entry => {
                            Array.remove(callbacks, entry);
                        });
                    }
                },

                triggerCallbacks(event, ...args) {
                    const entries = this.$$callbacks[event];
                    if (!entries) {
                        return;
                    }

                    /*
                        copying the entries over into a new array
                        sovles two issues:

                        1. We need to make sure to preserve the
                            original ordering when priorities are
                            equal, and this gives us a chance
                            to record the original index. (I originally
                            thought this would not be necessary, that sort
                            would by default preserve the original
                            order, but it seems like sometimes it does
                            and sometimes it does not.)
                        2. We need to clone the array so that no
                            problems are caused if a callback removes
                            one of the callbacks in the entries array (most
                            likely it might remove itself)
                    */
                    const reorderedEntries = [];
                    entries.forEach((entry, i) => {
                        reorderedEntries.push(
                            angular.extend({}, entry, {
                                origIndex: i,
                            }),
                        );
                    });

                    // a callback might cancel itself, which could cause troubles
                    // as we're looping through the array, so we need to slice in order
                    // to clone it
                    reorderedEntries.sort((a, b) => {
                        if (a.options.priority !== b.options.priority) {
                            return a.options.priority > b.options.priority ? 1 : -1;
                        }
                        return a.origIndex > b.origIndex ? 1 : -1;
                    });
                    reorderedEntries.forEach(entry => {
                        entry.callback.apply(this, args);
                    });
                },

                includesBehavior(behavior) {
                    return !!this.behaviors && !!this.behaviors[behavior];
                },

                optionsForBehavior(behavior) {
                    return (this.behaviors && this.behaviors[behavior]) || {};
                },

                remove() {
                    // if the component has already been removed, do nothing
                    if (!this.frame()) {
                        return;
                    }

                    this.triggerCallbacks('remove');
                    this.off();
                    this.frame().removeComponent(this);
                },

                toJasminePP() {
                    return `${this.type}:${this.id}`;
                },
            };
        });
    },
]);

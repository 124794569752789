import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*
    Represents a single blank inside of text, created by ProcessesChallengeBlanks
*/

import template from 'Lessons/angularModule/views/lesson/frame_list/frame/componentized/component/challenge/challenge_blank.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('cfChallengeBlank', [
    '$injector',

    $injector => {
        const UiComponentDirHelper = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentDirHelper',
        );
        const ChallengeBlankDirHelper = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.Challenge.ChallengeBlankDirHelper',
        );
        const ComponentEventListener = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.ComponentEventListener',
        );
        const $interval = $injector.get('$interval');
        const $timeout = $injector.get('$timeout');
        const safeApply = $injector.get('safeApply');
        const safeDigest = $injector.get('safeDigest');
        const Capabilities = $injector.get('Capabilities');

        return UiComponentDirHelper.getOptions({
            // since these can come from mathjax, which won't let us use a
            // non-standard attribute name, we need to support attributes
            // as well as elements
            restrict: 'EA',
            transclude: true,
            templateUrl,
            link(scope, element, attrs, $ctrl, $transclude) {
                // eslint-disable-line

                UiComponentDirHelper.link(scope, element);
                ChallengeBlankDirHelper.link(scope);

                /* *************** Applicable to MultipleChoiceChallenge and UserInputChallenge **** */

                // inline is true for fill_in_the_blanks and compose_blanks, false for
                // blanks_on_image and compose_blanks_on_image
                scope.inline = () => angular.isDefined(attrs.inline) || angular.isDefined(attrs.withinMathjax);

                // When stretchToFit is true, then all elements will stretch to the
                // full width of the blank (i.e. width: 100%, height: 100%)
                //
                // stretchToFit is never true when inline is true.  It is true
                // for the _on_image editor_templates, except for when the 'Reset Dimensions'
                // button is pressed, at which point we want the blank to be resized based
                // on the content within it, and so we don't want the content within it
                // to stretch.
                scope.stretchToFit = () => attrs.stretchToFit === 'true';

                // If the click target is clicked when the challenge is
                // not active, then setting the challenge to active will
                // be sufficient to focus the input box (since this is a
                // click event, iOS will let us give focus.)
                //
                // If the challenge is already active, we may still need
                // to set focus on the input box.
                scope.onClickTargetClicked = () => {
                    // don't allow completed challenges to be re-selected, unless editing
                    if (scope.viewModel.complete && !scope.frameViewModel.editorMode) {
                        return;
                    }

                    if (!scope.viewModel.active) {
                        scope.viewModel.active = true;
                    } else if (scope.setFocus) {
                        scope.setFocus(true);
                    }
                };

                /*
                    Another mathjax hack.  Mathjax uses the little-known
                    'clip' css property to hide stuff that is bigger
                    that it thinks it should be.  I don't know what things
                    it is trying to hide, but it is hiding the edges of
                    our inputs.  So, if we're inside mathjax then we will crawl up the
                    DOM looking for an element with 'clip' set and unset it, thus
                    revealing the edges of our input boxes.
                */
                if (angular.isDefined(attrs.withinMathjax)) {
                    $timeout(() => {
                        let parent = element.parent();
                        while (parent) {
                            const parentHTML = parent.get(0);
                            // Break on top-level MathJax element, which contains the class "MathJax"
                            if (
                                !angular.isDefined(parentHTML) ||
                                !parentHTML.classList ||
                                parentHTML.classList.contains('MathJax') ||
                                parentHTML.classList.contains('MathJax_CHTML')
                            ) {
                                break;
                            }
                            // I've only seen 'auto', but being defensive
                            if (parent.css('clip') && parent.css('clip') !== '' && parent.css('clip') !== 'auto') {
                                parent.css('clip', 'auto');
                            }
                            parent = parent.parent();
                        }
                    });
                }

                /*
                    When a MultipleChoiceChallenge blank is inside MathJax, we rely upon the natural content width to properly
                    size the blank. This is unlike most blanks, where we set a standard width to avoid giving away the answer.
                    The reason for this discrepancy is that in MathJax, a blank often will "break" the math layout if we just made
                    it a standard a size.

                    To accomplish this, we manually transclude the content into the placeholder element, then replace all of its
                    text content with underscores. This ensures that the natural width is reasonably well represented, but keeps
                    screenreaders and "search for text" cheaters from being able to reveal the answer easily.
                */
                $timeout(() => {
                    $transclude(clone => {
                        element
                            .find('.multiple_choice.incomplete')
                            .append(clone)
                            .contents()
                            .filter((index, element) => element.nodeType === 1 || element.nodeType === 3) // eslint-disable-line
                            .each((index, element) => {
                                // eslint-disable-line
                                const replacementText = element.textContent.replace(/./g, '_');

                                // need to insert a bit more HTML to make MathJax spacing happy
                                // basically, if the element we're replacing is not a simple mjx-char (which has valid height already),
                                // we then need to insert an mjx-char span around the replaced text content to ensure non-zero height.
                                if (
                                    angular.isDefined(attrs.withinMathjax) &&
                                    !(element.classList && element.classList.contains('mjx-char'))
                                ) {
                                    element.innerHTML = `<span class="mjx-char MJXc-TeX-main-R" style="padding-top: 0.381em; padding-bottom: 0.381em;">${replacementText}</span>`;
                                } else {
                                    element.textContent = replacementText;
                                }
                            });
                    });
                });

                /* *************** Applicable only to UserInputChallenge **** */

                // !!!!! Do not use isA() in a directive.  It will break in preview mode, since
                // there are two copies of the same class, one for each window.
                if (scope.model.type !== 'UserInputChallengeModel') {
                    return;
                }

                scope.$watch('viewModel.correctAnswerText', correctAnswerText => {
                    if (!correctAnswerText) {
                        scope.inputSize = 1;
                        return;
                    }

                    const localeObj = scope.model.localeObject;
                    scope.inputSize = Math.max(1, localeObj.spacesNecessaryForInput(correctAnswerText));
                });

                //---------------------------
                // Component Event Handling
                //---------------------------

                let hintIntervalPromise;
                let activatedListener;

                function getInput() {
                    return element.find(scope.inputNodeName());
                }

                function stopListeningForActivated() {
                    if (activatedListener) {
                        activatedListener.cancel();
                    }
                }

                function listenForActivated() {
                    stopListeningForActivated();
                    activatedListener = new ComponentEventListener(scope.viewModel, 'activated', () => {
                        // since blanks do not call apply as answers are entered,
                        // this can get activated without a digest.  Make sure it gets digested
                        safeDigest(scope);
                        scope.setFocus(true);
                    });
                }

                /*
                    Since we validate on every keystroke, we don't want to
                    apply every time.  Takes too long.  So we validate, check to
                    see if anything has changed since the last validation, and
                    only apply if it has. (Actually we digest instead of apply. See
                    below)
                */
                function validateAndApply(sameViewAsDispatched) {
                    // viewModel is already synchronized
                    if (sameViewAsDispatched) {
                        scope.viewModel.validate();
                    }

                    // validation can change the userAnswer (capitalization, etc.), so
                    // re-sync the input
                    getInput().val(scope.viewModel.userAnswer);

                    // check if this validation result is the same as the last one. IF
                    // so, just return.
                    const currentClasses = [
                        scope.viewModel.showingIncorrectStyling,
                        scope.viewModel.showingCorrectStyling,
                        scope.viewModel.showingIncompleteStyling,
                    ];
                    const shouldDigest = !(
                        scope.lastValidationClasses && _.isEqual(scope.lastValidationClasses, currentClasses)
                    );
                    scope.lastValidationClasses = currentClasses;

                    if (sameViewAsDispatched) {
                        if (!shouldDigest) {
                            return;
                        }

                        // Doing a $digest, rather than an $apply, means that
                        // only this scope and it's children will be updated.  This SHOULD
                        // be fine up until the point where all challenges are complete
                        // and the continue button needs to show up.  But the continue
                        // button itself is smart enough to force an apply in that case,
                        // so it should be okay then as well.  This might be awesome or
                        // it might be terrible.  We'll just have to wait and see.
                        safeDigest(scope);
                    } else {
                        // allow other view to complete validation prior to digesting
                        $timeout(() => {
                            safeDigest(scope);
                        });
                    }
                }

                function incrementHintValidationAndApply(sameViewAsDispatched) {
                    if (sameViewAsDispatched) {
                        scope.viewModel.incrementHint();
                    }
                    validateAndApply(sameViewAsDispatched);
                }

                listenForActivated();

                /*
                    We do not set focus on touch devices for two reasons:

                    1. Mobile safari does not allow it, and it causes bugs if you try to do it.
                    2. We want to give the user a chance to see the full image before we
                        take up half the screen with a keyboard.
                */
                if (!Capabilities.touchEnabled) {
                    // We have to wait for the frame to be animated onto the screen so
                    // that the browser doesn't freak out about us focusing an element
                    // that's off the screen and try to animate it on itself.
                    scope.$on('frame_visible', () => {
                        if (scope.viewModel.active) {
                            scope.setFocus(true);
                        }
                    });
                }

                /*
                    If someone clicks outside of any blank in compose_blanks_on_image,
                    they probably did it by accident.  Focus on the active challenge. This
                    will do the right thing if they were aiming for the first challenge when
                    just starting the frame and missed.  It will do nothing if they were aiming
                    for the hint button or aiming for a different challenge than the active one,
                    which is a lot better than what it does now, which is to hide the keyboard.
                */
                scope.$on('component_overlay:click_outside_any_overlay', () => {
                    if (scope.viewModel.active) {
                        scope.setFocus(true);
                    }
                });

                //---------------------------
                // DOM Event Handling
                //---------------------------

                // Only putting this on the scope so we
                // can spy on it in tests. Otherwise, could be
                // a local function.
                scope.setFocus = value => {
                    if (!scope.frameViewModel || scope.frameViewModel.editorMode) {
                        return;
                    }

                    const input = getInput();

                    if (value) {
                        input.focus();
                    } else {
                        input.blur();
                    }
                };

                scope.inputNodeName = function () {
                    if (scope.viewModel.complete || scope.frameViewModel.editorMode) {
                        return undefined;
                    }
                    return this.inline() ? 'input' : 'textarea';
                };

                // setting focus takes 8-65ms on mobile safari, if we keep listening
                // for activated here, then we will call input.focus() even though this
                // input is in the process of getting focus.  That is a waste of time.  So
                // stop listening for activated for a moment
                scope.onFocus = () => {
                    // scope.handleBrowserSpecificFocus();

                    if (scope.viewModel.active) {
                        return;
                    }
                    stopListeningForActivated();
                    scope.viewModel.active = true;
                    listenForActivated();
                    safeApply(scope);
                };

                scope.onBlur = () => {
                    // scope.handleBrowserSpecificBlur();
                };

                scope.cancelNewLine = e => {
                    // The event created by Angular's ngKeydown directive is a jQuery event and
                    // according to https://api.jquery.com/category/events/event-object/ jQuery normalizes
                    // on the event.which property for cross-browser consistency. A note for the future, however,
                    // is that https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/which states that
                    // the event.which property is in the process of being deprecated. See also
                    // http://stackoverflow.com/questions/4471582/javascript-keycode-vs-which.
                    if (e.which === 13 && !e.shiftKey) {
                        e.preventDefault();
                        return true;
                    }
                    return false;
                };

                scope.onTextInputChange = () => {
                    const val = getInput().val();

                    // do not trigger a validation if the userAnswer has not changed. This could happen
                    // when tabbing into the box or when clicking a a special character.  Tried using keypress
                    // instead of keyup to only listen for characters, but didn't work because the event is
                    // fired before the input's val() has chnaged. Lotta ins, lotta outs.
                    if (scope.viewModel.userAnswer === val || (!scope.viewModel.userAnswer && !val)) {
                        return;
                    }
                    scope.viewModel.userAnswer = val;
                    validateAndApply(true);
                };

                //---------------------------
                // Styling
                //---------------------------

                let inputStyleValue;
                scope.inputStyle = () => {
                    if (!scope.frameViewModel) {
                        return {};
                    }

                    /*
                        For some reason, within mathjax, setting size
                        on the input box is ineffective.  Trial and error
                        led to a strategy of taking a multiple of the length
                        in ems.  Seems to work for now.  Fingers crossed
                        it continues working going forward

                        This is purposely included on both the outer <a> tag and
                        the inner <input> tag because that's what works best.
                        (see https://github.com/quanticedu/back_royal/issues/2096)

                        Note: one time in sentry we saw an error from the editor where scope.viewModel was undefined, minutes
                        after the editor had logged any events other than editor:ping.  I moved scope.frameViewModel.editorMode check
                        in front of scope.viewModel.complete to silence this.
                    */
                    if (
                        angular.isDefined(attrs.withinMathjax) &&
                        !scope.stretchToFit() &&
                        !scope.frameViewModel.editorMode &&
                        !scope.viewModel.complete
                    ) {
                        // This can have a bind-once when used on the <input> element because that element
                        // gets hidden anyway when scope.viewModel.complete is true.  On the <a> tag
                        // however, we can't bind once because complete will change.  We do not need
                        // to recalculate the styles on each digest though, since correctAnswerText will
                        // not change.
                        if (!inputStyleValue) {
                            // approximate the width, taking into account common smaller characters
                            // also provide a minimum width, for things like tiny exponents
                            inputStyleValue = {};
                            const text = scope.viewModel.correctAnswerText;
                            const longChars = text.replace(/\,|\.|\:|\;|\!/g, '');
                            const narrowCharCount = text.length - longChars.length;

                            const localeObj = scope.model.localeObject;
                            const longCharCount = localeObj.spacesNecessaryForInput(longChars);
                            const minimum = 1;
                            inputStyleValue.width = `${Math.max(
                                0.59 * longCharCount + 0.24 * narrowCharCount,
                                minimum,
                            )}em`;
                        }
                        return inputStyleValue;
                    }
                    return {};
                };

                //---------------------------
                // Hint Handling
                //---------------------------

                scope.frameViewModel.on('giveHint', (event, currentChallengeViewModel) => {
                    if (scope.viewModel.active && scope.viewModel === currentChallengeViewModel) {
                        scope.provideHint(event);
                    }
                });

                // Provides a hint to the user by first backspacing any invalid character that
                // may have been provided, then appending the next correct character according
                // to viewModel / validator rules
                scope.provideHint = evt => {
                    // account for preview-mode shared frameViewModel and duplicate event
                    const sameViewAsDispatched = evt.view === window;

                    /* As long as this handler is on mousedown or touchstart,
                        rather than click, it will be called before the input
                        loses focus, and this scope.setFocus will do nothing.  However,
                        if the input is not yet focused,
                        we want to focus it now and bring up the mobile keyboard */

                    if (sameViewAsDispatched) {
                        scope.setFocus(true);
                    }

                    /* It is important to stop propagation for a number of reasons:
                        1. We don't want to lose focus on the input (for this reason we need to
                            do this on mousedown and touchstart, not just click)
                        2. If this click causes the challenge to be complete, we don't want
                            it to bubble up to the click handler and cause the completed challenge
                            to get re-activated.
                    */
                    evt.preventDefault();
                    evt.stopImmediatePropagation();

                    // don't allow multiple interval loop overlaps
                    if (hintIntervalPromise) {
                        return;
                    }

                    // if we're starting off as incorrect, increment hints until our first correct
                    if (scope.viewModel.showingIncorrectStyling) {
                        // update "backspaces" at a given interval
                        hintIntervalPromise = $interval(
                            () => {
                                // cancel promise if we're green, or if we've backspaced over everything
                                if (
                                    scope.viewModel.showingIncompleteStyling ||
                                    scope.viewModel.userAnswer.length === 0
                                ) {
                                    $interval.cancel(hintIntervalPromise);
                                    hintIntervalPromise = undefined;
                                }
                                incrementHintValidationAndApply(sameViewAsDispatched);
                            },
                            125,
                            0,
                            false,
                        );
                    } else {
                        incrementHintValidationAndApply(sameViewAsDispatched);
                    }
                };

                //---------------------------
                // Cleanup
                //---------------------------

                scope.$on('$destroy', () => {
                    // scope.handleBrowserSpecificBlur();

                    if (hintIntervalPromise) {
                        $interval.cancel(hintIntervalPromise);
                    }
                    stopListeningForActivated();
                });
            },
        });
    },
]);

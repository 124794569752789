import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import 'FrontRoyalUiBootstrap/popover';
import { QUANTIC_DOMAIN, QUANTIC_CHINA_DOMAIN } from 'PedagoDomainConstants';

angularModule.directive('cfText', [
    '$injector',
    $injector => {
        const UiComponentDirHelper = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentDirHelper',
        );
        const $document = $injector.get('$document');
        const $compile = $injector.get('$compile');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const ErrorLogService = $injector.get('ErrorLogService');
        const ConfigFactory = $injector.get('ConfigFactory');

        return UiComponentDirHelper.getOptions({
            template: '<span></span>',
            link(scope, elem) {
                UiComponentDirHelper.link(scope, elem);

                // I don't know why adding this class in the
                // template does not work anymore
                elem.addClass('cf-text');

                // assign to a variable so it can be mocked in tests
                scope.compile = $compile;

                scope.$watch('model.formatted_text', () => {
                    elem.removeClass('error');

                    // copying functionality from compile_dir over here so
                    // that we can catch errors

                    // empty out the existing element using jquery
                    // so that all elements get removed properly
                    elem.empty();

                    // assign new content to the dom
                    elem.html(scope.model.formatted_text);

                    // remove mathjax script tag on processed text
                    // it is not needed at this point and can trigger mathjax to process
                    // again, causing rendering issues
                    elem.find("script[id^='MathJax-Element']").remove();

                    // compile the new DOM and link it to the current
                    // scope.
                    // NOTE: we only compile .childNodes so that
                    // we don't get into infinite loop compiling ourselves
                    // NOTE: We catch errors because, while typing
                    try {
                        scope.compile(elem.contents())(scope);
                    } catch (e) {
                        if (!scope.frameViewModel.editorMode && !scope.frameViewModel.previewMode) {
                            const id = [
                                scope.frameViewModel.frame.lesson().id,
                                scope.frameViewModel.frame.id,
                                scope.model.id,
                            ].join('/');
                            ErrorLogService.notify(
                                `Error processing text for (lesson_id/frame_id/component_id=${id}): ${e.message}`,
                            );
                        }

                        elem.text('Error processing text.');
                        elem.addClass('error');
                    }
                });

                scope.modalViewModel = function modalViewModel(index) {
                    return this.viewModel.modalsViewModels[index];
                };

                // FIXME: move these close modal listeners onto modal-popup itself

                // removes all the modals except the one that was just clicked (if available)
                scope.closeModals = event => {
                    const clickedModal = event && event.target ? $(event.target).parent()[0] : undefined;
                    elem.find('.lesson_popover').each(modal => {
                        if (modal !== clickedModal) {
                            $(modal).popover('hide');
                        }
                    });
                };

                scope.openExternalLink = link => {
                    // Replace quantic.edu --> quantic.cn in Quantic.cn context.
                    const chinaRegionMode = ConfigFactory.getSync(true).chinaRegionMode();
                    if (chinaRegionMode) {
                        link = link.replace(`//${QUANTIC_DOMAIN}`, `//${QUANTIC_CHINA_DOMAIN}`);
                    }
                    NavigationHelperMixin.loadUrl(link, '_blank');
                };

                $document.on('click', scope.closeModals);
                scope.$on('$destroy', () => {
                    scope.closeModals();
                    $document.off('click', scope.closeModals);
                });

                scope.$watch('model.fontSize', fontSize => {
                    if (angular.isDefined(fontSize)) {
                        elem.css({
                            fontSize,

                            // seems like we shouldn't have to do this, since it probably
                            // already is 1em in the css, but if the fontSize is set
                            // in a style attr, then the line-height needs to be as well
                            lineHeight: '1em',
                        });
                    } else {
                        elem.css({
                            fontSize: 'inherit',
                            lineHeight: undefined,
                        });
                    }
                });

                scope.$watch('model.alignment', alignment => {
                    if (angular.isDefined(alignment)) {
                        elem.removeClass('center left right').addClass(alignment);
                    } else {
                        elem.removeClass('center left right');
                    }
                });
            },
        });
    },
]);

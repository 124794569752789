import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*
    When this behavior is on, all answers will be deselected, enabled, and will
    have any existing correct/incorrect styling removed whenever a new challenge
    is activated.
*/
angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.Behaviors.ResetAnswersOnActivated',
    [
        'AModuleAbove',
        'Lesson.FrameList.Frame.Componentized.Component.ComponentEventListener',
        (AModuleAbove, ComponentEventListener) =>
            new AModuleAbove({
                included(MultipleChoiceChallengeModel) {
                    MultipleChoiceChallengeModel.supportBehavior('ResetAnswersOnActivated');

                    MultipleChoiceChallengeModel.ViewModel.setCallback('after', 'initialize', function () {
                        const challengeViewModel = this;
                        let listener;

                        this.model.on('behavior_added:ResetAnswersOnActivated', () => {
                            listener = new ComponentEventListener(challengeViewModel, 'activated', () => {
                                challengeViewModel.answerListViewModel.clearStates();
                            });
                        });

                        this.model.on('behavior_removed:ResetAnswersOnActivated', () => {
                            if (listener) {
                                listener.cancel();
                                listener = undefined;
                            }
                        });
                    });
                },
            }),
    ],
);

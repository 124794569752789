import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*
    When this behavior is on, the order of the challenge list will be randomized.
*/
angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.Challenges.Behaviors.RandomizeChallengeOrder', [
    '$injector',
    $injector => {
        const AModuleAbove = $injector.get('AModuleAbove');
        const $window = $injector.get('$window');

        return new AModuleAbove({
            included(ChallengesModel) {
                ChallengesModel.supportBehavior('RandomizeChallengeOrder');

                ChallengesModel.ViewModel.setCallback('after', 'initialize', function () {
                    const challengesViewModel = this;

                    challengesViewModel.model.on('behavior_added:RandomizeChallengeOrder', () => {
                        function reorder() {
                            if ($window.ScreenshotOptions && $window.ScreenshotOptions.disableRandomization) {
                                return;
                            }
                            challengesViewModel.orderedChallengeViewModels = _.shuffle(
                                challengesViewModel.challengesViewModels,
                            );
                        }
                        challengesViewModel.model.challenges.on('childAdded', reorder);
                        challengesViewModel.model.challenges.on('childRemoved', reorder);
                    });

                    challengesViewModel.model.on('behavior_removed:RandomizeChallengeOrder', () => {
                        challengesViewModel.orderedChallengeViewModels = challengesViewModel.challengesViewModels;
                    });
                });
            },
        });
    },
]);

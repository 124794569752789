import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.MultipleChoiceMessage.MultipleChoiceMessageViewModel',

    [
        'Lesson.FrameList.Frame.Componentized.Component.ComponentViewModel',
        'Lesson.FrameList.Frame.Componentized.Component.ComponentEventListener',
        '$injector',

        (ComponentViewModel, ComponentEventListener, $injector) => {
            const ErrorLogService = $injector.get('ErrorLogService');

            return ComponentViewModel.subclass(() => ({
                initialize($super, frameViewModel, model) {
                    $super(frameViewModel, model);
                    this._createListeners();
                },

                _createListeners() {
                    if (this.model.event === 'selected') {
                        this._listenForSelected();
                    } else if (this.model.event === 'validated') {
                        this._listenForValidated();
                    } else {
                        throw new Error(`Unexpected event "${this.model.event}"`);
                    }
                },

                _listenForSelected() {
                    // look for any selected answer the matches
                    // the answerMatcher and then listen for the selected
                    // event on it
                    this.model.challenge.answers.forEach(answer => {
                        if (this.model.appliesToAnswer(answer)) {
                            const answerViewModel = this.viewModelFor(answer);
                            // eslint-disable-next-line no-new
                            new ComponentEventListener(answerViewModel, 'selected', () => {
                                // if the answer is selected as part of a different
                                // challenge, don't do anything
                                if (this.challengeViewModel.active) {
                                    this._showMessage();
                                }
                            });
                        }
                    });
                },

                _listenForValidated() {
                    // listen for the challenge to be validated, and then look for any
                    // selected answer the matches the answerMatcher
                    // eslint-disable-next-line no-new
                    new ComponentEventListener(this.challengeViewModel, 'validated', validationResult => {
                        let result;
                        let message;

                        // if the validation event explicitly states with answer was selected
                        // to trigger the validation (see ImmediateValidation), then
                        // show the message for the selected answer
                        if (validationResult.info.event && validationResult.info.event.type === 'answerSelected') {
                            result = !validationResult.hasErrorRelatedToAnswer(
                                validationResult.info.event.target.model,
                            );
                            message = this._showMessageForSelectedAnswer(validationResult.info.event.target, result);
                        }

                        // do nothing when validations happen on unselected
                        else if (
                            validationResult.info.event &&
                            validationResult.info.event.type === 'answerUnselected'
                        ) {
                            // do nothing
                        }

                        // If the validation was not kicked off by an answer being selected (as
                        // with venn diagram), then we can only handle a single selected answer.  If
                        // multiple answers are selected then we won't know what to do and we will throw.
                        // (FIXME: we should really always have an event we can explicitly check)
                        else if (!validationResult.info.event) {
                            // TODO: we are theorizing that this branch should no longer be hit now
                            // that the Venn Diagram interaction type is gone.  adding a sentry call
                            // so that we can confirm or disprove this theory.
                            // If you see this code being reached, please update these comments
                            // accordingly.
                            ErrorLogService.notifyInProd(
                                `MultipleChoiceMessageViewModel: validationResult missing event for model ${validationResult.challenge.component_type}`,
                            );

                            const selectedAnswerViewModels = validationResult.filterAnswersViewModels({
                                selected: true,
                            });

                            if (selectedAnswerViewModels.length > 1) {
                                throw new Error('Cannot show messages for multiple selected answers.');
                            } else if (selectedAnswerViewModels.length === 0) {
                                return;
                            }

                            const selectedAnswerViewModel = selectedAnswerViewModels[0];
                            result = !validationResult.hasErrorRelatedToAnswer(selectedAnswerViewModel.model);
                            message = this._showMessageForSelectedAnswer(selectedAnswerViewModel, result);
                        }

                        if (message) {
                            validationResult.addMessage(message);
                        }
                    });
                },

                _showMessageForSelectedAnswer(answerViewModel, correct) {
                    if (this.model.answerMatcher.matches(answerViewModel.model)) {
                        return this._showMessage({
                            correct,
                        });
                    }
                    return undefined;
                },

                _clearMessage() {
                    this.playerViewModel.clearMessage();
                },

                _showMessage(options = {}) {
                    this.playerViewModel.showMessage(
                        angular.extend(
                            {
                                content: '<cf-text view-model="textViewModel"></cf-text>',
                                scope: {
                                    textViewModel: this.frameViewModel.viewModelFor(this.model.messageText),
                                },
                                correctExists: !!angular.isDefined(options.correct),
                            },
                            options,
                        ),
                    );

                    return this.model.messageText;
                },
            }));
        },
    ],
);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*
    A MultipleChoice challenge has an AnswerList, which includes a list of SelectableAnswer
    components.  The user can select one or more of these answers in order to respond to
    the challenge.

    See the following flowchart for a picture of how all the pieces of a
    Multiple Choice Challenge fit together:
    https://pedago.atlassian.net/wiki/display/TECH/Componentized+Frame#ComponentizedFrame-MCChallengeFlowchart
*/
angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.Answer.SelectableAnswer.SelectableAnswerModel', [
    'Lesson.FrameList.Frame.Componentized.Component.Answer.AnswerModel',
    'Lesson.FrameList.Frame.Componentized.Component.Answer.SelectableAnswer.SelectableAnswerViewModel',
    'Lesson.FrameList.Frame.Componentized.Component.Mixins.HasTextOrImage',
    'Lesson.FrameList.Frame.Componentized.Component.AnswerList.AnswerListModel',
    (AnswerModel, SelectableAnswerViewModel, HasTextOrImage, AnswerListModel) =>
        AnswerModel.subclass(function () {
            this.alias('ComponentizedFrame.SelectableAnswer');
            this.extend({
                ViewModel: SelectableAnswerViewModel,
            });
            this.setEditorViewModel(
                'Lesson.FrameList.Frame.Componentized.Component.Answer.SelectableAnswer.SelectableAnswerEditorViewModel',
            );

            this.include(HasTextOrImage);

            // NOTE: in other components, we call setImageContext
            // to define the context to use for referenced images.  In this
            // case, however, the logic is more complex, so we override the
            // imageContext method below.

            // used in image_hotspot case
            this.key('hasBackground');

            Object.defineProperty(this.prototype, 'displayName', {
                value: 'Answer Choice',
            });

            return {
                // overriding the default method in component_model.js, since images
                // are sometimes displayed in the `answerButton` context and sometimes
                // in the `wideAnswerButton` context.
                //
                // This is all necessary because a model needs to know the context in
                // which it will use any images ahead of time (that is, separate from
                // any viewModel or directive/view code) in order to enable preloading.
                //
                // This is a bit hackish, because it requires SelectableAnswer to know
                // a lot about the wider frame in which it is being used.  SelectableAnswer
                // now needs to know the editor_template of the frame and it needs to know
                // about the other answers that exist.
                // Also, this is not as efficient as it could be, since each answer will loop
                // through all the answers in order to decide which context to use., and this happens
                // on every digest.  These are small arrays though, so I don't think it's
                // a practical issue.
                imageContext(key) {
                    if (key !== 'image') {
                        throw new Error('Only know how to determine context for `image`');
                    }

                    /*
                        NOTE: it's not really ok to cache this in the editor.  Changing the
                        answers on the frame can change which context we should use.  But all
                        that happens is that we end up displaying images in the editor in the
                        wrong context, which no one should ever notice.  Seems like it's
                        worth the performance improvement, which will effect both the editor
                        and the player.
                    */
                    this.$$cachedImageContexts = this.$$cachedImageContexts || {};
                    if (!this.$$cachedImageContexts[key]) {
                        // On matching frames, we never use wide buttons
                        if (this.frame().editor_template === 'matching') {
                            return 'answerButton';
                        }

                        // eslint-disable-next-line arrow-body-style
                        const answerListModel = _.find(this.frame().componentsForType(AnswerListModel), model => {
                            return _.includes(model.answers, this);
                        });

                        const singleColumn = answerListModel?.useSingleColumn();

                        this.$$cachedImageContexts[key] = singleColumn ? 'wideAnswerButton' : 'answerButton';
                    }
                    return this.$$cachedImageContexts[key];
                },
            };
        }),
]);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import 'ExtensionMethods/array';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Answer.SelectableAnswer.SelectableAnswerViewModel',

    [
        'Lesson.FrameList.Frame.Componentized.Component.Answer.AnswerViewModel',

        AnswerViewModel =>
            AnswerViewModel.subclass(function () {
                // The selected property on answer can be set to true or
                // false.  Events will be fired both before and after the
                // property is changed.
                Object.defineProperty(this.prototype, 'selected', {
                    get() {
                        return this._selected || false;
                    },
                    set(val) {
                        if (val === this._selected) {
                            return;
                        }

                        if (val) {
                            this.fire('beforeSelected');
                            this._selected = true;
                            Array.remove(this.cssClasses, 'unselected');
                            this.cssClasses.push('selected');
                            this.fire('selected');
                        } else {
                            this.fire('beforeUnselected');
                            this._selected = false;
                            Array.remove(this.cssClasses, 'selected');
                            this.cssClasses.push('unselected');
                            this.fire('unselected');
                        }
                    },
                });

                return {
                    initialize($super, frameViewModel, model) {
                        this._selected = false;
                        $super(frameViewModel, model);
                    },

                    resetCssClasses($super) {
                        $super();
                        this.cssClasses.push('unselected');
                    },
                };
            }),
    ],
);

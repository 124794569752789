const factories: Record<string, () => unknown> = {};

export default class Injector {
    $injector: ng.auto.IInjectorService;
    values: Record<string, unknown>;

    static factory(name: string, fn: () => unknown) {
        factories[name] = fn;
    }

    constructor($injector: ng.auto.IInjectorService) {
        this.$injector = $injector;
        this.values = {};
    }

    get(name: string, opts: Record<string, unknown>): unknown {
        opts = opts || {};
        const optional = opts.optional || false;
        if (!this.values[name]) {
            const factory = factories[name];

            try {
                this.values[name] = factory ? factory() : this.$injector.get(name);
            } catch (err: unknown) {
                if (!optional || (err instanceof Error && !err.message.match(/Unknown provider/))) {
                    throw err;
                }
            }
        }
        return this.values[name];
    }
}

import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import 'ExtensionMethods/array';

/*
    When this behavior is on, the order of the answer list will be randomized.

    supported options:

    ensureLast: value is an array of ids of answer components.  One of the answers
                in the list will be the last one after randomization.
*/
angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.AnswerList.Behaviors.RandomizeAnswerOrder', [
    '$injector',
    $injector => {
        const AModuleAbove = $injector.get('AModuleAbove');
        const $window = $injector.get('$window');

        return new AModuleAbove({
            included(AnswerListModel) {
                AnswerListModel.supportBehavior('RandomizeAnswerOrder');

                AnswerListModel.ViewModel.setCallback('after', 'initialize', function () {
                    const answerListViewModel = this;
                    answerListViewModel.model.on('behavior_added:RandomizeAnswerOrder', function () {
                        const options = this.optionsForBehavior('RandomizeAnswerOrder') || {};

                        function reorder() {
                            if ($window.ScreenshotOptions && $window.ScreenshotOptions.disableRandomization) {
                                return;
                            }

                            const unshuffledList = answerListViewModel.answersViewModels.slice(0);
                            let shuffledList;

                            if (options.ensureLast) {
                                const lastId = _.shuffle(options.ensureLast)[0];
                                let lastAnswerViewModel;
                                unshuffledList.forEach(answerViewModel => {
                                    if (answerViewModel.model.id === lastId) {
                                        lastAnswerViewModel = answerViewModel;
                                    }
                                });
                                if (!lastAnswerViewModel) {
                                    throw new Error(`No answer found for "${lastId}"`);
                                }
                                Array.remove(unshuffledList, lastAnswerViewModel);
                                shuffledList = _.shuffle(unshuffledList);
                                shuffledList.push(lastAnswerViewModel);
                            } else {
                                shuffledList = _.shuffle(unshuffledList);
                            }

                            answerListViewModel.orderedAnswerViewModels = shuffledList;
                        }

                        function hasDisallowedOrder() {
                            if (answerListViewModel.disallowedAnswerOrder) {
                                return _.isEqual(
                                    answerListViewModel.orderedAnswerViewModels,
                                    answerListViewModel.disallowedAnswerOrder,
                                );
                            }
                            return false;
                        }

                        function ensureReordered() {
                            let i = 0;
                            answerListViewModel.orderedAnswerViewModels = undefined;
                            while (i === 0 || (i < 10 && hasDisallowedOrder())) {
                                i += 1;
                                reorder();
                            }
                        }

                        answerListViewModel.model.answers.on('childAdded', ensureReordered);
                        answerListViewModel.model.answers.on('childRemoved', ensureReordered);
                        answerListViewModel.model.on('set:disallowedAnswerOrder', ensureReordered);
                    });

                    answerListViewModel.model.on('behavior_removed:RandomizeAnswerOrder', () => {
                        answerListViewModel.orderedAnswerViewModels = answerListViewModel.answersViewModels;
                    });
                });
            },
        });
    },
]);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*
	This is shared between

	1. the cfChallengeBlank directive, which is used in text components
		that use the ProcessesChallengeBlanks behavior
	2. cfChallengeOverlayBlank directive, which is used in the blanks on
		image editor template
    3. the matching-challenge-button directive, which is used with the "matching"
        challenges editor template.  (Note: this one is not a "blank", so this
        file is probably named wrong.  Should be something like ChallengeProxyDirHelper)
*/

angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.Challenge.ChallengeBlankDirHelper', [
    () => ({
        link(scope) {
            Object.defineProperty(scope, 'challengeBlankCssClasses', {
                get() {
                    const classes = ['blank'];

                    if (this.frameViewModel.editorMode) {
                        classes.push('editable');
                    }

                    if (this.viewModel.complete) {
                        classes.push('completed');
                    } else {
                        classes.push('pending');
                    }

                    if (this.viewModel.showingIncorrectStyling) {
                        classes.push('incorrect');
                    } else if (this.viewModel.showingIncompleteStyling) {
                        classes.push('incomplete');
                    }

                    if (this.viewModel.active) {
                        classes.push('selected');
                    }

                    if (this.viewModel.showsCorrectnessDuringInput) {
                        classes.push('shows-correctness-during-input');
                    }

                    return classes;
                },
            });

            Object.defineProperty(scope, 'disabled', {
                get() {
                    return this.viewModel.complete || this.viewModel.active;
                },
            });
        },
    }),
]);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import template from 'Lessons/angularModule/views/lesson/frame_list/frame/componentized/component/answer_list/answer_list_poll.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('cfAnswerListPoll', [
    'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentDirHelper',
    '$injector',
    (UiComponentDirHelper, $injector) => ({
        restrict: 'E',
        templateUrl,

        link(scope, elem) {
            UiComponentDirHelper.link(scope, elem);

            const TranslationHelper = $injector.get('TranslationHelper');
            const translationHelper = new TranslationHelper(
                'lessons.lesson.frame_list.frame.componentized.component.answer_list.answer_list_buttons',
            );

            let fillBarWidths;
            let pollResults;

            scope.indexLabel = (index, answerViewModel) => {
                if (
                    scope.viewModel.currentChallengeViewModel &&
                    scope.viewModel.currentChallengeViewModel.complete &&
                    !!pollResults[answerViewModel.model.id]
                ) {
                    return `${pollResults[answerViewModel.model.id].rounded}%`;
                }
                // we only have translations for up to 10 poll options
                return translationHelper.get(`poll_label_${Math.min(10, index)}`);
            };

            scope.fillBarWidth = answerViewModel => {
                const width = fillBarWidths[answerViewModel.model.id];
                const minWidth = `${scope.getBorderRadius() * 2}px`;
                if (width === '0px') {
                    return minWidth;
                }
                return width;
            };

            // method that can be mocked in tests

            // Passing in just 'border-radius' to the jquery css() function doesn't
            // work in Microsoft Edge because border-radius is composed of sub-properties,
            // such as border-top-left-radius, border-top-right-radius, etc. However, passing
            // in one of the sub-properties works. Also, all of the sub-properties are set to
            // the same calculated constant, so it doesn't matter which one we pass in.
            scope.getBorderRadius = () => elem.find('button').css('border-top-left-radius') || '0px';

            scope.getButtonWidth = () => {
                const w = elem.find('button').width();
                return w || 370; // default to smallest size button could possibly be
            };

            function getPollResults(minCount, selectedAnswer) {
                const challenge = scope.viewModel.currentChallengeViewModel.model;
                // eslint-disable-next-line no-shadow
                const pollResults = {};
                let totalCount = 0;
                scope.model.answers.forEach(answer => {
                    let count = challenge.usage_report && challenge.usage_report.first_answer_counts[answer.id];
                    count = count || 0;
                    count += minCount;
                    if (answer === selectedAnswer) {
                        count += 1;
                    }
                    pollResults[answer.id] = {
                        count,
                    };
                    totalCount += count;
                });

                // round as we go, to ensure we total to 100 at the end
                // adapted from: http://stackoverflow.com/questions/13483430/how-to-make-rounded-percentages-add-up-to-100
                let cumulativeActual = 0;
                angular.forEach(pollResults, (obj, answerId) => {
                    const perc = obj.count / totalCount;
                    pollResults[answerId]['%'] = perc;

                    // calculate rounded total before current entry
                    const baseline = Math.round(100 * cumulativeActual);
                    cumulativeActual += perc;
                    // calculate new rounded total
                    const cumulativeRounded = Math.round(100 * cumulativeActual);

                    // 1. the final rounded cumulative total will always equal 100
                    // 2. sum of differences between all the totals will always add to 100
                    // 3. therefore, use difference between old and new total as the rounded value for this entry
                    pollResults[answerId].rounded = cumulativeRounded - baseline;
                });
                pollResults.totalCount = totalCount;
                return pollResults;
            }

            scope.$watch('viewModel.currentChallengeViewModel.complete', complete => {
                const borderRadius = Number(scope.getBorderRadius().match(/\d+/)[0]);
                const minFillBarWidth = 2 * borderRadius; // don't get any smaller than the circle on the left

                if (!complete) {
                    fillBarWidths = {};
                    scope.model.answers.forEach(answer => {
                        fillBarWidths[answer.id] = `${minFillBarWidth}px`;
                    });
                } else {
                    const selectedAnswer = _.last(scope.viewModel.currentChallengeViewModel.challengeResponses).answer;
                    fillBarWidths = {};
                    const minFillBarPerc = minFillBarWidth / scope.getButtonWidth();

                    // first calculate the poll results without any seed data.  If
                    // it turns out that there are fewer than five answers, then
                    // calculate the poll data again with initial seed data of
                    // 3 responses for each answer
                    pollResults = getPollResults(0, selectedAnswer);
                    if (pollResults.totalCount < 5) {
                        pollResults = getPollResults(3, selectedAnswer);
                    }

                    angular.forEach(pollResults, (obj, answerId) => {
                        const perc = obj['%'];
                        const displayPerc = minFillBarPerc + perc * (1 - minFillBarPerc);
                        fillBarWidths[answerId] = `${Math.min(100 * displayPerc, 100)}%`;
                    });
                }
            });
        },
    }),
]);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.directive('componentizedContinueButton', () => ({
    scope: {
        frameViewModel: '<',
    },

    restrict: 'E',

    // has to be false because otherwise this and the cf-ui-component will fight over the scope
    replace: false,

    template: '<cf-ui-component view-model="frameViewModel.continueButtonViewModel"></cf-ui-component>',
}));

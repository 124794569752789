import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.MatchingChallengeButton.MatchingChallengeButtonViewModel',

    [
        'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentViewModel',

        UiComponentViewModel =>
            UiComponentViewModel.subclass(function () {
                ['active', 'complete', 'showingIncorrectStyling'].forEach(prop => {
                    Object.defineProperty(this.prototype, prop, {
                        get() {
                            return this.challengeViewModel[prop];
                        },
                    });
                });

                return {
                    directiveName: 'cf-matching-challenge-button',
                };
            }),
    ],
);

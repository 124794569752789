import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.UserInputMessage.UserInputMessageModel', [
    'Lesson.FrameList.Frame.Componentized.Component.ComponentModel',
    'Lesson.FrameList.Frame.Componentized.Component.UserInputMessage.UserInputMessageViewModel',

    (ComponentModel, UserInputMessageViewModel) =>
        ComponentModel.subclass(function () {
            this.alias('ComponentizedFrame.UserInputMessage');
            this.extend({
                ViewModel: UserInputMessageViewModel,
            });
            this.setEditorViewModel(
                'Lesson.FrameList.Frame.Componentized.Component.UserInputMessage.UserInputMessageEditorViewModel',
            );

            this.references('challenge').through('challenge_id');
            this.references('messageText').through('message_text_id');

            Object.defineProperty(this.prototype, 'displayName', {
                value: 'Answer Message',
            });

            // This is always set to true right now, but it leaves us open to
            // the possibility of having messages when someone enters an incorrect
            // answer on a compose challenge
            this.key('show_on_correct_answer');

            return {};
        }),
]);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import template from 'Lessons/angularModule/views/lesson/show_standalone_lesson.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('showStandaloneLesson', [
    '$injector',
    function factory($injector) {
        const Lesson = $injector.get('Lesson');
        const $rootScope = $injector.get('$rootScope');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                lessonId: '@lessonId',
                mode: '@mode',
            },
            link(scope) {
                Object.defineProperty(scope, 'playerViewModel', {
                    get() {
                        if (!this.lesson) {
                            return undefined;
                        }
                        if (this._playerViewModel) {
                            return this._playerViewModel;
                        }
                        this._playerViewModel = this.lesson.createPlayerViewModel({
                            logProgress: !!$rootScope.currentUser,
                            demoMode: scope.mode === 'demo',
                        });

                        return this._playerViewModel;
                    },
                });

                scope.$watch('lessonId', lessonId => {
                    if (lessonId) {
                        Lesson.show(lessonId, {
                            filters: {
                                published: true,
                            },
                            'except[]': ['EDITOR_FIELDS'],
                        }).then(response => {
                            scope.lesson = response.result;
                        });
                    }
                });
            },
        };
    },
]);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*
    a TilePromptBoard is a ui component associated with a Challenges Component
    that uses the "tile_prompt" template.  It is placed in the sharedContentForImage
    section in the template.

    It renders a list of challenges and a sharedAnswerList
*/
angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.TilePromptBoard.TilePromptBoardModel', [
    'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentModel',
    'Lesson.FrameList.Frame.Componentized.Component.TilePromptBoard.TilePromptBoardViewModel',
    (UiComponentModel, TilePromptBoardViewModel) =>
        UiComponentModel.subclass(function () {
            this.alias('ComponentizedFrame.TilePromptBoard');
            this.extend({
                ViewModel: TilePromptBoardViewModel,
            });
            this.setEditorViewModel(
                'Lesson.FrameList.Frame.Componentized.Component.TilePromptBoard.TilePromptBoardEditorViewModel',
            );

            this.references('tilePrompts').through('tile_prompt_ids');
            this.references('answerList').through('answer_list_id');
            this.references('challengesComponent').through('challenges_component_id');

            return {};
        }),
]);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.Mixins.HasTextOrImage', [
    'AModuleAbove',

    AModuleAbove =>
        new AModuleAbove({
            included(Model) {
                Model.references('text').through('text_id');
                Model.references('image').through('image_id');

                Model.extend({
                    supportsTextOrImage: true,
                });

                // when this component is removed, remove any blank images
                // that it references
                Model.setCallback('after', 'copyAttrsOnInitialize', function () {
                    this.on('remove', function () {
                        if (this.image && !this.image.image) {
                            this.image.remove();
                        }
                    });
                });

                Object.defineProperty(Model.prototype, 'contentType', {
                    get() {
                        if (this.image) {
                            return 'image';
                        }
                        return 'text';
                    },
                    configurable: true,
                });

                Object.defineProperty(Model.prototype, 'textOrImage', {
                    get() {
                        return this.contentType === 'text' ? this.text : this.image;
                    },
                });

                // used in select dropdowns
                Object.defineProperty(Model.prototype, 'label', {
                    get() {
                        if (this.contentType === 'image') {
                            return this.image ? this.image.label : '';
                        }
                        return this.text ? this.text.text : '';
                    },
                });

                // used in component_overlay.html to make sure that text boxes
                // can be skewed but images cannot
                Object.defineProperty(Model.prototype, 'restrictAspectRatio', {
                    get() {
                        if (this.image) {
                            return true;
                        }
                        return false;
                    },
                });

                Model.ViewModel.include({
                    textOrImageViewModel() {
                        return this.textViewModel || this.imageViewModel;
                    },
                });
            },

            // return true if text.text is the same for both models or
            // of image is the same component.
            //
            // otherwise return false
            contentEqualTo(otherModel) {
                let contentIsDifferent = false;
                if (this.contentType !== otherModel.contentType) {
                    contentIsDifferent = true;
                } else if (otherModel.contentType === 'text' && this.text.text !== otherModel.text.text) {
                    contentIsDifferent = true;
                } else if (otherModel.contentType === 'image' && this.image !== otherModel.image) {
                    contentIsDifferent = true;
                }
                return !contentIsDifferent;
            },
        }),
]);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import * as userAgentHelper from 'userAgentHelper';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.AnswerMatcher.MatchesExpectedText.MatchesExpectedTextModel',
    [
        '$injector',
        $injector => {
            const $rootScope = $injector.get('$rootScope');
            const $window = $injector.get('$window');
            const AnswerMatcherModel = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.AnswerMatcher.AnswerMatcherModel',
            );
            const Locale = $injector.get('Locale');
            const hindiNumerals = $injector.get('HINDI_NUMERALS');

            return AnswerMatcherModel.subclass(function () {
                this.alias('ComponentizedFrame.MatchesExpectedText');
                this.extend({
                    // answerMatchers have no state, so need no viewModel
                    ViewModel: null,
                });
                this.setEditorViewModel(
                    'Lesson.FrameList.Frame.Componentized.Component.AnswerMatcher.MatchesExpectedText.MatchesExpectedTextEditorViewModel',
                );

                this.key('expectedText');
                this.key('correctThreshold');
                this.key('caseSensitive');
                this.key('mode');

                return {
                    // Currencies recognized for 'currency' matching mode
                    _currencies: '$€¢£¥₩₪฿₫₴₹₤',

                    initialize($super, attrs) {
                        attrs = angular.extend(
                            {
                                correctThreshold: 100,
                                caseSensitive: false,
                                mode: 'exact',
                            },
                            attrs,
                        );

                        this.isCordovaAndroid = $window.CORDOVA && userAgentHelper.isAndroidDevice();

                        $super(attrs);
                    },

                    matches(challengeResponse) {
                        return this.matchesWithDetails(challengeResponse).matches;
                    },

                    matchesWithDetails(challengeResponse) {
                        let inputtedText = challengeResponse.text;
                        let nextCharacterExpected;

                        if (!this.expectedText || !inputtedText) {
                            nextCharacterExpected = this.expectedText ? this.expectedText.charAt(0) : undefined;

                            return {
                                matches: false,
                                partialMatch: false,
                                userAnswerToDisplay: inputtedText,
                                netCharacterExpected: nextCharacterExpected,
                            };
                        }

                        let targetText = this.expectedText;
                        let userText = inputtedText;

                        // Default to case insensitive matching
                        if (!this.caseSensitive) {
                            targetText = targetText.toLowerCase();
                            userText = userText.toLowerCase();
                        }

                        // Default to straight quotes. Smart punctuation was enabled by default in iOS11
                        // and has caused some learners to unjustly get test questions wrong.
                        //
                        // See https://stackoverflow.com/a/9401374/1747491 for the code snippit below
                        // See https://daringfireball.net/2018/02/ios_messages_smart_punctuation for an article
                        //  discussing the setting being turned on in iOS11
                        // See https://www.cl.cam.ac.uk/~mgk25/ucs/quotes.html for the unicode values for smart
                        //  punctuation
                        // See https://chrisbracco.com/curly-quotes/ for how to type smart quotes in macOS
                        targetText = targetText.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
                        userText = userText.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');

                        // In Arabic lessons, we've decided to ignore the pref_decimal_delim and always assume the user wants to type in Arabic style,
                        // i.e.: commas as decimal separators
                        const isArabicLesson = this.localeObject === Locale.arabic;

                        // Determine numeric decimal delimiter preferences
                        let decimalDelim =
                            $rootScope.currentUser && $rootScope.currentUser.pref_decimal_delim
                                ? $rootScope.currentUser.pref_decimal_delim
                                : '.';
                        if (isArabicLesson) {
                            decimalDelim = ',';
                        }
                        const ignoreDelim = decimalDelim === '.' ? ',' : '.';

                        // check if targetText is a decimal less than zero
                        // It may or may not have a leading zero before the decimal point
                        // If it matches this form, enable skipping leading zero
                        // We also want this to work with negative numbers
                        const ignoreLeadingDecimalZero = !!(
                            this.mode === 'decimal' &&
                            (targetText.match(/^-?0?\.[0-9]+$/) ||
                                (isArabicLesson && targetText.match(new RegExp(`^-?٠?,[${hindiNumerals.join('')}]+$`))))
                        );

                        // We also want this to work with the currency mode...
                        // we don't end these next two Arabic regexes with $ because there might be a dinar (د.أ) at the end
                        const ignoreLeadingCurrencyDecimalZero = !!(
                            this.mode === 'currency' &&
                            (targetText.match(new RegExp(`^-?[${this._currencies}]?0?\\.[0-9]+$`)) ||
                                (isArabicLesson && targetText.match(new RegExp(`^-?٠?,[${hindiNumerals.join('')}]+`))))
                        );
                        const leadingDecimalZeroCharacter = isArabicLesson ? '٠' : '0';

                        // handle special-case delimeter inversion (i.e.: when the user's decimal pref differs from the English version '.')
                        // note: we ignore this logic in Arabic lessons and force the user to use commas as decimal delimeters
                        if (
                            !isArabicLesson &&
                            (this.mode === 'number' || this.mode === 'currency' || this.mode === 'decimal')
                        ) {
                            // invert the notation
                            if (decimalDelim !== '.') {
                                const tmpDelim = '----DELIM----';
                                targetText = targetText
                                    .replace(/\./g, tmpDelim)
                                    .replace(/,/g, '.')
                                    .replace(new RegExp(tmpDelim, 'g'), ',');
                            }
                        }

                        // Remove characters we ignore based on mode
                        let modeRegexp;
                        const currencyPrefixRegexp = new RegExp(`[${this._currencies}]`);
                        if (this.mode === 'number' || this.mode === 'decimal') {
                            modeRegexp = new RegExp(`[${ignoreDelim}]`, 'g');
                        } else if (this.mode === 'currency') {
                            modeRegexp = new RegExp(`[${ignoreDelim}${this._currencies}]`, 'g');
                        }

                        // Threshold for correctness
                        const threshold = Math.ceil((targetText.length * this.correctThreshold) / 100);

                        // Scan one character at a time to see if they match
                        let mismatch = false;
                        let targetIndex = 0;
                        let userIndex = 0;
                        for (; targetIndex < targetText.length; ) {
                            // Get current chars to compare
                            const targetChar = targetText[targetIndex];
                            nextCharacterExpected = targetChar;

                            const userChar = userIndex >= userText.length ? '' : userText[userIndex];

                            if (targetChar === userChar) {
                                // if they match without filtering, great! increment both
                                targetIndex += 1;
                                userIndex += 1;
                            } else if (
                                userChar === ' ' &&
                                (this.mode === 'number' || this.mode === 'currency' || this.mode === 'decimal')
                            ) {
                                // On android, we had an inexplicable bug where spaces would randomly
                                // get added in number fields.  If a space is incorrectly added, just remove
                                // it from the input box immediately.  This is a hack but it avoids the bug
                                // and should not be something users complain about.  See https://trello.com/c/aFSk3SHi
                                userText =
                                    userText.slice(0, userIndex) + userText.slice(userIndex + 1, userText.length);
                                inputtedText = userText;
                            } else if (modeRegexp && targetChar.match(modeRegexp)) {
                                // else, do they match if you filter the target char? if so, look ahead
                                targetIndex += 1;
                            } else if (
                                this.mode === 'currency' &&
                                userIndex === 0 &&
                                targetIndex === 0 &&
                                userChar.match(currencyPrefixRegexp) &&
                                !targetChar.match(currencyPrefixRegexp)
                            ) {
                                // else if we're currency mode and the user has typed a currency prefix, but the expected answer didn't include it, just ignore it
                                userIndex += 1;
                            } else if (
                                ignoreLeadingDecimalZero &&
                                userIndex === 0 &&
                                targetIndex === 0 &&
                                userChar === leadingDecimalZeroCharacter &&
                                targetChar === decimalDelim
                            ) {
                                // else if we're ignoring leading zeroes in a decimal and the user entered zero when they didn't have to
                                userIndex += 1;
                            } else if (
                                ignoreLeadingDecimalZero &&
                                userIndex === 1 &&
                                targetIndex === 1 &&
                                userChar === leadingDecimalZeroCharacter &&
                                targetChar === decimalDelim
                            ) {
                                // else if we're ignoring leading zeroes in a negative decimal and the user entered zero when they didn't have to
                                userIndex += 1;
                            } else if (
                                ignoreLeadingDecimalZero &&
                                userIndex === 0 &&
                                targetIndex === 0 &&
                                userChar === decimalDelim &&
                                targetChar === leadingDecimalZeroCharacter
                            ) {
                                // else if we're ignoring leading zeroes in a decimal and the user left off the leading zero
                                targetIndex += 1;
                            } else if (
                                ignoreLeadingDecimalZero &&
                                userIndex === 1 &&
                                targetIndex === 1 &&
                                userChar === decimalDelim &&
                                targetChar === leadingDecimalZeroCharacter
                            ) {
                                // else if we're ignoring leading zeroes in a negative decimal and the user left off the leading zero
                                targetIndex += 1;
                            } else if (
                                ignoreLeadingCurrencyDecimalZero &&
                                userIndex <= 1 &&
                                targetIndex <= 1 &&
                                userChar === leadingDecimalZeroCharacter &&
                                targetChar === decimalDelim
                            ) {
                                // else if we're ignoring leading zeroes in a currency decimal and the user entered zero when they didn't have to
                                userIndex += 1;
                            } else if (
                                ignoreLeadingCurrencyDecimalZero &&
                                userIndex > 0 &&
                                userIndex <= 2 &&
                                targetIndex > 0 &&
                                targetIndex <= 2 &&
                                userChar === leadingDecimalZeroCharacter &&
                                targetChar === decimalDelim
                            ) {
                                // else if we're ignoring leading zeroes in a negative currency decimal and the user entered zero when they didn't have to
                                userIndex += 1;
                            } else if (
                                ignoreLeadingCurrencyDecimalZero &&
                                userIndex <= 1 &&
                                targetIndex <= 1 &&
                                userChar === decimalDelim &&
                                targetChar === leadingDecimalZeroCharacter
                            ) {
                                // else if we're ignoring leading zeroes in a currency decimal and the user left off the leading zero
                                targetIndex += 1;
                            } else if (
                                ignoreLeadingCurrencyDecimalZero &&
                                userIndex > 0 &&
                                userIndex <= 2 &&
                                targetIndex > 0 &&
                                targetIndex <= 2 &&
                                userChar === decimalDelim &&
                                targetChar === leadingDecimalZeroCharacter
                            ) {
                                // else if we're ignoring leading zeroes in a negative currency decimal and the user left off the leading zero
                                targetIndex += 1;
                            } else if (
                                isArabicLesson &&
                                this.mode === 'currency' &&
                                (targetText.substr(targetIndex) === 'د.أ' || targetText.substr(targetIndex) === ' د.أ')
                            ) {
                                // Else if we're in Arabic currency mode and all that is left is the dinar (د.أ) abbreviation, with or without a space
                                targetIndex = threshold;
                                break;
                            } else if (userIndex >= userText.length) {
                                // Else have we reached the end of user text?
                                break;
                            } else {
                                // Found a mismatch
                                mismatch = true;
                                break;
                            }
                        }

                        // Was there a mismatched character?
                        if (mismatch) {
                            return {
                                matches: false,
                                partialMatch: false,
                                userAnswerToDisplay: inputtedText,
                                nextCharacterExpected,
                            };
                            // If not, are we complete or just partially complete?
                        }
                        // Have we exceeded the threshold for complete?
                        if (targetIndex >= threshold) {
                            return {
                                matches: true,
                                partialMatch: false,
                                userAnswerToDisplay: this.expectedText, // display the real expected text
                                nextCharacterExpected: undefined,
                            };

                            // Else, it's correct so far, but not complete yet
                        }
                        // Exact mode: show appropriate capitalization as they type

                        // NOTE: We've disabled the exact-mode handling in Cordova Android in an
                        // attempt to prevent character duplication across capitalization bounds
                        // (ie: "Cc") as reported by multiple users. We haven't been able to repro
                        // the core issue prior to this attempt.
                        const answerToDisplay =
                            this.mode === 'exact' && !this.isCordovaAndroid
                                ? this.expectedText.substr(0, userText.length)
                                : inputtedText;
                        return {
                            matches: false,
                            partialMatch: true,
                            userAnswerToDisplay: answerToDisplay,
                            nextCharacterExpected,
                        };
                    },
                };
            });
        },
    ],
);

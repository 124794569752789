import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.Text.TextHelpers', [
    '$injector',

    () => ({
        splitOnSpecialBlock(text, isMarkdownFormatted, cb) {
            // data structure to track text blocks as we split and classify them
            const textBlocks = [
                {
                    inSpecialBlock: null,
                    text: '',
                },
            ];
            // state variables used during the parsing process
            let openingDelimiter;
            let inSpecialBlock = null;
            let inBlank = false;

            // helpers for managing text block state
            // Note: these operate on the state variables and data structure above

            // Start a new special block after matching an opening delimiter
            function startSpecialBlock(name, match) {
                inSpecialBlock = name;
                openingDelimiter = match;

                // create a text block for this special block
                textBlocks.push({
                    inSpecialBlock,
                    text: '',
                });
            }

            // Continue the active block by appending the text that was just matched
            function continueActiveBlock(activeTextBlock, match) {
                activeTextBlock.text += match;
            }

            // Close out the current special block, including the opening delimiter and matched text
            // Also initializes an empty text block in preparation for the next match
            function endSpecialBlock(activeTextBlock, match) {
                activeTextBlock.text = openingDelimiter + activeTextBlock.text + match;
                inSpecialBlock = null;

                // create empty next text block
                textBlocks.push({
                    inSpecialBlock: null,
                    text: '',
                });
            }

            // We want to split on mathjax and code blocks only if they
            // are not inside of blanks, because it should be
            // possible to put mathjax and code inside of a blank.
            //
            // So, we split on '%%', '[', and ']', and then go through,
            // only paying attention to '%%' if we have not already
            // seen a '[' without a matching ']'
            //
            // E.g.: if markdown formatted, we look for <code> blocks.  If not, we look for backticks

            // 1. Determine the regex to use, depending on whether the text is already markdown formatted or not
            const regex = isMarkdownFormatted ? /(<code>|<\/code>|\%\%|\$\$|\[|\])/ : /( {4}|\n|`|\%\%|\$\$|\[|\])/;

            // 2. Split the text into blocks using the regex
            text.split(regex).forEach(match => {
                // For each matched range of text...
                //
                // 3. Get the most recent text block from the parsing data structure
                const activeTextBlock = _.last(textBlocks);

                // 4. Do one of the following based on the text we just matched:
                // - Extend the most recent text block by appending the matched text to it
                // - Close out the text block if it was special and we reached the end of it
                // - Create a new special text block if we just encountered the start of a new one

                // Look for delimiters that could indicate the beginning or ending of a special block of text...
                // We used to combine these, but to avoid bugs, let's have two lists based on whether it's been markdown formatted
                const delimiters = isMarkdownFormatted ? ['%%', '$$', '<code>', '</code>'] : ['%%', '$$', '`', '    '];

                if (_.includes(delimiters, match)) {
                    // If we're not in a blank, then we can enter or leave a special block.
                    if (!inBlank) {
                        if (inSpecialBlock) {
                            // special case: we might hit a match for 4 spaces when inside of a multi-line code block
                            // in this case, we want to just continue, not prematurely terminate the special block
                            if (inSpecialBlock === 'multiLineCodeTag' && match === '    ') {
                                continueActiveBlock(activeTextBlock, match);
                            } else {
                                endSpecialBlock(activeTextBlock, match);
                            }
                        } else if (match === '%%' || match === '$$') {
                            startSpecialBlock('mathjax', match);
                        } else if (match === '<code>' || match === '`') {
                            startSpecialBlock('codeTag', match);
                        } else if (match === '    ') {
                            startSpecialBlock('multiLineCodeTag', match);
                        } else if (match === '</code>') {
                            // do nothing.  This is an edge case because we do not expect to hit a closing tag when
                            // we are not inside of a special block, but it is possible while typing in other stuff.
                            // See the test 'should handle a closing code tag when not in a special block'
                        } else {
                            throw new Error('unexpected match');
                        }

                        // if we are in a blank, we ignore the special delimiter we matched and just extend the block...
                    } else {
                        continueActiveBlock(activeTextBlock, match);
                    }

                    // If no special delimiters found, we may be entering or leaving a blank...
                } else if (match === '[' || match === ']') {
                    if (!inSpecialBlock) {
                        inBlank = match === '[';
                    }
                    continueActiveBlock(activeTextBlock, match);
                } else {
                    // special case: multi-line code tags don't having a matching tag to terminate them
                    // So, if don't match one of the delimiters in the logic above, but we're in a multi-line code block
                    // and we just hit a newline, go ahead and close out this special block
                    if (inSpecialBlock === 'multiLineCodeTag' && match === '\n') {
                        endSpecialBlock(activeTextBlock, match);
                        // otherwise just continue the active block as usual
                    } else {
                        continueActiveBlock(activeTextBlock, match);
                    }
                }
            });

            // if we reached the end of the split blocks and we're still in a multi-line code block, go ahead and close it
            if (inSpecialBlock === 'multiLineCodeTag') {
                const activeTextBlock = _.last(textBlocks);
                endSpecialBlock(activeTextBlock, '');
            }

            const results = [];
            textBlocks.forEach(entry => {
                const result = cb(entry.text, entry.inSpecialBlock);
                results.push(result);
            });
            return results.join('');
        },
    }),
]);

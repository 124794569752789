import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import template from 'Lessons/angularModule/views/lesson/frame_list/frame/componentized/component/answer_list/answer_list_image_hotspot.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('cfAnswerListImageHotspot', [
    () => ({
        restrict: 'E',
        templateUrl,

        link(scope) {
            scope.imageHotspotCssClasses = answerViewModel =>
                scope.cssClassesForAnswer(answerViewModel).concat({
                    'hidden-answer': scope.hideImageHotspotAnswer(answerViewModel),
                });

            scope.hideImageHotspotAnswer = answerViewModel => {
                if (!scope.viewModel.currentChallengeViewModel) {
                    return true;
                }
                if (
                    scope.viewModel.currentChallengeViewModel.complete &&
                    scope.selectionMode !== 'check_all_selection_mode' &&
                    !answerViewModel.showingCorrectStyling &&
                    !scope.frameViewModel.editorMode
                ) {
                    return true;
                }
                return false;
            };
        },
    }),
]);

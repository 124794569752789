import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*
    When this behavior is on, any time an answer is validated and determined
    to be correct, the ui component for that answer will have correct styling added to it.
*/
angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.Behaviors.ShowCorrectStyling',
    [
        '$injector',
        $injector => {
            const AModuleAbove = $injector.get('AModuleAbove');
            const ComponentEventListener = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.ComponentEventListener',
            );

            return new AModuleAbove({
                included(MultipleChoiceChallengeModel) {
                    MultipleChoiceChallengeModel.supportBehavior('ShowCorrectStyling');

                    MultipleChoiceChallengeModel.ViewModel.setCallback('after', 'initialize', function () {
                        const challengeViewModel = this;
                        let listener;

                        this.model.on('behavior_added:ShowCorrectStyling', () => {
                            // eslint-disable-next-line no-new
                            listener = new ComponentEventListener(challengeViewModel, 'validated', validationResult => {
                                validationResult
                                    .filterAnswersViewModels({
                                        selected: true,
                                        correct: true,
                                    })
                                    .forEach(answerViewModel => {
                                        answerViewModel.addCorrectStyling();
                                    });
                            });
                        });

                        this.model.on('behavior_removed:ShowCorrectStyling', () => {
                            listener?.cancel();
                            listener = undefined;
                        });
                    });
                },
            });
        },
    ],
);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.TilePrompt.TilePromptViewModel',

    [
        '$injector',

        $injector => {
            const UiComponentViewModel = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentViewModel',
            );

            return UiComponentViewModel.subclass(function () {
                ['active', 'complete', 'showingIncorrectStyling'].forEach(prop => {
                    Object.defineProperty(this.prototype, prop, {
                        get() {
                            return this.challengeViewModel[prop];
                        },
                    });
                });

                return {
                    directiveName: 'cf-tile-prompt',
                };
            });
        },
    ],
);

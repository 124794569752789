/* eslint-disable @typescript-eslint/ban-ts-comment */
/* @ts-ignore-next-line */
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from 'react/jsx-runtime';
import { reactPropsLogger } from 'ReactPropsLogger';
import { type JsxTransformer } from './JsxTransformer.types';

/**
 * This module mirrors the jsx-runtime module in react. React exposes the jsx(s) functions
 * as a way to transpile the jsx into consumable js code. The only reason we are doing this is so we can intercept the props
 * and add in logging as we find necessary with `reactPropsLogger`.
 *
 * We use babel to transpile our react code and configure it to point to this module "JsxTransformer" instead of the "react" module
 *
 */

export const jsx: JsxTransformer = (type, props, key, staticValidation) =>
    _jsx(type, reactPropsLogger(props, type), key, staticValidation);

export const jsxs: JsxTransformer = (type, props, key, staticValidation) =>
    _jsxs(type, reactPropsLogger(props, type), key, staticValidation);

export const Fragment = _Fragment;

import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import template from 'Lessons/angularModule/views/lesson/frame_list/frame/componentized/component/answer_list/answer_list.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('cfAnswerList', [
    '$injector',
    $injector => {
        const $window = $injector.get('$window');
        const UiComponentDirHelper = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentDirHelper',
        );
        const scopeTimeout = $injector.get('scopeTimeout');

        return UiComponentDirHelper.getOptions({
            templateUrl,
            link(scope, elem) {
                UiComponentDirHelper.link(scope);

                /*
                The renderer defines which html elements should be
                put onto the screen.  Which renderer to use is defined
                by the 'skin' property on the model.

                Note that there
                are cases where different skins use the same renderer (in
                which case the only difference in display is due to css
                differences).

                Also note that there is a 'buttons' skin and a 'checkboxes'
                skin that both use the 'buttons' renderer.  This might be
                confusing, but I couldn't come up with another name.
                */
                Object.defineProperty(scope, 'renderer', {
                    get() {
                        const renderer = {
                            buttons: 'buttons',
                            checkboxes: 'buttons',
                            matching: 'buttons',
                            image_hotspot: 'image_hotspot',
                            poll: 'poll',
                        }[this.model.skin];
                        if (!renderer) {
                            throw new Error(
                                `Cannot determine renderer for answerList with skin set to "${this.model.skin}"`,
                            );
                        }
                        return renderer;
                    },
                });

                Object.defineProperty(scope, 'selectionMode', {
                    get() {
                        return scope.viewModel.currentChallengeViewModel.model?.selection_mode;
                    },
                });

                Object.defineProperty(scope, 'checkMany', {
                    get() {
                        return this.model.skin === 'checkboxes';
                    },
                });

                Object.defineProperty(scope, 'checkManyCaptionLocaleKey', {
                    get() {
                        if (!scope.viewModel.currentChallengeViewModel?.shouldShowCheckManyCaption) {
                            return undefined;
                        }

                        return scope.selectionMode === 'check_all_selection_mode'
                            ? 'select_all_answers'
                            : 'select_multiple_answers';
                    },
                });

                scope.disableAnswer = function (answerViewModel) {
                    if (!scope.frameViewModel) {
                        return true;
                    }

                    // this is important at least in image hotspot, because
                    // otherwise you can't move or resize an answer once it is
                    // selected and validated
                    if (scope.frameViewModel.editorMode && this.model.skin === 'image_hotspot') {
                        return false;
                    }

                    if (!scope.viewModel.currentChallengeViewModel) {
                        return true;
                    }
                    if (scope.viewModel.currentChallengeViewModel.complete) {
                        return true;
                    }
                    return answerViewModel.disabled;
                };

                let buttons; // cache to prevent unnecessary button selector queries
                scope.onClickAnswer = answerViewModel => {
                    // prevent double clicks (FIXME: there should be a better way to do this. https://github.com/angular/angular.js/issues/9826)
                    if (
                        scope.lastAnswerClick &&
                        new Date() - scope.lastAnswerClick < 500 &&
                        !$window.RUNNING_IN_TEST_MODE
                    ) {
                        return;
                    }
                    scope.lastAnswerClick = new Date();

                    // currentChallengeViewModel is set in an 'activated' callback in
                    // MultipleChoiceChallengeViewModel.
                    if (!scope.viewModel.currentChallengeViewModel) {
                        throw new Error('No currentChallenge set.');
                    }
                    scope.viewModel.currentChallengeViewModel.toggleAnswer(answerViewModel);

                    // this logic governs the bouncing text animation we use on check many screens
                    // the desired behavior:
                    //
                    // - only do this on check many screens
                    // - start the bouncing after the first correct answer
                    // - reset the repeating bounce animation after each subsequent correct/incorrect answer
                    // - stop the bouncing when the screen is complete
                    if (scope.checkManyCaptionLocaleKey && !scope.viewModel.currentChallengeViewModel.complete) {
                        // After the first correct answer, we can start bouncing
                        if (
                            answerViewModel.showingCorrectStyling ||
                            scope.viewModel.currentChallengeViewModel.model?.selection_mode ===
                                'check_all_selection_mode'
                        ) {
                            scope.startBouncingCaption = true;
                        }

                        // after every correct or incorrect answer, we reset the bouncing animation
                        // cycle by adding and removing the CSS class. This is done by setting and unsetting
                        // the `resetBouncingAnimation` scope variable.
                        scope.resetBouncingAnimation = true;
                        scopeTimeout(
                            scope,
                            () => {
                                scope.resetBouncingAnimation = false;
                            },
                            100,
                        );
                    }

                    // blur all the buttons to make sure they don't have focus.  Otherwise
                    // unexpected things can happen if you press enter to validate (only an
                    // issue without ImmediateValidation.  Venn Diagram used to need this
                    // but now that it is gone it isn't clear that this is still necessary
                    // but it was difficult to ascertain so we decided to just leave it).
                    if (!buttons) {
                        buttons = elem.find('button');
                    }
                    buttons.blur();
                };

                scope.bounceCaption = () => {
                    if (
                        scope.checkManyCaptionLocaleKey &&
                        scope.startBouncingCaption &&
                        !scope.resetBouncingAnimation &&
                        scope.viewModel.currentChallengeViewModel &&
                        !scope.viewModel.currentChallengeViewModel.complete
                    ) {
                        return true;
                    }
                    return false;
                };

                scope.cssClassesForAnswer = answerViewModel => {
                    const skinClassMap = {
                        buttons: 'button',
                        checkboxes: 'checkbox-button',
                        venn_diagram: 'venn_diagram',
                        image_hotspot: 'image_hotspot',
                    };

                    const classes = [skinClassMap[scope.model.skin]];

                    // just used for tests
                    if (answerViewModel.model.contentType === 'image') {
                        classes.push('image');
                    }

                    // used by image_hotspot
                    else {
                        classes.push('no_image');
                    }

                    if (answerViewModel.model.hasBackground) {
                        classes.push('has_background');
                    }

                    if (scope.frameViewModel.editorMode) {
                        classes.push('editable');
                    }

                    if (
                        scope.viewModel.currentChallengeViewModel &&
                        scope.viewModel.currentChallengeViewModel.complete
                    ) {
                        classes.push('challenge-complete');
                    }

                    return classes.concat(answerViewModel.cssClasses);
                };

                scope.cssClassesForIcon = answerViewModel => {
                    const classes = ['icon'];

                    if (scope.model.skin === 'buttons') {
                        classes.push('btn-hexagon-lg');
                    } else if (scope.model.skin === 'checkboxes') {
                        classes.push(
                            answerViewModel.selected || answerViewModel.showingValidatedStyling
                                ? 'btn-hexagon-lg'
                                : 'checkbox',
                        );
                    }

                    return classes;
                };
            },
        });
    },
]);

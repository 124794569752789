import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import 'ExtensionMethods/array';

angularModule.factory('Lesson.FrameList.Frame.Componentized.FlexibleComponentizedFrameViewModel', [
    '$injector',

    $injector => {
        const ComponentizedBaseFrameViewModel = $injector.get(
            'Lesson.FrameList.Frame.Componentized.ComponentizedBaseViewModel',
        );
        const ComponentEventListener = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.ComponentEventListener',
        );
        return ComponentizedBaseFrameViewModel.subclass(function () {
            this.whoAmI = 'FlexibleComponentizedFrameViewModel';

            Object.defineProperty(this.prototype, 'mainUiComponentViewModel', {
                get() {
                    const mainUiComponent = this.frame.mainUiComponent;
                    if (mainUiComponent) {
                        return this.viewModelFor(mainUiComponent);
                    }

                    return undefined;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'complete', {
                get() {
                    return this.mainUiComponentViewModel.complete;
                },
                configurable: true,
            });

            return {
                initialize($super, frame, options) {
                    $super(frame, options);

                    // listen for completed (must use $$ to prevent stupid angular JSON.stringify error)
                    if (this.mainUiComponentViewModel) {
                        this.$$_completedEventListener = new ComponentEventListener(
                            this.mainUiComponentViewModel,
                            'completed',
                            () => {
                                this._triggerCallbacks('completed');
                            },
                        );
                    }
                },

                // provides a hint for the given frame's context (challenge / whatever).
                // currently only implmented for UserInputChallenge handling via ChallengeBlankDir
                giveHint(event) {
                    this._triggerCallbacks('giveHint', event, this.mainUiComponentViewModel.currentChallengeViewModel);
                },
            };
        });
    },
]);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Answer.SelectableAnswer.SelectableAnswerNavigatorModel',
    [
        'Lesson.FrameList.Frame.Componentized.Component.Answer.AnswerModel',
        'Lesson.FrameList.Frame.Componentized.Component.Answer.SelectableAnswer.SelectableAnswerNavigatorViewModel',
        (AnswerModel, SelectableAnswerNavigatorViewModel) =>
            AnswerModel.subclass(function () {
                this.alias('ComponentizedFrame.SelectableAnswerNavigator');
                this.extend({
                    ViewModel: SelectableAnswerNavigatorViewModel,
                });
                this.setEditorViewModel(
                    'Lesson.FrameList.Frame.Componentized.Component.Answer.SelectableAnswer.SelectableAnswerNavigatorEditorViewModel',
                );

                this.references('challenge').through('challenge_id');
                this.references('answerMatcher').through('answer_matcher_id');
                this.references('frameNavigator').through('frame_navigator_id');

                this.key('event');
                this.key('next_frame_id');

                return {
                    initialize($super, answer) {
                        $super(answer);
                    },
                    appliesToAnswer(answer) {
                        return this.answerMatcher.matches(answer);
                    },
                };
            }),
    ],
);

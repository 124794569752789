import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*


 */
angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.AnswerMatcher.SimilarToSelectableAnswer.SimilarToSelectableAnswerModel',
    [
        'Lesson.FrameList.Frame.Componentized.Component.AnswerMatcher.AnswerMatcherModel',
        'Lesson.FrameList.Frame.Componentized.Component.Challenge.ChallengeResponse',
        'Lesson.FrameList.Frame.Componentized.Component.Answer.SelectableAnswer.SelectableAnswerModel',
        (AnswerMatcherModel, ChallengeResponse, SelectableAnswerModel) =>
            AnswerMatcherModel.subclass(function () {
                this.alias('ComponentizedFrame.SimilarToSelectableAnswer');
                this.extend({
                    // answerMatchers have no state, so need no viewModel
                    ViewModel: null,
                });
                this.setEditorViewModel(
                    'Lesson.FrameList.Frame.Componentized.Component.AnswerMatcher.SimilarToSelectableAnswer.SimilarToSelectableAnswerEditorViewModel',
                );

                this.references('answer').through('answer_id');

                return {
                    matches(challengeResponseOrSelectableAnswer) {
                        let challengeResponse;
                        if (
                            challengeResponseOrSelectableAnswer &&
                            challengeResponseOrSelectableAnswer.isA &&
                            challengeResponseOrSelectableAnswer.isA(SelectableAnswerModel)
                        ) {
                            challengeResponse = ChallengeResponse.fromSelectableAnswer(
                                challengeResponseOrSelectableAnswer,
                            );
                        } else {
                            challengeResponse = challengeResponseOrSelectableAnswer;
                        }

                        return this.matchesChallengeResponse(challengeResponse);
                    },

                    matchesChallengeResponse(challengeResponse) {
                        const textModel = this.answer.text;

                        if (challengeResponse && !challengeResponse.isA(ChallengeResponse)) {
                            throw new Error('matches must be called with an instance of ChallengeResponse');
                        }

                        if (textModel && this.answer.image) {
                            throw new Error('Cannot match answer with both text and image.');
                        }

                        if (angular.isDefined(this.answer.x) && angular.isDefined(this.answer.y)) {
                            // NOTE: We may wish to migrate to a ExactAnswer validator for this in the future
                            return this.answer.x === challengeResponse.x && this.answer.y === challengeResponse.y;
                        }
                        if (textModel) {
                            if (typeof challengeResponse.text !== 'string') {
                                return false;
                            }

                            // If our answer is a blank string, do not match a different answer that is also a blank
                            // string. See https://trello.com/c/Kpm5WDt6 for why we do this.
                            if (!textModel.text) {
                                return textModel.id === challengeResponse.answer?.text?.id;
                            }

                            // this used to be case-insensitive but that didn't see to make any sense and caused
                            // trouble with a python-related frame.  See last checkbox on https://trello.com/c/1vawP4jj
                            const textMatches = textModel.text === challengeResponse.text;

                            return textMatches;
                        }
                        if (this.answer.image) {
                            // FIXME: is it okay to just check the equality of
                            // components here, or do we need to check the url?
                            return this.answer.image === challengeResponse.image;
                        }
                        throw new Error('What should I do here?');
                    },
                };
            }),
    ],
);

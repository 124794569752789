import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*
    A ChallengeValidator is referenced by an instance of Challenge.  It holds all of the
    information about what answers are expected by the challenge and all of the logic
    for determining whether the challenge's userAnswers match those expectations.

    The rules for what determines correctness is a combination of the list of
    expectedAnswerMatchers and the active behaviors.

    Validation happens when the validate() method is called.  The Challenge is
    responsible for deciding when validate() is called.

    When validate() is called, the validator initializes an empty list of errors and executes
    any validations that have been added by behaviors.  Those validations can add errors
    to the list.  Once all validations have been run, the list of errors is used to instantiate
    an instance of ValidationResult, which is then included in the dispatched "validated" event.
*/
angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.ChallengeValidator.ChallengeValidatorModel', [
    'Lesson.FrameList.Frame.Componentized.Component.ComponentModel',
    'Lesson.FrameList.Frame.Componentized.Component.ChallengeValidator.ChallengeValidatorViewModel',
    'Lesson.FrameList.Frame.Componentized.Component.ChallengeValidator.Behaviors.HasAllExpectedAnswers',
    'Lesson.FrameList.Frame.Componentized.Component.ChallengeValidator.Behaviors.HasNoUnexpectedAnswers',
    (ComponentModel, ChallengeValidatorViewModel, HasAllExpectedAnswers, HasNoUnexpectedAnswers) =>
        ComponentModel.subclass(function () {
            this.alias('ComponentizedFrame.ChallengeValidator');
            this.extend({
                ViewModel: ChallengeValidatorViewModel,
            });
            this.setEditorViewModel(
                'Lesson.FrameList.Frame.Componentized.Component.ChallengeValidator.ChallengeValidatorEditorViewModel',
            );

            this.include(HasAllExpectedAnswers);
            this.include(HasNoUnexpectedAnswers);

            this.references('challenge').through('challenge_id');

            // expectedAnswerMatchers is optional.  Used by some validations to check the
            // provided answers against a list of expectations.
            this.references('expectedAnswerMatchers').through('expected_answer_matcher_ids');

            return {
                isExpectedAnswer(answer) {
                    if (!this.expectedAnswerMatchers) {
                        return false;
                    }

                    for (const expectedAnswerMatcher of this.expectedAnswerMatchers) {
                        if (expectedAnswerMatcher.matches(answer)) {
                            return true;
                        }
                    }

                    return false;
                },
            };
        }),
]);

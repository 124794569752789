import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import template from 'Lessons/angularModule/views/lesson/frame_list/frame/componentized/component/tile_prompt_board/tile_prompt_board.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('cfTilePromptBoard', [
    '$injector',

    $injector => {
        const UiComponentDirHelper = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentDirHelper',
        );
        const ComponentEventListener = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.ComponentEventListener',
        );
        const $timeout = $injector.get('$timeout');
        const $document = $injector.get('$document');
        const Capabilities = $injector.get('Capabilities');
        const safeApply = $injector.get('safeApply');
        const $rootScope = $injector.get('$rootScope');

        return UiComponentDirHelper.getOptions({
            templateUrl,

            link(scope, elem) {
                UiComponentDirHelper.link(scope, elem);

                //------------------------
                // Touch Handling
                //------------------------

                const TILT_THRESHOLD = 40;

                const SELECT_THRESHOLD = 80;
                let activeClassListForTouchedTile;
                let touchIgnore;
                let touchLastX;
                let touchStartX;

                function touchStart(ev) {
                    activeClassListForTouchedTile = elem.find('.tile.selected').get(0).classList;
                    touchIgnore = $(ev.target).is('button');
                    touchStartX = ev.originalEvent.touches[0].pageX;
                }

                function touchMove(ev) {
                    if (touchIgnore) {
                        return;
                    }

                    const curr = ev.originalEvent.touches[0].pageX;

                    if (Math.abs(touchStartX - curr) >= TILT_THRESHOLD) {
                        ev.preventDefault(); // prevent scrolling
                        if (curr > touchStartX) {
                            activeClassListForTouchedTile.remove('animate-left-partial');
                            activeClassListForTouchedTile.add('animate-right-partial');
                        } else {
                            activeClassListForTouchedTile.remove('animate-right-partial');
                            activeClassListForTouchedTile.add('animate-left-partial');
                        }
                    } else {
                        activeClassListForTouchedTile.remove('animate-left-partial');
                        activeClassListForTouchedTile.remove('animate-right-partial');
                    }

                    touchLastX = curr;
                }

                function touchEnd(ev) {
                    if (touchIgnore || touchLastX === 0) {
                        return;
                    }

                    if (Math.abs(touchStartX - touchLastX) >= SELECT_THRESHOLD) {
                        ev.preventDefault(); // prevent scrolling
                        if (touchLastX > touchStartX + SELECT_THRESHOLD) {
                            safeApply(scope, scope.selectRight);
                        } else if (touchLastX < touchStartX - SELECT_THRESHOLD) {
                            safeApply(scope, scope.selectLeft);
                        }
                    } else {
                        activeClassListForTouchedTile.remove('animate-left-partial');
                        activeClassListForTouchedTile.remove('animate-right-partial');
                    }

                    touchStartX = 0;
                    touchLastX = 0;
                }

                function setupTouchListeners() {
                    // NOTE: We can change this to use hammer drag events if we'd prefer to also monitor mouse drags
                    const tileContainer = elem.find(elem.find('.tile-container'));
                    tileContainer.on('touchstart', touchStart);
                    tileContainer.on('touchmove', touchMove);
                    tileContainer.on('touchend', touchEnd);
                    tileContainer.on('touchcancel', touchEnd);
                }

                function cleanupTouchListeners() {
                    const tileContainer = elem.find(elem.find('.tile-container'));
                    tileContainer.off('touchstart', touchStart);
                    tileContainer.off('touchmove', touchMove);
                    tileContainer.off('touchend', touchEnd);
                    tileContainer.off('touchcancel', touchEnd); // important!
                }

                // only setup this touch events if we actually need them
                if (Capabilities.touchEnabled) {
                    setupTouchListeners();
                }

                //------------------------
                // Keypress Handling
                //------------------------

                function keyDown(e) {
                    // this logic is stolen from click_key_dir.js to ensure we don't trigger keyboard shortcuts when we shouldn't
                    if (
                        ($rootScope.currentUser && !$rootScope.currentUser.pref_keyboard_shortcuts) ||
                        $('body').hasClass('modal-open') ||
                        elem.is(':disabled') ||
                        $(e.target).is('input') ||
                        $(e.target).is('textarea') ||
                        elem.closest('[disabled="disabled"]').length > 0 ||
                        e.metaKey
                    ) {
                        return;
                    }
                    if (e.which === 37) {
                        safeApply(scope, scope.selectLeft);
                    } else if (e.which === 39) {
                        safeApply(scope, scope.selectRight);
                    }
                }

                function setupKeyListeners() {
                    if (!scope.frameViewModel.editorMode) {
                        $document.on('keydown.tilePromptBoard', keyDown);
                    }
                }

                function cleanupKeyListeners() {
                    if (!scope.frameViewModel.editorMode) {
                        $document.off('keydown.tilePromptBoard', keyDown);
                    }
                }

                if (!Capabilities.touchEnabled) {
                    setupKeyListeners();
                }

                //------------------------
                // Tile Selection
                //------------------------

                let correctListener;

                let incorrectListener;

                // creates challenge validation listeners in order to style cards appropriately on any sort of UI that will trigger
                // interaction (self's touch event handling + answer component validation initiation)
                function setupChallengeValidationListeners(challengeViewModel) {
                    correctListener = new ComponentEventListener(challengeViewModel, 'validatedCorrect', response => {
                        const $curElement = elem.find('.tile.selected');
                        const curElement = $curElement.get(0);
                        const activeClassList = curElement.classList;
                        const selectedIndex = scope.viewModel.answerListViewModel.getAnswerViewModelIndex(
                            response.info.event.target,
                        );

                        activeClassList.add('animate-full');

                        if (selectedIndex === 0) {
                            activeClassList.add('animate-left-full');
                        } else {
                            activeClassList.add('animate-right-full');
                        }

                        // hide current element after a delay (no need for digest)
                        $timeout(
                            () => {
                                $curElement.hide();
                            },
                            1000,
                            false,
                        );
                    });

                    incorrectListener = new ComponentEventListener(
                        challengeViewModel,
                        'validatedIncorrect',
                        response => {
                            // this listener will fire on 'answerSelected' and 'answerUnselected'
                            const activeClassList = elem.find('.tile.selected').get(0).classList;

                            const selectedIndex = scope.viewModel.answerListViewModel.getAnswerViewModelIndex(
                                response.info.event.target,
                            );
                            const selecting = response.info.event.type === 'answerSelected';

                            if (selectedIndex === 0) {
                                if (selecting) {
                                    activeClassList.add('animate-left-partial');
                                } else {
                                    activeClassList.remove('animate-left-partial');
                                }
                            } else if (selecting) {
                                activeClassList.add('animate-right-partial');
                            } else {
                                activeClassList.remove('animate-right-partial');
                            }
                        },
                    );
                }

                // cleans up correct / incorrect listeners
                function cleanupChallengeValidationListeners() {
                    if (correctListener) {
                        correctListener.cancel();
                    }
                    if (incorrectListener) {
                        incorrectListener.cancel();
                    }
                    incorrectListener = undefined;
                    correctListener = undefined;
                }

                scope.selectLeft = () => {
                    scope.viewModel.answerListViewModel.selectAnswerByIndex(0);
                };

                scope.selectRight = () => {
                    scope.viewModel.answerListViewModel.selectAnswerByIndex(1);
                };

                //------------------------
                // Tile Ordinality
                //------------------------

                // determines whether the provided TilePromptViewModel is the current prompt to be displayed for interaction
                scope.isTileActive = tilePromptViewModel => {
                    if (!tilePromptViewModel || !scope.viewModel.answerListViewModel.currentChallengeViewModel) {
                        return false;
                    }
                    return (
                        scope.viewModel.answerListViewModel.currentChallengeViewModel.model ===
                        tilePromptViewModel.model.challenge
                    );
                };

                // determines whether the provided TilePromptViewModel is the next to be displayed
                scope.isTileNext = tilePromptViewModel => {
                    if (!tilePromptViewModel) {
                        return false;
                    }

                    const tileChallenge = tilePromptViewModel.model.challenge;
                    const nextChallengeViewModel = scope.viewModel.challengesComponentViewModel.nextChallengeViewModel;

                    if (!nextChallengeViewModel) {
                        return false;
                    }

                    return !scope.isTileActive(tilePromptViewModel) && tileChallenge === nextChallengeViewModel.model;
                };

                //------------------------
                // Tile Styling
                //------------------------

                let _classesForTile = {};
                scope.classesForTile = (tilePromptViewModel, isLast) => {
                    if (!_classesForTile[tilePromptViewModel.model.id]) {
                        const isActive = scope.isTileActive(tilePromptViewModel);
                        const isNext = scope.isTileNext(tilePromptViewModel);

                        _classesForTile[tilePromptViewModel.model.id] = {
                            tile: true,
                            selected: isActive,
                            next: isNext,
                            last: isLast && !isNext && !isActive,
                        };
                    }

                    return _classesForTile[tilePromptViewModel.model.id];
                };

                // onFinishRender gets called initially, once the tiles are rendered.
                // Usually, we can immediately calculate the necessary height of
                // the tiles.  Sometimes, though, it takes a beat before the tiles
                // have settled into their full height, so we do the update again
                // 200ms later just to be sure.  This can be necessary, for example,
                // if the text has an inline display image within it.  Unfortunately,
                // this little blip can actually be visible to users, but in the normal
                // case the second update does nothing so it won't be.
                const delayToEnsureImageRenders = 200;
                scope.onFinishRender = () => {
                    scope.updateMaxTileHeight();
                    scope.updateMaxTileHeight(delayToEnsureImageRenders);
                };

                scope.updateMaxTileHeight = delay => {
                    delay = delay || 0;
                    $timeout(() => {
                        const tileContainer = elem.find('.tile-container');
                        const tiles = elem.find('.tile');
                        const tilePrompts = elem.find('.cf-tile-prompt');

                        // reset container heights to allow content to scale them
                        tilePrompts.css('height', 'auto');
                        tiles.css('height', 'auto');

                        // calculate max tile height
                        const maxTileHeight = Math.max.apply(
                            null,
                            tiles
                                .map(function () {
                                    return $(this).height();
                                })
                                .get(),
                        );

                        // update heights
                        tiles.css('height', maxTileHeight);
                        tileContainer.css('height', maxTileHeight);
                        tilePrompts.css('height', '100%');
                    }, delay);
                };

                //-----------------------
                // Watches
                //-----------------------

                scope.$watch('frameViewModel', frameViewModel => {
                    if (frameViewModel && frameViewModel.editorMode) {
                        scope.model.on(
                            '.tilePrompts[].text:set:text',
                            () => {
                                // After a text change, there are 2 things we need
                                // to wait for.
                                // 1. It takes a while before text-dir actually
                                //      knows about the change and renders it to
                                //      the screen.
                                const delayToEnsureTextChangeApplied = 200;
                                scope.updateMaxTileHeight(delayToEnsureTextChangeApplied);
                                // 2. See the comment above onFinishRender
                                scope.updateMaxTileHeight(delayToEnsureTextChangeApplied + delayToEnsureImageRenders);
                            },
                            false,
                        );
                    }
                });

                // handles challenge listener wiring / cleanup
                scope.$watch('viewModel.answerListViewModel.currentChallengeViewModel', newVal => {
                    if (newVal) {
                        cleanupChallengeValidationListeners();
                        setupChallengeValidationListeners(
                            scope.viewModel.answerListViewModel.currentChallengeViewModel,
                        );
                    }
                    _classesForTile = {};
                });

                // cleanup touch event listeners when we're complete with the challenges
                scope.$watch('viewModel.challengesComponentViewModel', () => {
                    if (scope.viewModel.challengesComponentViewModel) {
                        // eslint-disable-next-line no-new
                        new ComponentEventListener(scope.viewModel.challengesComponentViewModel, 'completed', () => {
                            cleanupTouchListeners();
                            cleanupKeyListeners();
                            elem.find('.tile-prompt-board').addClass('completed'); // skip ng-class digest overhead in template
                        });
                    }
                });

                //-----------------------
                // Misc
                //-----------------------

                scope.$on('$destroy', () => {
                    cleanupChallengeValidationListeners();
                    cleanupTouchListeners();
                    cleanupKeyListeners();
                });
            },
        });
    },
]);

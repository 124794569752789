import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.Text.Behaviors.ProcessesMathjax', [
    '$injector',
    $injector => {
        const AModuleAbove = $injector.get('AModuleAbove');

        return new AModuleAbove({
            included(TextModel) {
                TextModel.supportBehavior('ProcessesMathjax');
            },
        });
    },
]);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import 'ExtensionMethods/array';

angularModule.factory('Lesson.FrameList.Frame.FlexibleComponentized', [
    '$injector',
    $injector => {
        const ComponentizedBase = $injector.get('Lesson.FrameList.Frame.ComponentizedBase');
        const ComponentModel = $injector.get('Lesson.FrameList.Frame.Componentized.Component.ComponentModel');
        const TranslationHelper = $injector.get('TranslationHelper');
        const FlexibleComponentizedFrameViewModel = $injector.get(
            'Lesson.FrameList.Frame.Componentized.FlexibleComponentizedFrameViewModel',
        );

        const translationHelper = new TranslationHelper('lessons.lesson.frame_list.frame.componentized');

        return ComponentizedBase.subclass(function () {
            const FlexibleComponentized = this;

            // See comment in Frame#get_klass_for_type for why the alias does not match the class name here
            this.alias('componentized');

            this.supportsComponent('mainUiComponent', ComponentModel);

            this.extend({
                title: 'Componentized',
                FrameViewModel: FlexibleComponentizedFrameViewModel,
                getInteractionTypeConfig: (title, abbreviation, template) => ({
                    title,
                    abbreviation,
                    matchesFrame: frame =>
                        frame.constructor === FlexibleComponentized && frame.editor_template === template.identifier,
                    setupFrame: newFrame => this._createFrameWithMainUiComponent(newFrame, template),
                    FrameKlass: FlexibleComponentized,
                    isInteractive: template.identifier !== 'no_interaction',
                }),
                _createFrameWithMainUiComponent(newFrame, template) {
                    const EditorViewModel = template.MainUiComponentEditorViewModel;
                    const helper = EditorViewModel.addComponentTo(newFrame).setup();
                    newFrame.mainUiComponent = helper.model;
                    newFrame.mainUiComponentEditorViewModel.applyTemplate(template);
                    return newFrame;
                },
            });

            Object.defineProperty(this.prototype, 'mainTextComponent', {
                get() {
                    return this.mainUiComponent ? this.mainUiComponent.mainTextComponent : undefined;
                },

                configurable: true,
            });

            Object.defineProperty(this.prototype, 'mainImage', {
                get() {
                    return this.mainUiComponentEditorViewModel?.mainImage;
                },
                set(image) {
                    // when first initializing one of these by switching from another
                    // frame type, mainImageId might be set, but we might not have
                    // a mainUiComponent
                    if (this.mainUiComponentEditorViewModel) {
                        this.mainUiComponentEditorViewModel.mainImage = image;
                    }
                },
                configurable: true, // spec
            });

            const getMultipleChoiceInstructions = function () {
                // See CheckManyMixin#getEditorTemplate
                if (this.mainUiComponent.challenges[0].editor_template.match(/check_many/)) {
                    const localeKey =
                        this.mainUiComponent.challenges[0].selection_mode === 'check_all_selection_mode'
                            ? 'select_all_answers'
                            : 'select_all_answers_that_apply';
                    return translationHelper.get(localeKey);
                }
                return translationHelper.get('select_an');
            };

            Object.defineProperty(this.prototype, 'miniInstructions', {
                get() {
                    const key = this.mainUiComponent.editor_template;

                    if (!this.$$_miniInstructionsMap) {
                        this.$$_miniInstructionsMap = {
                            no_interaction: translationHelper.get('no_interaction'),
                            basic_multiple_choice: getMultipleChoiceInstructions,
                            multiple_card_multiple_choice: getMultipleChoiceInstructions,
                            matching: translationHelper.get('matching'),
                            this_or_that: translationHelper.get('this_or_that'),
                            fill_in_the_blanks: translationHelper.get('fill_in_the_blanks'),
                            image_hotspot: getMultipleChoiceInstructions,
                            compose_blanks: translationHelper.get('compose_blanks'),
                            compose_blanks_on_image: translationHelper.get('compose_blanks_on_image'),
                            blanks_on_image: translationHelper.get('blanks_on_image'),
                            multiple_choice_poll: translationHelper.get('multiple_choice_poll'),
                        };
                    }

                    const valueGetter = this.$$_miniInstructionsMap[key];

                    // should we support custom values?
                    if (!valueGetter) {
                        throw new Error(`No miniInstructions for "${key}"`);
                    } else if (typeof valueGetter === 'function') {
                        return valueGetter.apply(this);
                    } else {
                        return valueGetter;
                    }
                },
            });

            Object.defineProperty(this.prototype, 'editor_template', {
                get() {
                    return this.mainUiComponent ? this.mainUiComponent.editor_template : undefined;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'mainUiComponentEditorViewModel', {
                get() {
                    return this.mainUiComponent && this.editorViewModelFor(this.mainUiComponent);
                },
            });

            return {
                directiveName: 'componentized',
                infoPanelDirectiveName: 'componentized-info-panel',

                generateTutorBotDescription() {
                    return this.mainUiComponent?.tutorBotDescription || null;
                },
            };
        });
    },
]);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*

    When this behavior is on, any time a challenge is deactivated, frameViewModel.clearMessage
    will be called

*/
angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.Challenge.Behaviors.ClearMessagesOnDeactivated', [
    'AModuleAbove',
    'Lesson.FrameList.Frame.Componentized.Component.ComponentEventListener',
    (AModuleAbove, ComponentEventListener) =>
        new AModuleAbove({
            included(ChallengeModel) {
                ChallengeModel.supportBehavior('ClearMessagesOnDeactivated');

                ChallengeModel.ViewModel.setCallback('after', 'initialize', function () {
                    const challengeViewModel = this;

                    this.model.on('behavior_added:ClearMessagesOnDeactivated', () => {
                        new ComponentEventListener(challengeViewModel, 'deActivated', () => {
                            challengeViewModel.playerViewModel.clearMessage();
                        });
                    });
                });
            },
        }),
]);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.MatchingBoard.MatchingBoardViewModel',

    [
        '$injector',

        $injector => {
            const UiComponentViewModel = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentViewModel',
            );

            return UiComponentViewModel.subclass(function () {
                Object.defineProperty(this.prototype, 'orderedMatchingChallengeButtonViewModels', {
                    get() {
                        if (!this._orderedMatchingChallengeButtonViewModels) {
                            // move completed challenges to the top
                            this._orderedMatchingChallengeButtonViewModels =
                                this.matchingChallengeButtonsViewModels.sort((a, b) => {
                                    const indexA = angular.isUndefined(this._completedChallenges[a.model.challenge.id])
                                        ? 999
                                        : this._completedChallenges[a.model.challenge.id];
                                    const indexB = angular.isUndefined(this._completedChallenges[b.model.challenge.id])
                                        ? 999
                                        : this._completedChallenges[b.model.challenge.id];

                                    if (indexA < indexB) {
                                        return -1;
                                    }
                                    if (indexA > indexB) {
                                        return 1;
                                    }
                                    return 0;
                                });
                        }

                        return this._orderedMatchingChallengeButtonViewModels;
                    },
                });

                return {
                    directiveName: 'cf-matching-board',

                    initialize($super, frameViewModel, model) {
                        $super(frameViewModel, model);
                        frameViewModel.bodyBackgroundColor = 'turquoise';

                        // keep a map of the answer_ids and challenge_ids pointing to the index of
                        // when they were selected (so the first is 0, the second is 1, etc.)
                        // so that we can order the answers in the ui
                        this._selectedCorrectAnswers = {};
                        this._completedChallenges = {};

                        const self = this;
                        this.model.on(
                            '.matchingChallengeButtons[]:set:challenge',
                            challenge => {
                                // reset when a new challenge is added
                                self._orderedMatchingChallengeButtonViewModels = undefined;
                                self.viewModelFor(challenge).on('validatedCorrect', result => {
                                    const answerViewModel = result.info.event.target;
                                    self._selectedCorrectAnswers[answerViewModel.model.id] = Object.keys(
                                        self._selectedCorrectAnswers,
                                    ).length;
                                    self._orderAnswers();
                                });

                                self.viewModelFor(challenge).on('completed', () => {
                                    self._completedChallenges[challenge.id] = Object.keys(
                                        self._completedChallenges,
                                    ).length;
                                    self._resetChallengeOrder();
                                });
                            },
                            true,
                        );

                        this.model.on('.matchingChallengeButtons:childAdded', this._resetChallengeOrder.bind(this));
                        this.model.on('.matchingChallengeButtons:childRemoved', this._resetChallengeOrder.bind(this));
                    },

                    _orderAnswers() {
                        const ordered = this.answerListViewModel.orderedAnswerViewModels.sort((a, b) => {
                            const indexA = angular.isUndefined(this._selectedCorrectAnswers[a.model.id])
                                ? 999
                                : this._selectedCorrectAnswers[a.model.id];
                            const indexB = angular.isUndefined(this._selectedCorrectAnswers[b.model.id])
                                ? 999
                                : this._selectedCorrectAnswers[b.model.id];

                            if (indexA < indexB) {
                                return -1;
                            }
                            if (indexA > indexB) {
                                return 1;
                            }
                            return 0;
                        });
                        this.answerListViewModel.orderedAnswerViewModels = ordered;
                    },

                    _resetChallengeOrder() {
                        this._orderedMatchingChallengeButtonViewModels = undefined;

                        /*
                            It is against the rules for the answers to be in the correct order (i.e.
                            first answer is the answer for the first challenge. Second answer is the
                            answer for the second challenge, etc.)  So here we figure out what the
                            correct order is, and tell RandomizeAnswerOrder to never generate that order.
                        */
                        this.answerListViewModel.disallowedAnswerOrder = this.matchingChallengeButtonsViewModels.map(
                            buttonViewModel => buttonViewModel.challengeViewModel.correctAnswerViewModel,
                        );
                    },
                };
            });
        },
    ],
);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import 'ExtensionMethods/array';

angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.ComponentReference', [
    'SuperModel',
    '$injector',

    (SuperModel, $injector) => {
        /*
            ComponentReference handles references between one component and another.

            This file defines a ComponentReference base class.  Every time a ComponentModel klass
            calls

                references('something').through('something_id')

            a new subclass of ComponentReference is created.  That subclass has
            the following class properties:

                - `key` (the public property used to get the referenced component.  'something' in the example above)
                - `idKey` (the property that holds the ids of the referenced components. 'something_id' in the example above)
                - `modelKlass` (the subclass of ComponentModel that has defines reference)

            A getter/setter is created on the model's `key` property.  Every time the property is
            get or set, an instance of the ComponentReference subclass will be created.  This instance
            is useful because we can run callbacks on it later on.

            When the getter for a reference is called, it can return either a single component
            or a list of components, depending on the value of the `idKey` property on the model.  If
            a list is returned, it will be an array with a few special methods added onto it.  It is not
            safe to modify this array using anything other than these special methods, as it will not
            modify the underlying list of ids.  The methods are:

                - push(referencedModel) : behaves just as you would expect, and modifies the underlying id property on the model
                - remove, splice, clone : also behaves as you would expect
                - on(event, callback) : sets up a callback to be called when the list is modified. Supported events are:
                    - childAdded : triggered for each current value in the list when `on` is called, and then again any time
                                    a component is added to the list.


        */

        // I tried to do this by creating a class that has the
        // Array prototype, but that caused trouble because then
        // you can't compare this thing to a vanilla array with the same
        // list of components, which we wanted to do in tests.  This is
        // potentially a performance issue, since we're redifining 'push', etc.
        // over and over rather than just once on the prototype of a class.  But
        // I'm guessing (hoping) that that won't really be an issue.

        function getProxyList(reference, componentIds) {
            const list = [];
            angular.forEach(componentIds, id => {
                const component = reference.frame.dereference(id);
                list.push(component);
            });

            const idKey = reference.idKey;
            const model = reference.model;

            // we want to define these properties with Object.defineProperty so
            // that if this array is compared to a vanilla array with all the same elements
            // in tests, it will evaluate to equal in tests
            Object.defineProperty(list, 'push', {
                get() {
                    return function (component) {
                        const ComponentModel = $injector.get(
                            'Lesson.FrameList.Frame.Componentized.Component.ComponentModel',
                        );
                        if (!component.isA || !component.isA(ComponentModel)) {
                            throw new Error(
                                `Cannot add something that is not a ComponentModel to ${model.type}.${reference.key}`,
                            );
                        }
                        try {
                            // this line probably unnecessary? If we got this far,
                            // I think the id property had to have already been set
                            model[idKey] = model[idKey] || [];
                            // support either a component or an editorViewModel (are
                            // we using this?  do we want to?)
                            model[idKey].push(component.id || component.model.id);
                            Array.prototype.push.call(this, component);
                        } catch (e) {
                            throw new Error(
                                `Error trying to push component onto "${idKey}" on ${model.type}: "${e.message}"`,
                            );
                        }
                        reference.model.triggerCallbacks(reference.callbackKey('childAdded'), component);
                    };
                },
            });

            Object.defineProperty(list, 'remove', {
                get() {
                    return function (component) {
                        if (!model[idKey].includes(component.id)) {
                            return false;
                        }
                        try {
                            // this line probably unnecessary? If we got this far,
                            // I think the id property had to have already been set
                            model[idKey] = model[idKey] || [];

                            Array.remove(model[idKey], component.id);

                            // Array.remove will call into this.splice, which will
                            // ensure that childRemovedis fired
                            Array.remove(this, component);
                        } catch (e) {
                            throw new Error(
                                `Error trying to remove component from "${idKey}" on ${model.type}: "${e.message}"`,
                            );
                        }
                        return true;
                    };
                },
            });

            Object.defineProperty(list, 'splice', {
                get() {
                    return function (...args) {
                        // this line probably unnecessary? If we got this far,
                        // I think the id property had to have already been set
                        model[idKey] = model[idKey] || [];

                        // make copies for preservation of components
                        let i;

                        const oldList = [];
                        for (i = this.length - 1; i >= 0; i--) {
                            oldList[i] = this[i];
                        }

                        // perform vanilla splice underlying collection and rebuild ids
                        Array.prototype.splice.apply(this, args);
                        model[idKey] = this.map(comp => comp.id);

                        // iterate through proxy looking for missing components
                        for (i = oldList.length - 1; i >= 0; i--) {
                            const component = oldList[i];
                            if (!this.includes(component)) {
                                reference.model.triggerCallbacks(reference.callbackKey('childRemoved'), component);
                            }
                        }

                        // iterate through arguments looking for appended items
                        for (i = args.length - 1; i >= 2; i--) {
                            const added = args[i];

                            reference.model.triggerCallbacks(reference.callbackKey('childAdded'), added);
                        }
                    };
                },
            });

            // used in tests
            Object.defineProperty(list, 'clone', {
                get() {
                    return function () {
                        return this.map(item => item);
                    };
                },
            });

            // throw errors on any unimplemented collection methods
            angular.forEach(['shift', 'unshift', 'pop', 'slice'], unsupported => {
                Object.defineProperty(list, unsupported, {
                    get() {
                        return () => {
                            throw new Error(`proxyList does not support the ${unsupported} method`);
                        };
                    },
                });
            });

            // supports childAdded and childRemoved
            Object.defineProperty(list, 'on', {
                get() {
                    return function (event, callback, runNow) {
                        const list = this;
                        // for the childAdded event (but not for childRemoved) call the callback for all existing components
                        if (event === 'childAdded' && runNow !== false) {
                            list.forEach(component => {
                                callback(component);
                            });
                        }

                        // whenever a new component is added, call the callback
                        return reference.model.on(reference.callbackKey(event), component => {
                            callback(component);
                        });
                    };
                },
            });

            return list;
        }

        return SuperModel.subclass(function () {
            // FIXME: AClassAbove should support inherited
            const subclassWithoutExtension = this.subclass.bind(this);

            this.extend({
                key: undefined,
                idKey: undefined,
                modelKlass: undefined,

                subclass(options) {
                    const subclass = subclassWithoutExtension(options);
                    subclass._createViewModelAccessors();
                    return subclass;
                },

                callbackKey(event) {
                    return `${event}_${this.key}`;
                },

                _createViewModelAccessors() {
                    this._createAccessors(this.modelKlass.ViewModel, 'ViewModel', 'viewModelFor', 'viewModelsFor');
                },

                _createAccessors(klass, suffix, getter, listGetter) {
                    // these are set to configurable true because they can conceivable be set twice
                    // if two classes share an EditorViewModel class.
                    const key = this.key;
                    if (klass) {
                        Object.defineProperty(klass.prototype, `${key}_${suffix}`.camelize(), {
                            get() {
                                return this[getter](this.model[key]);
                            },
                            configurable: true,
                        });

                        Object.defineProperty(klass.prototype, `${key}_${suffix}s`.camelize(), {
                            get() {
                                return this.model[key] ? this[listGetter](this.model[key]) : [];
                            },
                            configurable: true,
                        });
                    }
                },
            });

            Object.defineProperty(this.prototype, 'frame', {
                get() {
                    return this.model.frame();
                },
            });

            // delegate some properties to the class
            ['key', 'idKey', 'modelKlass', 'callbackKey'].forEach(prop => {
                Object.defineProperty(this.prototype, prop, {
                    get() {
                        return this.constructor[prop];
                    },
                });
            });

            return {
                initialize(model) {
                    this.model = model;
                    this._lastId = undefined;
                    this._lastValue = undefined;
                },

                get() {
                    let returnValue;
                    const idOrIds = this.model[this.idKey];

                    // If the id has not changed, then return the last value.  This
                    // works with a string id or with an array.  In the string case,
                    // an identical id means we can return the identical component.
                    // In the array case, while the array is identical, it's contents
                    // may have changed.  This is not a problem, though, because any
                    // changes must have been made to the proxyArray, so it is up
                    // to date. WE DO NOT SUPPORT CHANGES DIRECTLY TO THE LIST OF IDS. THAT
                    // WILL BREAK THIS.
                    if (idOrIds === this._lastId) {
                        returnValue = this._lastValue;
                    } else if (_.isArray(idOrIds)) {
                        returnValue = getProxyList(this, idOrIds);
                    } else if (idOrIds) {
                        returnValue = this.frame.dereference(idOrIds);
                    } else {
                        returnValue = undefined;
                    }
                    this._lastValue = returnValue;
                    this._lastId = idOrIds;
                    return returnValue;
                },

                set(modelOrModels) {
                    const ComponentModel = $injector.get(
                        'Lesson.FrameList.Frame.Componentized.Component.ComponentModel',
                    );

                    if (_.isArray(modelOrModels)) {
                        return this._setArrayValue(modelOrModels);
                    }
                    if (modelOrModels) {
                        if (!modelOrModels.isA || !modelOrModels.isA(ComponentModel)) {
                            throw new Error(
                                `Cannot set ${this.model.type}.${this.key} to something that is not a ComponentModel`,
                            );
                        }
                        this.model[this.idKey] = modelOrModels.id;
                        return modelOrModels;
                    }
                    delete this.model[this.idKey];
                    return undefined;
                },

                _setArrayValue(modelOrModels) {
                    const ComponentModel = $injector.get(
                        'Lesson.FrameList.Frame.Componentized.Component.ComponentModel',
                    );

                    const currentValue = this.get();
                    // We need to keep track of what is added and removed
                    // so that we can fire childAdded and childRemoved events
                    const current = {};
                    const added = {};
                    const removed = {};

                    // Build up the Set of current models, so we can
                    // keep track of what is added and what removed.  We also
                    // start with all items in the removed Set, and delete
                    // items from it in the loop below where necessary.
                    if (currentValue && _.isArray(currentValue)) {
                        currentValue.forEach(model => {
                            current[model.id] = true; // just used as a fake Set, do no need for values
                            removed[model.id] = model;
                        });
                    }

                    this.model[this.idKey] = modelOrModels.map(model => {
                        if (!model.isA || !model.isA(ComponentModel)) {
                            throw new Error(
                                `Cannot add something that is not a ComponentModel to ${this.model.type}.${this.key}`,
                            );
                        }
                        if (current[model.id]) {
                            delete removed[model.id];
                        } else {
                            added[model.id] = model;
                        }
                        return model.id;
                    });

                    const reference = this;
                    Object.values(removed).forEach(model => {
                        reference.model.triggerCallbacks(reference.callbackKey('childRemoved'), model);
                    });
                    Object.values(added).forEach(model => {
                        reference.model.triggerCallbacks(reference.callbackKey('childAdded'), model);
                    });
                    return this.get();
                },
            };
        });
    },
]);

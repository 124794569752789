import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.FrameNavigator.FrameNavigatorModel', [
    'Lesson.FrameList.Frame.Componentized.Component.ComponentModel',
    'Lesson.FrameList.Frame.Componentized.Component.FrameNavigator.FrameNavigatorViewModel',
    (ComponentModel, FrameNavigatorViewModel) =>
        ComponentModel.subclass(function () {
            this.alias('ComponentizedFrame.FrameNavigator');

            this.extend({
                ViewModel: FrameNavigatorViewModel,
            });
            this.setEditorViewModel(
                'Lesson.FrameList.Frame.Componentized.Component.FrameNavigator.FrameNavigatorEditorViewModel',
            );

            this.references('selectableAnswerNavigators').through('selectable_answer_navigator_ids');
            this.key('next_frame_id');
            this.key('has_branching');

            Object.defineProperty(this.prototype, 'nextFrameOverride', {
                get() {
                    return this.next_frame_id ? this.lesson.frameForId(this.next_frame_id) : undefined;
                },
            });

            return {
                mightNavigateTo(frame) {
                    // Check if any selectableAnswerNavigators point to
                    // this frame.  If they all point somewhere, but not
                    // to this frame, then return false.  If at least one of them
                    // points nowhere, then we might fall back to the default.  Move
                    // on the decide what that is.
                    let mightFallbackToDefault = this.selectableAnswerNavigators.length === 0;

                    for (const selectableAnswerNavigator of this.selectableAnswerNavigators) {
                        if (selectableAnswerNavigator.next_frame_id === frame.id) {
                            return true;
                        }
                        if (!selectableAnswerNavigator.next_frame_id) {
                            mightFallbackToDefault = true;
                        }
                    }

                    if (!mightFallbackToDefault) {
                        return false;
                    }

                    if (this.next_frame_id === frame.id) {
                        return true;
                    }
                    if (this.next_frame_id) {
                        return false;
                    }

                    if (this.frame().nextFrame() === frame) {
                        return true;
                    }

                    return false;
                },
            };
        }),
]);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Challenge.ChallengeResponse',

    [
        'AClassAbove',
        'Lesson.FrameList.Frame.Componentized.Component.Image.ImageModel',

        (AClassAbove, ImageModel) => {
            const ChallengeResponse = AClassAbove.subclass(function () {
                this.extend({
                    fromSelectableAnswer(selectableAnswer) {
                        return new ChallengeResponse({
                            text: selectableAnswer.text ? selectableAnswer.text.text : undefined,
                            image: selectableAnswer.image,
                            x: selectableAnswer.x,
                            y: selectableAnswer.y,

                            // this is not used for validation, but is passed into the HasUnexpectedAnswer
                            // validation error so that we can know which button needs to flash red
                            answer: selectableAnswer,
                        });
                    },
                });

                Object.defineProperty(this.prototype, 'text', {
                    get() {
                        return this._text;
                    },
                    set(val) {
                        if (typeof val === 'string') {
                            this._text = val;
                        } else if (val) {
                            throw new Error('"text" must be a string.');
                        } else {
                            this._text = undefined;
                        }
                        return val;
                    },
                });

                Object.defineProperty(this.prototype, 'image', {
                    get() {
                        return this._image;
                    },
                    set(val) {
                        if (!val) {
                            this._image = undefined;
                            return val;
                        }

                        if (!val.isA || !val.isA(ImageModel)) {
                            throw new Error('"image" must be an instance of ImageModel.');
                        }
                        this._image = val;
                        return val;
                    },
                });

                return {
                    initialize(options = {}) {
                        this.text = options.text;
                        this.image = options.image;
                        this.x = options.x;
                        this.y = options.y;

                        // sometimes, a challenge response is associated with a
                        // a particular answer component (in Multiple choice challenge).
                        // Sometimes, there is no answer (in User input challenge )
                        this.answer = options.answer;
                    },

                    // check this answer against a list of
                    // Component.AnswerMatcher.AnswerMatcherModel instances.
                    // If any of them match, return true
                    matchesAny(answerMatchers) {
                        for (const answerMatcher of answerMatchers) {
                            if (answerMatcher.matches(this)) {
                                return true;
                            }
                        }

                        return false;
                    },

                    asJson() {
                        return {
                            text: this.text,
                            image_id: this.image ? this.image.id : undefined,
                            x: this.x,
                            y: this.y,
                            answer_id: this.answer ? this.answer.id : undefined,
                        };
                    },
                };
            });

            return ChallengeResponse;
        },
    ],
);

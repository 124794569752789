/* eslint-disable func-names */

angular.module('Iguana').factory('Iguana.Alias', [
    '$injector',
    function ($injector) {
        return {
            classMixin: {
                injectablesMap: {},

                alias(value) {
                    if (value) {
                        this._alias = value;
                        if (this._aliasedKlasses[value]) {
                            throw new Error(
                                `A klass has already been aliased to "${value}".  Cannot alias another to the same name.`,
                            );
                        }
                        this._aliasedKlasses[value] = this;
                    }
                    return this._alias;
                },

                getAliasedKlass(alias, throwIfUnfound) {
                    if (angular.isUndefined(throwIfUnfound)) {
                        throwIfUnfound = true;
                    }

                    if (!this._aliasedKlasses[alias]) {
                        // Check the injectables map for the alias.
                        let path = this.injectablesMap[alias];

                        // try using the alias itself as the path
                        if (!path || !$injector.has(path)) {
                            path = alias;
                        }

                        if (path && $injector.has(path)) {
                            const klass = $injector.get(path);
                            this._aliasedKlasses[alias] = klass;
                            if (alias !== klass.alias()) {
                                const message = `Iguana class does not have the expected alias: "${klass.alias()}" != "${alias}"`;
                                throw new Error(message);
                            }
                        }
                    }

                    if (!this._aliasedKlasses[alias] && throwIfUnfound) {
                        throw new Error(`No class aliased to "${alias}".`);
                    }
                    return this._aliasedKlasses[alias];
                },

                mapInjectables(obj) {
                    angular.extend(this.injectablesMap, obj);
                },

                // this same object will be shared between all subclasses of Iguana,
                // so any class can access any aliased class
                _aliasedKlasses: {},
            },
        };
    },
]);

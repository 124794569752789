/* eslint-disable no-nested-ternary */
import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import template from 'Lessons/angularModule/views/lesson/frame_list/frame/componentized/component/continue_button/continue_button.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('cfChallengesContinueButton', [
    '$injector',
    $injector => {
        const UiComponentDirHelper = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentDirHelper',
        );
        const MessagingService = $injector.get('Lesson.MessagingService');
        const SoundManager = $injector.get('SoundManager');
        const SoundConfig = $injector.get('SoundConfig');
        const safeApply = $injector.get('safeApply');
        const $timeout = $injector.get('$timeout');

        return UiComponentDirHelper.getOptions({
            // Note: the template here is shared between this directive and alwaysReadyContinueButton
            templateUrl,
            link(scope, elem) {
                UiComponentDirHelper.link(scope);

                scope.MessagingService = MessagingService;

                Object.defineProperty(scope, 'targetComponentViewModel', {
                    get() {
                        return this.viewModel.targetComponentViewModel;
                    },
                });

                /*
                    This might be genius, or it might be a terrible hack.  Challenge blanks,
                    for performance reasons, do not trigger scope.$apply as the answer is entered
                    and validated.  This is fine up until the frame is completed. At that point
                    though, the continue button will not appear if there is no apply.  So, when
                    the frame is completed, ensure there will be an apply.
                */
                const completeListener = scope.targetComponentViewModel.on('completed', () => {
                    safeApply(scope);
                });

                scope.onContinueButtonClick = () => {
                    if (scope.frameViewModel.playerViewModel.activeFrame !== scope.viewModel.frame) {
                        return;
                    }

                    SoundManager.playUrl(SoundConfig.DEFAULT_CLICK);
                    if (scope.targetComponentViewModel.complete) {
                        scope.frameViewModel.gotoNextFrame();
                    } else if (scope.targetComponentViewModel.readyToValidate) {
                        scope.targetComponentViewModel.validate();
                    } else if (scope.targetComponentViewModel.allowSkip) {
                        scope.frameViewModel.skipFrame();
                    }
                };

                scope.buttonClasses = () => ({
                    'check-answer': scope.showCheckAnswer,
                    incorrect: scope.targetComponentViewModel.hasInvalidAnswer,
                    ready: scope.showContinueButton,
                    'with-message': scope.MessagingService.hasMessage,
                    green: !scope.showSkipButton,
                    purple: scope.showSkipButton,
                });

                scope.$watchGroup(
                    [
                        'targetComponentViewModel.readyToValidate',
                        'model.show_continue_when_ready_to_validate',
                        'targetComponentViewModel.complete',
                        'model.show_continue_when_ready_to_validate',
                        'targetComponentViewModel.allowSkip',
                    ],
                    () => {
                        scope.showCheckAnswer =
                            scope.targetComponentViewModel.readyToValidate &&
                            !scope.model.show_continue_when_ready_to_validate;

                        const readyToContinue =
                            scope.targetComponentViewModel.complete ||
                            (scope.targetComponentViewModel.readyToValidate &&
                                scope.model.show_continue_when_ready_to_validate);

                        if (readyToContinue) {
                            scope.showContinueButton = !scope.frameViewModel.isPractice;
                            scope.showPracticeModeContinueButtons = scope.frameViewModel.isPractice;
                        } else {
                            scope.showContinueButton = false;
                            scope.showPracticeModeContinueButtons = false;
                        }

                        scope.showSkipButton = !scope.showContinueButton && scope.targetComponentViewModel.allowSkip;

                        scope.showButton = scope.showCheckAnswer || scope.showContinueButton || scope.showSkipButton;
                    },
                );

                scope.lessonType = scope.frameViewModel?.playerViewModel?.lesson?.test
                    ? 'exam'
                    : scope.frameViewModel?.playerViewModel?.lesson?.assessment
                    ? 'assessment'
                    : 'lesson';

                scope.$on('$destroy', () => {
                    completeListener.cancel();
                });

                // This should really be view model logic, not directive logic, but
                // we've been doing this with dirty-checking forever and it doesn't
                // seem worth re-factoring at this point.
                scope.$watch('showButton', (val, oldVal) => {
                    scope.frameViewModel.continueButtonVisible = val;

                    // FIXME: SUPER-HACK! Chrome ~45 is causing animations to prevent clicks from
                    // getting handled. Seems like it's deferring the animation completion during
                    // multiple clicks during the animation cycle.
                    if (!oldVal && val) {
                        $timeout(() => {
                            elem.find('button').addClass('animation-removed');
                        }, 1000); // NOTE: see also - front_royal_footer.scss > .continue-button-wrapper > button animation
                    }
                });
            },
        });
    },
]);

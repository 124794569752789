import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import 'SafeApply/angularModule';

angularModule.factory('LessonsCtrlBase', [
    'Lesson',
    'safeApply',

    function factory(Lesson, safeApply) {
        const controller = function (scope) {
            this.scope = scope;

            if (scope.published) {
                throw new Error('Thought no one did this anymore');
            }

            Lesson.index({
                filters: {
                    published: false,
                    archived: scope.archived,
                    content_search: scope.searchText,
                },
                'fields[]': Lesson.fieldsForEditorList,
            }).then(response => {
                safeApply(this.scope, () => {
                    this.lessons = response.result;
                    this.scope.lessons = response.result;
                    this.onLoad();
                });
            });
        };

        controller.prototype = {
            onLoad() {},
        };

        return controller;
    },
]);

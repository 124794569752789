import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*
    When this behavior is on, any time an answer is selected, frameViewModel.clearMessage
    will be called
*/
angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.Behaviors.ClearMessagesOnAnswerSelect',
    [
        'AModuleAbove',
        'Lesson.FrameList.Frame.Componentized.Component.ComponentEventListener',
        (AModuleAbove, ComponentEventListener) =>
            new AModuleAbove({
                included(MultipleChoiceChallengeModel) {
                    MultipleChoiceChallengeModel.supportBehavior('ClearMessagesOnAnswerSelect');

                    MultipleChoiceChallengeModel.ViewModel.setCallback('after', 'initialize', function () {
                        const challengeViewModel = this;

                        this.model.on('behavior_added:ClearMessagesOnAnswerSelect', () => {
                            let listener;

                            function onAnswerListSet(answerList) {
                                if (listener) {
                                    listener.cancel();
                                    listener = undefined;
                                }

                                if (answerList) {
                                    listener = answerList.answers.on('childAdded', answer => {
                                        const answerViewModel = challengeViewModel.viewModelFor(answer);

                                        new ComponentEventListener(answerViewModel, 'beforeSelected', () => {
                                            challengeViewModel.playerViewModel.clearMessage();
                                        });
                                    });
                                }
                            }

                            // whenever an answer list is added, watch for answers to be
                            // added to it, and then listen for each answer to be selected
                            if (challengeViewModel.model.answerList) {
                                onAnswerListSet(challengeViewModel.model.answerList);
                            }

                            challengeViewModel.model.on('set:answerList', onAnswerListSet);
                        });
                    });
                },
            }),
    ],
);

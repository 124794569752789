/* eslint-disable func-names,prefer-arrow-callback */

angular.module('Iguana').factory('Iguana.Crud', [
    '$injector',
    function ($injector) {
        const $q = $injector.get('$q');

        return {
            included(Iguana) {
                Iguana.setIdProperty('id');
            },

            classMixin: {
                setCollection(collection) {
                    this.extend({
                        collection,
                    });
                },

                setAdapter(adapterName) {
                    let adapterKlass;
                    try {
                        adapterKlass = $injector.get(adapterName);
                    } catch (e) {
                        throw new Error(`Cannot find adapter "${adapterName}"`);
                    }

                    this.extend({
                        adapterKlass,
                    });
                },

                setBaseUrl(url) {
                    // remove trailing slash
                    url = url.replace(/\/$/, '');
                    this.extend({
                        baseUrl: url,
                    });
                },

                setIdProperty(idProperty) {
                    this.extend({
                        idProperty,
                    });
                },

                adapter() {
                    if (!this._adapter) {
                        if (!this.adapterKlass) {
                            throw new Error('No adapter set.  You need to call setAdapter()');
                        }
                        // eslint-disable-next-line new-cap
                        this._adapter = new this.adapterKlass(this);
                    }
                    return this._adapter;
                },

                // This method can be used if, for example, you want to
                // post your index calls because your params are too long
                // for a get call.
                overrideAction(actionName, override) {
                    this._actionOverrides = this._actionOverrides || {};
                    this._actionOverrides[actionName] = override;
                },

                show(...args) {
                    return this._callAdapterMethAndInstantiateResult('show', true, args);
                },

                index(...args) {
                    return this._callAdapterMethAndInstantiateResult('index', false, args);
                },

                create(obj, metadata, options) {
                    const instance = this.new(obj);
                    if (!instance.isNew()) {
                        throw new Error('Cannot call create on instance that is already saved.');
                    }
                    return instance.save(metadata, options);
                },

                update(obj, metadata, options) {
                    const instance = this.new(obj);
                    if (instance.isNew()) {
                        throw new Error('Cannot call update on instance that is not already saved.');
                    }
                    return instance.save(metadata, options);
                },

                destroy(id, metadata, options) {
                    const args = [id];
                    if (options) {
                        args.push(options);
                    }
                    if (metadata) {
                        args.push(metadata);
                    }
                    return this._callAdapterMeth('destroy', args, metadata).then(
                        function (response) {
                            return this._prepareEmptyResponse(response);
                        }.bind(this),
                    );
                },

                saveWithoutInstantiating(meth, obj, metadata, options) {
                    const args = [obj];
                    if (metadata) {
                        args.push(metadata);
                    }
                    if (options) {
                        args.push(options);
                    }
                    return this._callAdapterMeth(meth, args).then(function (response) {
                        return {
                            result: response.result[0],
                            meta: response.meta,
                        };
                    });
                },

                _prepareEmptyResponse(response) {
                    return {
                        result: null,
                        meta: response.meta,
                    };
                },

                _callAdapterMethAndInstantiateResult(meth, singlify, args) {
                    return this._callAdapterMeth(meth, args).then(
                        function (response) {
                            return this._instantiateFromResponse(singlify, response);
                        }.bind(this),
                    );
                },

                _instantiateFromResponse(singlify, response) {
                    const instances = this._instantiateFromResult(response.result);
                    const result = singlify ? instances[0] : instances;
                    return {
                        result,
                        meta: response.meta,
                    };
                },

                _instantiateFromResult(result) {
                    if (!result) {
                        return [];
                    }
                    const instances = [];
                    angular.forEach(
                        result,
                        function (attrs) {
                            instances.push(this.new(attrs));
                        }.bind(this),
                    );
                    return instances;
                },

                _callAdapterMeth(meth, args) {
                    if (!this.collection) {
                        throw new Error(
                            'Cannot make an api call because collection has not been set.  You need to call setCollection().',
                        );
                    }
                    args = Array.prototype.slice.call(args, 0);
                    args.unshift(this.collection);
                    // eslint-disable-next-line prefer-spread
                    return this.adapter()[meth].apply(this.adapter(), args);
                },
            },

            instanceMixin: {
                save(metadata, options) {
                    const action = this.isNew() ? 'create' : 'update';
                    return this._createOrUpdate(action, metadata, options);
                },

                create(metadata, options) {
                    return this._createOrUpdate('create', metadata, options);
                },

                isNew() {
                    const id = this[this.idProperty()];
                    return !id;
                },

                _createOrUpdate(action, metadata, options) {
                    let promise;
                    let publicPromise;
                    this.runCallbacks('save', function () {
                        this.$$saving = true;
                        promise = this._save(action, metadata, options);
                        const requestId = `${new Date().getTime()}:${Math.random()}`;

                        // if saving is not in progress already, then
                        // create a new $$savePromise (this check allows
                        // us to support concurrent saves of the same
                        // object)
                        if (!this.$$savePromise) {
                            // create the promise
                            let _resolve;
                            let _reject;
                            publicPromise = $q(function (resolve, reject) {
                                _resolve = resolve;
                                _reject = reject;
                            });
                            publicPromise.resolve = _resolve;
                            publicPromise.reject = _reject;
                            publicPromise.savePromises = {};
                            publicPromise.errors = [];

                            publicPromise.finally(
                                function () {
                                    this.$$saving = false;
                                    this.$$savePromise = undefined;
                                }.bind(this),
                            );
                            this.$$savePromise = publicPromise;
                        }
                        publicPromise = this.$$savePromise;
                        publicPromise.savePromises[requestId] = promise;

                        promise.then(
                            function () {
                                delete publicPromise.savePromises[requestId];
                                if (Object.keys(publicPromise.savePromises).length === 0) {
                                    if (publicPromise.errors.length === 0) {
                                        publicPromise.resolve();
                                    } else {
                                        publicPromise.reject({
                                            errors: publicPromise.errors,
                                        });
                                    }
                                }
                            },
                            function (err) {
                                publicPromise.errors.push(err);
                                delete publicPromise.savePromises[requestId];
                                if (Object.keys(publicPromise.savePromises).length === 0) {
                                    publicPromise.reject({
                                        errors: publicPromise.errors,
                                    });
                                }
                            },
                        );
                    });
                    return promise;
                },

                _save(action, metadata, options) {
                    return this.constructor
                        .saveWithoutInstantiating(action, this.objectForSave(), metadata, options)
                        .then(
                            function (response) {
                                const attrs = angular.extend({}, response.result);

                                this.copyAttrs(attrs);
                                return {
                                    result: this,
                                    meta: response.meta,
                                };
                            }.bind(this),
                        );
                },

                destroy(metadata, options) {
                    this.$$destroying = true;
                    this.$$saving = true;
                    const returnValue = this.constructor.destroy(this[this.idProperty()], metadata, options);
                    this.$$savePromise = returnValue;
                    returnValue.finally(
                        function () {
                            this.$$destroying = false;
                            this.$$saving = false;
                            this.$$savePromise = undefined;
                        }.bind(this),
                    );
                    return returnValue;
                },

                idProperty() {
                    return this.constructor.idProperty;
                },
            },
        };
    },
]);

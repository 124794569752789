import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.FrameNavigator.FrameNavigatorViewModel',

    [
        'Lesson.FrameList.Frame.Componentized.Component.ComponentViewModel',

        ComponentViewModel =>
            ComponentViewModel.subclass(function () {
                // can be overriden by particular subclasses or particular behaviors
                Object.defineProperty(this.prototype, 'nextFrameId', {
                    get() {
                        // Defaults to whatever global frame next value has been set.
                        // When editor selects "branching" and specifies an answer-specific override,
                        // SelectableAnswerEditorViewModel._listenForSelected sets nextFrameId with
                        // an override value.
                        const defaultValue = this.model.next_frame_id;
                        return this.nextFrameIdOverride || defaultValue;
                    },
                });

                Object.defineProperty(this.prototype, 'nextFrameIdOverride', {
                    get() {
                        return this._nextFrameIdOverride;
                    },
                    set(val) {
                        this._nextFrameIdOverride = val;
                    },
                });

                return {};
            }),
    ],
);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*

    When this behavior is on, a challenge will be marked as "complete" 1500 ms after it
    is validated as being correct.

    You might want to turn this behavior off if there was more for a user to explore in
    a challenge even after getting it correct.

*/
angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.Challenge.Behaviors.CompleteOnCorrect', [
    'AModuleAbove',
    'Lesson.FrameList.Frame.Componentized.Component.ComponentEventListener',
    (AModuleAbove, ComponentEventListener) =>
        new AModuleAbove({
            included(ChallengeModel) {
                ChallengeModel.supportBehavior('CompleteOnCorrect');

                ChallengeModel.ViewModel.setCallback('after', 'initialize', function () {
                    const challengeViewModel = this;

                    this.model.on('behavior_added:CompleteOnCorrect', () => {
                        new ComponentEventListener(challengeViewModel, 'validatedCorrect', () => {
                            challengeViewModel.complete = true;
                        });
                    });
                });
            },
        }),
]);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import 'ExtensionMethods/array';

angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.Answer.AnswerViewModel', [
    '$injector',

    $injector => {
        const ComponentViewModel = $injector.get('Lesson.FrameList.Frame.Componentized.Component.ComponentViewModel');
        const SoundManager = $injector.get('SoundManager');
        const SoundConfig = $injector.get('SoundConfig');

        return ComponentViewModel.subclass(function () {
            // only some answer types (i.e. SelectableAnswer) support the selected flag
            Object.defineProperty(
                this.prototype,
                'selected',
                {
                    get() {
                        throw new Error(`${this.type} does not support the "selected" property.`);
                    },
                    set() {
                        throw new Error(`${this.type} does not support the "selected" property.`);
                    },
                },
                true,
            );

            // returns true if the UI representation of the answer is currently
            // displayed as being incorrect
            Object.defineProperty(this.prototype, 'showingIncorrectStyling', {
                get() {
                    return this.cssClasses.includes('incorrect');
                },
            });

            // returns true if the UI representation of the answer is currently
            // displayed as being incorrect
            Object.defineProperty(this.prototype, 'showingCorrectStyling', {
                get() {
                    return this.cssClasses.includes('correct');
                },
            });

            // returns true if the UI representation of the answer is currently
            // displayed as being incorrect
            Object.defineProperty(this.prototype, 'showingValidatedStyling', {
                get() {
                    return this.showingCorrectStyling || this.showingIncorrectStyling;
                },
            });

            // Disable an answer whenever it is showing correct styling, because
            // you should not be able to unselect an answer once it is correct.
            //
            // Disable an answer whenever is is showing incorrect styling to
            // prevent double-clicks and to let the styles run through smoothly.
            Object.defineProperty(this.prototype, 'disabled', {
                get() {
                    return this.showingValidatedStyling;
                },
            });

            return {
                initialize($super, frameViewModel, model) {
                    $super(frameViewModel, model);
                    this.resetCssClasses();
                },

                resetCssClasses() {
                    this.cssClasses = [];
                    this._addUnvalidatedClassIfUnvalidated();
                },

                addCorrectStyling() {
                    if (!this.cssClasses.includes('correct')) {
                        this.cssClasses.push('correct');
                        SoundManager.playUrl(SoundConfig.VALIDATE_CORRECT);
                    }
                    Array.remove(this.cssClasses, 'unvalidated');
                },

                addIncorrectStyling() {
                    if (!this.cssClasses.includes('incorrect')) {
                        this.cssClasses.push('incorrect');
                        SoundManager.playUrl(SoundConfig.VALIDATE_INCORRECT);
                    }
                    Array.remove(this.cssClasses, 'unvalidated');
                },

                // NOTE: the selected sounds are handled somewhat differently. see also: PlayScalingSoundOnSelected

                removeCorrectStyling() {
                    Array.remove(this.cssClasses, 'correct');
                    this._addUnvalidatedClassIfUnvalidated();
                },

                removeIncorrectStyling() {
                    Array.remove(this.cssClasses, 'incorrect');
                    this._addUnvalidatedClassIfUnvalidated();
                },

                _addUnvalidatedClassIfUnvalidated() {
                    if (!this.cssClasses.includes('correct') && !this.cssClasses.includes('incorrect')) {
                        this.cssClasses.push('unvalidated');
                    }
                },
            };
        });
    },
]);

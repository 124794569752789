import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.directive('componentized', [
    () => ({
        restrict: 'E',

        // has to be false because otherwise this and the cf-ui-component will fight over the scope
        replace: false,

        template: '<cf-ui-component view-model="frameViewModel.mainUiComponentViewModel"></cf-ui-component>',

        scope: {
            editor: '@',
            frameViewModel: '<',
        },

        link() {},
    }),
]);

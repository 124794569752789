import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*
    The ChallengesContinueButton looks for a specific list of states on the Challenges component
    to determine it's own state at any particular time.

    The targetComponent should have exactly one of these states at all times:

    * waitingForAnswer
	* readyToValidate
	* ready

	The targetComponent can also set any of these flags to true at any time:

	* hasInvalidAnswer
*/
angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.ContinueButton.ChallengesContinueButton.ChallengesContinueButtonModel',
    [
        'Lesson.FrameList.Frame.Componentized.Component.ContinueButton.ContinueButtonModel',
        'Lesson.FrameList.Frame.Componentized.Component.ContinueButton.ChallengesContinueButton.ChallengesContinueButtonViewModel',
        (ContinueButtonModel, ChallengesContinueButtonViewModel) =>
            ContinueButtonModel.subclass(function () {
                this.alias('ComponentizedFrame.ChallengesContinueButton');
                this.extend({
                    ViewModel: ChallengesContinueButtonViewModel,
                });
                this.setEditorViewModel(
                    'Lesson.FrameList.Frame.Componentized.Component.ContinueButton.ChallengesContinueButton.ChallengesContinueButtonEditorViewModel',
                );

                this.references('targetComponent').through('target_component_id');

                // used with check_many/no_incorrect_answer
                this.key('show_continue_when_ready_to_validate');

                return {};
            }),
    ],
);

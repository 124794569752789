import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*
    An AnswerList includes a list of SelectableAnswer components. It is referenced
    by one or more MultipleChoiceChallenge components, who use it to get the list
    of possible answers for each challenge.

    When multiple Challenges share an answerList, they share not only the list of AnswersModels,
    but also the state of those answers, as represented in the AnswerViewModels.  So, for example, when
    switching from one challenge to another, a selected answer will remain selected by default
    (though the challenge can decide to unselect all answers when it is activated).

    See the following flowchart for a picture of how all the pieces of a
    Multiple Choice Challenge fit together:
    https://pedago.atlassian.net/wiki/display/TECH/Componentized+Frame#ComponentizedFrame-MCChallengeFlowchart


    Example:

    {
        "id": "a93b590a-7d89-4cf4-a137-1ebcb780413f",
        "__type": "Lesson::FrameList::Frame::Componentized::Component::AnswerList",
        "component_type": "ComponentizedFrame.AnswerList",
        "randomize": false,
        "skin": "buttons",
        "answer_ids": [
            "bbf992ed-6a34-401b-aece-af45e5c3dd3c",
            "bfa74ed3-7fb3-4472-8ee7-0320bb4026f6"
        ]
    },
*/
angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.AnswerList.AnswerListModel', [
    'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentModel',
    'Lesson.FrameList.Frame.Componentized.Component.AnswerList.AnswerListViewModel',
    'Lesson.FrameList.Frame.Componentized.Component.AnswerList.Behaviors.RandomizeAnswerOrder',
    (UiComponentModel, AnswerListViewModel, RandomizeAnswerOrder) =>
        UiComponentModel.subclass(function () {
            this.alias('ComponentizedFrame.AnswerList');
            this.extend({
                ViewModel: AnswerListViewModel,
            });
            this.setEditorViewModel(
                'Lesson.FrameList.Frame.Componentized.Component.AnswerList.AnswerListEditorViewModel',
            );

            this.include(RandomizeAnswerOrder);

            this.references('answers').through('answer_ids');

            this.key('skin');

            // used with the buttons skin to force all buttons to stretch wide in a single column
            this.key('force_single_column');

            return {
                useSingleColumn() {
                    if (this.force_single_column === true) {
                        return true;
                    }
                    if (this.force_single_column === false) {
                        return false;
                    }
                    // eslint-disable-next-line arrow-body-style
                    const wideImage = _.find(this.answers, answerModel => {
                        return (
                            answerModel.contentType === 'image' &&
                            answerModel.image &&
                            answerModel.image.aspectRatio > 2.5
                        );
                    });
                    return !!wideImage;
                },
            };
        }),
]);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*
    When this behavior is on, validator.validate() will be called any time
    an answer is selected.
*/
angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.Behaviors.ImmediateValidation',
    [
        'AModuleAbove',
        'Lesson.FrameList.Frame.Componentized.Component.ComponentEventListener',
        (AModuleAbove, ComponentEventListener) =>
            new AModuleAbove({
                included(MultipleChoiceChallengeModel) {
                    MultipleChoiceChallengeModel.supportBehavior('ImmediateValidation');

                    MultipleChoiceChallengeModel.ViewModel.setCallback('after', 'initialize', function () {
                        const challengeViewModel = this;
                        let answerSelectedListeners = [];

                        challengeViewModel.model.on('behavior_added:ImmediateValidation', () => {
                            function onAnswerListSet(answerList) {
                                answerSelectedListeners.forEach(listener => {
                                    listener.cancel();
                                });
                                answerSelectedListeners = [];

                                if (!answerList) {
                                    return;
                                }

                                answerList.answers.on('childAdded', answer => {
                                    const answerViewModel = challengeViewModel.viewModelFor(answer);
                                    // for compose blanks, listem for unfocus event or whatever
                                    let listener = new ComponentEventListener(answerViewModel, 'selected', () => {
                                        // if this is the active challenge, then validate
                                        if (challengeViewModel.active) {
                                            challengeViewModel.validate({
                                                event: {
                                                    target: answerViewModel,
                                                    type: 'answerSelected',
                                                },
                                            });
                                        } // this is what info in challenge_view_model.validate is used for: to get messages to work
                                    });

                                    answerSelectedListeners.push(listener);

                                    listener = new ComponentEventListener(answerViewModel, 'unselected', () => {
                                        // if this is the active challenge, then validate
                                        if (challengeViewModel.active) {
                                            challengeViewModel.validate({
                                                event: {
                                                    target: answerViewModel,
                                                    type: 'answerUnselected',
                                                },
                                            });
                                        }
                                    });

                                    answerSelectedListeners.push(listener);
                                });
                            }

                            // whenever an answer list is added, watch for answers to be
                            // added to it, and then listen for each answer to be selected
                            if (challengeViewModel.model.answerList) {
                                onAnswerListSet(challengeViewModel.model.answerList);
                            }

                            challengeViewModel.model.on('set:answerList', onAnswerListSet);
                        });

                        challengeViewModel.model.on('behavior_removed:ImmediateValidation', () => {
                            answerSelectedListeners.forEach(listener => {
                                listener.cancel();
                            });
                            answerSelectedListeners = [];
                        });
                    });
                },
            }),
    ],
);

import { getBackRoyalApiBaseConfig } from 'BackRoyalApi';
import { createApi } from 'ReduxHelpers';
import { type AnyObject } from '@Types';
import { lessonLaunchPath, streamDashboardPath } from './contentPaths';
import {
    type StreamIndexResponse,
    type StreamShowResponse,
    type LinkToLessonInStream,
    type StreamCompoundItemVersionKey,
} from './Lessons.types';

enum TagType {
    publishedStream = 'publishedStream',
}

export const getLessonLinksStreamFields = ['id', 'title', 'chapters', 'lessons', 'image'] as const;
export const getLessonLinksLessonFields = ['id', 'title'] as const;

// This API is intended to be used in user-facing code. In order to prevent us from importing editor-only code
// into the student bundle, there is a separate LessonsEditorApi
export const lessonsApi = createApi('LessonsApi', {
    ...getBackRoyalApiBaseConfig('LessonsApi'),
    tagTypes: Object.values(TagType),

    /*
            Flatten out all of the lessons in the stream index call into an object
            that has enough information to build a link to the lesson. This assumes that
            each lesson only appears in a single stream for a given user, since the
            response is keyed off of lessonId
        */
    endpoints: builder => ({
        getLinksToLessonsInStreams: builder.query<Record<string, LinkToLessonInStream>, void>({
            query: () => ({
                url: 'lesson_streams/index.json',
                method: 'POST',
                body: {
                    filters: { user_can_see: true, locale: 'en' },
                    fields: getLessonLinksStreamFields,
                    lesson_fields: getLessonLinksLessonFields,
                },
            }),
            transformResponse: (
                response: StreamIndexResponse<
                    typeof getLessonLinksStreamFields[number],
                    typeof getLessonLinksLessonFields[number]
                >,
            ) => {
                const lessonLinks: Record<string, LinkToLessonInStream> = {};
                response.contents.lessonStreams.forEach(stream => {
                    let lessonIndex = 0;
                    stream.chapters.forEach((chapter, chapterIndex) => {
                        chapter.lessonIds.forEach(lessonId => {
                            const lesson = stream.lessons.find(l => l.id === lessonId);
                            if (!lesson) {
                                // FIXME: log error
                                return;
                            }
                            lessonLinks[lessonId] = {
                                streamId: stream.id,
                                streamDashboardPath: streamDashboardPath({
                                    streamTitle: stream.title,
                                    streamId: stream.id,
                                }),
                                lessonLaunchPath: lessonLaunchPath({ lessonId, streamId: stream.id, chapterIndex }),
                                lessonIndexInStream: lessonIndex,
                                lessonTitle: lesson.title,
                                streamTitle: stream.title,
                                streamImage: stream.image,
                            };
                            lessonIndex += 1;
                        });
                    });
                });
                return lessonLinks;
            },
        }),

        getPublishedStream: builder.query<
            StreamShowResponse,
            {
                id: string;
                loadFullContentForLessonId?: string | null;
                loadFullContentForAllLessons?: boolean;
                userCohortIds: string[] | null;
                prefLocale: string;
            }
        >({
            query: ({ id, loadFullContentForLessonId, loadFullContentForAllLessons, userCohortIds, prefLocale }) => {
                let filters: AnyObject = { inLocaleOrEn: prefLocale };
                if (userCohortIds) {
                    filters = {
                        ...filters,
                        userCanSee: true,
                        published: true,
                        cohortIds: userCohortIds,
                    };
                } else {
                    filters = {
                        ...filters,
                        userCanSee: null,
                    };
                }
                return {
                    url: `lesson_streams/${id}.json`,
                    method: 'GET',
                    params: {
                        filters,
                        loadFullContentForLessonId,
                        loadFullContentForAllLessons,
                    },
                };
            },
            providesTags: (_result, _error, { id }) => [{ type: TagType.publishedStream, id }],
        }),

        // It's maybe a little odd that this is a mutation, but being a mutation allows it
        // to invalidate the tags for the streams that the server tells us are out of date.
        invalidateOutdatedStreams: builder.mutation<
            StreamIndexResponse<'id', never>,
            { id: string; compoundItemVersionKey: StreamCompoundItemVersionKey }[]
        >({
            query: streamParams => ({
                url: 'lesson_streams/get_outdated.json',
                method: 'POST',
                body: {
                    streams: streamParams,
                    fields: ['id'],
                    limit: 0, // No need to limit since we're just loading ids
                },
            }),

            invalidatesTags: result =>
                result?.contents?.lessonStreams?.map(({ id }) => ({ type: TagType.publishedStream, id })) || [],
        }),
    }),
});

/* eslint-disable func-names */

angular.module('Iguana').factory('Iguana.Callbacks', [
    function () {
        return {
            included(Iguana) {
                Iguana.defineCallbacks('copyAttrs');
                Iguana.defineCallbacks('copyAttrsOnInitialize');
                Iguana.defineCallbacks('save');
                Iguana.defineCallbacks('processEmbeds');
            },
        };
    },
]);

/* eslint-disable func-names */

angular.module('Iguana').factory('Iguana.Serializers', [
    function () {
        return {
            instanceMixin: {
                asJson() {
                    return angular.fromJson(angular.toJson(this));
                },

                // Children can override this method to define the object and properties used for save calls.
                // This is useful when you have a lot of stuff on an Iguana object that isn't used in a controller.
                objectForSave() {
                    return this.asJson();
                },

                toJson() {
                    return angular.toJson(this);
                },
            },
        };
    },
]);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.Challenge.ChallengeViewModel', [
    '$injector',

    $injector => {
        const ComponentViewModel = $injector.get('Lesson.FrameList.Frame.Componentized.Component.ComponentViewModel');
        const ComponentEventListener = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.ComponentEventListener',
        );
        const ValidationResult = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.ChallengeValidator.ValidationResult',
        );

        return ComponentViewModel.subclass(function () {
            this.extend({
                ValidationResult,
            });

            Object.defineProperty(this.prototype, 'active', {
                get() {
                    return this._active;
                },
                set(val) {
                    if (val === this._active) {
                        return;
                    }

                    if (val) {
                        this._active = true;
                        this.validatorViewModel.active = true;
                        this.fire('activated');
                    } else {
                        this._active = false;
                        this.validatorViewModel.active = false;
                        this.fire('deActivated');
                    }
                },
            });

            Object.defineProperty(this.prototype, 'complete', {
                get() {
                    return this._complete;
                },
                set(val) {
                    if (val === this._complete) {
                        return;
                    }
                    if (val) {
                        this._complete = true;
                        this.fire('completed');
                    } else {
                        throw new Error('"complete" cannot be set back to false once it is true.');
                    }
                },
            });

            Object.defineProperty(this.prototype, 'showingIncorrectStyling', {
                get() {
                    throw new Error(
                        `Subclasses of ChallengeViewModel should define showingIncorrectStyling. "${this.type}" does not.`,
                    );
                },
                configurable: true, // changed in tests
            });

            // overriden in subclasses
            Object.defineProperty(this.prototype, 'challengeResponses', {
                writable: true,
                configurable: true,
            });

            // can be overriden by particular subclasses or particular behaviors
            Object.defineProperty(this.prototype, 'readyToValidate', {
                get() {
                    if (this._readyToValidateGetter) {
                        return this._readyToValidateGetter();
                    }
                    return false;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'ValidationResult', {
                get() {
                    return this.constructor.ValidationResult;
                },
            });

            Object.defineProperty(this.prototype, 'validator', {
                get() {
                    return this.model.validator;
                },
            });

            return {
                initialize($super, frameViewModel, model) {
                    $super(frameViewModel, model);

                    // active means that this is the challenge the user
                    // is currently trying to respond to. Only one challenge
                    // can be active at any time.
                    this.active = false;

                    this._complete = false;

                    this.validationResults = [];

                    // index is the position of this challenge in the
                    // ChallengesViewModel list of challenges. It is set
                    // by the ChallengesViewModel. It is used to set the
                    // z-index on ChallengeOverlayBlanks on _on_image screens.
                    this.index = undefined;

                    new ComponentEventListener(this.validatorViewModel, 'validated', this.onAfterValidated.bind(this));

                    new ComponentEventListener(this, 'completed', this.logCompleted.bind(this));

                    model.on('remove', () => {
                        this.active = false;
                    });
                },

                validate(info) {
                    const validationResult = this.validatorViewModel.validate(info);
                    return validationResult;
                },

                logCompleted() {
                    const score = this.getScore();
                    this.frameViewModel.log(
                        'lesson:challenge_complete',
                        {
                            challenge_type: this.model.type,
                            challenge_id: this.model.id,
                            score,
                        },
                        {
                            segmentio: false,
                        },
                    );
                },

                getScore() {
                    // subclasses should override getScore
                },

                // need to add validatedIncomplete here?
                onAfterValidated(validationResult) {
                    // We have to do this before firing the validated event
                    // so that these changes get sent up when the lesson progress
                    // is saved at the end of the frame
                    if (this.playerViewModel) {
                        this.playerViewModel.setChallengeScore(this);
                    }

                    const result = validationResult.result;
                    this.fire('validated', validationResult);
                    if (result === true) {
                        this.fire('validatedCorrect', validationResult);
                    } else if (result === false) {
                        this.fire('validatedIncorrect', validationResult);
                    } else {
                        throw new Error('Do we need to support a result that is neither correct nor incorrect?');
                    }
                },
            };
        });
    },
]);

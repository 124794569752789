import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.ContinueButton.AlwaysReadyContinueButton.AlwaysReadyContinueButtonViewModel',

    [
        'Lesson.FrameList.Frame.Componentized.Component.ContinueButton.ContinueButtonViewModel',

        ContinueButtonViewModel =>
            ContinueButtonViewModel.subclass(() => ({
                directiveName: 'cf-always-ready-continue-button',
            })),
    ],
);

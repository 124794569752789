import { formatScore } from 'FormatScore';
import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import template from 'Lessons/angularModule/views/lesson/assessment_lesson_end.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('assessmentLessonEnd', [
    '$injector',
    function factory($injector) {
        const scopeTimeout = $injector.get('scopeTimeout');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                playerViewModel: '<',
                currentUser: '<',
            },
            link(scope) {
                Object.defineProperty(scope, 'lesson', {
                    get() {
                        return scope.playerViewModel.lesson;
                    },
                });

                scope.hideScore = scope.currentUser.preferStrictSmartCaseScore && scope.playerViewModel.retaking;

                // NOTE: must be floored to match styles in assessment_lesson_end.scss
                scope.score = Math.floor(100 * scope.playerViewModel.lessonScore);
                scope.realScore = formatScore(scope.playerViewModel.lessonScore || 0);
                scope.offsetLeft = scope.realScore.length > 2;

                const minutes = Math.floor(scope.playerViewModel.secondsInLesson / 60);
                const seconds = Math.floor(scope.playerViewModel.secondsInLesson % 60);
                scope.minutesInLesson = `${minutes}:${String.padNumber(seconds, 2)}`;

                // Delay animation of the gauges to allow time for the display animations
                scopeTimeout(
                    scope,
                    () => {
                        scope.show = true;
                        // add secondary timeout for updating value to handle decimals after animation complete
                        scopeTimeout(
                            scope,
                            () => {
                                scope.showRealScore = true;
                            },
                            1000,
                        );
                    },
                    500,
                );
            },
        };
    },
]);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*
    A HasUnexpectedAnswer ValidationError will be created by the HasNoUnexpectedAnswers behavior
    if there is an answer in challenge.userAnswers that does not match any of the validator's
    expectedAnswerMatchers.
*/
angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.ChallengeValidator.ValidationError.HasUnexpectedAnswer',

    [
        'Lesson.FrameList.Frame.Componentized.Component.ChallengeValidator.ValidationError',
        'Lesson.FrameList.Frame.Componentized.Component.Answer.AnswerModel',

        (ValidationError, AnswerModel) =>
            ValidationError.subclass(() => ({
                initialize($super, unexpectedAnswer, text, options = {}) {
                    if (!unexpectedAnswer) {
                        // do nothing
                    } else if (unexpectedAnswer.isA && unexpectedAnswer.isA(AnswerModel)) {
                        options.relatedAnswers = [unexpectedAnswer];
                    } else {
                        throw new Error('HasUnexpectedAnswer must be instantiated with an answer');
                    }

                    $super(options);
                    this.unexpectedAnswer = unexpectedAnswer;
                    this.text = text;
                },

                asJson() {
                    return {
                        error_class: 'HasUnexpectedAnswer',
                        unexpected_answer_id: this.unexpectedAnswer ? this.unexpectedAnswer.id : undefined,
                        text: this.text,
                    };
                },
            })),
    ],
);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*
    The ContinueButton is displayed on the side of the screen and handles

    1. display of high-level state of the frame (i.e. are you ready to move on to the next frame)
    2. navigation to the next frame
*/
angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.ContinueButton.ContinueButtonModel', [
    'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentModel',
    'Lesson.FrameList.Frame.Componentized.Component.ContinueButton.ContinueButtonViewModel',
    (UiComponentModel, ContinueButtonViewModel) =>
        UiComponentModel.subclass(function () {
            this.alias('ComponentizedFrame.ContinueButton');
            this.extend({
                ViewModel: ContinueButtonViewModel,
            });
            this.setEditorViewModel(
                'Lesson.FrameList.Frame.Componentized.Component.ContinueButton.ContinueButtonEditorViewModel',
            );

            return {};
        }),
]);

// FIXME: This entire module can probably be removed as part of https://trello.com/c/44m4boVb.
export default async function shoveProgressIntoContent(streams, user) {
    return user.progress.getAllProgress().then(progressResponse => {
        const streamProgressByLocalePackId = _.keyBy(progressResponse.streamProgress, 'locale_pack_id');
        const lessonProgressByLocalePackId = _.keyBy(progressResponse.lessonProgress, 'locale_pack_id');

        _.forEach(streams, stream => {
            stream.lesson_streams_progress = streamProgressByLocalePackId[stream.localePackId];
            stream.favorite = !!progressResponse.favoriteStreamsSet[stream.localePackId];

            // FIXME: this is a bit scary, at least until we've unified all content into a single
            // cache.  Maybe we could get away with stream progress never referencing stream() at all
            // and not rely on the embedded relationship?  Right now, I don't think it creates any practical
            // problems in the wild to remove this, but that's just an accident.  It is because we only ever
            // complete a stream after the player has been launched, and in that case stream_progress.stream()
            // will be set when the full content is loaded.  So, removing this just breaks a spec right
            // now where we're manually completing a stream on the stream dashboard.
            if (stream.lesson_streams_progress) {
                stream.lesson_streams_progress.$$embeddedIn = stream;
            }

            _.forEach(stream.lessons, lesson => {
                lesson.lesson_progress = lessonProgressByLocalePackId[lesson.localePackId];
            });
        });

        return streams;
    });
}

import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.ComponentEventListener', [
    'AClassAbove',

    AClassAbove =>
        AClassAbove.subclass(function () {
            // if true, can see all events getting fired from viewmodels
            this.debug = false;

            return {
                initialize(targetViewModel, eventName, callback, options) {
                    this.targetViewModel = targetViewModel;
                    this.eventName = eventName;
                    this.callback = callback;
                    this.targetViewModel.registerEventListener(this);
                    options = options || {};
                    this.priority = options.priority || 0;
                },

                trigger(...args) {
                    this.callback.apply(null, args);
                },

                cancel() {
                    this.targetViewModel.deregisterEventListener(this);
                },
            };
        }),
]);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*
    When this behavior is on, any time a selected answer is validated and
    determined to be incorrect, the ui component for that answer will get
    an 'incorrect' class for 1500 ms.  After 1500ms the answer will be deselected
    and the incorrect class removed.
*/
angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.Behaviors.FlashIncorrectStyling',
    [
        '$injector',
        $injector => {
            const AModuleAbove = $injector.get('AModuleAbove');
            const ComponentEventListener = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.ComponentEventListener',
            );
            const resolvableTimeout = $injector.get('resolvableTimeout');

            return new AModuleAbove({
                included(MultipleChoiceChallengeModel) {
                    MultipleChoiceChallengeModel.supportBehavior('FlashIncorrectStyling');

                    MultipleChoiceChallengeModel.ViewModel.setCallback('after', 'initialize', function () {
                        let deactivatedListener;
                        let validatedListener;
                        let viewModelListeners = [];

                        this.model.on('behavior_added:FlashIncorrectStyling', () => {
                            let options = this.optionsForBehavior('FlashIncorrectStyling');
                            options = angular.extend(
                                {
                                    duration: 1500,
                                },
                                options || {},
                            );
                            // FIXME: add an option for autoDeselect

                            const _flashIncorrectStylingTimeoutPromises = {};

                            validatedListener = new ComponentEventListener(this, 'validated', validationResult => {
                                validationResult
                                    .filterAnswersViewModels({
                                        selected: true,
                                        correct: false,
                                    })
                                    .forEach(answerViewModel => {
                                        if (answerViewModel.showingIncorrectStyling) {
                                            return;
                                        }

                                        // add the incorrect styling
                                        answerViewModel.addIncorrectStyling();

                                        // create a timeout to unselect the answer in 1500 ms
                                        const promise = resolvableTimeout(() => {
                                            if (answerViewModel.selected) {
                                                answerViewModel.selected = false;
                                            } else {
                                                answerViewModel.removeIncorrectStyling();
                                                const promise =
                                                    _flashIncorrectStylingTimeoutPromises[answerViewModel.model.id];
                                                resolvableTimeout.resolve(promise);
                                            }
                                        }, options.duration);

                                        // store the promise for that timeout so it can be cancelled
                                        _flashIncorrectStylingTimeoutPromises[answerViewModel.model.id] = promise;

                                        // when the answer becomes unselected (for any reason) remove the incorrect styling
                                        // and cancel the promise
                                        const unselectedListener = new ComponentEventListener(
                                            answerViewModel,
                                            'unselected',
                                            () => {
                                                answerViewModel.removeIncorrectStyling();
                                                const promise =
                                                    _flashIncorrectStylingTimeoutPromises[answerViewModel.model.id];
                                                resolvableTimeout.resolve(promise);
                                            },
                                        );
                                        viewModelListeners.push(unselectedListener);
                                    });
                            });

                            // if we switch to a new challenge, resolves all outstanding promises
                            deactivatedListener = new ComponentEventListener(this, 'deActivated', () => {
                                angular.forEach(_flashIncorrectStylingTimeoutPromises, promise => {
                                    resolvableTimeout.resolve(promise);
                                });
                            });
                        });

                        // remove all listeners
                        this.model.on('behavior_removed:FlashIncorrectStyling', () => {
                            const listeners = viewModelListeners.concat([deactivatedListener, validatedListener]);
                            listeners.forEach(listener => {
                                if (listener) {
                                    listener.cancel();
                                    listener = undefined;
                                }
                            });

                            viewModelListeners = deactivatedListener = validatedListener = undefined;
                        });
                    });
                },
            });
        },
    ],
);

import { getBackRoyalApiBaseConfig } from 'BackRoyalApi';
import { createApi } from 'ReduxHelpers';
import { type StreamIndexResponse, type StreamIndexParams } from './Lessons.types';

// This API is intended to be used in the editor. In order to prevent us from importing editor-only code
// into the student bundle, there is a separate LessonsEditorApi
export const lessonsEditorApi = createApi('lessonsEditorApi', {
    ...getBackRoyalApiBaseConfig('lessonsEditorApi'),
    endpoints: builder => ({
        /*
            Flatten out all of the lessons in the stream index call into an object
            that has enough information to build a link to the lesson. This assumes that
            each lesson only appears in a single stream for a given user, since the
            response is keyed off of lessonId
        */
        getStreams: builder.query<StreamIndexResponse, StreamIndexParams>({
            query: params => ({
                url: 'lesson_streams/index.json',
                method: 'POST',
                body: params,
            }),
        }),
    }),
});

import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import template from 'Lessons/angularModule/views/lesson/lesson_finish_screen_demo_content.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('lessonFinishScreenDemoContent', [
    '$injector',
    function factory($injector) {
        const $window = $injector.get('$window');
        const EventLogger = $injector.get('EventLogger');
        const ShareService = $injector.get('Navigation.ShareService');
        const SiteMetadata = $injector.get('SiteMetadata');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                playerViewModel: '<',
            },
            link(scope) {
                scope.learnMore = () => {
                    // we're loaded in an iframe, so we ask the parent window to scroll to the next section
                    $window.parent.postMessage('scrollDownPage', '*');
                };

                scope.startFreeTrial = () => {
                    // we're loaded in an iframe, so we ask the parent window to navigate to the pricing page
                    $window.parent.postMessage('goToPricingPage', '*');
                };

                scope.share = (provider = 'twitter') => {
                    const label = 'user:marketing:demo:share';
                    const meta = SiteMetadata.smartlyDemoShareInfo();
                    ShareService.share(label, provider, meta);
                };
            },
        };
    },
]);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import 'ExtensionMethods/array';

angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.ComponentViewModel', [
    'SuperModel',
    'Lesson.FrameList.Frame.Componentized.Component.ComponentEventListener',

    (SuperModel, ComponentEventListener) => {
        const ComponentViewModel = SuperModel.subclass(function () {
            this.defineCallbacks('initialize');

            Object.defineProperty(this.prototype, 'componentName', {
                get() {
                    return this.model.componentName;
                },
            });

            Object.defineProperty(this.prototype, 'type', {
                get() {
                    return `${this.componentName}ViewModel`;
                },
            });

            Object.defineProperty(this.prototype, 'frame', {
                get() {
                    return this.model.frame();
                },
            });

            Object.defineProperty(this.prototype, 'playerViewModel', {
                get() {
                    return this.frameViewModel.playerViewModel;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'editorMode', {
                get() {
                    return this.frameViewModel.editorMode;
                },
            });

            return {
                initialize(frameViewModel, model) {
                    this.frameViewModel = frameViewModel;
                    this.model = model;
                    this._registeredEventListeners = {};
                    this.runCallbacks('initialize', () => {});
                },

                toJasminePP() {
                    return `ViewModel for ${this.model.type}: ${this.model.id}`;
                },

                on(event, callback) {
                    return new ComponentEventListener(this, event, callback);
                },

                includesBehavior(behavior) {
                    return this.model.includesBehavior(behavior);
                },

                optionsForBehavior(behavior) {
                    return this.model.optionsForBehavior(behavior);
                },

                viewModelFor(componentModel) {
                    return componentModel ? this.frameViewModel.viewModelFor(componentModel) : undefined;
                },

                viewModelsFor(componentModels) {
                    return componentModels ? this.frameViewModel.viewModelsFor(componentModels) : undefined;
                },

                // this is tested in ComponentEventListener spec
                registerEventListener(eventListener) {
                    let list = this._registeredEventListeners[eventListener.eventName];
                    if (!list) {
                        list = this._registeredEventListeners[eventListener.eventName] = [];
                    }
                    list.push(eventListener);
                },

                // this is tested in ComponentEventListener spec
                deregisterEventListener(eventListener) {
                    let list = this._registeredEventListeners[eventListener.eventName];
                    if (!list) {
                        list = this._registeredEventListeners[eventListener.eventName] = [];
                    }
                    Array.remove(list, eventListener);
                },

                // this is tested in ComponentEventListener spec
                fire() {
                    const eventName = arguments[0];
                    const args = Array.prototype.slice.call(arguments, 1);

                    // a callback might cancel itself, which could cause troubles
                    // as we're looping through the array, so we need to slice in order
                    // to clone it
                    const listeners = _.sortBy(this._registeredEventListenersFor(eventName), 'priority');

                    if (ComponentEventListener.debug) {
                        console.log(
                            'fire ',
                            `${this.type}:${eventName}`,
                            '. Has ',
                            listeners.length,
                            ' listeners: ',
                            listeners,
                        );
                    }
                    listeners.forEach(eventListener => {
                        eventListener.trigger(...args);
                    });
                },

                _registeredEventListenersFor(eventName) {
                    return this._registeredEventListeners[eventName] || [];
                },
            };
        });

        return ComponentViewModel;
    },
]);

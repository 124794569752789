import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*
    MultipleChoiceChallenge inherits from challenge.

    multipleChoiceChallenges each have an answerList, which contains instances of Answer.SelectableAnswer.
    The challenge includes logic for selecting and deselecting answers, and builds it's list of
    userAnswers by checking which of the answers have been
    selected by the user.

    There are also a number of behaviors associated with multipleChoiceChallenge, which can be
    found in the behaviors directory.
*/
angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.MultipleChoiceChallengeModel',
    [
        'Lesson.FrameList.Frame.Componentized.Component.Challenge.ChallengeModel',
        'Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.MultipleChoiceChallengeViewModel',
        'Lesson.FrameList.Frame.Componentized.Component.Answer.SelectableAnswer.SelectableAnswerModel',
        '$injector',
        (ChallengeModel, MultipleChoiceChallengeViewModel, SelectableAnswerModel, $injector) =>
            ChallengeModel.subclass(function () {
                this.alias('ComponentizedFrame.MultipleChoiceChallenge');
                this.extend({
                    ViewModel: MultipleChoiceChallengeViewModel,
                });
                this.setEditorViewModel(
                    'Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.MultipleChoiceChallengeEditorViewModel',
                );

                // answer model: text for right now, maybe wrap in generic UserInput component that has subclass text?

                // reuse none of these, instead create:
                // ImmediateValidationOnKeyUp
                // Supports an incomplete state: green if incomplete (dark green if right, red if wrong)
                [
                    'ClearMessagesOnAnswerSelect',
                    'DisallowMultipleSelect',
                    'FlashIncorrectStyling',
                    'ImmediateValidation',
                    'ReadyToValidateWhenAnswerIsSelected',
                    'ResetAnswersOnActivated',
                    'ShowCorrectStyling',
                    'PlayScalingSoundOnSelected',
                ].forEach(behavior => {
                    this.include(
                        $injector.get(
                            `Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.Behaviors.${behavior}`,
                        ),
                    );
                });

                this.references('answerList').through('answer_list_id');
                this.references('messages').through('message_ids');
                this.key('no_incorrect_answers');
                this.key('selection_mode');

                Object.defineProperty(this.prototype, 'answers', {
                    get() {
                        return this.answerList ? this.answerList.answers : [];
                    },
                });

                // Note: this only returns answers that have been specifically
                // assigned to this challenge.  Other answers with the same text
                // or image may also be acceptable, but will not be included here.
                Object.defineProperty(this.prototype, 'correctAnswer', {
                    get() {
                        const correctAnswers = this.correctAnswers;
                        if (correctAnswers.length > 1) {
                            throw new Error('There is more than one correct answer');
                        }
                        return correctAnswers[0];
                    },
                });

                // Note: this only returns answers that have been specifically
                // assigned to this challenge.  Other answers with the same text
                // or image may also be acceptable, but will not be included here.
                Object.defineProperty(this.prototype, 'correctAnswers', {
                    get() {
                        // retain the same reference so that we don't trigger multiple digests during
                        // update phases for things such as editor components
                        if (!this.$$proxyCorrectAnswers) {
                            this.$$proxyCorrectAnswers = [];
                        }
                        this.$$proxyCorrectAnswers.splice(0, this.$$proxyCorrectAnswers.length);

                        this.validator.expectedAnswerMatchers.forEach(matcher => {
                            this.$$proxyCorrectAnswers.push(matcher.answer);
                        });

                        return this.$$proxyCorrectAnswers;
                    },
                });

                return {
                    // maybe override?
                    isExpectedAnswer(answer) {
                        return this.validator.isExpectedAnswer(answer);
                    },

                    hasMessageFor(answer, event) {
                        return !!this.messageComponentFor(answer, event);
                    },

                    // this is inefficient and called over and over
                    // cache the result and listen for the answer to change
                    // to invalidate the cache. But it doesn't seem to
                    // be a big deal for now.
                    messageComponentFor(answer, event) {
                        if (!answer || !answer.isA || !answer.isA(SelectableAnswerModel)) {
                            throw new Error('messageComponentFor requires a selectable answer');
                        }
                        if (event && typeof event !== 'string') {
                            throw new Error('messageComponentFor requires event to be a string');
                        }

                        const messages = [];

                        this.messages?.forEach(message => {
                            if ((!event || message.event === event) && message.appliesToAnswer(answer)) {
                                messages.push(message);
                            }
                        });

                        if (messages.length > 1) {
                            const e = new Error('Multiple messages match a single answer');

                            // add some stuff in here that will get included in the
                            // log sent by EventLogger
                            e.debug = {
                                messages: messages.map(m => m.asJson()),
                                frame: this.frame && this.frame() && this.frame().asJson && this.frame().asJson(),
                            };
                            throw e;
                        }

                        return messages[0];
                    },
                };
            }),
    ],
);

/* eslint-disable func-names */

angular.module('Iguana').factory('Iguana.Keys', [
    '$injector',
    function () {
        return {
            classMixin: {
                defineSetter(key, setter) {
                    const internalKey = this.internalKeyFor(key);

                    this.setCallback('after', 'copyAttrsOnInitialize', function () {
                        // if this property already exists, copy it to the internal key
                        // eslint-disable-next-line no-prototype-builtins
                        if (this.hasOwnProperty(key)) {
                            this[internalKey] = this[key];
                        }

                        Object.defineProperty(this, key, {
                            get() {
                                return this.readKey(key);
                            },
                            set: setter,
                            enumerable: true,
                            configurable: true, // let developers mess with this if they want to
                        });
                    });
                },

                internalKeyFor(key) {
                    return `$$___${key}`;
                },
            },

            instanceMixin: {
                readKey(key) {
                    const internalKey = this.constructor.internalKeyFor(key);
                    return this[internalKey];
                },

                writeKey(key, val) {
                    const internalKey = this.constructor.internalKeyFor(key);
                    this[internalKey] = val;
                    return val;
                },
            },
        };
    },
]);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import { setupStyleHelpers } from 'AppBranding';
import template from 'Lessons/angularModule/views/lesson/frame_list/frame/componentized/component/layout/text_image_interactive/text_image_interactive.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('cfTextImageInteractive', [
    '$injector',
    $injector => {
        const $rootScope = $injector.get('$rootScope');
        const UiComponentDirHelper = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentDirHelper',
        );
        const DialogModal = $injector.get('DialogModal');

        return UiComponentDirHelper.getOptions({
            templateUrl,

            link(scope, element) {
                UiComponentDirHelper.link(scope, element);
                setupStyleHelpers($injector, scope);

                // Referenced inside of setupBrandNameProperties.
                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                Object.defineProperty(scope, 'currentChallengeViewModel', {
                    get() {
                        return scope.viewModel.viewModelFor(scope.frameViewModel?.frame?.mainUiComponent)
                            ?.currentChallengeViewModel;
                    },
                });

                Object.defineProperty(scope, 'isImageHotspotWithCaption', {
                    get() {
                        return (
                            !!scope.currentChallengeViewModel?.shouldShowCheckManyCaption &&
                            scope.currentChallengeViewModel?.answerListViewModel?.model?.skin === 'image_hotspot'
                        );
                    },
                });

                scope.sections = [];

                ['text', 'first_image', 'second_image', 'interactive', 'interactive_image'].forEach(label => {
                    scope.sections.push({
                        label,
                        viewModels() {
                            const key = `content_for_${label}`.camelize();
                            const componentList = scope.model[key];
                            if (!componentList) {
                                return undefined;
                            }

                            return scope.viewModel.viewModelsFor(componentList.components);
                        },
                    });
                });

                // stop propagation of the click event as well as the mousedown
                // and touchstart events, which are handled by provideHint below
                scope.stopPropagation = evt => {
                    evt.preventDefault();
                    evt.stopImmediatePropagation();
                };

                scope.zoomImage = (imageViewModel, context) => {
                    DialogModal.alert({
                        content: '<zoom-container></zoom-container>',
                        scope: {
                            imageViewModel,
                            context,
                        },
                        title: undefined,
                        close: undefined,
                        size: 'fullscreen',
                        classes: ['zoom'],
                    });
                };
            },
        });
    },
]);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.ChallengeValidator.ChallengeValidatorViewModel',

    [
        'Lesson.FrameList.Frame.Componentized.Component.ComponentViewModel',

        ComponentViewModel =>
            ComponentViewModel.subclass(function () {
                // will be active whenever the associated challenge is active
                Object.defineProperty(this.prototype, 'active', {
                    get() {
                        return this._active;
                    },
                    set(val) {
                        if (val === this._active) {
                            return;
                        }
                        if (val) {
                            this._active = true;
                            this.fire('activated');
                        } else {
                            this._active = false;
                            this.fire('deActivated');
                        }
                    },
                });

                return {
                    initialize($super, frameViewModel, model) {
                        // validations will be set by behaviors.  Currently,
                        // possible validations include HasAllExpectedAnswers and
                        // HasNoUnexpectedAnswers
                        this.validations = [];
                        $super(frameViewModel, model);
                    },

                    // run through all validations and send out any resulting
                    // errors in the validated event
                    validate(info) {
                        const errors = [];
                        this.validations.forEach(validation => {
                            validation.apply(this, [errors, this.challengeViewModel.challengeResponses]);
                        });

                        // Send out the ValidationResult, which has information about
                        // whether the whole challenge was correct or not, and information
                        // about why it was incorrect if it was.
                        const validationResult = new this.challengeViewModel.ValidationResult(
                            this.challengeViewModel,
                            errors,
                            info,
                        );

                        // this has to happen here before any of the events fire,
                        // because this data may be needed in some of those handlers.  For
                        // example, in getScore.
                        this.challengeViewModel.validationResults.push(validationResult);

                        // Important to log before firing validated event, to ensure that
                        // the state does not change before we log it.
                        if (validationResult.shouldLogEvent) {
                            this.frameViewModel.log('lesson:challenge_validation', validationResult.asJson(), {
                                segmentio: false, // no need to log this to segmentio until we need it there
                            });
                        }

                        this.fire('validated', validationResult);
                        return validationResult;
                    },
                };
            }),
    ],
);

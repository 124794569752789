import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.ChallengeOverlayBlank.ChallengeOverlayBlankViewModel',

    [
        'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentViewModel',

        UiComponentViewModel =>
            UiComponentViewModel.subclass(function () {
                ['active', 'complete'].forEach(prop => {
                    Object.defineProperty(this.prototype, prop, {
                        get() {
                            return this.challengeViewModel[prop];
                        },
                    });
                });

                // place the overlays for the firster challenges at higher
                // z-indexes, to that ambiguous clicks on the click
                // targets will activate the earlier ones
                Object.defineProperty(this.prototype, 'overlayZIndex', {
                    get() {
                        return -this.challengeViewModel.index;
                    },
                });

                return {
                    directiveName: 'cf-challenge-overlay-blank',

                    initialize($super, frameViewModel, model) {
                        $super(frameViewModel, model);

                        // stretchToFit will always be true, except in the
                        // editor when the 'Reset Dimensions'
                        // button was just pressed
                        this.stretchToFit = true;
                    },
                };
            }),
    ],
);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*
    Interactive Images is a ui component which inlcudes one or more
    component overlays in a swipable/clickable carousel.
*/
angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.InteractiveCards.InteractiveCardsModel', [
    'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentModel',
    'Lesson.FrameList.Frame.Componentized.Component.InteractiveCards.InteractiveCardsViewModel',
    (UiComponentModel, InteractiveCardsViewModel) =>
        UiComponentModel.subclass(function () {
            this.alias('ComponentizedFrame.InteractiveCards');
            this.extend({
                ViewModel: InteractiveCardsViewModel,
            });
            this.setEditorViewModel(
                'Lesson.FrameList.Frame.Componentized.Component.InteractiveCards.InteractiveCardsEditorViewModel',
            );

            this.references('overlays').through('overlay_ids');
            this.references('challengesComponent').through('challenges_component_id');

            this.key('force_card_height');
            this.key('wide');

            Object.defineProperty(this.prototype, 'allOverlayComponents', {
                get() {
                    let overlayComponents = [];
                    this.overlays.forEach(overlay => {
                        overlayComponents = overlayComponents.concat(overlay.overlayComponents);
                    });
                    return overlayComponents;
                },
            });

            return {
                cardIndexForChallenge(challengeModel) {
                    let index = 0;
                    let counter = 0;
                    this.overlays.forEach(overlay => {
                        overlay.overlayComponents.forEach(blank => {
                            if (blank.challenge === challengeModel) {
                                index = counter;
                            }
                        });
                        counter += 1;
                    });
                    return index;
                },

                cardForChallenge(challengeModel) {
                    const index = this.cardIndexForChallenge(challengeModel);
                    return this.overlays[index];
                },
            };
        }),
]);

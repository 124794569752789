import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import template from 'Lessons/angularModule/views/lesson/frame_list/frame/no_interaction/no_interaction.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('noInteraction', [
    () => ({
        restrict: 'E',
        templateUrl,
        scope: {
            editor: '@',
            frameViewModel: '<',
        },

        link() {},
    }),
]);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*
    A Layout defines some sections of the screen in which UiComponents can be placed.
    The base class actually has no functionality (anymore), but maybe it will if we ever have multiple
    layouts?  Maybe it's nice for code organization even if it doesn't?
*/
angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.Layout.LayoutModel', [
    'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentModel',
    'Lesson.FrameList.Frame.Componentized.Component.Layout.LayoutViewModel',
    (UiComponentModel, LayoutViewModel) =>
        UiComponentModel.subclass(function () {
            this.alias('ComponentizedFrame.Layout');
            this.extend({
                ViewModel: LayoutViewModel,
            });
            this.setEditorViewModel('Lesson.FrameList.Frame.Componentized.Component.Layout.LayoutEditorViewModel');

            return {};
        }),
]);

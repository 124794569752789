import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import template from 'Lessons/angularModule/views/lesson/frame_list/frame/componentized/component/tile_prompt/tile_prompt.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('cfTilePrompt', [
    '$injector',
    $injector => {
        const UiComponentDirHelper = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentDirHelper',
        );
        const ChallengeBlankDirHelper = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.Challenge.ChallengeBlankDirHelper',
        );

        return UiComponentDirHelper.getOptions({
            templateUrl,
            link(scope) {
                UiComponentDirHelper.link(scope);

                // it's a little odd that we're including something called ChallengeBlankDirHelper
                // here, but it has useful stuff for us, and I didn't feel like renaming it
                ChallengeBlankDirHelper.link(scope);

                Object.defineProperty(scope, 'cssClasses', {
                    get() {
                        const classes = [];
                        classes.push(scope.model.contentType);

                        if (scope.frameViewModel.editorMode) {
                            classes.push('editable');
                        }

                        // challengeBlankCssClasses comes from ChallengeBlankDirHelper
                        return classes.concat(this.challengeBlankCssClasses);
                    },
                });
            },
        });
    },
]);

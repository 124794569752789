/* eslint-disable jsx-a11y/alt-text */
import { type PropsWithoutRef, useState } from 'react';

type Props = {
    fallbackSrc?: string;
} & Omit<PropsWithoutRef<JSX.IntrinsicElements['img']>, 'onError'>;
export const ImageWithFallback = ({ fallbackSrc, src: _src, ...rest }: Props) => {
    const [src, setSrc] = useState(_src);
    const [attempt, setAttempt] = useState(0);

    return (
        <img
            src={src}
            {...rest}
            onError={() => {
                if (attempt <= 2) {
                    setAttempt(v => v + 1);
                    setSrc(fallbackSrc || _src);
                }
            }}
        />
    );
};

import angularModule from '../../../../../../lessons_module';

angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.Challenges.ChallengesViewModel', [
    '$injector',

    $injector => {
        const UiComponentViewModel = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentViewModel',
        );
        const ComponentEventListener = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.ComponentEventListener',
        );
        const $timeout = $injector.get('$timeout');

        return UiComponentViewModel.subclass(function () {
            Object.defineProperty(
                this.prototype,
                'currentChallenge',
                {
                    get() {
                        // if the currentChallenge has been removed from the frame, then
                        // it is no longer the current challenge
                        if (this._currentChallenge && this._currentChallenge.frame() !== this.frame) {
                            this._currentChallenge = undefined;
                            this.currentChallenge = this.model.challenges && this.model.challenges[0];
                        }

                        return this._currentChallenge;
                    },
                    set(val) {
                        if (this._currentChallenge === val) {
                            return;
                        }
                        if (this._currentChallenge) {
                            this.currentChallengeViewModel.active = false;
                        }
                        this._currentChallenge = val;
                        if (val) {
                            this.currentChallengeViewModel.active = true;
                        }
                    },
                },
                true,
            );

            Object.defineProperty(
                this.prototype,
                'currentChallengeViewModel',
                {
                    get() {
                        return this.currentChallenge && this.viewModelFor(this.currentChallenge);
                    },
                },
                true,
            );

            Object.defineProperty(this.prototype, 'nextChallengeViewModel', {
                get() {
                    return this.viewModelFor(this._nextIncompleteChallenge(this.currentChallengeIndex + 1));
                },
            });

            Object.defineProperty(
                this.prototype,
                'currentChallengeIndex',
                {
                    get() {
                        return this.currentChallenge
                            ? this.orderedChallengeViewModels.indexOf(this.currentChallengeViewModel)
                            : -1;
                    },
                },
                true,
            );

            // When we're not using RandomizeChallengeOrder, this will return the same as currentChallengeIndex.
            // When challenges are randomized, this returns the index of the challenge in the model's unrandomized
            // list.
            Object.defineProperty(
                this.prototype,
                'indexOfCurrentChallengeInModel',
                {
                    get() {
                        return this.currentChallenge
                            ? this.challengesViewModels.indexOf(this.currentChallengeViewModel)
                            : -1;
                    },
                },
                true,
            );

            Object.defineProperty(
                this.prototype,
                'nextChallengeIndex',
                {
                    get() {
                        return this.nextChallengeViewModel
                            ? this.orderedChallengeViewModels.indexOf(this.nextChallengeViewModel)
                            : -1;
                    },
                },
                true,
            );

            Object.defineProperty(this.prototype, 'allChallengesComplete', {
                get() {
                    let allComplete = true;
                    this.viewModelsFor(this.model.challenges).forEach(challengeViewModel => {
                        if (!challengeViewModel.complete) {
                            allComplete = false;
                        }
                    });
                    return allComplete;
                },
            });

            Object.defineProperty(this.prototype, 'orderedChallengeViewModels', {
                get() {
                    return this._orderedChallengeViewModels || this.challengesViewModels;
                },
                set(val) {
                    this._orderedChallengeViewModels = val;
                    this._resetCurrentChallenge();
                    this._setChallengeIndexes();
                },
            });

            // for each content section, there can either be
            // a single component set on the challenges model which
            // will be shared between all of the included challenge models,
            // or the content can be set on each specific challenge, in which
            // case we return the content for the current challenge.
            ['Text', 'FirstImage', 'SecondImage', 'Interactive', 'InteractiveImage'].forEach(prop => {
                // prepend common string
                prop = `contentFor${prop}`;

                // i.e. sharedContentForText
                const sharedProp = `shared_${prop}`.camelize();

                Object.defineProperty(this.prototype, `${prop}ViewModel`, {
                    get() {
                        // if sharedContentForText, etc. is defined, return it's
                        // view model
                        if (this.model[sharedProp]) {
                            return this[`${sharedProp}ViewModel`];
                        }

                        // otherwise, delegate to the current challenge
                        if (this.currentChallengeViewModel) {
                            return this.currentChallengeViewModel[`${prop}ViewModel`];
                        }

                        return undefined;
                    },
                });
            });

            /** *********** State stuff for continue button **************** */

            Object.defineProperty(this.prototype, 'readyToValidate', {
                get() {
                    return this.currentChallengeViewModel && this.currentChallengeViewModel.readyToValidate;
                },
            });

            Object.defineProperty(this.prototype, 'waitingForAnswer', {
                get() {
                    return !this.readyToValidate && !this.complete;
                },
            });

            Object.defineProperty(this.prototype, 'complete', {
                get() {
                    return this._complete;
                },
                set(val) {
                    if (val === this._complete) {
                        return;
                    }
                    if (val) {
                        this._complete = true;
                        this.fire('completed');
                    } else {
                        throw new Error('"complete" cannot be set back to false once it is true.');
                    }
                },
            });

            Object.defineProperty(this.prototype, 'hasInvalidAnswer', {
                get() {
                    return this.currentChallengeViewModel && this.currentChallengeViewModel.showingIncorrectStyling;
                },
            });

            Object.defineProperty(this.prototype, 'allowSkip', {
                get() {
                    return this.frame.isPractice;
                },
            });

            return {
                directiveName: 'cf-challenges',

                initialize($super, frameViewModel, model) {
                    $super(frameViewModel, model);
                    this._setChallengeIndexes();

                    this._complete = false;
                    this._listenForChallengesActivated();
                    this._listenForAllChallengesCompleted();
                },

                validate() {
                    this.currentChallengeViewModel.validate();
                },

                gotoFirstIncompleteChallenge() {
                    this.currentChallenge = undefined;
                    return this._gotoNextIncompleteChallenge(0);
                },

                gotoNextChallenge() {
                    return this._gotoNextIncompleteChallenge(this.currentChallengeIndex + 1);
                },

                _setChallengeIndexes() {
                    let list = this.orderedChallengeViewModels;

                    // this is just because we have tests that mock out the randomized list
                    // to be something other than an array.  In the wild, this won't happen
                    if (list && !_.isArray(list)) {
                        list = [];
                    }
                    list.forEach((challengeViewModel, i) => {
                        challengeViewModel.index = i;
                    });
                },

                _resetCurrentChallenge() {
                    if (this.hasIntroContent) {
                        this.currentChallenge = undefined;
                    } else {
                        this.gotoFirstIncompleteChallenge();
                    }
                },

                _nextIncompleteChallenge(startingIndex) {
                    let i = startingIndex;
                    let startedLooking = false;
                    let nextChallenge;

                    // loop around until you find another incomplete challenge or
                    // make it back to the current one
                    while (!nextChallenge && (!startedLooking || i !== startingIndex)) {
                        startedLooking = true;
                        const challengeViewModel = this.orderedChallengeViewModels[i];

                        if (!challengeViewModel) {
                            i = 0;
                        } else if (!challengeViewModel.complete) {
                            nextChallenge = challengeViewModel.model;
                        } else {
                            i += 1;
                        }
                    }

                    return nextChallenge;
                },

                _gotoNextIncompleteChallenge(startingIndex) {
                    const nextChallenge = this._nextIncompleteChallenge(startingIndex);
                    if (nextChallenge) {
                        this.currentChallenge = nextChallenge;
                    }
                },

                _listenForAllChallengesCompleted() {
                    this.challengesViewModels.forEach(challengeViewModel => {
                        // eslint-disable-next-line no-new
                        new ComponentEventListener(challengeViewModel, 'completed', () => {
                            if (this.allChallengesComplete) {
                                this.fire('allChallengesComplete');
                            }
                        });
                    });
                },

                /*
                        any challenge might be activated from some action outside of this component,
                        like by pressing the blank for that challenge.  Whenever a challenge is set
                        to "active", it should become the currentChallenge.
                    */
                _listenForChallengesActivated() {
                    const challengeActivatedListeners = {};

                    // listen for challenges to be activated
                    this.model.challenges.on('childAdded', challenge => {
                        const challengeViewModel = this.viewModelFor(challenge);

                        if (challengeActivatedListeners[challenge.id]) {
                            // this shouldn't happen, but we
                            $injector
                                .get('ErrorLogService')
                                .notify(
                                    `Challenge (id=${challenge.id}) has already been added to model with an existing activated listener.`,
                                );
                            return;
                        }

                        challengeActivatedListeners[challenge.id] = new ComponentEventListener(
                            challengeViewModel,
                            'activated',
                            () => {
                                this.currentChallenge = challengeViewModel.model;
                            },
                        );
                    });

                    // if a new challenge is added in the editor, make it active.
                    // this listener must be set up after the activated listener
                    // above, so that we will be listening for this challenge
                    // to be activated when it is set to active.
                    // See the comment in text_spec.js 'should add a bunch of new blanks in different places'
                    // for why this needs a timeout
                    this.model.challenges.on(
                        'childAdded',
                        challenge => {
                            const challengeViewModel = this.viewModelFor(challenge);
                            $timeout().then(() => {
                                challengeViewModel.active = true;
                            });
                        },

                        // only apply this to newly added challenges; do not
                        // apply it to existing challenges right away
                        false,
                    );

                    this.model.challenges.on('childRemoved', challenge => {
                        const listener = challengeActivatedListeners[challenge.id];
                        if (listener) {
                            listener.cancel();
                            delete challengeActivatedListeners[challenge.id];
                        }
                    });

                    this._resetCurrentChallenge();
                },
            };
        });
    },
]);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.TilePromptBoard.TilePromptBoardViewModel',

    [
        'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentViewModel',

        UiComponentViewModel =>
            UiComponentViewModel.subclass(function () {
                const _orderedPrompts = [];

                // returns a list of tile prompt view models that correspond to the order of (possibly) randomized challenges
                Object.defineProperty(this.prototype, 'orderedTilePromptsViewModels', {
                    get() {
                        _orderedPrompts.length = 0; // ES5+ efficient clearing of array

                        // loop through ordered challenges
                        for (const challengeViewModel of this.challengesComponentViewModel.orderedChallengeViewModels) {
                            // search and break on finding the corresponding prompt
                            for (const tilePromptViewModel of this.tilePromptsViewModels) {
                                if (tilePromptViewModel.challengeViewModel.model.id === challengeViewModel.model.id) {
                                    _orderedPrompts.push(tilePromptViewModel);
                                    break;
                                }
                            }
                        }

                        return _orderedPrompts;
                    },
                });

                return {
                    initialize($super, frameViewModel, model) {
                        $super(frameViewModel, model);
                        frameViewModel.bodyBackgroundColor = 'purple';
                    },

                    directiveName: 'cf-tile-prompt-board',
                };
            }),
    ],
);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import 'ExtensionMethods/array';

angularModule.factory('Lesson.FrameList.Frame.NoInteraction', [
    '$injector',
    $injector => {
        const ComponentizedBase = $injector.get('Lesson.FrameList.Frame.ComponentizedBase');
        const NoInteractionViewModel = $injector.get(
            'Lesson.FrameList.Frame.NoInteraction.NoInteractionFrameViewModel',
        );
        const TextModel = $injector.get('Lesson.FrameList.Frame.Componentized.Component.Text.TextModel');
        const ImageModel = $injector.get('Lesson.FrameList.Frame.Componentized.Component.Image.ImageModel');
        const TranslationHelper = $injector.get('TranslationHelper');

        const translationHelper = new TranslationHelper('lessons.lesson.frame_list.frame.componentized');

        return ComponentizedBase.subclass(function () {
            this.alias('no_interaction');

            this.supportsComponent('mainTextComponent', TextModel);
            this.supportsComponent('topContextImage', ImageModel);
            this.supportsComponent('bottomContextImage', ImageModel);

            this.extend({
                title: 'NoInteraction',
                FrameViewModel: NoInteractionViewModel,
            });

            Object.defineProperty(this.prototype, 'mainImage', {
                get() {
                    return this.topContextImage || this.bottomContextImage;
                },
                set(val) {
                    this.topContextImage = val;
                },
            });

            Object.defineProperty(this.prototype, 'miniInstructions', {
                get() {
                    return translationHelper.get('no_interaction');
                },
            });

            return {
                directiveName: 'no-interaction',
                // buttonDirectiveName: 'componentized-continue-button',
                infoPanelDirectiveName: 'no-interaction-info-panel',

                setup() {
                    this.mainTextComponent = TextModel.EditorViewModel.addComponentTo(this).setup().model;
                },

                generateTutorBotDescription() {
                    // FIXME
                    return {};
                },
            };
        });
    },
]);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.ContinueButton.ContinueButtonViewModel',

    [
        '$injector',
        $injector => {
            const UiComponentViewModel = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentViewModel',
            );

            return UiComponentViewModel.subclass(() => ({
                onPracticeModeContinueButtonClick(helpful) {
                    this.frameViewModel.log('lesson:frame:practice_mode_continue_click', {
                        helpful,
                    });
                    this.frameViewModel.gotoNextFrame();
                },
            }));
        },
    ],
);

import { InstitutionId } from './Institutions.types';

// FIXME: Miya Miya is NOT technically an internal institution, but really it should be.
// It was changed by an admin to be external for some reason. Perhaps to piggy back off
// of some unintended behavior. See also https://trello.com/c/yCBL3ynb.
export const INTERNAL_INSTITUTION_IDS = [
    InstitutionId.quantic,
    InstitutionId.valar,
    InstitutionId.smartly,
    InstitutionId.miya_miya,
];

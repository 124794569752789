import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*
    a Text is a UiComponent that will be rendered as a <div> tag when included on the screen

    Example:
    {
	    "id": "63cca272-f8bf-4cee-8f40-b92a5296a270",
	    "component_type": "ComponentizedFrame.Text",
	    "text": "Here is the main text"
	},
*/
angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.Text.TextModel', [
    '$injector',

    $injector => {
        const UiComponentModel = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentModel',
        );
        const TextViewModel = $injector.get('Lesson.FrameList.Frame.Componentized.Component.Text.TextViewModel');

        function normalizeText(text) {
            if (!text) {
                return text;
            }
            return text.replace(/[‘’]/g, "'").replace(/[“”]/g, '"');
        }

        return UiComponentModel.subclass(function () {
            this.alias('ComponentizedFrame.Text');
            this.extend({
                ViewModel: TextViewModel,
            });
            this.setEditorViewModel('Lesson.FrameList.Frame.Componentized.Component.Text.TextEditorViewModel');

            [
                'ProcessesMarkdown',
                'ProcessesModals',
                'ProcessesInlineImages',
                'ProcessesMathjax',
                'ProcessesChallengeBlanks',
                'ProcessesStorableImages',
            ].forEach(behavior => {
                // WTF: calling injector dependency within include() call fails for some inexplicable reason
                const behaviorDI = $injector.get(
                    `Lesson.FrameList.Frame.Componentized.Component.Text.Behaviors.${behavior}`,
                );
                this.include(behaviorDI);
            });

            // define the 'text' key so that we can listen for
            // changes to it (ProcessesModals, for example, needs to do this)
            this.key('text', normalizeText);
            this.key('formatted_text');

            this.key('fontSize');

            // Note: if alignment is not set explicitly, the alignment of the text in the UI will be dependent on the context
            // of the text component.  Usually the default is left but on image hotspot frames, for example, the default
            // is center.  This is defined in css.
            this.key('alignment');

            // used by ProcessesModals
            this.references('modals').through('modal_ids');

            // used by ProcessesChallengeBlanks
            this.references('challengesComponent').through('challenges_id');

            Object.defineProperty(this.prototype, 'hasModals', {
                get() {
                    return this.modals && this.modals.length > 0;
                },
            });

            return {
                initialize($super, attrs) {
                    $super(attrs);
                },
            };
        });
    },
]);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.directive('cfChallenges', [
    'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentDirHelper',

    UiComponentDirHelper =>
        UiComponentDirHelper.getOptions({
            template: '<div><cf-ui-component view-model="viewModel.layoutViewModel" ></cf-ui-component></div>',
        }),
]);

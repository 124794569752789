import angularModule from 'Lessons/angularModule/scripts/lessons_module';
/*
    When this behavior is on, any time an answer is selected it will play one of the scaling sounds
*/
angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.Behaviors.PlayScalingSoundOnSelected',
    [
        '$injector',
        $injector => {
            const AModuleAbove = $injector.get('AModuleAbove');
            const ComponentEventListener = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.ComponentEventListener',
            );
            const SoundManager = $injector.get('SoundManager');
            const SoundConfig = $injector.get('SoundConfig');

            return new AModuleAbove({
                included(MultipleChoiceChallengeModel) {
                    MultipleChoiceChallengeModel.supportBehavior('PlayScalingSoundOnSelected');

                    MultipleChoiceChallengeModel.ViewModel.setCallback('after', 'initialize', function () {
                        const challengeViewModel = this;
                        let listeners;

                        function clearListeners() {
                            if (listeners) {
                                listeners.forEach(listener => {
                                    listener.cancel();
                                });
                                listeners = undefined;
                            }
                        }

                        let answerListListener;

                        this.model.on('behavior_removed:PlayScalingSoundOnSelected', () => {
                            clearListeners();
                            if (answerListListener) {
                                answerListListener.cancel();
                                answerListListener = undefined;
                            }
                        });

                        this.model.on('behavior_added:PlayScalingSoundOnSelected', () => {
                            function onAnswerListSet() {
                                const answerListViewModel = challengeViewModel.answerListViewModel;
                                const answerList = answerListViewModel.model;

                                clearListeners();

                                if (answerList) {
                                    listeners = [];
                                    const childAddedListener = answerList.answers.on('childAdded', answer => {
                                        const answerViewModel = challengeViewModel.viewModelFor(answer);

                                        // if the user can toggle their selection in a multiple select situation, play the sound as well
                                        const supportedEvents = challengeViewModel.model.behaviors
                                            .DisallowMultipleSelect
                                            ? ['selected']
                                            : ['selected', 'unselected'];
                                        supportedEvents.forEach(eventType => {
                                            const selectedListener = new ComponentEventListener(
                                                answerViewModel,
                                                eventType,
                                                () => {
                                                    const answerIndex =
                                                        answerListViewModel.orderedAnswerViewModels.indexOf(
                                                            answerViewModel,
                                                        ) % SoundConfig.SCALING_CLICKS.length;
                                                    const soundToPlay = SoundConfig.SCALING_CLICKS[answerIndex];

                                                    SoundManager.playUrl(soundToPlay);
                                                },
                                            );

                                            listeners.push(selectedListener);
                                        });
                                    });

                                    listeners.push(childAddedListener);
                                }
                            }

                            // whenever an answer list is added, watch for answers to be
                            // added to it, and then listen for each answer to be selected
                            answerListListener = challengeViewModel.model.on('set:answerList', onAnswerListSet, true);
                        });
                    });
                },
            });
        },
    ],
);

export default angular.module('imageFadeInOnLoad', []).directive('imageFadeInOnLoad', () => ({
    restrict: 'A',

    link(scope, element) {
        element.css({
            visibility: 'hidden',
        });
        element.one('load', () => {
            element.css({
                visibility: 'visible',
            });
            element.addClass('fade-in');
        });
    },
}));

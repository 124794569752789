/* eslint-disable no-prototype-builtins */
import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Layout.TextImageInteractive.TextImageInteractiveViewModel',

    [
        'Lesson.FrameList.Frame.Componentized.Component.Layout.LayoutViewModel',
        '$rootScope',

        (LayoutViewModel, $rootScope) =>
            LayoutViewModel.subclass(function () {
                const sections = ['Text', 'FirstImage', 'SecondImage', 'Interactive', 'InteractiveImage'];

                sections.forEach(section => {
                    const prop = `contentFor${section}ViewModel`;
                    Object.defineProperty(this.prototype, prop, {
                        get() {
                            // FIXME: I seemed to save 5-16ms per digest by caching this, but then
                            // realized it can't be cached because it changes. Any way we can speed it up?
                            const staticComponent = this.model[`staticContentFor${section}`];
                            if (staticComponent) {
                                return this.viewModelFor(staticComponent);
                            }
                            if (this.targetViewModel) {
                                return this.targetViewModel[prop];
                            }

                            return undefined;
                        },
                    });
                });

                Object.defineProperty(this.prototype, 'firstContextImageSize', {
                    get() {
                        const key = 'context_image_size';
                        if (this.model.staticContentForFirstImage) {
                            return this.model[key];
                        }
                        if (this.targetViewModel && this.targetViewModel.model.hasOwnProperty(key)) {
                            return this.targetViewModel.model[key];
                        }
                        return undefined;
                    },
                });

                Object.defineProperty(this.prototype, 'secondContextImageSize', {
                    get() {
                        const key = 'context_image_2_size';
                        if (this.model.staticContentForSecondImage) {
                            return this.model[key];
                        }
                        if (this.targetViewModel && this.targetViewModel.model.hasOwnProperty(key)) {
                            return this.targetViewModel.model[key];
                        }
                        return undefined;
                    },
                });

                Object.defineProperty(this.prototype, 'contextForFirstImage', {
                    get() {
                        return this.model.getImageContext(this.firstContextImageSize);
                    },
                });

                Object.defineProperty(this.prototype, 'contextForSecondImage', {
                    get() {
                        return this.model.getImageContext(this.secondContextImageSize);
                    },
                });

                Object.defineProperty(this.prototype, 'textOnly', {
                    get() {
                        // eslint-disable-next-line no-restricted-syntax
                        for (const section of sections) {
                            const prop = `contentFor${section}ViewModel`;
                            const sectionViewModel = this[prop];
                            if (section === 'Text' && !sectionViewModel) {
                                return false;
                            }
                            if (section !== 'Text' && sectionViewModel) {
                                return false;
                            }
                        }

                        return true;
                    },
                });

                // When this is the mainUiComponent. it is automatically complete.
                Object.defineProperty(this.prototype, 'complete', {
                    get() {
                        return true;
                    },
                });

                return {
                    directiveName: 'cf-text-image-interactive',

                    initialize($super, frameViewModel, model) {
                        $super(frameViewModel, model);

                        // Since this is used as a mainUiComponent, it needs to fire completed.
                        // Since there is no interaction, it is completed right away.
                        $rootScope.$evalAsync(() => {
                            this.fire('completed');
                        });
                    },
                };
            }),
    ],
);

import angularModule from 'Lessons/angularModule/scripts/lessons_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Answer.SelectableAnswer.SelectableAnswerNavigatorViewModel',

    [
        'Lesson.FrameList.Frame.Componentized.Component.Answer.AnswerViewModel',
        'Lesson.FrameList.Frame.Componentized.Component.ComponentEventListener',

        (AnswerViewModel, ComponentEventListener) =>
            AnswerViewModel.subclass(() => ({
                initialize($super, frameViewModel, model) {
                    $super(frameViewModel, model);
                    this._createListeners();
                },

                _createListeners() {
                    if (this.model.event === 'gotoLastSelectedWhenMovingOnFromFrame') {
                        this._listenForSelected();
                    }
                },

                _listenForSelected() {
                    // look for any selected answer the matches
                    // the answerMatcher and then listen for the selected
                    // event on it
                    //
                    // Theoretically, we should cancel this listener if the
                    // model is removed from the frame.  Practically this isn't
                    // an issue because this thing only makes a difference
                    // in the player (and preview mode), and in those cases no
                    // changes can happen after the viewModel is initialized.
                    this.model.challenge.answers.forEach(answer => {
                        if (this.model.appliesToAnswer(answer)) {
                            const answerViewModel = this.viewModelFor(answer);
                            new ComponentEventListener(answerViewModel, 'selected', () => {
                                // if the answer is selected as part of a different
                                // challenge, don't do anything
                                if (this.challengeViewModel.active) {
                                    this.frameNavigatorViewModel.nextFrameIdOverride = this.model.next_frame_id;
                                }
                            });
                        }
                    });
                },
            })),
    ],
);
